import React from 'react';

import { useParams } from 'react-router';

//Components
import NavbarOrganization from '../features/navbars/NavbarOrganization';
import { Phase2Form } from '../features/phase2/Phase2Form';

//Formfy
import { usePhase2RegistryState } from '../formfy/API/usePhase2RegistryState';
import { usePhase2FormFields } from '../formfy/API/usePhase2FormFields';

//Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(8, 0, 4) /* top | horizontal | bottom */,
        },
        paper: {
            padding: theme.spacing(2, 2, 2) /* top | horizontal | bottom */,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            paddingTop: theme.spacing(2),
        },
    })
);

type Params = {
    phase1Id: string;
    orgId: string;
};

export default function CreateNewPhase2() {
    const classes = useStyles();

    const params: Params = useParams();

    const phase2RegistryState = usePhase2RegistryState();

    const { phase2FormFields } = usePhase2FormFields(params.phase1Id);

    return (
        <React.Fragment>
            <CssBaseline />
            <NavbarOrganization />

            <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.root}
            >
                <Grid item xs={10} sm={8} md={6} lg={5} xl={4}>
                    <div>
                        <Phase2Form
                            phase2RegistryState={phase2RegistryState}
                            phase2FormFields={phase2FormFields}
                        />
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
