import { db } from '../firebase';

export const createCrossoverTemporaryUser = (orgId: string, userId: string) => {
    console.log('to aqui');
    return db
        .collection('organizations')
        .doc(orgId)
        .collection('users')
        .doc(userId)
        .set({ organizationAccessLevel: 'Temp' })
        .then(() => {
            console.log('createCrossoverTemporaryUser correctly createdd');
        });
};
