import { ProcessWatcherProps } from './TiffUploadProcessWatcher';
//import { Upload } from '@aws-sdk/lib-storage';
import { useState } from 'react';
//import { S3Client, S3 } from '@aws-sdk/client-s3';

//const target = { Bucket: 'b', Key: 'k', Body: 'b' };

export const useWatchers = () => {
    const [watchers, setWatchers] = useState<Array<ProcessWatcherProps>>([
        // {
        //   s3Upload: new Upload({
        //     client: new S3({}) || new S3Client({}),
        //     params: target,
        //   }),
        //   tilesetDocId: 'docId',
        //   //@ts-ignore
        //   file: new File(['foo'], 'foo.txt', {
        //     type: 'text/plain',
        //   }),
        // },
    ]);

    const addWatcher = (watcher: ProcessWatcherProps) => {
        setWatchers([...watchers, watcher]);
    };

    const removeWatcher = (watcher: ProcessWatcherProps) => {
        setWatchers(
            watchers.filter((w) => w.tilesetDocId !== watcher.tilesetDocId)
        );
    };

    return { addWatcher, removeWatcher, watchers };
};
