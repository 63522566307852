import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { FieldblocksFormManagePage } from '../pages/FieldblocksFormManagePage';
import { Phase1FormManagePage } from '../pages/Phase1FormManagePage';
import { Phase2FormManagePage } from '../pages/Phase2FormManagePage';

export const FFAppRouter = () => {
    return (
        <Switch>
            <Route
                path="/organizationId/:orgId/formfy/phase1FormManage"
                component={Phase1FormManagePage}
                exact
            />
            <Route
                path="/organizationId/:orgId/formfy/phase2FormManage/"
                component={Phase2FormManagePage}
                exact
            />
            <Route
                path="/organizationId/:orgId/formfy/fieldblocksFormManage/"
                component={FieldblocksFormManagePage}
                exact
            />
        </Switch>
    );
};
