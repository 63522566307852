import React, { FunctionComponent, useEffect } from 'react';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    makeStyles,
    CardActions,
    Box,
} from '@material-ui/core';
import { FormField, FormFieldTypes } from '../../formfy/types';
import { RenderFormField } from '../ui/RenderFormField';
import { useHistory, useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    marginTop: {
        marginTop: theme.spacing(2),
    },
}));

type Props = {
    phase2RegistryState: any;
    phase2FormFields: FormField[] | 'not_initialized';
};

type Params = {
    orgId: string;
    phase1Id: string;
};

export const Phase2Form: FunctionComponent<Props> = ({
    phase2RegistryState,
    phase2FormFields,
}) => {
    const history = useHistory();

    const { orgId, phase1Id }: Params = useParams();

    const classes = useStyles();

    const start2 = () => {
        phase2RegistryState.start(undefined);
    };

    const finish2 = () => {
        phase2RegistryState.finish();
    };

    const handleChange2 = (fieldId: string, value: FormFieldTypes) => {
        phase2RegistryState.handleChange(fieldId, value);
    };

    const onSaveClick2 = () => {
        phase2RegistryState.save().then((phase2DocId: string | undefined) => {
            console.log('young');
            console.log(phase2DocId);

            if (phase2DocId) {
                history.push(
                    '/organizationId/' +
                        orgId +
                        '/phase1Id/' +
                        phase1Id +
                        '/phase2Id/' +
                        phase2DocId
                );
            }
        });
        // close();
    };

    useEffect(() => {
        if (phase2RegistryState.ableToStart) {
            start2();
        }

        return () => {
            finish2();
        };
    }, [phase2RegistryState.ableToStart]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card className={classes.marginTop}>
            <CardHeader
                title={
                    <FormattedMessage
                        id="app.phase2FormTitle"
                        defaultMessage="Nova Área"
                    />
                }
                subheaderTypographyProps={{ variant: 'subtitle2' }}
                subheader={
                    <FormattedMessage
                        id="app.phase2FormSubtitle"
                        defaultMessage="Crie áreas para que possa adicionar mapas, anotações, links e facilmente compartilhar informações"
                    />
                }
            ></CardHeader>
            <CardContent>
                {phase2RegistryState.state !== null &&
                    phase2FormFields !== 'not_initialized' &&
                    phase2FormFields.map((field: FormField) => {
                        if (field.fieldId === 'createdAt') {
                            return; //eslint-disable-line
                        }

                        if (phase2RegistryState.state !== null) {
                            const stateField =
                                phase2RegistryState.state[field.fieldId];
                            // const value = stateField.value;

                            const onChange = (value: FormFieldTypes) =>
                                handleChange2(field.fieldId, value);

                            return (
                                <Box paddingTop={1} key={field.fieldId}>
                                    <RenderFormField
                                        stateField={stateField}
                                        formField={field}
                                        onChange={onChange}
                                    />
                                </Box>
                            );
                        } else {
                            return <></>;
                        }
                    })}
            </CardContent>
            <CardActions>
                <Button
                    onClick={onSaveClick2}
                    variant="contained"
                    color="primary"
                    size="medium"
                >
                    <FormattedMessage
                        id="app.createNewArea"
                        defaultMessage="Criar nova área"
                    />
                </Button>
                <Button
                    component={RouterLink}
                    to={`/organizationId/${orgId}/phase1Id/${phase1Id}`}
                    variant="outlined"
                    color="primary"
                    size="medium"
                >
                    <FormattedMessage
                        id="app.cancel"
                        defaultMessage="Cancelar"
                    />
                </Button>
            </CardActions>
        </Card>
    );
};
