import React, { FunctionComponent } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import DatePicker from '../ui/DatePicker';
import { FormattedMessage } from 'react-intl';

type Props = {
    file: File;
    onFileChange: (event: any) => void;
    photoDate: Date;
    setPhotoDate: (date: Date) => void;
};

export const AddPhotoStep0: FunctionComponent<Props> = ({
    file,
    onFileChange,
    photoDate,
    setPhotoDate,
}) => {
    return (
        <>
            <Box>
                <Typography variant="body2" gutterBottom>
                    <strong>{file && file.name}</strong>
                </Typography>

                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={onFileChange}
                />
                <label htmlFor="raised-button-file">
                    <Button
                        variant="contained"
                        component="span"
                        color="primary"
                        fullWidth
                    >
                        {file ? (
                            <FormattedMessage
                                id="app.change"
                                defaultMessage="Alterar"
                            />
                        ) : (
                            <FormattedMessage
                                id="app.chooseFile"
                                defaultMessage="Escolher arquivo"
                            />
                        )}
                    </Button>
                </label>
            </Box>
            <DatePicker
                label={<FormattedMessage id="app.date" defaultMessage="Data" />}
                selectedDate={photoDate}
                setSelectedDate={setPhotoDate}
                pickerId={'linksDatePicker'}
            />
        </>
    );
};
