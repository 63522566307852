import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { FormField, FormFieldTypes } from '../../formfy/types';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: theme.spacing(0.3),
        marginTop: theme.spacing(0.3),
        minWidth: '100%',
        // maxWidth: '100%',
    },
    wordBreak: { whiteSpace: 'unset', wordBreak: 'break-all' },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

type Props = {
    field: FormField;
    value: FormFieldTypes | null;
    onChange: (value: FormFieldTypes) => void;
};

export const ListSelectionFormField: FunctionComponent<Props> = ({
    field,
    value,
    onChange,
}) => {
    const classes = useStyles();
    const [selected, setSelected] = useState<string>(
        typeof value === 'string' ? value : ''
    );

    // const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const handleChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
        child: React.ReactNode
    ) => {
        setSelected(
            typeof event.target.value === 'string' ? event.target.value : ''
        );
        onChange(
            typeof event.target.value === 'string' ? event.target.value : ''
        );
    };

    return (
        <TextField
            label={field.name}
            value={selected}
            //@ts-ignore
            onChange={handleChange}
            select
            variant="outlined"
            fullWidth
        >
            {field.selectItems &&
                field.selectItems.map((fieldType) => (
                    <MenuItem
                        value={fieldType}
                        key={fieldType}
                        classes={{ root: classes.wordBreak }}
                    >
                        {fieldType}
                    </MenuItem>
                ))}
        </TextField>
    );
};
