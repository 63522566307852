import { PhotoData } from '../../types/photoData';
import { db } from '../firebase';

export const uploadPhotoDocument = (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    photoData: Omit<PhotoData, 'photoDocId'>
) => {
    return db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('photos')
        .add(photoData)
        .then((doc) => console.log('Photo correctly uploaded'));
};
