import React from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
    Grid,
    Avatar,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { SoilAnalysisLinkData } from '../../types/link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import excelLogo from 'assets/excel-logo.png';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green, pink, red } from '@material-ui/core/colors';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

type Props = {
    linkData: SoilAnalysisLinkData;
    type: 'SideBar' | 'Page';
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        pink: {
            color: theme.palette.getContrastText(pink[500]),
            backgroundColor: pink[500],
        },
        green: {
            color: '#fff',
            backgroundColor: green[500],
        },
        red: {
            color: '#fff',
            backgroundColor: red[900],
        },
    })
);

const LinkCard: React.FunctionComponent<Props> = ({ linkData }) => {
    const classes = useStyles();

    const [fileType, setfileType] = React.useState<
        'pdf' | 'spreadsheets' | null
    >(null);
    React.useEffect(() => {
        const name = linkData.name.toLowerCase();
        const url = linkData.link.toLowerCase();

        if (name.includes('pdf') || url.includes('pdf')) {
            setfileType('pdf');
        }
        if (name.includes('spreadsheets') || url.includes('spreadsheets')) {
            setfileType('spreadsheets');
        }
    }, [linkData.name, linkData.link]);

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={10}>
                        <Typography variant="body1">
                            <strong> {`${linkData.name}`}</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        {fileType === 'pdf' && (
                            <Avatar className={classes.red}>
                                <PictureAsPdfIcon />
                            </Avatar>
                        )}
                        {fileType === 'spreadsheets' && (
                            <Avatar src={excelLogo} />
                        )}
                    </Grid>
                </Grid>

                {/* <div>
                    Link: <strong> {`${linkData.link}`}</strong>
                </div> */}
                {/* <div>
                    Date: <strong> {`${linkData.createdAt.toString()}`}</strong>
                </div> */}
            </CardContent>
            <CardActions disableSpacing>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.open(linkData.link, '_blank')}
                    fullWidth
                    endIcon={<OpenInNewIcon />}
                >
                    <FormattedMessage id="app.open" defaultMessage="Abrir" />
                </Button>
            </CardActions>

            {/* <CardActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setInOptions(!inOptions)}
                >
                    <FormattedMessage
                        id="app.options"
                        defaultMessage="Opções"
                    />
                </Button>
            </CardActions> */}
        </Card>
    );
};

export default LinkCard;
