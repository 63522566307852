import {
    makeStyles,
    Theme,
    createStyles,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PhotoData } from '../../types/photoData';
import { removePhotoFromDrive } from './removePhotoFromDrive';
import { removeThumbnail } from './removeThumbnail';
import { deletePhoto } from '../../firebase/utils/deletePhoto';
import { useParams } from 'react-router-dom';
import { CustomAlert } from './CustomAlert';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        thumbnailContainer: {
            height: 160,
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
        },
        thumbnail: {
            width: '100%',
        },
        deletePhoto: {
            position: 'absolute',
            cursor: 'pointer',
            right: '0px',
            opacity: 0.5,
            '&:hover': {
                opacity: 1,
            },
        },
        iconButton: {
            padding: '4px',
        },
    })
);

type Params = {
    orgId: string;
    phase1Id: string;
    phase2Id: string;
};

type Props = {
    photo: PhotoData;
};

export const PhotoListItemDeleteButton: FunctionComponent<Props> = ({
    photo,
}) => {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id } = useParams<Params>();
    const [inConfirmRemove, setInConfirmRemove] = useState(false);
    const [alertMessage, setAlertMessage] = useState<Element | null>(null);

    const startRemove = () => {
        setInConfirmRemove(true);
    };

    const stopRemove = () => {
        setInConfirmRemove(false);
    };

    const removePhoto = async () => {
        try {
            await deletePhoto(orgId, phase1Id, phase2Id, photo);

            const driveResponse = await removePhotoFromDrive(photo);
            if (driveResponse.status !== 205) {
                // throw new Error('Houve um erro na remoção da foto no Drive!');
                setAlertMessage(
                    //@ts-ignore
                    <FormattedMessage
                        id="app.errorInDriveRemove"
                        defaultMessage="Houve um erro na remoção da foto no Drive!"
                    />
                );
            }

            const storageResponse = await removeThumbnail(photo);
            if (storageResponse.status !== 205) {
                // throw new Error(
                //   'Houve um erro na remoção da foto no Firebase Storage!'
                // );
                setAlertMessage(
                    //@ts-ignore
                    <FormattedMessage
                        id="app.errorInStorageRemove"
                        defaultMessage="Houve um erro na remoção da foto no Firebase Storage!"
                    />
                );
            }
        } catch (error) {
            // alert(error.message);
        } finally {
            setInConfirmRemove(false);
        }
    };

    return (
        <div className={classes.deletePhoto}>
            <IconButton
                className={classes.iconButton}
                color="primary"
                onClick={startRemove}
            >
                <DeleteIcon />
            </IconButton>
            <Dialog open={inConfirmRemove}>
                <DialogTitle>
                    <FormattedMessage
                        id="app.removePhoto"
                        defaultMessage="Remover Foto"
                    />
                </DialogTitle>
                <DialogContent>
                    <FormattedMessage
                        id="app.confirmPhotoDelete"
                        defaultMessage="Deseja remover esta foto de nosso armazenamento?"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={stopRemove}
                    >
                        <FormattedMessage
                            id="app.cancel"
                            defaultMessage="Cancelar"
                        />
                    </Button>
                    <Box flexGrow={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={removePhoto}
                        >
                            <FormattedMessage
                                id="app.remove"
                                defaultMessage="Remover"
                            />
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            {alertMessage && (
                <CustomAlert
                    message={alertMessage}
                    onClose={() => {
                        setAlertMessage(null);
                    }}
                />
            )}
        </div>
    );
};
