import { Annotation } from './../../types/annotation';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SetAnnotations = Annotation[] | 'not_initialized';

// type AddProcessWatcher =

type SliceState = { annotations: Annotation[] | 'not_initialized' };

const initialState: SliceState = {
    annotations: 'not_initialized',
};

const annotationsSlice = createSlice({
    name: 'annotationsSlice',
    initialState,
    reducers: {
        setAnnotations: (state, action: PayloadAction<SetAnnotations>) => {
            // tslint:disable-next-line
            state.annotations = action.payload;
        },
    },
});

export const actions = annotationsSlice.actions;
export const { setAnnotations } = annotationsSlice.actions;
export type setAnnotationsType = typeof setAnnotations;

export default annotationsSlice.reducer;
