import { makeId } from './../../utils/index';
import { storage } from './../../firebase/firebase';

export const uploadThumbnail = async (imageFile: File, phase2Id: string) => {
    const storageRef = storage.ref();
    const randomId = makeId(6);
    const thumbnailNameInStorage = phase2Id + '_' + randomId;
    var photoRef = storageRef.child(
        `propertiesPhotos/${thumbnailNameInStorage}.jpg`
    );
    const snapshot = await photoRef.put(imageFile);

    console.log(snapshot);

    const thumbnailUrl = await photoRef
        .getDownloadURL()
        .then((url: string) => url)
        .catch((error) => {
            console.log(error.message);
            let placeholderUrl =
                'https://static.patchplants.com/img/placeholders/patch_placeholder_grey.png';
            return placeholderUrl;
        });

    return { thumbnailUrl, thumbnailNameInStorage };
};
