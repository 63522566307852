import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { OrganizationLoginPage } from '../pages/OrganizationLoginPage';
import { useDownloadOrgInfo } from '../hooks/useDownloadOrgInfo';
import { useOrgUser } from '../hooks/useOrgUser';
import { Phase2Page } from '../pages/Phase2Page';
import { RootState } from '../store/configureStore';
import { useSelector } from 'react-redux';
import { createCrossoverTemporaryUser } from '../firebase/utils/createCrossoverTemporaryUser';
import { signInAsOrganizationDefaultTemporaryUser } from '../features/auth/signInAsTempUser';
import { validateShareLink } from '../firebase/utils/validateShareLink';
import { createSharedLinkAccessToOrgUser } from '../firebase/utils/createSharedLinkAccessToOrgUser';
import LoadingComponentPage from '../pages/LoadingComponentPage';
import { ErrorPage } from '../pages/ErrorPage';
// import { useDocumentTitle } from '../hooks/useDocumentTitle';

type Params = {
    orgId: string;
    phase1Id: string;
    phase2Id: string;
    shareLink: string;
};

export const SharingRoute = ({ component, ...rest }: any) => {
    const { orgId, phase1Id, phase2Id, shareLink }: Params =
        rest.computedMatch.params;
    // const [loading, isOrg] = useDownloadOrgInfo(orgId);
    useDownloadOrgInfo(orgId);
    // console.log('useDownloadOrgInfo => loading isOrg', loading, isOrg);
    const [orgUser] = useOrgUser(orgId);
    const userId = useSelector((state: RootState) => state.auth.userId);
    // const [correctComponent, setCorrectComponent] = useState<any>();
    const [sharingLoading, setSharingLoading] = useState(true);
    const [status, setStatus] = useState(0);
    const [ableToStart, setAbleToStart] = useState(false);
    // const shortOrgName = useSelector(
    //   (state: RootState) => state.organization.publicInfo.shortOrganizationName
    // );
    // useDocumentTitle(shortOrgName);

    useEffect(() => {
        if (userId !== null && orgUser !== 'not_initialized') {
            setAbleToStart(true);
        }
    }, [userId, orgUser]);

    useEffect(() => {
        if (!ableToStart) {
            return;
        }

        validateShareLink(orgId, phase1Id, phase2Id, shareLink).then(
            (result) => {
                if (!result?.success) {
                    alert('Unable to validate share link!');
                    setStatus(2);
                    setSharingLoading(false);
                    return;
                }

                if (!userId) {
                    signInAsOrganizationDefaultTemporaryUser(orgId)
                        .then((tempUserId) => {
                            console.log('Signed in as temp user!', tempUserId);
                            setStatus(1);
                            setSharingLoading(false);
                        })
                        .catch((error) => {
                            alert('Impossible to sign in as temporary user!');
                            console.log(error);
                            setStatus(2);
                        });
                } else if (orgUser) {
                    const isDefaultTemporaryOrgUser =
                        //@ts-ignore
                        orgUser.organizationAccessLevel === 'Temp';

                    if (isDefaultTemporaryOrgUser) {
                        console.log('Logged in as defaultTemporaryOrgUser');
                        setStatus(1);
                        setSharingLoading(false);
                        return;
                    }

                    createSharedLinkAccessToOrgUser(
                        orgId,
                        userId,
                        result.shareLinkId
                    )
                        .then(() => {
                            console.log('ShareLink added to user ' + userId);
                            setStatus(1);
                            setSharingLoading(false);
                        })
                        .catch((error) => {
                            alert('Impossible to shareLink access to orgUser!');
                            console.log(error);
                            setStatus(2);
                        });
                } else {
                    createCrossoverTemporaryUser(orgId, userId)
                        .then(() => {
                            console.log(
                                'Created crossover temporary user successfull'
                            );
                            console.log('setting phase2Page as correct');
                            //start(Phase2Page);
                            setStatus(1);
                            setSharingLoading(false);
                        })
                        .catch((error) => {
                            alert(
                                'Impossible to createCrossoverTemporaryUser!'
                            );
                            console.log(error);
                            setStatus(2);
                        });
                }
            }
        );
    }, [ableToStart]); //eslint-disable-line

    return (
        <>
            {sharingLoading ? (
                <LoadingComponentPage />
            ) : status === 1 ? (
                <Route component={Phase2Page} {...rest} />
            ) : status === 2 ? (
                <Route component={OrganizationLoginPage} {...rest} />
            ) : (
                <Route component={ErrorPage} {...rest} />
            )}
        </>
    );
};
