import React, { Fragment } from 'react';

//Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';

//Types
import { IOrganizationPublic } from '../../types/organization';

//Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        centerImageContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        imageContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
            width: '400px',
        },
        fullNameMargin: {
            margin: theme.spacing(2) /* top | horizontal | bottom */,
        },
    })
);

export default function OrganizationLogoComponent() {
    const classes = useStyles();

    const orgPublicInfo: IOrganizationPublic = useSelector(
        (state: RootState) => state.organization.publicInfo
    );

    const [renderSwicthType, setSwicthType] = React.useState('');

    React.useEffect(() => {
        if (orgPublicInfo.organizationLogoUrl !== null) {
            setSwicthType('PLATFORM_HAS_LOGO_UPLOADED');
        }

        if (
            orgPublicInfo.organizationLogoUrl === null &&
            orgPublicInfo.fullOrganizationName !== ''
        ) {
            setSwicthType(
                'PLATFORM_HAS_NO_LOGO_UPLOADED_BUT_HAS_FULL_COMPANY_NAME_SET'
            );
        }
    }, [
        orgPublicInfo.organizationLogoUrl,
        orgPublicInfo.organizationLogoWidth,
        orgPublicInfo.fullOrganizationName,
    ]);

    function renderSwitch(swicthType: string) {
        switch (swicthType) {
            case 'PLATFORM_HAS_LOGO_UPLOADED':
                return (
                    <Fragment>
                        {/* <div className={classes.centerImageContainer}>
              <div className={classes.imageContainer}> */}
                        <div>
                            <img
                                src={orgPublicInfo.organizationLogoUrl}
                                alt="company-Logo"
                                width="100%"
                                height="auto"
                            />
                        </div>

                        {/* </div>
            </div> */}
                    </Fragment>
                );
            // case 'PLATFORM_HAS_LOGO_UPLOADED':
            //   return (
            //     <Fragment>
            //       <div className={classes.centerImageContainer}>
            //         <div className={classes.imageContainer}>
            //           <img
            //             src={orgPublicInfo.organizationLogoUrl}
            //             alt="company-Logo"
            //             width={orgPublicInfo.organizationLogoWidth}
            //             height="auto"
            //           />
            //         </div>
            //       </div>
            //     </Fragment>
            //   );
            case 'PLATFORM_HAS_NO_LOGO_UPLOADED_BUT_HAS_FULL_COMPANY_NAME_SET':
                return (
                    <Fragment>
                        <div className={classes.centerImageContainer}>
                            <Typography
                                variant="h4"
                                align="center"
                                className={classes.fullNameMargin}
                            >
                                {orgPublicInfo.fullOrganizationName}
                            </Typography>
                        </div>
                    </Fragment>
                );
            default:
                return (
                    <Fragment>
                        <div className={classes.centerImageContainer}>
                            <Paper className={classes.imageContainer}>
                                <Typography
                                    variant="h5"
                                    align="center"
                                    className={classes.fullNameMargin}
                                >
                                    YOUR COMPANY LOGO
                                </Typography>
                            </Paper>
                        </div>
                    </Fragment>
                );
        }
    }

    return <Fragment>{renderSwitch(renderSwicthType)}</Fragment>;
}
