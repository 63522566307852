// import { IUser } from './../../types/user';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SliceState = {
    // user: IUser | null;
    // userId: string | 'not_initialized';
    userId: string | null; // i wish it was 'not_initialized' but can't
    isAppAdmin: boolean;
    // orgUserId: string;
};

const initialState: SliceState = {
    // user: null,
    userId: null,
    isAppAdmin: false,
    // orgUserId: '',
};

const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        // setUser: (state, action: PayloadAction<IUser>) => {
        //   const user: IUser = action.payload;
        //   state.user = user;
        // },
        setUserId: (state, action: PayloadAction<string>) => {
            const userId: string = action.payload;
            state.userId = userId;
        },
        setIsAppAdmin: (state, action: PayloadAction<boolean>) => {
            state.isAppAdmin = action.payload;
        },
        // setOrgUserId: (state, action: PayloadAction<string>) => {
        //   state.orgUserId = action.payload;
        // },
    },
});

export const actions = authSlice.actions;
export const {
    // setUser,
    setUserId,
    setIsAppAdmin,
    // setOrgUserId,
} = authSlice.actions;
// export type setUserType = typeof setUser;
export type setUserIdType = typeof setUserId;
export type setIsAppAdminType = typeof setIsAppAdmin;
// export type setOrgUserIdType = typeof setOrgUserId;

export default authSlice.reducer;
