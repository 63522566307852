// @ts-nocheck
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { createShareLink } from '../../firebase/utils/createShareLink';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { FormattedMessage } from 'react-intl';

//Components
import SideBarMenu from '../sidebarMenu/SideBarMenu';
import SoilAnalysisSideBar from 'features/soilAnalysis/SoilAnalysisSideBar';
import FormsSideBar from 'features/formsSideBar/FormsSideBar';
import FieldBlocksSidebar from '../fieldBlocks/FieldBlocksSidebar';
import { useHasFieldblocksAccess } from '../fieldBlocks/useHasFieldblocksAccess';
import { BreadCrumb } from '../navbars/BreadCrumb';
import APPsAndReservaLegalDrawer from 'features/apps-and-reserva-legal/APPsAndReservaLegalDrawer';

//Types
import { IOrganizationPublic } from '../../types/organization';

//MaterialUI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    Slider,
    Menu,
    MenuItem,
    IconButton,
    Typography,
    Toolbar,
    AppBar,
} from '@material-ui/core';

import AppsIcon from '@material-ui/icons/Apps';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            position: 'relative',
        },
        title: {
            flexGrow: 1,
        },
        rigthSidebarOpener: {
            color: theme.palette.success.main,
            borderColor: theme.palette.success.main,
        },
        rightSideMenu: {
            display: 'flex',
        },
    })
);

type Params = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

export default function MapNavbar() {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id }: Params = useParams();
    const [period, setPeriod] = useState(0);
    const [inShareLink, setInShareLink] = useState(false);

    const orgPublicInfo: IOrganizationPublic = useSelector(
        (state: RootState) => state.organization.publicInfo
    );

    const { hasFieldblocksAccess } = useHasFieldblocksAccess();

    const onCreateClick = () => {
        createShareLink(
            orgId,
            phase1Id,
            phase2Id,
            period,
            window.location.origin
        )
            .then((link: string) => {
                alert(
                    'Your link is: ' + link + ', valid for ' + period + ' days'
                );
                setInShareLink(false);
            })
            .catch((e) => {
                console.log('Impossible to create shareLink');
                setInShareLink(false);
            });
    };

    const valuetext = (value: number) => {
        setPeriod(value);
        return `${value}days`;
    };

    const [isAPPsAndReservaLegalDrawerOpen, setAPPsAndReservaLegalDrawerOpen] =
        React.useState(false);

    const [isSoilAnlaysisDrawerOpen, setSoilAnlaysisDrawerOpen] =
        React.useState(false);

    const [isFormsSideBarOpen, setFormsSideBarOpen] = React.useState(false);

    const [isFieldBlocksSideBarOpen, setFieldBlocksSideBarOpen] =
        React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isAnyDrawerOpen = () => {
        return (
            isAPPsAndReservaLegalDrawerOpen ||
            isSoilAnlaysisDrawerOpen ||
            isFormsSideBarOpen ||
            isFieldBlocksSideBarOpen
        );
    };

    return (
        <div className={classes.root}>
            <BreadCrumb position={'absolute'} />

            <AppBar position="fixed">
                <Toolbar variant="dense">
                    <SideBarMenu />
                    <APPsAndReservaLegalDrawer
                        isDrawerOpen={isAPPsAndReservaLegalDrawerOpen}
                        setisDrawerOpen={setAPPsAndReservaLegalDrawerOpen}
                    />
                    <SoilAnalysisSideBar
                        isDrawerOpen={isSoilAnlaysisDrawerOpen}
                        setisDrawerOpen={setSoilAnlaysisDrawerOpen}
                    />
                    <FormsSideBar
                        isDrawerOpen={isFormsSideBarOpen}
                        setisDrawerOpen={setFormsSideBarOpen}
                    />
                    <FieldBlocksSidebar
                        isDrawerOpen={isFieldBlocksSideBarOpen}
                        setisDrawerOpen={setFieldBlocksSideBarOpen}
                    />
                    <Typography
                        variant="h6"
                        color="inherit"
                        aria-label="Organization_Name"
                        noWrap
                        className={classes.title}
                    >
                        {orgPublicInfo.shortOrganizationName}
                    </Typography>

                    {hasFieldblocksAccess && (
                        <div className={classes.rightSideMenu}>
                            <Box
                                paddingX={0.5}
                                display={{
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'block',
                                }}
                            >
                                <Button
                                    className={classes.rigthSidebarOpener}
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        setAPPsAndReservaLegalDrawerOpen(true);
                                    }}
                                    disabled={isAnyDrawerOpen()}
                                >
                                    <FormattedMessage
                                        id="app.appsAndReservaLegalSideBar"
                                        defaultMessage="APPs e Reserva Legal"
                                    />
                                </Button>
                            </Box>
                            <Box
                                paddingX={0.5}
                                display={{
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'block',
                                }}
                            >
                                <Button
                                    className={classes.rigthSidebarOpener}
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        setSoilAnlaysisDrawerOpen(true);
                                    }}
                                    disabled={isAnyDrawerOpen()}
                                >
                                    <FormattedMessage
                                        id="app.soilAnalysisSideBar"
                                        defaultMessage="Análises de Solo"
                                    />
                                </Button>
                            </Box>
                            <Box
                                paddingX={0.5}
                                display={{
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'block',
                                }}
                            >
                                <Button
                                    className={classes.rigthSidebarOpener}
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        setFormsSideBarOpen(true);
                                    }}
                                    disabled={isAnyDrawerOpen()}
                                >
                                    <FormattedMessage
                                        id="app.formsSidebar"
                                        defaultMessage="Formulários"
                                    />
                                </Button>
                            </Box>
                            <Box
                                paddingX={0.5}
                                display={{
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'block',
                                }}
                            >
                                <Button
                                    className={classes.rigthSidebarOpener}
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        setFieldBlocksSideBarOpen(true);
                                    }}
                                    disabled={isAnyDrawerOpen()}
                                >
                                    <FormattedMessage
                                        id="app.fieldblocks"
                                        defaultMessage="Talhões"
                                    />
                                </Button>
                            </Box>

                            <Box
                                marginRight={-1}
                                display={{ sm: 'block', md: 'none' }}
                            >
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    size="medium"
                                    onClick={handleClick}
                                >
                                    <AppsIcon />
                                </IconButton>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            setAPPsAndReservaLegalDrawerOpen(
                                                true
                                            );
                                            handleClose();
                                        }}
                                    >
                                        <FormattedMessage
                                            id="app.appsAndReservaLegalSideBar"
                                            defaultMessage="APPs e Reserva Legal"
                                        />
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            setSoilAnlaysisDrawerOpen(true);
                                            handleClose();
                                        }}
                                    >
                                        <FormattedMessage
                                            id="app.soilAnalysisSideBar"
                                            defaultMessage="Análises de Solo"
                                        />
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            setFormsSideBarOpen(true);
                                            handleClose();
                                        }}
                                    >
                                        <FormattedMessage
                                            id="app.formsSidebar"
                                            defaultMessage="Formulários"
                                        />
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            setFieldBlocksSideBarOpen(true);
                                            handleClose();
                                        }}
                                    >
                                        <FormattedMessage
                                            id="app.fieldblocks"
                                            defaultMessage="Talhões"
                                        />
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </div>
                    )}
                </Toolbar>
            </AppBar>

            {inShareLink && (
                <Dialog
                    onClose={() => setInShareLink(false)}
                    aria-labelledby="simple-dialog-title"
                    open={inShareLink}
                >
                    <DialogTitle id="simple-dialog-title">
                        How long?
                    </DialogTitle>
                    <Slider
                        defaultValue={0}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={0}
                        max={30}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onCreateClick}
                    >
                        <FormattedMessage
                            id="app.create"
                            defaultMessage="Criar"
                        />
                    </Button>
                </Dialog>
            )}
        </div>
    );
}
