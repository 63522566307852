import {
    createStyles,
    Button,
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    makeStyles,
    Paper,
    Theme,
    Typography,
    TextField,
    CardActions,
} from '@material-ui/core';
//@ts-ignore
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import Fab from '@material-ui/core/Fab';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from '../ui/DatePicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { awaitMapLoad } from '../../utils';
import { Phase2PageContext } from '../../contexts/Phase2PageContext';
import { Annotation } from '../../types/annotation';
import { useParams } from 'react-router-dom';
import { uploadAnnotation } from '../../firebase/utils/uploadAnnotation';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '8px',
        },
        rootItem: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        paperAreaSize: {
            padding: theme.spacing(1),
            height: '40px',
            backgroundColor: '#ecf0f1',
        },
        formControl: {
            margin: theme.spacing(0, 1),
            minWidth: 175,
        },
    })
);

interface Props {
    OnGoingAddArea: boolean;
    onGoingAddDistance: boolean;
    onGoingAddPoint: boolean;
    setOnGoingAddPoint: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PointAnnotationToolButtonComponent: FunctionComponent<Props> = ({
    OnGoingAddArea,
    onGoingAddDistance,
    onGoingAddPoint,
    setOnGoingAddPoint,
}) => {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id }: any = useParams();
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [altitude, setAltitude] = React.useState(0);
    const [longitude, setLongitude] = React.useState(0);
    const [latitude, setLatitude] = React.useState(0);
    const { mapClass } = useContext(Phase2PageContext);
    const selectedDate = useSelector(
        (state: RootState) => state.timeline.selectedDate
    );

    const [newAnnotationDate, setNewAnnotationDate] = React.useState(
        selectedDate !== 'not_sure' ? selectedDate : new Date()
    );

    useEffect(() => {
        if (selectedDate !== 'not_sure') {
            setNewAnnotationDate(selectedDate);
        }
    }, [selectedDate]);

    useEffect(() => {
        const pointClickCallback = (_lngLat: any) => {
            const { lng, lat } = _lngLat;

            const elevation = mapClass.map.queryTerrainElevation(
                { lng, lat },
                { exaggerated: false }
            );

            setLatitude(lat);
            setLongitude(lng);
            setAltitude(elevation.toFixed(2));
        };
        awaitMapLoad(mapClass).then((map: any) => {
            if (!map.pointListenerStarted) {
                map.pointListenerStarted = true;
                map.on('click', (e: any) => {
                    if (!map.inAddPoint) return;

                    if (map.pointMarker) {
                        map.pointMarker.remove();
                    }

                    map.pointMarker = new mapboxgl.Marker()
                        .setLngLat([e.lngLat.lng, e.lngLat.lat])
                        .addTo(map);

                    pointClickCallback(map.pointMarker._lngLat);
                });
            }
        });
    }, [mapClass]);

    useEffect(() => {
        awaitMapLoad(mapClass).then((map) => {
            mapClass.map.inAddPoint = onGoingAddPoint;

            mapClass.map.getCanvas().style.cursor = onGoingAddPoint
                ? 'crosshair'
                : '';
        });
    }, [onGoingAddPoint]); //eslint-disable-line

    const onCancel = () => {
        // setOnGoingAddPoint(false);
        reset();
    };

    const onSave = () => {
        if (!title) {
            return alert('Título é obrigatório.');
        }

        const annotation = makeAnnotation();

        uploadAnnotation(orgId, phase1Id, phase2Id, annotation);

        reset();
    };

    const makeAnnotation = () => {
        const annotation: Annotation = {
            title,
            description,
            date: newAnnotationDate,
            feature: makePointFeature(),
        };

        return annotation;
    };

    const makePointFeature = () => {
        return {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [longitude, latitude],
            },
        };
    };

    const clearPointMarker = () => {
        if (mapClass?.map?.pointMarker) {
            mapClass.map.pointMarker.remove();
        }
    };

    const reset = () => {
        try {
            setAltitude(0);
            setLongitude(0);
            setLatitude(0);
            setTitle('');
            setDescription('');
            clearPointMarker();
            setOnGoingAddPoint(false);
        } catch (error: any) {}
    };

    return (
        <React.Fragment>
            {!(OnGoingAddArea || onGoingAddDistance || onGoingAddPoint) && (
                <Grid item xs={3}>
                    <Paper className={classes.paper}>
                        <Typography
                            variant="subtitle2"
                            display="block"
                            align="center"
                        >
                            Pontos
                        </Typography>

                        <div className={classes.rootItem}>
                            <Fab
                                size="small"
                                color="primary"
                                onClick={() => setOnGoingAddPoint(true)}
                            >
                                <MyLocationIcon />
                            </Fab>
                        </div>
                    </Paper>
                </Grid>
            )}
            {onGoingAddPoint && (
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Box marginBottom={1}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="subtitle2"
                                                display="block"
                                                align="center"
                                            >
                                                Ponto
                                            </Typography>

                                            <div className={classes.rootItem}>
                                                <Fab
                                                    size="small"
                                                    color="primary"
                                                >
                                                    <MyLocationIcon />
                                                </Fab>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body1"
                                                align="left"
                                            >
                                                Altitude:
                                            </Typography>
                                            <Paper
                                                variant="outlined"
                                                className={
                                                    classes.paperAreaSize
                                                }
                                            >
                                                <Typography
                                                    display="inline"
                                                    variant="body1"
                                                    align="left"
                                                    gutterBottom
                                                >
                                                    {`${altitude} ` +
                                                        (altitude
                                                            ? 'metros'
                                                            : '')}
                                                </Typography>
                                            </Paper>
                                            <Box marginTop={1}>
                                                <Typography
                                                    variant="body1"
                                                    align="left"
                                                >
                                                    Latitude:
                                                </Typography>
                                                <Paper
                                                    variant="outlined"
                                                    className={
                                                        classes.paperAreaSize
                                                    }
                                                >
                                                    <Typography
                                                        display="inline"
                                                        variant="body1"
                                                        align="left"
                                                        gutterBottom
                                                    >
                                                        {latitude || ''}
                                                    </Typography>
                                                </Paper>
                                            </Box>
                                            <Box marginTop={1}>
                                                <Typography
                                                    variant="body1"
                                                    align="left"
                                                >
                                                    Longitude:
                                                </Typography>
                                                <Paper
                                                    variant="outlined"
                                                    className={
                                                        classes.paperAreaSize
                                                    }
                                                >
                                                    <Typography
                                                        display="inline"
                                                        variant="body1"
                                                        align="left"
                                                        gutterBottom
                                                    >
                                                        {longitude || ''}
                                                    </Typography>
                                                </Paper>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}></Grid>
                                    </Grid>
                                    <Box marginY={1}>
                                        <TextField
                                            value={title}
                                            label={
                                                <FormattedMessage
                                                    id="app.title"
                                                    defaultMessage="Título"
                                                />
                                            }
                                            onChange={(e) =>
                                                setTitle(e.target.value)
                                            }
                                            required
                                            fullWidth
                                        />
                                    </Box>

                                    <Box marginY={1}>
                                        <TextField
                                            value={description}
                                            label={
                                                <FormattedMessage
                                                    id="app.description"
                                                    defaultMessage="Descrição"
                                                />
                                            }
                                            multiline
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                            fullWidth
                                        />
                                    </Box>

                                    <Box marginY={1}>
                                        <DatePicker
                                            //@ts-ignore
                                            label={
                                                <FormattedMessage
                                                    id="app.date"
                                                    defaultMessage="Data associada"
                                                />
                                            }
                                            selectedDate={newAnnotationDate}
                                            setSelectedDate={
                                                setNewAnnotationDate
                                            }
                                            pickerId={'annotationsDatePicker'}
                                        />
                                    </Box>
                                </CardContent>
                                <CardActions>
                                    <Box marginX={1} component="span">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            onClick={onCancel}
                                        >
                                            <FormattedMessage
                                                id="app.cancel"
                                                defaultMessage="Cancelar"
                                            />
                                        </Button>
                                    </Box>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={onSave}
                                        fullWidth
                                        // disabled={accessLevel === 'Guest' || accessLevel === 'Temp'}
                                    >
                                        <FormattedMessage
                                            id="app.save"
                                            defaultMessage="Salvar"
                                        />
                                    </Button>
                                </CardActions>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box marginTop={0} marginBottom={0.5}>
                            <Divider />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
};
