import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePhotos } from '../../hooks/usePhotos';
import { RootState } from '../../store/configureStore';
import { useSelector } from 'react-redux';
import { PhotoData } from './../../types/photoData';
import { dateToMilis } from '../../utils';

//MaterialUI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import IconButton from '@material-ui/core/IconButton';
// import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Box } from '@material-ui/core';
import { PhotoListItemDeleteButton } from './PhotoListItemDeleteButton';
import { Photo } from './Photo';
// import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1, 1, 1, 1),
            margin: theme.spacing(0.5, 0, 0.5, 0),
        },

        thumbnailContainer: {
            height: 160,
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
        },
        thumbnail: {
            width: '100%',
            // height: '100%',
        },
        // paper: {
        //   padding: theme.spacing(0.25),
        // },
        gridPhotoName: {
            '&:hover': {
                cursor: 'pointer',
            },
            // backgroundColor: 'purple',
        },
        divCenterName: {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            // backgroundColor: 'yellow',
        },
        deletePhoto: {
            position: 'absolute',
            cursor: 'pointer',
            right: '0px',
            opacity: 0.5,
            '&:hover': {
                opacity: 1,
            },
        },
    })
);

export const PhotosListSidebar = () => {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id }: any = useParams();
    const { photos } = usePhotos(orgId, phase1Id, phase2Id);

    const selectedDate = useSelector(
        (state: RootState) => state.timeline.selectedDate
    );

    const [filteredPhotos, setFilteredPhotos] = useState<PhotoData[]>([]);

    useEffect(() => {
        if (selectedDate !== 'not_sure' && photos !== 'not_initialized') {
            const filtered = photos.filter(
                (photo) => dateToMilis(photo.date) === dateToMilis(selectedDate)
            );

            setFilteredPhotos(filtered);
        }
    }, [selectedDate, photos]); //eslint-disable-line

    return (
        <React.Fragment>
            {filteredPhotos.map((photo) => (
                <Grid key={photo.photoDocId} item xs={12}>
                    {/* <Box my={0.25}> */}
                    <Paper className={classes.paper}>
                        <Grid container>
                            <Box className={classes.thumbnailContainer}>
                                <PhotoListItemDeleteButton photo={photo} />
                                <a
                                    href={photo.gdriveUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Photo photo={photo} />
                                </a>

                                {/* <Grid className={classes.gridPhotoName} item xs={11}>
                <div className={classes.divCenterName}>
                <Typography variant='body1'>
                <strong>foto</strong>
                </Typography>
                </div>
                </Grid>
                <Grid item xs={1}>
                será uma foto uma hora
              </Grid> */}
                            </Box>
                        </Grid>
                    </Paper>
                    {/* </Box> */}
                </Grid>
            ))}
        </React.Fragment>
    );
};
