import { Tileset } from './../types/tileset';
import React from 'react';
// import { Map } from 'mapbox-gl/dist/mapbox-gl-csp';

type Content = {
    // map: Map;
    mapClass: any;
    setMapClass: (map: any) => void;
    draw: any;
    setDraw: (draw: any) => void;
    filteredTilesets: Tileset[];
    storeMoveUp: (ts: any, aboveTS: any) => void;
    storeMoveDown: (ts: any, belowTS: any) => void;
};

export const Phase2PageContext = React.createContext<Content>({
    mapClass: null,
    setMapClass: () => {},
    draw: null,
    setDraw: () => {},
    filteredTilesets: [],
    storeMoveUp: (index: number) => {},
    storeMoveDown: (index: number) => {},
});

// {
// selectedDate: new Date(),
// setSelectedDate: () => {},
// }

//export Phase2PageContext ;
