import { db } from '../firebase';

export const createSharedLinkAccessToOrgUser = (
    orgId: string,
    userId: string,
    shareLinkId: string
) => {
    return db
        .collection('organizations')
        .doc(orgId)
        .collection('users')
        .doc(userId)
        .update({ shareLinkId });
};
