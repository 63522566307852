import { SoilAnalysisLinkData } from './../../types/link';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SetLinks = SoilAnalysisLinkData[] | 'not_initialized';

type SliceState = {
    links: SoilAnalysisLinkData[] | 'not_initialized';
};

const initialState: SliceState = {
    links: 'not_initialized',
};

const soilAnalysisSlice = createSlice({
    name: 'soilAnalysisSlice',
    initialState,
    reducers: {
        setSoilAnalysisLinks: (state, action: PayloadAction<SetLinks>) => {
            state.links = action.payload;
        },
    },
});

export const actions = soilAnalysisSlice.actions;
export const { setSoilAnalysisLinks } = soilAnalysisSlice.actions;
export type setLinksType = typeof setSoilAnalysisLinks;

export default soilAnalysisSlice.reducer;
