import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { OrganizationLoginPage } from '../pages/OrganizationLoginPage';
import { useDownloadOrgInfo } from '../hooks/useDownloadOrgInfo';
import { useOrgUser } from '../hooks/useOrgUser';
import LoadingComponentPage from '../pages/LoadingComponentPage';
// import { useSelector } from "react-redux";
// import { RootState } from "../store/configureStore";
// import { useDocumentTitle } from '../hooks/useDocumentTitle';

type Params = { orgId: string };

export const OrganizationRoute = ({ component, ...rest }: any) => {
    const { orgId }: Params = rest.computedMatch.params;
    const [loading, isOrg] = useDownloadOrgInfo(orgId);
    const [orgUser] = useOrgUser(orgId);
    // const shortOrgName = useSelector(
    //   (state: RootState) => state.organization.publicInfo.shortOrganizationName
    // );
    // useDocumentTitle(shortOrgName);

    return (
        <>
            {loading ? (
                <LoadingComponentPage />
            ) : isOrg ? (
                orgUser ? (
                    <Route component={component} {...rest} />
                ) : (
                    <Route component={OrganizationLoginPage} {...rest} />
                )
            ) : (
                <Redirect to="/" />
            )}
        </>
    );
};
