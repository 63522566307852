import phase1Reducer from '../features/phase1/phase1Slice';
import phase2Reducer from '../features/phase2/phase2Slice';
// import fieldblocksReducer from '../features/fieldblocks/fieldblocksSlice';
import fieldblocksReducer from '../features/fieldblocks/fieldblocksSlice';
import formsReducer from '../features/forms/formsSlice';
// import { combineReducers } from 'redux'

const formFyReducers = {
    phase1: phase1Reducer,
    phase2: phase2Reducer,
    fieldblocks: fieldblocksReducer,
    forms: formsReducer,
};

export default formFyReducers;
