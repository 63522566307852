import { Registry } from '../types/registry';
import { FormFieldTypes, FormField } from './../types/formField';
import { useSelector } from 'react-redux';
import { storeFieldblockRegistry } from './../firebase/utils/storeFieldblockRegistry';

//Typescript
import { RootState } from '../../store/configureStore';
import { useActions } from '../customHooks/useActions';
import { useState, useEffect } from 'react';

export const useFieldblocksRegistryState = (
    phase1Id: string,
    phase2Id: string
) => {
    const {
        startFieldblocksRegistryState,
        finishFieldblocksRegistryState,
        setFieldblocksRegistryState,
    } = useActions();

    const formFields = useSelector(
        (state: RootState) => state.fieldblocks.formFields
    );

    const orgId: string = useSelector(
        (state: RootState) => state.organization.orgId
    );

    const registryState = useSelector(
        (state: RootState) => state.fieldblocks.registryState
    );

    const [ableToStart, setAbleToStart] = useState(false);

    useEffect(() => {
        if (formFields !== 'not_initialized') {
            setAbleToStart(true);
        }
    }, [formFields]);

    const start = (initialRegistry: Registry | undefined) => {
        if (formFields !== 'not_initialized') {
            startFieldblocksRegistryState({ formFields, initialRegistry });
        }
    };

    const handleChange = (fieldId: string, value: FormFieldTypes) => {
        setFieldblocksRegistryState({ fieldId, value });
    };

    const finish = () => {
        finishFieldblocksRegistryState(undefined);
    };

    const save = async () => {
        if (registryState === null) {
            alert('Error! Not creating a registry, impossible to fire save()!');
            return undefined;
        }

        let registry: Registry = {};

        //@ts-ignore
        formFields.forEach((field: FormField) => {
            registry[field.fieldId] = registryState[field.fieldId].value;
        });

        if (registry.createdAt === null) {
            registry.createdAt = new Date();
        }
        if (phase1Id && phase2Id) {
            return storeFieldblockRegistry(
                orgId,
                registry,
                phase1Id,
                phase2Id,
                registryState.fieldblockId
            ).then((docId) => {
                finish();
                return docId;
            });
        } else {
            alert(
                'Impossible to save Fieldblocks Registry because phase1Id is null!'
            );
        }
    };

    return {
        state: registryState,
        start,
        finish,
        handleChange,
        save,
        ableToStart,
    };
};
