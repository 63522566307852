import { db } from '../firebase';
import { UserHasAcessPhase2Path } from '../../types/user';
import firebase from 'firebase/compat/app';

export const removePhase2UserAcces = ({
    orgId,
    userId,
    phase1Id,
    phase2Id,
}: UserHasAcessPhase2Path) => {
    const { FieldValue } = firebase.firestore;

    console.log(orgId, userId, phase1Id, phase2Id);

    return new Promise<void>((resolve, reject) => {
        const docRef = db.doc(`organizations/${orgId}/users/${userId}/`);

        docRef
            .get()
            .then((doc) => {
                const data: any = doc.data();
                const { phase2UserHasAccessPath } = data;

                return phase2UserHasAccessPath.filter((path: any) => {
                    return path.phase1Id === phase1Id;
                });
            })
            .then((pathsWithPhase1Id) => {
                let phase1ToBeRemoved: any = '';
                if (pathsWithPhase1Id.length <= 1) {
                    phase1ToBeRemoved = phase1Id;
                }

                return phase1ToBeRemoved;
            })
            .then((phase1ToBeRemoved) => {
                const updateParams = {
                    phase1UserHasAccess:
                        FieldValue.arrayRemove(phase1ToBeRemoved),
                    phase2UserHasAccess: FieldValue.arrayRemove(phase2Id),
                    phase2UserHasAccessPath: FieldValue.arrayRemove({
                        orgId,
                        phase1Id,
                        phase2Id,
                    }),
                };

                docRef.update(updateParams).then((doc) => {
                    console.log('Document successfully deleted!', doc);
                    resolve();
                });
            })
            .catch((error) => {
                console.log('Error removing document: ', error);
                reject(error);
            });
    });
};
