import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Phase2PageContext } from 'contexts/Phase2PageContext';
import { db } from '../../firebase/firebase';
import { ReservaLegalOuAreaPreservacaoPermanente } from 'formfy/types/index';
import { useAppDispatch } from 'store/reduxCustomHooks';
import { RootState } from 'store/configureStore';
import {
    setReservaLegal,
    setAreaPreservacaoPermanente,
} from 'features/apps-and-reserva-legal/APPsAndReservaLegalSlice';

import { awaitMapClassStarted } from './../mapboxGl/handleTilesetUnrenderOnRemove';

import { useParams } from 'react-router';

import {
    setLayers,
    removeLayers,
} from 'features/apps-and-reserva-legal/setLayers';

type Params = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

type Feature = {
    geometry: {
        coordinates: [number, number][][]; // [longitude, latitude][][]
        type: 'Polygon';
    };
    id: string;
    properties: {
        title: string;
    };
};

export default function RenderAPPsAndReservaLegalLayers() {
    const { phase2Id }: Params = useParams();
    const dispatch = useAppDispatch();
    const { mapClass } = useContext(Phase2PageContext);

    const reservaLegalFeatures = useSelector(
        (state: RootState) => state.appsAndReservaLegal.reservaLegal
    );

    const areaPreservacaoPermanenteFeatures = useSelector(
        (state: RootState) =>
            state.appsAndReservaLegal.areaPreservacaoPermanente
    );

    // const dispatch = useAppDispatch();

    useEffect(() => {
        const dbRef = db.collection('area-preservacao-permanente');
        const unsubscribe = dbRef.onSnapshot((querySnapshot) => {
            const list: ReservaLegalOuAreaPreservacaoPermanente[] = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                data.type = 'area-preservacao-permanente';
                data.id = doc.id;
                list.push(data as ReservaLegalOuAreaPreservacaoPermanente);
            });
            // console.log(
            //     'Current list mano area-preservacao-permanente: ',
            //     list
            // );
            dispatch(setAreaPreservacaoPermanente(list)); // TO-DO: setarea-preservacao-permanente
        });

        return () => unsubscribe();
    }, [dispatch]);
    useEffect(() => {
        const dbRef = db.collection('reserva-legal');
        const unsubscribe = dbRef.onSnapshot((querySnapshot) => {
            const list: ReservaLegalOuAreaPreservacaoPermanente[] = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                data.type = 'reserva-legal';
                data.id = doc.id;
                list.push(data as ReservaLegalOuAreaPreservacaoPermanente);
            });
            // console.log('Current list mano reserva-legal: ', list);
            dispatch(setReservaLegal(list)); // TO-DO: setreserva-legal
        });

        return () => unsubscribe();
    }, [dispatch]);

    useEffect(() => {
        if (mapClass && reservaLegalFeatures !== 'not_initialized') {
            const geoJsonFeatures: Feature[] = [];
            reservaLegalFeatures.forEach((element) => {
                if (
                    element.phase2Id === phase2Id &&
                    typeof element.coordinates === 'string' &&
                    element.id
                ) {
                    const coordinates = JSON.parse(element.coordinates);
                    const newFeature = {
                        geometry: {
                            coordinates: coordinates,
                            type: 'Polygon' as const, // explicitly set the type to "Polygon"
                        },
                        id: element.id,
                        properties: {
                            title: 'Reserva Legal',
                        },
                    };
                    geoJsonFeatures.push(newFeature);
                }
            });
            awaitMapClassStarted(mapClass).then(() => {
                setLayers(mapClass.map, geoJsonFeatures);
            });

            return () => {
                // removeLayers(mapClass.map, geoJsonFeatures);
                awaitMapClassStarted(mapClass).then(() => {
                    removeLayers(mapClass.map, geoJsonFeatures);
                });
            };
        }
    }, [reservaLegalFeatures, mapClass, phase2Id]);

    useEffect(() => {
        if (
            mapClass &&
            areaPreservacaoPermanenteFeatures !== 'not_initialized'
        ) {
            const geoJsonFeatures: Feature[] = [];
            areaPreservacaoPermanenteFeatures.forEach((element) => {
                if (
                    element.phase2Id === phase2Id &&
                    typeof element.coordinates === 'string' &&
                    element.id
                ) {
                    const coordinates = JSON.parse(element.coordinates);
                    const newFeature = {
                        geometry: {
                            coordinates: coordinates,
                            type: 'Polygon' as const, // explicitly set the type to "Polygon"
                        },
                        id: element.id,
                        properties: {
                            title: 'APP',
                        },
                    };
                    geoJsonFeatures.push(newFeature);
                }
            });
            awaitMapClassStarted(mapClass).then(() => {
                setLayers(mapClass.map, geoJsonFeatures);
            });
            return () => {
                // removeLayers(mapClass.map, geoJsonFeatures);
                awaitMapClassStarted(mapClass).then(() => {
                    removeLayers(mapClass.map, geoJsonFeatures);
                });
            };
        }
    }, [areaPreservacaoPermanenteFeatures, mapClass, phase2Id]);
    return null;
}
