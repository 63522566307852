import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router';
import { useActions } from '../../hooks/useActions';
import { usePhase1FormFields } from '../API/usePhase1FormFields';

// import { Phase1FormFields } from '../features/phase1/FormFields';
import { Phase1FormFieldsTable } from '../features/phase1/Phase1FormFieldsTable';
// import { downloadPhase1FormFields } from '../firebase/utils/downloadPhase1FormFields';
import NavbarOrganization from '../../features/navbars/NavbarOrganization';
import { AddPhase1FormField } from '../features/phase1/AddFormField';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Phase1FormManagePreview } from '../features/phase1/Phase1FormManagePreview';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            // padding: theme.spacing(0, 0, 3) /* top | horizontal | bottom */,
            padding: theme.spacing(8, 0, 4) /* top | horizontal | bottom */,
        },
    })
);
export const Phase1FormManagePage: FunctionComponent<any> = () => {
    const classes = useStyles();
    const { phase1FormFields } = usePhase1FormFields();
    const params: any = useParams();
    const { setOrgId } = useActions();

    useEffect(() => {
        setOrgId(params.orgId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <NavbarOrganization />
            <Grid
                container
                className={classes.header}
                justify="center"
                spacing={1}
            >
                <Grid item xs={12} sm={9}>
                    <Phase1FormFieldsTable
                        phase1FormFields={phase1FormFields}
                    />
                </Grid>
                <Grid item xs={12} sm={9}>
                    <AddPhase1FormField phase1FormFields={phase1FormFields} />
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={4}>
                    <Phase1FormManagePreview />
                </Grid>
            </Grid>
        </>
    );
};
