import React, { Fragment, FunctionComponent } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../store/configureStore';
import { Link as RouterLink } from 'react-router-dom';
// import { usePhase1FormFields } from '../formfy/API/usePhase1FormFields';
// import { usePhase1Registries } from '../formfy/API/usePhase1Registries';
// import { usePhase1RegistryState } from '../formfy/API/usePhase1RegistryState';
// import { Phase1Card } from '../features/phase1/Phase1Card';
// import { Phase1Form } from '../features/phase1/Phase1Form';

//Components
import NavbarOrganization from '../features/navbars/NavbarOrganization';
import OrganizationLogoComponent from '../features/organizations/OrganizationLogoComponent';
import OrganizationCopyrightComponent from '../features/organizations/OrganizationCopyrightComponent';
import ListOfPhase1Registries from '../features/phase1/ListOfPhase1Registries';

//MateiralUI
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { FormattedMessage } from 'react-intl';
// import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    headerRoot: {
        flexGrow: 1,
        padding: theme.spacing(6, 0, 0) /* top | horizontal | bottom */,
        backgroundColor: 'white',
    },
    allProjectsGrid: {
        padding: theme.spacing(2, 0, 10) /* top | horizontal | bottom */,
    },
    allProjectsGridTitleandButtons: {
        padding: theme.spacing(0, 0, 0) /* top | horizontal | bottom */,
    },
    card: {
        margin: theme.spacing(1),
        maxWidth: '300px',
    },
    flex: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));

type Props = any;

export const OrganizationPage: FunctionComponent<Props> = ({ match }) => {
    const classes = useStyles();

    // const { phase1FormFields, phase1FieldIdsToNames } = usePhase1FormFields();
    // const { phase1Registries } = usePhase1Registries();
    // console.log('phase1Registries =>', phase1Registries);

    // const phase1RegistryState = usePhase1RegistryState();

    // const [inAddPhase1, setInAddPhase1] = useState(false);

    // const toggleInAddPhase1 = () => {
    //   setInAddPhase1(!inAddPhase1);
    // };

    const numberOfPhase1Registries = useSelector(
        (state: RootState) =>
            state.phase1.registries instanceof Array &&
            state.phase1.registries.length
    );

    const orgId: string = useSelector(
        (state: RootState) => state.organization.orgId
    );

    const accessLevel: string = useSelector((state: RootState) => {
        const orgUser = state.organization.orgUser;
        if (orgUser && orgUser !== 'not_initialized') {
            return orgUser.organizationAccessLevel;
        } else {
            return 'Guest';
        }
    });

    return (
        <Fragment>
            <CssBaseline />
            <NavbarOrganization />

            <div className={classes.headerRoot}>
                <Grid container>
                    <Grid item xs={12}>
                        <OrganizationLogoComponent />
                    </Grid>
                </Grid>
            </div>

            <Container maxWidth="lg">
                <Grid container className={classes.allProjectsGrid}>
                    <Grid
                        container
                        item
                        className={classes.allProjectsGridTitleandButtons}
                        alignItems="center"
                        spacing={1}
                        xs={12}
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            {accessLevel !== 'Guest' && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    startIcon={<LockOpenIcon />}
                                    fullWidth
                                    component={RouterLink}
                                    to={`${match.url.replace(
                                        /\/$/,
                                        ''
                                    )}/members`}
                                >
                                    <FormattedMessage
                                        id="app.manage-members-button"
                                        defaultMessage="GERENCIAR ACESSO"
                                        description="manage-members-button"
                                    />
                                </Button>
                            )}
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            {accessLevel !== 'Guest' && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    startIcon={<PersonAddIcon />}
                                    fullWidth
                                    component={RouterLink}
                                    to={`/organizationId/${orgId}/create-new-phase1`}
                                >
                                    <FormattedMessage
                                        id="app.newGrower"
                                        defaultMessage="Novo Produtor(a)"
                                    />
                                </Button>
                            )}
                        </Grid>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Box paddingTop={0} paddingBottom={1}>
                            <Divider />
                        </Box>
                    </Grid> */}

                    <Grid container item>
                        <Grid item xs={12}>
                            <Box paddingTop={2} paddingBottom={2}>
                                <Typography
                                    variant="h5"
                                    align="left"
                                    color="textPrimary"
                                >
                                    <FormattedMessage
                                        id="app.growers"
                                        defaultMessage="Produtores ({numberOfPhase1Registries})"
                                        values={{
                                            numberOfPhase1Registries,
                                        }}
                                    />
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container item spacing={2}>
                        <ListOfPhase1Registries />
                    </Grid>
                </Grid>
            </Container>

            {/* <Container maxWidth="lg">
        {accessLevel !== 'Guest' && (
          <div>
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to={`${match.url.replace(/\/$/, '')}/members`}
            >
              Manage Members
            </Button>
          </div>
        )}
      </Container> */}

            <OrganizationCopyrightComponent />
        </Fragment>
    );
};
