import React from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Button } from '@material-ui/core';

export const AddButton = ({ onClick, label, customProps }) => {
    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                onClick={onClick}
                size="small"
                startIcon={<AddCircleIcon />}
                {...customProps}
            >
                {label}
            </Button>
        </div>
    );
};

export default AddButton;
