import { FormField } from './../../types/formField';
import db from '../../../firebase/firebase';

export const updateField = (
    formId: string,
    fieldId: string,
    updates: FormField,
    orgId: string
) => {
    return db
        .collection('organizations')
        .doc(orgId)
        .collection(formId)
        .doc(fieldId)
        .update(updates)
        .catch((err) => {
            alert('Unable to update field!');
            console.log(err);
        });
};
