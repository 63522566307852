import React, { FunctionComponent, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

//Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';

import {
    Button,
    Card,
    CardContent,
    makeStyles,
    CardActions,
    Typography,
    Box,
} from '@material-ui/core';
import { FormField, FormFieldTypes } from '../../formfy/types';
import { RenderFormField } from '../ui/RenderFormField';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    marginTop: {
        marginTop: theme.spacing(2),
    },
    title: {
        fontSize: 14,
    },
}));

type Props = {
    phase1RegistryState: any;
    phase1FormFields: FormField[] | 'not_initialized';
};

export const Phase1Form: FunctionComponent<Props> = ({
    phase1RegistryState,
    phase1FormFields,
}) => {
    const history = useHistory();

    const orgId: string = useSelector(
        (state: RootState) => state.organization.orgId
    );

    const classes = useStyles();

    const start1 = () => {
        phase1RegistryState.start(undefined);
    };

    const finish1 = () => {
        phase1RegistryState.finish();
    };

    const handleChange1 = (fieldId: string, value: FormFieldTypes) => {
        phase1RegistryState.handleChange(fieldId, value);
    };

    const onSaveClick1 = () => {
        phase1RegistryState.save().then((phase1DocId: string | undefined) => {
            if (phase1DocId) {
                history.push(
                    '/organizationId/' + orgId + '/phase1Id/' + phase1DocId
                );
            }
        });
    };

    useEffect(() => {
        if (phase1RegistryState.ableToStart) {
            start1();
        }

        return () => {
            finish1();
        };
    }, [phase1RegistryState.ableToStart]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card className={classes.marginTop}>
            <CardContent>
                <Box paddingBottom={2}>
                    <Typography variant="h5" gutterBottom>
                        <FormattedMessage
                            id="app.new-project-page"
                            defaultMessage="Novo projeto"
                            description="new-project-page"
                        />
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <FormattedMessage
                            id="app.new-project-page-subtitle"
                            defaultMessage="Após criar um projeto, você pode adicionar áreas a ele."
                            description="new-project-page-subtitle"
                        />
                    </Typography>
                </Box>
                {phase1RegistryState.state !== null &&
                    phase1FormFields !== 'not_initialized' &&
                    phase1FormFields.map((field: FormField) => {
                        if (field.fieldId === 'createdAt') {
                            return; //eslint-disable-line
                        }

                        if (phase1RegistryState.state !== null) {
                            const stateField =
                                phase1RegistryState.state[field.fieldId];

                            const onChange = (value: FormFieldTypes) =>
                                handleChange1(field.fieldId, value);

                            return (
                                <Box paddingTop={1} key={field.fieldId}>
                                    <RenderFormField
                                        stateField={stateField}
                                        formField={field}
                                        onChange={onChange}
                                    />
                                </Box>
                            );
                        } else {
                            return <></>;
                        }
                    })}
            </CardContent>
            <CardActions>
                <Button
                    onClick={onSaveClick1}
                    variant="contained"
                    color="primary"
                    size="medium"
                >
                    <FormattedMessage
                        id="app.create-new-phase1-button"
                        defaultMessage="Criar novo projeto"
                    />
                </Button>
                <Button
                    component={RouterLink}
                    to={`/organizationId/${orgId}`}
                    variant="outlined"
                    color="primary"
                    size="medium"
                >
                    <FormattedMessage
                        id="app.cancel"
                        defaultMessage="Cancelar"
                    />
                </Button>
            </CardActions>
        </Card>
    );
};
