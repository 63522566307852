import { Button, Dialog, TextField } from '@material-ui/core';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useParams } from 'react-router';
//import { uploadLink } from '../firebase/utils/uploadLink';
import { useLinks } from '../hooks/useLinks';
import { Links } from '../features/links/Links';
import { FormattedMessage } from 'react-intl';

type Params = {
    orgId: string;
    phase1Id: string;
    phase2Id: string;
};

export const ManageAnnotationsPage = () => {
    //const [open, setOpen] = React.useState(false);
    const [link, setLink] = React.useState('');
    const [linkName, setLinkName] = React.useState('');
    const [inAdd, setInAdd] = React.useState(false);
    const { orgId, phase1Id, phase2Id } = useParams<Params>();

    const { links } = useLinks(orgId, phase1Id, phase2Id);

    console.log(links);

    function handleClickOpen() {
        setInAdd(true);
    }

    const onSave = () => {
        //uploadLink(orgId, phase1Id, phase2Id, { link, linkName });
        setInAdd(false);
    };

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
                fullWidth
            >
                <FormattedMessage
                    id="app.addLink"
                    defaultMessage="Adicionar Link"
                />
            </Button>
            {inAdd && (
                <Dialog open={inAdd} onClose={() => setInAdd(false)}>
                    <TextField
                        id="filled-basic"
                        label="Link"
                        variant="filled"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    />
                    <TextField
                        id="filled-basic"
                        label="Nome do Link"
                        variant="filled"
                        value={linkName}
                        onChange={(e) => setLinkName(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onSave}
                    >
                        Save
                    </Button>
                </Dialog>
            )}
            <div>
                <Links links={links} />
            </div>
        </div>
    );
};
