import { findUserOrgs } from './../features/members/findUserOrgs';
import { UserOrgs } from './../types/user';
import { setUserOrgsType } from './../features/members/membersSlice';
import { useActions } from './useActions';
import { useSelector } from 'react-redux';
import { RootState } from '../store/configureStore';
import { useEffect } from 'react';

export const useUserOrgs = () => {
    const userId = useSelector((state: RootState) => state.auth.userId);
    const actions = useActions();
    const setUserOrgs: setUserOrgsType = actions.setUserOrgs;
    const userOrgs: UserOrgs = useSelector(
        (state: RootState) => state.members.userOrgs
    );

    useEffect(() => {
        if (!userId) return;

        findUserOrgs(userId).then((userDocs: UserOrgs) => {
            setUserOrgs(userDocs);
        });

        return () => {
            setUserOrgs([]);
        };
    }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

    return userOrgs;
};
