import React from 'react';
import MapsSidebarMenuComponent from './MapsSidebarMenuComponent';
import HeaderSidebarMenu from './HeaderSidebarMenu';
import { PhotosSidebarMenu } from './PhotosSidebarMenu';
import { LinksSideBarMenu } from './LinksSideBarMenu';
import { AnnotationsSidebarMenu } from './AnnotationsSidebarMenu';
import ShareSidebarMenu from './ShareSidebarMenu';
import AnnotationsToolsSidebarMenu from './AnnotationsToolsSidebarMenu';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { Timeline } from '../timeline/Timeline';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divList: {
            width: 350,
        },
        menuButton: {
            marginRight: theme.spacing(1),
        },
        divHideMenuButton: {
            padding: theme.spacing(1),
        },
        dateDependent: {
            backgroundColor: '#bbb',
            margin: theme.spacing(0.5),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            borderRadius: 10,
        },
    })
);

export default function SideBarMenu() {
    const classes = useStyles();
    const [isDrawerOpen, setisDrawerOpen] = React.useState(true);
    const accessLevel = useSelector((state: RootState) => {
        const orgUser = state.organization.orgUser;
        if (orgUser && orgUser !== 'not_initialized') {
            return orgUser.organizationAccessLevel;
        }
    });

    const toggleDrawer =
        (openBoolean: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            setisDrawerOpen(openBoolean);
        };

    return (
        <React.Fragment>
            <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                size="medium"
                onClick={toggleDrawer(true)}
            >
                <MenuIcon />
            </IconButton>
            <Drawer
                variant="persistent"
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
            >
                <div className={classes.divList}>
                    <HeaderSidebarMenu toggleDrawer={toggleDrawer} />

                    {accessLevel !== 'Guest' && accessLevel !== 'Temp' && (
                        <>
                            <Box paddingX={1}>
                                <ShareSidebarMenu />
                            </Box>

                            <Box paddingY={0.5}>
                                <Divider />
                            </Box>
                        </>
                    )}

                    <Box className={classes.dateDependent}>
                        <Box paddingX={1}>
                            <Timeline />
                        </Box>

                        <Box paddingY={0.5}>
                            <Divider />
                        </Box>

                        <Box paddingX={1}>
                            <AnnotationsToolsSidebarMenu />
                        </Box>

                        <Box paddingY={0.5}>
                            <Divider />
                        </Box>

                        <Box paddingX={1}>
                            <AnnotationsSidebarMenu />
                        </Box>

                        <Box paddingY={0.5}>
                            <Divider />
                        </Box>

                        <Box paddingX={1}>
                            <PhotosSidebarMenu />
                        </Box>

                        <Box paddingY={0.5}>
                            <Divider />
                        </Box>

                        <Box paddingX={1}>
                            <LinksSideBarMenu />
                        </Box>

                        <Box paddingY={0.5}>
                            <Divider />
                        </Box>

                        <Box paddingX={1} paddingTop={0.5}>
                            <MapsSidebarMenuComponent />
                        </Box>
                    </Box>

                    <Box paddingY={0.5}>
                        <Divider />
                    </Box>
                </div>
            </Drawer>
        </React.Fragment>
    );
}
