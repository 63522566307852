import React from 'react';
import NextButton from './ui/NextButton';
import Switch from '@material-ui/core/Switch';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
    createStyles({
        right: {
            marginLeft: 'auto',
        },
        flex: {
            marginTop: theme.spacing(1),
            display: 'flex',
            // justify: 'center',
        },
        dialog: {
            width: 400,
        },
        marginBottom: {
            marginBottom: theme.spacing(1),
        },
    })
);

export const SetFieldSetup = ({
    nextStep,
    fieldSetup,
    setFieldSetup,
    setShowAddForm,
    inEdit,
}) => {
    const classes = useStyles();
    const handleChange = (event) => {
        setFieldSetup({
            ...fieldSetup,
            [event.target.name]: event.target.checked,
        });
    };

    const handleClickNext = () => {
        nextStep();
    };

    return (
        <div>
            <strong>Opções</strong>
            <div>
                Descrição{' '}
                <Switch
                    checked={fieldSetup.isDescription}
                    onChange={handleChange}
                    color="primary"
                    name="isDescription"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </div>
            <div>
                Texto Ajuda{' '}
                <Switch
                    checked={fieldSetup.isHelpText}
                    onChange={handleChange}
                    color="primary"
                    name="isHelpText"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </div>
            <div>
                Obrigatório{' '}
                <Switch
                    checked={fieldSetup.isRequired}
                    onChange={handleChange}
                    color="primary"
                    name="isRequired"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </div>
            <div className={classes.flex}>
                {!!nextStep && (
                    <NextButton onClick={handleClickNext} label={'Próximo'} />
                )}

                {!inEdit && (
                    <Button
                        className={classes.right}
                        variant="outlined"
                        color="default"
                        onClick={() => setShowAddForm(false)}
                    >
                        Cancelar
                    </Button>
                )}
            </div>
        </div>
    );
};
