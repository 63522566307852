import { awaitMapClassStarted } from './../mapboxGl/handleTilesetUnrenderOnRemove';
import { setFieldblocksLayer } from './setFieldblocksLayer';
import { setLayersForGleineExample } from './setLayersForGleineExample';
import { useParams } from 'react-router-dom';
import { downloadFieldblocksFeatures } from './../../firebase/utils/downloadFieldblocksFeatures';
import { useHasFieldblocksAccess } from './useHasFieldblocksAccess';
import { useActions } from './../../formfy/customHooks/useActions';
import { RootState } from './../../store/configureStore';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

type Params = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

export const useRenderFieldblocks = (mapClass: any) => {
    const { hasFieldblocksAccess } = useHasFieldblocksAccess();
    const { orgId, phase1Id, phase2Id }: Params = useParams();

    const fieldblocksFeatures = useSelector(
        (state: RootState) => state.fieldblocks.features
    );

    const { setFeatures } = useActions();

    useEffect(() => {
        if (hasFieldblocksAccess && fieldblocksFeatures === 'not_initialized') {
            downloadFieldblocksFeatures(orgId, phase1Id, phase2Id, setFeatures);
        }
    }, [hasFieldblocksAccess, fieldblocksFeatures]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            hasFieldblocksAccess &&
            mapClass &&
            fieldblocksFeatures !== 'not_initialized'
        ) {
            if (phase2Id === '1mNHZiCpbZeU5LreRqBT') {
                // Gleine example
                awaitMapClassStarted(mapClass).then(() => {
                    setLayersForGleineExample(
                        mapClass.map,
                        fieldblocksFeatures
                    );
                });
            } else {
                awaitMapClassStarted(mapClass).then(() => {
                    setFieldblocksLayer(mapClass.map, fieldblocksFeatures);
                });
            }
        }
    }, [fieldblocksFeatures, mapClass]); // eslint-disable-line react-hooks/exhaustive-deps
};
