import { LinkData } from './../../types/link';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SetLinks = LinkData[] | 'not_initialized';

type SliceState = {
    links: LinkData[] | 'not_initialized';
};

const initialState: SliceState = {
    links: 'not_initialized',
};

const linksSlice = createSlice({
    name: 'linksSlice',
    initialState,
    reducers: {
        setLinks: (state, action: PayloadAction<SetLinks>) => {
            state.links = action.payload;
        },
    },
});

export const actions = linksSlice.actions;
export const { setLinks } = linksSlice.actions;
export type setLinksType = typeof setLinks;

export default linksSlice.reducer;
