import { setFormsType } from './../../features/forms/formsSlice';
import { db } from './../../../firebase/firebase';
import { getFormMetadata } from './getFormMetadata';

export const downloadForms = (phase2Id: string, setForms: setFormsType) => {
    db.collection('registries')
        .where('propertyId', '==', phase2Id)
        .onSnapshot((querySnapshot) => {
            let registriesPromises: any = [];

            console.log('I happened');

            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                const registryData = doc.data();

                registryData.docId = doc.id;

                registriesPromises.push(
                    getFormMetadata(registryData.formId).then(
                        (formData: any) => {
                            registryData.formName = formData.name;
                            registryData.formSlogan = formData.slogan;
                            return registryData;
                        }
                    )
                );
            });

            // return Promise.all(registriesPromises);
            Promise.all(registriesPromises)
                .then((registries) => {
                    // forms.push(registryData);
                    setForms(registries);
                })
                .catch((error) => {
                    console.log('Error getting documents: ', error);
                });
        });

    // }, [docPropriedadeId]);
};
