import { db } from '../firebase';

export const deleteAPPorReservaLegal = (
    docId: string,
    collection: 'area-preservacao-permanente' | 'reserva-legal'
) => {
    return db
        .collection(collection)
        .doc(docId)
        .delete()
        .then((doc) => console.log(`${collection} deleted from firestore!`));
};
