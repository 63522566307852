import { downloadPhase2NameAndCreateAt } from './../firebase/utils/downloadPhase2NameAndCreatedAt';
import { useEffect } from 'react';

import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { useActions } from './useActions';

type Params = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

export const usePhase2NameAndCreatedAt = () => {
    const { orgId, phase1Id, phase2Id }: Params = useParams();

    const phase2Info = useSelector(
        (state: RootState) => state.phase2.selectedPhase2
    );

    const orgUser = useSelector((state: RootState) => {
        return state.organization.orgUser;
    });

    const { setPhase2NameAndCreatedAt } = useActions();

    useEffect(() => {
        downloadPhase2NameAndCreateAt({
            orgId,
            phase1Id,
            phase2Id,
            setPhase2NameAndCreatedAt,
        });
    }, [orgUser]); //eslint-disable-line

    return { phase2Info };
};
