import React, { FunctionComponent } from 'react';
import { FormField } from '../../types';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MoveFieldUp from '../formFields/MoveFieldUp';
import MoveFieldDown from '../formFields/MoveFieldDown';
import FieldOptions from '../formFields/FieldOptions';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 650,
        },
        header: {
            // padding: theme.spacing(0, 0, 3) /* top | horizontal | bottom */,
            padding: theme.spacing(8, 0, 4) /* top | horizontal | bottom */,
        },
        headerRow: {
            '&& > *': {
                fontSize: '1.05rem',
            },
        },
        nameCell: {
            fontWeight: 500,
        },
        flex: {
            display: 'flex',
            justifyContent: 'space-around',
        },
        noPadding: {
            padding: 0,
        },
        dividerRight: {
            borderRight: `1px solid ${theme.palette.divider}`,
        },
    })
);

type Props = {
    phase2FormFields: FormField[] | 'not_initialized';
};

export const Phase2FormFieldsTable: FunctionComponent<Props> = ({
    phase2FormFields,
}) => {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow className={classes.headerRow}>
                        <TableCell>Campo</TableCell>
                        <TableCell align="center">Tipo</TableCell>
                        <TableCell align="center">Obrigatório</TableCell>
                        <TableCell align="center">Índice</TableCell>
                        <TableCell align="center">Lista de Itens</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {phase2FormFields !== 'not_initialized' &&
                        phase2FormFields.map((field) => (
                            <TableRow key={field.fieldId}>
                                <TableCell
                                    className={classes.nameCell}
                                    component="th"
                                    scope="row"
                                >
                                    {field.name}
                                </TableCell>
                                <TableCell align="center">
                                    {field.type}
                                </TableCell>
                                <TableCell align="center">
                                    {field.isRequired ? (
                                        <CheckBoxIcon color="primary" />
                                    ) : (
                                        <CheckBoxOutlineBlankIcon color="primary" />
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {field.idx + 1}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.dividerRight}
                                >
                                    {field.checklistItems
                                        ? field.checklistItems.join(',\n')
                                        : field.selectItems
                                        ? field.selectItems.map(
                                              (item: string) => (
                                                  <div>{item}</div>
                                              )
                                          )
                                        : []}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={clsx(
                                        classes.noPadding,
                                        classes.dividerRight
                                    )}
                                >
                                    <div>
                                        <span className={classes.flex}>
                                            <MoveFieldUp
                                                field={field}
                                                closePopover={() => {}}
                                                formId={'phase2FormFields'}
                                            />
                                            <MoveFieldDown
                                                field={field}
                                                closePopover={() => {}}
                                                formId={'phase2FormFields'}
                                            />
                                        </span>
                                    </div>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.noPadding}
                                >
                                    <FieldOptions
                                        field={field}
                                        formId={'phase2FormFields'}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
