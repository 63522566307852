import { db } from './../../../firebase/firebase';

export const storeFieldblockFeature = (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    fieldblockId: string,
    feature: any
) => {
    const newFeature: any = { ...feature };

    newFeature.geometry.coordinates = JSON.stringify(
        feature.geometry.coordinates
    );

    return db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('fieldblocks')
        .doc(fieldblockId)
        .update({ feature: newFeature })
        .catch((err) => {
            console.log(err);
            alert('Error!! Não foi possível salvar o registro de Fieldblocks!');
        });
};
