export const makeMousemoveListener = (map: any) => {
    const mousemoveListener = function (e: any) {
        if (!map.distanceMeasurerTurnedOn) return;

        var features = map.queryRenderedFeatures(e.point, {
            layers: ['measure-points'],
        });
        // UI indicator for clicking/hovering a point on the map
        map.getCanvas().style.cursor = features.length
            ? 'pointer'
            : 'crosshair';
    };

    return mousemoveListener;
};
