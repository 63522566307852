import React from 'react';

//Redux
import { useAppDispatch } from '../../store/reduxCustomHooks';
import { AsyncRemovePhase2UserAcces } from '../members/membersSlice';

//Components

//Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            padding: theme.spacing(1),
            '&:hover': {
                borderWidth: theme.spacing(0.25),
                borderColor: 'black',
            },
        },
    })
);

type Props = {
    user: {
        userId: string;
        orgId: string;
        phase1Id: string;
        phase2Id: string;
        userName: string;
        email: string;
        organizationAccessLevel: string;
        userAlreadyHasAccess: boolean;
    };
};

export default function Phase2PaperItemMember({ user }: Props) {
    const classes = useStyles();

    const dispatch = useAppDispatch();

    return (
        <div>
            <Box my={1}>
                <Paper variant="outlined" className={classes.paperRoot}>
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <Typography variant="body1" noWrap>
                                {user.userName}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" noWrap>
                                {user.email}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1" noWrap align="left">
                                {user.organizationAccessLevel}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                aria-label="delete"
                                size="small"
                                color="secondary"
                                onClick={() => {
                                    dispatch(
                                        AsyncRemovePhase2UserAcces({
                                            phase1Id: user.phase1Id,
                                            phase2Id: user.phase2Id,
                                            orgId: user.orgId,
                                            userId: user.userId,
                                        })
                                    );
                                }}
                            >
                                <HighlightOffIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </div>
    );
}
