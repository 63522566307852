import { setUserIdType } from './../../features/auth/authSlice';
import { auth } from '../firebase';

export function addAuthStateListener(setUserId: setUserIdType) {
    auth.onAuthStateChanged((user) => {
        if (user) {
            // console.log('user logged in', user.uid);
            setUserId(user.uid);
        } else {
            // User logged off
            // console.log('user logged off');
            setUserId('');
        }
    });
}
