import { ProcessWatcherProps } from './TiffUploadProcessWatcher';
import { Tileset } from './../../types/tileset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SetTilesets = Tileset[] | 'not_initialized';

// type AddProcessWatcher =

type SliceState = {
    tilesets: Tileset[] | 'not_initialized';
    processWatchers: ProcessWatcherProps[];
};

const initialState: SliceState = {
    tilesets: 'not_initialized',
    processWatchers: [],
};

const tilesetsSlice = createSlice({
    name: 'tilesetsSlice',
    initialState,
    reducers: {
        setTilesets: (state, action: PayloadAction<SetTilesets>) => {
            state.tilesets = action.payload;
        },
        addProcessWatcher: (
            state,
            action: PayloadAction<ProcessWatcherProps>
        ) => {
            state.processWatchers.push(action.payload);
        },
    },
});

export const actions = tilesetsSlice.actions;
export const { setTilesets, addProcessWatcher } = tilesetsSlice.actions;
export type setTilesetsType = typeof setTilesets;
export type addProcessWatcherType = typeof addProcessWatcher;

export default tilesetsSlice.reducer;
