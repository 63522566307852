import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import { storePhase2Field } from '../../firebase/utils/storePhase2Field';
import { FormField } from '../../types';
import { AddField } from '../formFields/AddField';

type Props = {
    phase2FormFields: FormField[] | 'not_initialized';
};

export const AddPhase2FormField: FunctionComponent<Props> = ({
    phase2FormFields,
}) => {
    const orgId: string = useSelector(
        (state: RootState) => state.organization.orgId
    );

    const storeField = (fieldInfo: any) => {
        return storePhase2Field(fieldInfo, orgId);
    };

    return (
        <div>
            <AddField
                fieldsArraySize={phase2FormFields.length}
                formId={'phase2FormFields'}
                storeField={storeField}
            />
        </div>
    );
};
