import { OrgUser } from './../../types/organization';
import { db } from './../../firebase/firebase';
// import firebase from 'firebase';

export const findUserOrgs = async (userId: string) => {
    const userDocs: Array<{ orgId: string; userDoc: OrgUser }> = [];

    try {
        const query = db.collectionGroup('users');
        const querySnapshot = await query
            .where('id', '==', userId)
            .get()
            .catch((error) => {
                console.log(error);
                return [];
            });

        querySnapshot.forEach((doc) => {
            const parentCollection = doc.ref.parent.parent;
            if (!parentCollection) {
                return;
            }
            if (parentCollection.parent.id === 'organizations') {
                const orgId = parentCollection.id;
                const userDoc: any = doc.data();
                userDocs.push({
                    orgId,
                    userDoc,
                });
            }
        });
    } catch (error) {
        console.log(error);
    }

    // for (const doc of userDocs) {
    //   const contents = await functionGetOrgPublicInfo(doc.orgId);
    //   console.log('contents', contents);
    // }

    return userDocs;
};
