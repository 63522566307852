import { Position } from './../../types/photoData';
import { uploadPhotoDocument } from './../../firebase/utils/uploadPhotoDocument';
import { uploadThumbnail } from './uploadThumbnail';
import { makeThumbnail } from './makeThumbnail';
import { uploadPhotoToDrive } from './uploadPhotoToDrive';
// import { createFolder } from './gdrive/createFolder';
import { db } from './../../firebase/firebase';
import { RootState } from './../../store/configureStore';
import { useSelector } from 'react-redux';
// import { drive } from './gdrive/drive';
import { useParams } from 'react-router-dom';

type Params = {
    orgId: string;
    phase1Id: string;
    phase2Id: string;
};

export const usePhotoUploader = () => {
    const { orgId, phase1Id, phase2Id } = useParams<Params>();
    const gdriveFolderId = useSelector(
        (state: RootState) => state.phase2.selectedPhase2?.gdriveFolderId
    );

    const updatePhase2GDriveFolderId = (id: string) => {
        return db
            .doc(`organizations/${orgId}/phase1/${phase1Id}/phase2/${phase2Id}`)
            .update({
                gdriveFolderId: id,
            })
            .then(() => console.log('phase2 document updated!'))
            .catch((error) =>
                console.log('not possible to update phase2 document')
            );
    };

    // const uploadImageToGDrive = (file: File) => {
    //   console.log(file);
    // };

    const uploadPhoto = async (
        file: File,
        filePosition: Position,
        chosenImagePosition: Position,
        photoDate: Date,
        locationOption: 'manual' | 'dontShow' | 'automatic'
    ) => {
        try {
            const response = await uploadPhotoToDrive({
                file,
                phase2Id,
                gdriveFolderId: gdriveFolderId || '',
            });

            console.log(response);

            if (response.status !== 201) {
                console.log(response);
                return alert('Não foi possível realizar o upload!');
            }

            const { folderId, fileId, urlData } = response.data;

            if (folderId === 'undefined') {
                console.log('Folder id is undefined!');
                return 'error';
            }

            const thumbnailFile = await makeThumbnail(file, phase2Id);

            if (!thumbnailFile)
                throw new Error('Não foi possível criar a thumbnail!');

            const { thumbnailUrl, thumbnailNameInStorage } =
                await uploadThumbnail(thumbnailFile, phase2Id);

            console.log({ thumbnailUrl, thumbnailNameInStorage });

            if (!gdriveFolderId) {
                await updatePhase2GDriveFolderId(folderId).then(() =>
                    console.log('Updated gdriveFolderId in ' + phase2Id)
                );
            }

            return uploadPhotoDocument(orgId, phase1Id, phase2Id, {
                gdriveUrl: urlData.webViewLink,
                createdAt: new Date(),
                thumbnailUrl,
                thumbnailNameInStorage,
                gdriveId: fileId,
                date: photoDate,
                position: filePosition,
                chosenImagePosition,
                locationOption,
            }).then(() => 'success');
        } catch (error) {
            console.log(error);
            return 'error';
        }
    };

    return {
        uploadPhoto,
    };
};
