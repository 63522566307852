//import { LinkData } from './../../types/link';
import { db } from '../firebase';
import { nanoid } from 'nanoid';

export const createShareLink = (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    period: number,
    originUrl: string
) => {
    const linkUrl = nanoid(8);

    const fullPath = `${originUrl}/organizationId/${orgId}/phase1Id/${phase1Id}/phase2Id/${phase2Id}/shareLink/${linkUrl}`;

    //const expiration = period * 1000 * 60 * 60 * 24
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + period);

    return db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('shareLinks')
        .add({ linkUrl, expirationDate })
        .then((doc) => {
            console.log('ShareLink correctly created');
            return fullPath;
        });
};
