import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
// import { fieldblocksType } from '../../API/useFieldblocks';
import { storeFieldblocksField } from '../../firebase/utils/storeFieldblocksField';
import { FormField } from '../../types';
import { AddField } from '../formFields/AddField';

type Props = {
    fieldblocksFormFields: FormField[] | 'not_initialized';
};

export const AddFieldblocksFormField: FunctionComponent<Props> = ({
    fieldblocksFormFields,
}) => {
    const orgId: string = useSelector(
        (state: RootState) => state.organization.orgId
    );

    const storeField = (fieldInfo: any) => {
        return storeFieldblocksField(fieldInfo, orgId);
    };

    return (
        <div>
            <AddField
                fieldsArraySize={fieldblocksFormFields.length}
                formId={'fieldblocksFormFields'}
                storeField={storeField}
            />
        </div>
    );
};
