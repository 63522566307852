import { db } from './../../../firebase/firebase';

export const startFieldblocksFormSpecialFields = (orgId: string) => {
    try {
        const organizationRef = db.collection('organizations').doc(orgId);
        const batch = db.batch();

        batch.set(
            organizationRef.collection('fieldblocksFormFields').doc('name'),
            {
                formId: 'fieldblocksFormFields',
                idx: 0,
                isDescription: false,
                isHelpText: false,
                isRequired: true,
                name: 'Nome',
                type: 'Texto Curto',
            }
        );

        batch.set(
            organizationRef
                .collection('fieldblocksFormFields')
                .doc('createdAt'),
            {
                formId: 'fieldblocksFormFields',
                idx: 1,
                isDescription: false,
                isHelpText: false,
                isRequired: true,
                name: 'Criado Em',
                type: 'Data',
            }
        );

        batch.commit().then(() => {
            console.log('startFieldblocksFormSpecialFields successful!');
        });
    } catch (error: any) {
        console.log(error);
        alert(error.message);
    }
};
