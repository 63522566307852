import React, { useState } from 'react';
import { AddFieldForm } from './AddFieldForm';
import { Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        love: {
            backgroundColor: theme.palette.primary.light,
        },
    })
);

export const AddField = ({ fieldsArraySize, formId, storeField }) => {
    const classes = useStyles();
    const [showAddForm, setShowAddForm] = useState(false);

    const toggleAddForm = () => {
        setShowAddForm(!showAddForm);
    };

    const submitFieldInfo = (fieldInfo) => {
        let field = { ...fieldInfo, idx: fieldsArraySize, formId };

        // let chosenStorer = customStoreField ? customStoreField : storeField;

        storeField(field).then((fieldId) => {
            if (!fieldId) {
                alert('Um erro aconteceu. Por favor, tente novamente.');
                return toggleAddForm();
            }
            // addField(field)
            toggleAddForm();
        });
    };

    return (
        <div>
            {showAddForm && (
                <AddFieldForm
                    setShowAddForm={setShowAddForm}
                    submitFieldInfo={submitFieldInfo}
                />
            )}
            <Button
                variant="contained"
                color="primary"
                onClick={toggleAddForm}
                // label={'adicionar campo'}
                size="large"
                startIcon={<AddCircleIcon />}
                className={classes.love}
                // {...customProps}
            >
                adicionar campo
            </Button>
            {/* {showAddForm ? (
        <CancelButton onClick={toggleAddForm} label={'cancelar'} />
      ) : (
        // <AddButton onClick={toggleAddForm} label={'adicionar campo'} />
        <Button
          variant='contained'
          color='primary'
          onClick={toggleAddForm}
          // label={'adicionar campo'}
          size='large'
          startIcon={<AddCircleIcon />}
          className={classes.love}
          // {...customProps}
        >
          adicionar campo
        </Button>
      )} */}
        </div>
    );
};

// const mapDispatchToProps = {
//   // addField,
// };

// const selectFormFields = (state, ownProps) => state.formFields[ownProps.formId];
// const selectFieldsArraySize = (state, ownProps) => {
//   let fields = selectFormFields(state, ownProps);
//   if (fields) {
//     return fields.length;
//   }
// };

// const mapStateToProps = (state, ownProps) => ({
//   fieldsArraySize: selectFieldsArraySize(state, ownProps),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(AddField);
