import { RootState } from '../store/configureStore';
import { useSelector } from 'react-redux';
import { useActions } from './useActions';
import { downloadLinks } from './../firebase/utils/downloadLinks';
import { useEffect } from 'react';

export const useLinks = (orgId: string, phase1Id: string, phase2Id: string) => {
    const { setLinks } = useActions();
    const links = useSelector((state: RootState) => state.links.links);

    useEffect(() => {
        if (links === 'not_initialized') {
            downloadLinks(orgId, phase1Id, phase2Id, setLinks);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return { links };
};
