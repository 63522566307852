import React, { FunctionComponent } from 'react';

//Functions

//MaterialUI
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TiffUploader } from './TiffUploader';
import { ProcessWatcherProps } from './TiffUploadProcessWatcher';
import { FormattedMessage } from 'react-intl';
import { DialogActions, DialogContent } from '@material-ui/core';

type Props = {
    addWatcher: (watcher: ProcessWatcherProps) => void;
};

const AddMapDialogComponent: FunctionComponent<Props> = ({ addWatcher }) => {
    const [open, setOpen] = React.useState(false);
    function handleClickOpen() {
        setOpen(true);
    }
    function handleClose() {
        setOpen(false);
    }

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
                fullWidth
            >
                {/* <FormattedMessage defaultMessage="Adicionar Mapa" id="app.addMap"> */}
                <FormattedMessage
                    id="app.addMap"
                    defaultMessage="Adicionar Mapa"
                />
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                disableBackdropClick
            >
                <DialogTitle id="form-dialog-title">
                    <FormattedMessage
                        id="app.addMap"
                        defaultMessage="Adicionar Mapa"
                    />
                </DialogTitle>
                <DialogContent>
                    <TiffUploader
                        addWatcher={addWatcher}
                        onSaveCallback={handleClose}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        color="primary"
                        fullWidth
                    >
                        <FormattedMessage
                            id="app.cancel"
                            defaultMessage="Cancelar"
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddMapDialogComponent;
