import React from 'react';
import { useParams } from 'react-router';
import { db, Timestamp } from './../../firebase/firebase';
import { SoilAnalysisLinkData } from 'types/link';
import { useAppDispatch } from '../../store/reduxCustomHooks';
import { setSoilAnalysisLinks } from 'features/soilAnalysis/soilAnalysisSlice';

export const isTimestamp = (value: any) => value instanceof Timestamp;

interface URLParams {
    phase2Id: string;
}

export default function RealtimeUpdatesSoilAnalysesByPhase2Id() {
    const { phase2Id }: URLParams = useParams();
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        const dbRef = db
            .collection('soilAnalysis')
            .where('phase2Id', '==', `${phase2Id}`);
        const unsubscribe = dbRef.onSnapshot((querySnapshot) => {
            const soilAnalysisLinksList: SoilAnalysisLinkData[] = [];
            querySnapshot.forEach((doc) => {
                const data: any = doc.data();
                Object.entries(data).forEach((e) => {
                    if (isTimestamp(e[1])) {
                        // @ts-ignore
                        data[e[0]] = e[1].toDate();
                    }
                });

                data.linkDocId = doc.id;
                soilAnalysisLinksList.push(data);
            });
            console.log(
                'Current soilAnalysisLinksList: ',
                soilAnalysisLinksList
            );
            dispatch(setSoilAnalysisLinks(soilAnalysisLinksList));
        });

        return () => unsubscribe();
    }, [phase2Id, dispatch]);

    return null;
}
