import { setTilesetsType } from '../../features/tilesets/tilesetsSlice';
import { Tileset } from '../../types/tileset';
import { db, Timestamp } from '../firebase';

export const isTimestamp = (value: any) => value instanceof Timestamp;

export const downloadTilesets = (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    setTilesets: setTilesetsType
) => {
    return db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('tilesets')
        .onSnapshot((querySnapshot) => {
            const tilesets: Tileset[] = [];

            querySnapshot.forEach((doc: any) => {
                const data: any = doc.data();

                Object.entries(data).forEach((e) => {
                    if (isTimestamp(e[1])) {
                        // @ts-ignore
                        data[e[0]] = e[1].toDate();
                    }
                });

                if (data.index === undefined) {
                    data.index = -1;
                }

                data.tilesetDocId = doc.id;
                tilesets.push(data);
            });

            // console.log(tilesets);

            setTilesets(tilesets);
        });
};
