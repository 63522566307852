import * as turf from '@turf/turf';

type Feature = {
    geometry: {
        coordinates: [number, number][][]; // [longitude, latitude][][]
        type: 'Polygon';
    };
    id: string;
    properties: {
        title: string;
    };
};

function addSource(map: any, feature: Feature) {
    const sourceUniqueId = `id-${feature.id}`;
    // console.log('Teste sourceUniqueId =>', sourceUniqueId);
    const fillUniqueId = `fill-id-${feature.id}`;
    const lineUniqueId = `line-id-${feature.id}`;
    const sourceTitleUniqueId = `source-title-id-${feature.id}`;
    const titleUniqueId = `title-id-${feature.id}`;
    // console.log('feature.properties.title', feature.properties.title);
    let fillColor = '#00ff00';
    // Add a data source containing GeoJSON data.
    map.addSource(sourceUniqueId, {
        type: 'geojson',
        data: {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: feature.geometry.coordinates,
            },
        },
    });

    // Add a new layer to visualize the polygon.
    map.addLayer({
        id: fillUniqueId,
        type: 'fill',
        source: sourceUniqueId, // reference the data source
        layout: {},
        paint: {
            'fill-color': fillColor,
            'fill-opacity': 0.8, // 100% opaque
            'fill-opacity-transition': { duration: 250 }, // 500 milliseconds = 1/2 seconds
        },
    });
    // Add a black outline around the polygon.
    map.addLayer({
        id: lineUniqueId,
        type: 'line',
        source: sourceUniqueId,
        layout: {},
        paint: {
            'line-color': '#000',
            'line-width': 3,
        },
    });

    const polygon = turf.polygon([feature.geometry.coordinates[0]]);
    const centroid = turf.centerOfMass(polygon); //Centroid of a polygon

    const centroidCoords = centroid.geometry.coordinates;
    // console.log(centroidCoords, 'centroidCoords');
    const area = turf.area(polygon);
    const hectares = area / 10000;
    const rounded_hectares = Math.round(hectares * 100) / 100;
    // console.log('rounded_area', rounded_hectares);
    // console.log(area, 'area oxi mano');

    map.addSource(sourceTitleUniqueId, {
        type: 'geojson',
        data: {
            type: 'Feature',
            geometry: {
                coordinates: centroidCoords,
                type: 'Point',
            },
            properties: {
                title: `${feature.properties.title} ${rounded_hectares} ha`,
            },
        },
    });

    // Add a polygons layer
    map.addLayer({
        id: titleUniqueId,
        type: 'symbol',
        source: sourceTitleUniqueId,
        layout: {
            'text-field': ['get', 'title'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            // "text-offset": [0, 0.6],
            // "text-anchor": "top",
            'text-size': 24,
        },
        paint: {
            'text-color': 'black',
        },
    });

    // Increase opacity on mouse enter
    map.on('mouseenter', fillUniqueId, function () {
        map.setPaintProperty(fillUniqueId, 'fill-opacity', 0.0);
        map.setPaintProperty(titleUniqueId, 'text-color', 'white');
    });

    // Reset opacity on mouse leave
    map.on('mouseleave', fillUniqueId, function () {
        map.setPaintProperty(fillUniqueId, 'fill-opacity', 0.8);
        map.setPaintProperty(titleUniqueId, 'text-color', 'black');
    });
}

export const setLayers = (map: any, features: Feature[]) => {
    features.forEach((feature) => {
        addSource(map, feature);
    });
};

export const removeLayers = (map: any, features: Feature[]) => {
    features.forEach((feature) => {
        const sourceUniqueId = `id-${feature.id}`;
        const fillUniqueId = `fill-id-${feature.id}`;
        const lineUniqueId = `line-id-${feature.id}`;
        const sourceTitleUniqueId = `source-title-id-${feature.id}`;
        const titleUniqueId = `title-id-${feature.id}`;

        if (map.getLayer(fillUniqueId)) {
            map.removeLayer(fillUniqueId);
        }
        if (map.getLayer(lineUniqueId)) {
            map.removeLayer(lineUniqueId);
        }

        if (map.getLayer(titleUniqueId)) {
            map.removeLayer(titleUniqueId);
        }

        if (
            !map.getLayer(sourceTitleUniqueId) &&
            !map.getLayer(lineUniqueId) &&
            map.getSource(sourceUniqueId)
        ) {
            map.removeSource(sourceUniqueId);
        }
        if (
            !map.getLayer(titleUniqueId) &&
            map.getSource(sourceTitleUniqueId)
        ) {
            map.removeSource(sourceTitleUniqueId);
        }
    });
};
