import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router';
import { useActions } from '../../hooks/useActions';
import { usePhase2FormFields } from '../API/usePhase2FormFields';

import { Phase2FormFieldsTable } from '../features/phase2/Phase2FormFieldsTable';
import NavbarOrganization from '../../features/navbars/NavbarOrganization';
import { AddPhase2FormField } from '../features/phase2/AddFormField';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Phase2FormManagePreview } from '../features/phase2/Phase2FormManagePreview';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            // padding: theme.spacing(0, 0, 3) /* top | horizontal | bottom */,
            padding: theme.spacing(8, 0, 4) /* top | horizontal | bottom */,
        },
    })
);
export const Phase2FormManagePage: FunctionComponent<any> = () => {
    const classes = useStyles();
    const { phase2FormFields } = usePhase2FormFields(undefined);
    const params: any = useParams();
    const { setOrgId } = useActions();

    useEffect(() => {
        setOrgId(params.orgId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <NavbarOrganization />
            <Grid
                container
                className={classes.header}
                justify="center"
                spacing={1}
            >
                <Grid item xs={12} sm={9}>
                    <Phase2FormFieldsTable
                        phase2FormFields={phase2FormFields}
                    />
                </Grid>
                <Grid item xs={12} sm={9}>
                    <AddPhase2FormField phase2FormFields={phase2FormFields} />
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={4}>
                    <Phase2FormManagePreview />
                </Grid>
            </Grid>
        </>
    );
};
