import { FormField } from './../../types/formField';
import db from '../../../firebase/firebase';

export const removeField = (
    field: FormField,
    fields: FormField[],
    orgId: string
) => {
    let batch = db.batch();

    console.log(field, fields, orgId);

    let colRef = db
        .collection('organizations')
        .doc(orgId)
        .collection(field.formId);

    let fieldRef = colRef.doc(field.fieldId);

    batch.delete(fieldRef);

    let belowFields = fields.slice(field.idx + 1, fields.length);
    belowFields.forEach((bfield) => {
        let bfieldRef = colRef.doc(bfield.fieldId);
        batch.update(bfieldRef, 'idx', bfield.idx - 1);
    });

    return batch.commit().catch(() => alert('Error!! Could not remove field!'));
};
