import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SliceState = {
    isMapLoaded: boolean | 'not_sure';
};

const initialState: SliceState = {
    isMapLoaded: 'not_sure',
};

const mapSlice = createSlice({
    name: 'mapSlice',
    initialState,
    reducers: {
        setIsMapLoaded: (state, action: PayloadAction<boolean>) => {
            state.isMapLoaded = action.payload;
        },
    },
});

export const actions = mapSlice.actions;
export const { setIsMapLoaded } = mapSlice.actions;

export type setIsMapLoadedType = typeof setIsMapLoaded;

export default mapSlice.reducer;
