import { Feature } from './../../../types/feature';
import { Registry } from '../../types/registry';
import { FormField } from '../../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormFieldTypes, FormFieldTypesNames } from '../../types/formField';

type SetFieldblocksFormFields = {
    fieldblocksFormFields: FormField[] | 'not_initialized';
};

type SetFieldblocksRegistries = {
    fieldblocksRegistries: Registry[] | 'not_initialized';
};

type SetFieldblocksRegistryState = {
    fieldId: string;
    value: FormFieldTypes;
};

type StartFieldblocksRegistryState = {
    formFields: FormField[];
    initialRegistry: Registry | undefined;
};

type RegistryStateField = {
    value: FormFieldTypes | null;
    type: FormFieldTypesNames;
};

type FieldblocksRegistryState =
    | ({
          [key: string]: RegistryStateField;
      } & {
          fieldblockId?: string; // used to check editMode vs addMode
      })
    | null;

type AllowedOrgIds = string[];

type SliceState = {
    formFields: FormField[] | 'not_initialized';
    registries: Registry[] | 'not_initialized';
    registryState: FieldblocksRegistryState;
    phase1Id: string | null;
    phase2Id: string | null;
    allowedOrgIds: AllowedOrgIds | 'not_initialized';
    features: Feature[] | 'not_initialized';
    // selectedFieldblocks: null | FieldblocksInfo;
};

const initialState: SliceState = {
    formFields: 'not_initialized',
    registries: 'not_initialized',
    phase1Id: null,
    phase2Id: null,
    registryState: null,
    allowedOrgIds: 'not_initialized',
    features: 'not_initialized',
};

function isEmpty(obj: object) {
    return Object.keys(obj).length === 0;
}

export const fieldblocksSlice = createSlice({
    name: 'fieldblocksSlice',
    initialState,
    reducers: {
        setFieldblocksFormFields: (
            state,
            action: PayloadAction<SetFieldblocksFormFields>
        ) => {
            const { fieldblocksFormFields } = action.payload;
            state.formFields = fieldblocksFormFields;
        },
        setFieldblocksRegistries: (
            state,
            action: PayloadAction<SetFieldblocksRegistries>
        ) => {
            const { fieldblocksRegistries } = action.payload;
            state.registries = fieldblocksRegistries;
        },
        setFieldblocksRegistryState: (
            state,
            action: PayloadAction<SetFieldblocksRegistryState>
        ) => {
            const { fieldId, value } = action.payload;
            if (state.registryState) {
                state.registryState[fieldId].value = value;
            }
        },
        startFieldblocksRegistryState: (
            state,
            action: PayloadAction<StartFieldblocksRegistryState>
        ) => {
            let { formFields, initialRegistry } = action.payload;

            let initialStateObj: FieldblocksRegistryState = {};

            if (initialRegistry !== undefined) {
                // edit case
                formFields.forEach((field: FormField) => {
                    // @ts-ignore: Object is possibly 'undefined'.
                    let value = initialRegistry[field.fieldId] || null;
                    let fieldState: RegistryStateField = {
                        type: field.type,
                        value,
                    };
                    // @ts-ignore: Object is possibly 'null'.
                    initialStateObj[field.fieldId] = { ...fieldState };
                });
            } else {
                // add case
                formFields.forEach((field: FormField) => {
                    let fieldState: RegistryStateField = {
                        type: field.type,
                        value: null,
                    };
                    // @ts-ignore: Object is possibly 'null'.
                    initialStateObj[field.fieldId] = { ...fieldState };
                });
            }

            state.registryState = initialStateObj;

            if (initialRegistry !== undefined && !isEmpty(initialRegistry))
                // @ts-ignore: Object is possibly 'null'.
                //state[formId].registryState.phase1Id = initialRegistry.phase1Id;
                state.registryState.fieldblockId = initialRegistry.fieldblockId;
        },
        finishFieldblocksRegistryState: (state, action) => {
            state.registryState = null;
        },
        setAllowedOrgIds: (state, action: PayloadAction<AllowedOrgIds>) => {
            state.allowedOrgIds = action.payload;
        },
        setFeatures: (
            state,
            action: PayloadAction<Feature[] | 'not_initialized'>
        ) => {
            state.features = action.payload;
        },
    },
});

export const actions = fieldblocksSlice.actions;

export const {
    setFieldblocksFormFields,
    setFieldblocksRegistries,
    startFieldblocksRegistryState,
    finishFieldblocksRegistryState,
    setAllowedOrgIds,
    setFeatures,
} = fieldblocksSlice.actions;

export type setFieldblocksFormFieldsType = typeof setFieldblocksFormFields;
export type setFieldblocksRegistriesType = typeof setFieldblocksRegistries;
export type startFieldblocksRegistryStateType =
    typeof startFieldblocksRegistryState;
export type finishFieldblocksRegistryStateType =
    typeof finishFieldblocksRegistryState;
export type setAllowedOrgIdsType = typeof setAllowedOrgIds;
export type setFeaturesType = typeof setFeatures;

export default fieldblocksSlice.reducer;
