import { Box, Button, Divider, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            // alignItems: 'center',
            paddingTop: '20vh',
        },
        infoDiv: {
            width: 300,
            wordWrap: 'break-word',
            justifyContent: 'center',
            textAlign: 'center',
        },
        font404: {
            fontSize: '1.5rem',
            fontWeight: 1000,
            letterSpacing: -2,
            marginBottom: theme.spacing(2),
        },
    })
);

const NotFoundPage: FunctionComponent = ({ history }: any) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.infoDiv}>
                    <Box marginBottom={4}>
                        <Typography variant="h3" color="primary">
                            DroneReport
                        </Typography>
                        <Divider></Divider>
                    </Box>
                    <Box marginBottom={4}></Box>
                    <div className={classes.font404}>
                        <FormattedMessage
                            id="app.error"
                            defaultMessage="Erro"
                        />{' '}
                        404.{' '}
                        <Typography variant="subtitle1" component="div">
                            <FormattedMessage
                                id="app.thisPathWasNotFound"
                                defaultMessage="A rota especificada não foi encontrada!"
                            />
                        </Typography>
                    </div>
                    <Typography variant="subtitle2">
                        <FormattedMessage
                            id="app.goToHomepage"
                            defaultMessage="Deseja voltar à página inicial?"
                        />
                    </Typography>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => history.push('/')}
                        >
                            <FormattedMessage
                                id="app.homepage"
                                defaultMessage="Página inicial"
                            />
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default NotFoundPage;
