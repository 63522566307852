import Resizer from 'react-image-file-resizer';

const resizeFile = (file: File) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            312,
            300,
            'JPEG',
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            'base64',
            312
        );
    });

function urltoFile(url: any, filename: any, mimeType: any) {
    return fetch(url)
        .then(function (res) {
            return res.arrayBuffer();
        })
        .then(function (buf) {
            return new File([buf], filename, { type: mimeType });
        });
}

//Usage example:

export const makeThumbnail = async (file: File, phase2Id: string) => {
    try {
        const image = await resizeFile(file);
        console.log(file);

        return urltoFile(image, 'hello.txt', 'image/jpg').then(function (file) {
            console.log(file);
            return file;
        });

        // console.log(image);
    } catch (err) {
        console.log(err);
    }
};
