import { CalculateCenterCoordinates } from './CalculateCenterCoordinates';
import {
    startPolygonAnnotationsSource,
    startPolygonAnnotationsTitlesSource,
    startLinestringsAnnotationsSource,
    startLinestringsAnnotationsTitlesSource,
    startPointsAnnotationsSource,
} from './startAnnotationsSources';
import { Annotation } from './../../types/annotation';

const annotationsToFeatures = (annotations: Annotation[]) => {
    return annotations.map((a) => {
        //@ts-ignore
        const coordinates = JSON.parse(a.feature.geometry.coordinates);

        let feature = {
            id: a.annotationDocId!,
            ...a.feature,
            geometry: { ...a.feature.geometry, coordinates },
            properties: {
                title: a.title,
            },
        };

        return feature;
    });
};

const annotationsToTitlesFeatures = (annotations: Annotation[]) => {
    return annotations.map((a) => {
        //@ts-ignore
        // const polygon = turf.polygon(JSON.parse(a.feature.geometry.coordinates));
        // const centerOfMass = turf.centerOfMass(polygon);

        const centerOfMass = CalculateCenterCoordinates(a);

        return {
            type: 'Feature',
            geometry: {
                coordinates: centerOfMass,
                type: 'Point',
            },
            properties: {
                title: a.title,
            },
        };
    });
};

const setPolygonAnnotationsLayer = (
    map: any,
    polygonAnnotations: Annotation[]
) => {
    const polygonsSource = map.getSource('polygonAnnotations');

    if (!polygonsSource) {
        startPolygonAnnotationsSource(map);
        startPolygonAnnotationsTitlesSource(map);
    }

    const polygonFeatures = annotationsToFeatures(polygonAnnotations);
    const titlesFeatures = annotationsToTitlesFeatures(polygonAnnotations);

    map.getSource('polygonAnnotations').setData({
        type: 'FeatureCollection',
        features: polygonFeatures,
    });

    map.getSource('polygonAnnotationsTitles').setData({
        type: 'FeatureCollection',
        features: titlesFeatures,
    });
};

const setLinestringAnnotationsLayer = (
    map: any,
    linestringsAnnotations: Annotation[]
) => {
    const linestringsSource = map.getSource('linestringsAnnotations');

    if (!linestringsSource) {
        startLinestringsAnnotationsSource(map);
        startLinestringsAnnotationsTitlesSource(map);
    }

    const linestringsFeatures = annotationsToFeatures(linestringsAnnotations);
    const titlesFeatures = annotationsToTitlesFeatures(linestringsAnnotations);

    map.getSource('linestringsAnnotations').setData({
        type: 'FeatureCollection',
        features: linestringsFeatures,
    });

    map.getSource('linestringsAnnotationsTitles').setData({
        type: 'FeatureCollection',
        //features: [],
        features: [],
    });

    map.getSource('linestringsAnnotationsTitles').setData({
        type: 'FeatureCollection',
        //features: [],
        features: titlesFeatures,
    });
};

const setPointsAnnotationsLayer = (
    map: any,
    pointsAnnotations: Annotation[]
) => {
    const pointsSource = map.getSource('pointsAnnotations');

    if (!pointsSource) {
        startPointsAnnotationsSource(map);
    }

    const pointsFeatures = annotationsToFeatures(pointsAnnotations);

    map.getSource('pointsAnnotations').setData({
        type: 'FeatureCollection',
        //features: [],
        features: pointsFeatures,
    });
};

export const setAnnotationsLayer = (map: any, annotations: Annotation[]) => {
    const linestringAnnotations = annotations.filter(
        (a) => a.feature.geometry.type === 'LineString'
    );
    const polygonAnnotations = annotations.filter(
        (a) => a.feature.geometry.type === 'Polygon'
    );
    const pointsAnnotations = annotations.filter(
        (a) => a.feature.geometry.type === 'Point'
    );

    setPolygonAnnotationsLayer(map, polygonAnnotations);
    setLinestringAnnotationsLayer(map, linestringAnnotations);
    setPointsAnnotationsLayer(map, pointsAnnotations);
};
