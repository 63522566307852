import { UserOrgs } from './../../types/user';
// import { OrgUser } from './../../types/organization';
import { RootState } from '../../store/configureStore';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
// import { getOrgUsers } from '../../firebase/utils/getOrgUsers';
import { removePhase2UserAcces } from '../../firebase/utils/removePhase2UserAcces';
import { addPhase2UserAcces } from '../../firebase/utils/addPhase2UserAcces';

import { UserHasAccess, UserHasAcessPhase2Path } from '../../types/user';

const initialState: SliceState = {
    userOrgs: [],
    orgUsers: [],
};

type SliceState = {
    userOrgs: UserOrgs;
    orgUsers: UserHasAccess[];
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const AsyncRemovePhase2UserAcces = createAsyncThunk(
    'members/removePhase2UserAcces',
    async ({ orgId, userId, phase1Id, phase2Id }: UserHasAcessPhase2Path) => {
        await removePhase2UserAcces({
            orgId,
            userId,
            phase1Id,
            phase2Id,
        });
        // The value we return becomes the `fulfilled` action payload
        return;
    }
);

export const ActionAddPhase2UserAcces = createAsyncThunk(
    'members/addPhase2UserAcces',
    async ({ orgId, userId, phase1Id, phase2Id }: UserHasAcessPhase2Path) => {
        await addPhase2UserAcces({
            orgId,
            userId,
            phase1Id,
            phase2Id,
        });
        // The value we return becomes the `fulfilled` action payload
        return;
    }
);

const membersSlice = createSlice({
    name: 'membersSlice',
    initialState,
    reducers: {
        setUserOrgs: (state, action: PayloadAction<UserOrgs>) => {
            state.userOrgs = action.payload;
        },
        setOrgUsers: (state, action: PayloadAction<UserHasAccess[]>) => {
            state.orgUsers = action.payload;
        },
    },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectOrgUsers = (state: RootState) => state.members.orgUsers;

export const actions = membersSlice.actions;
export const { setUserOrgs, setOrgUsers } = membersSlice.actions;
export type setUserOrgsType = typeof setUserOrgs;

export default membersSlice.reducer;
