import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router';
import { useActions } from '../../hooks/useActions';
import { useFieldblocksFormFields } from '../API/useFieldblocksFormFields';

// import { FieldblocksFormFields } from '../features/fieldblocks/FormFields';
import { FieldblocksFormFieldsTable } from '../features/fieldblocks/FieldblocksFormFieldsTable';
// import { downloadFieldblocksFormFields } from '../firebase/utils/downloadFieldblocksFormFields';
import NavbarOrganization from '../../features/navbars/NavbarOrganization';
import { AddFieldblocksFormField } from '../features/fieldblocks/AddFormField';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FieldblocksFormManagePreview } from '../features/fieldblocks/FieldblocksFormManagePreview';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            // padding: theme.spacing(0, 0, 3) /* top | horizontal | bottom */,
            padding: theme.spacing(8, 0, 4) /* top | horizontal | bottom */,
        },
    })
);
export const FieldblocksFormManagePage: FunctionComponent<any> = () => {
    const classes = useStyles();
    const { fieldblocksFormFields } = useFieldblocksFormFields();
    const params: any = useParams();
    const { setOrgId } = useActions();

    useEffect(() => {
        setOrgId(params.orgId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <NavbarOrganization />
            <Grid
                container
                className={classes.header}
                justify="center"
                spacing={1}
            >
                <Grid item xs={12} sm={9}>
                    <FieldblocksFormFieldsTable
                        fieldblocksFormFields={fieldblocksFormFields}
                    />
                </Grid>
                <Grid item xs={12} sm={9}>
                    <AddFieldblocksFormField
                        fieldblocksFormFields={fieldblocksFormFields}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={4}>
                    <FieldblocksFormManagePreview />
                </Grid>
            </Grid>
        </>
    );
};
