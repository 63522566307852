import { useParams } from 'react-router';
import { useAnnotations } from './../../hooks/useAnnotations';
import { RootState } from '../../store/configureStore';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

type Params = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

export const useDatesInMilis = () => {
    const { orgId, phase1Id, phase2Id }: Params = useParams();
    const { annotations } = useAnnotations(orgId, phase1Id, phase2Id);
    const tilesets = useSelector((state: RootState) => state.tilesets.tilesets);
    const links = useSelector((state: RootState) => state.links.links);
    const photos = useSelector((state: RootState) => state.photos.photos);
    const selectedDate = useSelector(
        (state: RootState) => state.timeline.selectedDate
    );

    const [ableToStart, setAbleToStart] = useState(false);

    const [datesInMilis, setDatesInMilis] = useState<number[]>([]);

    useEffect(() => {
        if (
            selectedDate !== 'not_sure' &&
            tilesets !== 'not_initialized' &&
            annotations !== 'not_initialized' &&
            links !== 'not_initialized'
        ) {
            setAbleToStart(true);
        }
    }, [tilesets, annotations, links, selectedDate]);

    useEffect(() => {
        if (ableToStart) {
            //@ts-ignore
            const tsDatesInMilis = tilesets.map((ts) => {
                let date = new Date(ts.mappedOn.getTime());
                const milis: number = date.setHours(0, 0, 0, 0);
                return milis;
            });

            //@ts-ignore
            const annotationsDatesInMilis = annotations.map((a) => {
                let date = new Date(a.date.getTime());
                const milis: number = date.setHours(0, 0, 0, 0);
                return milis;
            });

            //@ts-ignore
            const linksDatesInMilis = links.map((link) => {
                let date = new Date(link.date.getTime());
                const milis: number = date.setHours(0, 0, 0, 0);
                return milis;
            });

            //@ts-ignore
            const photosDatesInMilis = photos.map((photo) => {
                let date = new Date(photo.date.getTime());
                const milis: number = date.setHours(0, 0, 0, 0);
                return milis;
            });

            const datesInMilis: number[] = tsDatesInMilis.concat(
                annotationsDatesInMilis,
                linksDatesInMilis,
                photosDatesInMilis
            );

            const unique = [...new Set(datesInMilis)];
            setDatesInMilis(unique.sort());
        }
    }, [ableToStart, tilesets, annotations, links, photos]); //eslint-disable-line

    return { datesInMilis };
};
