import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router';

//Components
import AddMapDialogComponent from '../features/tilesets/AddMapDialogComponent';
import NavbarOrganization from '../features/navbars/NavbarOrganization';

//MaterialUI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { Watchers } from '../features/tilesets/Watchers';
import { useWatchers } from '../features/tilesets/useWatchers';
import { Tilesets } from '../features/tilesets/Tilesets';
import { usePhase2NameAndCreatedAt } from '../formfy/customHooks/usePhase2NameAndCreatedAt';
import Link from '@material-ui/core/Link';
import { usePhase1NameAndCreatedAt } from '../formfy/customHooks/usePhase1NameAndCreatedAt';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divRoot: {
            minHeight: '100vh',
        },
        rootGrid: {
            padding: theme.spacing(8, 0, 4) /* top | horizontal | bottom */,
        },
        header: {
            padding: theme.spacing(0, 0, 3) /* top | horizontal | bottom */,
        },
    })
);

type URLParams = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

export function ManageTilesetsPage() {
    const classes = useStyles();
    const { watchers, addWatcher, removeWatcher } = useWatchers();

    const { orgId, phase1Id, phase2Id }: URLParams = useParams();

    const { phase1Info } = usePhase1NameAndCreatedAt();
    const { phase2Info } = usePhase2NameAndCreatedAt();

    return (
        <div className={classes.divRoot}>
            <CssBaseline />
            {/* <NavbarManagePages /> */}
            <NavbarOrganization />
            <Container maxWidth="md">
                <Grid container className={classes.rootGrid}>
                    <Grid
                        container
                        item
                        className={classes.header}
                        justify="space-between"
                        alignItems="center"
                        spacing={1}
                        xs={12}
                    >
                        {/* <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Typography variant='h5' align='left' color='textPrimary'> */}
                        <Grid item xs={12} sm={8}>
                            <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/organizationId/${orgId}`}
                            >
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    Início
                                </Typography>
                            </Link>

                            <Box component="span" marginX={0.5}>
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    /
                                </Typography>
                            </Box>

                            <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/organizationId/${orgId}/phase1Id/${phase1Id}`}
                            >
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    {phase1Info?.name}
                                </Typography>
                            </Link>

                            <Box component="span" marginX={0.5}>
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    /
                                </Typography>
                            </Box>

                            <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/organizationId/${orgId}/phase1Id/${phase1Id}/phase2Id/${phase2Id}`}
                            >
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    {phase2Info?.name}
                                </Typography>
                            </Link>

                            <Box component="span" marginX={0.5}>
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    /
                                </Typography>
                            </Box>

                            <Typography
                                variant="h5"
                                color="inherit"
                                display="inline"
                            >
                                <FormattedMessage
                                    id="app.maps"
                                    defaultMessage="Mapas"
                                />
                            </Typography>
                            <Typography
                                variant="caption"
                                display="block"
                                color="textSecondary"
                            >
                                <FormattedMessage
                                    id="app.mapsFromArea"
                                    defaultMessage="Estes são os mapas da área"
                                />{' '}
                                {phase2Info?.name}
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            container
                            justify="flex-end"
                            alignItems="center"
                            spacing={1}
                            xs={12}
                            sm={4}
                        >
                            <Grid item xs={12}>
                                <AddMapDialogComponent
                                    addWatcher={addWatcher}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Box paddingTop={1} paddingBottom={1}>
                                <Divider />
                            </Box>
                        </Grid>
                        <Watchers
                            processWatchers={watchers}
                            removeWatcher={removeWatcher}
                        />
                        <Tilesets />
                        <div></div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
