import { LinkData } from './../../types/link';
import { db } from '../firebase';

export const uploadLink = (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    linkData: Omit<LinkData, 'linkDocId'>
) => {
    db.collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('links')
        .add(linkData)
        .then((doc) => console.log('Link correctly uploaded'));
};
