import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import * as turf from '@turf/turf';
import { useParams } from 'react-router';

// Components
import DeleteDialog from 'features/apps-and-reserva-legal/DeleteDialog';

//Material UI
import { Box, Typography, Grid } from '@material-ui/core';

type Params = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

export default function ListAPPsAndReservaLegalOnSideBarMenu() {
    const { phase2Id }: Params = useParams();
    const reservaLegalFeatures = useSelector((state: RootState) => {
        const list = state.appsAndReservaLegal.reservaLegal;
        if (list !== 'not_initialized') {
            return list.filter((item) => item.phase2Id === phase2Id);
        }
        return null;
    });

    const areaPreservacaoPermanenteFeatures = useSelector(
        (state: RootState) => {
            const list = state.appsAndReservaLegal.areaPreservacaoPermanente;
            if (list !== 'not_initialized') {
                return list.filter((item) => item.phase2Id === phase2Id);
            }
            return null;
        }
    );

    const calculateAreainHecatares = (
        coordinatesString: any
    ): string | null => {
        if (typeof coordinatesString === 'string') {
            const coordinates = JSON.parse(coordinatesString);
            const polygon = turf.polygon([coordinates[0]]);
            const area = turf.area(polygon);
            const hectares = area / 10000;
            const rounded_hectares = Math.round(hectares * 100) / 100;
            // console.log('rounded_area ai sim', rounded_hectares);
            return `${rounded_hectares} ha`;
        }

        return null;
    };

    return (
        <div>
            <div
                style={{
                    backgroundColor: '#185a2a',
                    marginTop: '16px',
                    borderRadius: '4px',
                    padding: '8px',
                }}
            >
                <Typography
                    color="inherit"
                    align="left"
                    variant="subtitle1"
                    gutterBottom
                >
                    <strong style={{ color: 'white' }}>
                        Área de Preservação Permanente
                    </strong>
                </Typography>

                {areaPreservacaoPermanenteFeatures &&
                areaPreservacaoPermanenteFeatures.length > 0 ? (
                    areaPreservacaoPermanenteFeatures.map((feature) => (
                        <Box
                            key={feature.id}
                            style={{
                                backgroundColor: '#fff',
                                borderRadius: '4px',
                                padding: '8px',
                                marginBottom: '8px',
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <Typography
                                        align="left"
                                        variant="subtitle1"
                                    >
                                        <strong> Área: </strong>
                                        {calculateAreainHecatares(
                                            feature.coordinates
                                        )}
                                    </Typography>
                                    {/* <Typography
                                        align="left"
                                        variant="subtitle1"
                                    >
                                        {feature.id}
                                    </Typography> */}
                                </Grid>
                                <Grid item xs={2}>
                                    {feature.id && (
                                        <DeleteDialog
                                            info={feature}
                                            area={calculateAreainHecatares(
                                                feature.coordinates
                                            )}
                                        />
                                    )}
                                </Grid>

                                {feature.notes && feature.notes.length > 0 && (
                                    <Grid item xs={12}>
                                        <Typography
                                            align="left"
                                            variant="subtitle1"
                                        >
                                            <strong> Anotações: </strong>
                                            {feature.notes}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                            <div></div>
                        </Box>
                    ))
                ) : (
                    <Typography align="left" variant="subtitle1">
                        <strong> Não há registros </strong>
                    </Typography>
                )}
            </div>
            <div
                style={{
                    backgroundColor: '#185a2a',
                    marginTop: '16px',
                    borderRadius: '4px',
                    padding: '8px',
                }}
            >
                <Typography
                    color="inherit"
                    align="left"
                    variant="subtitle1"
                    gutterBottom
                >
                    <strong style={{ color: 'white' }}>Reserva Legal</strong>
                </Typography>
                {reservaLegalFeatures && reservaLegalFeatures.length > 0 ? (
                    reservaLegalFeatures.map((feature) => (
                        <Box
                            key={feature.id}
                            style={{
                                backgroundColor: '#fff',
                                borderRadius: '4px',
                                padding: '8px',
                                marginBottom: '8px',
                            }}
                        >
                            {/* <div>
                                <Typography align="left" variant="subtitle1">
                                    <strong> Área: </strong>
                                    {calculateAreainHecatares(
                                        feature.coordinates
                                    )}
                                </Typography>
                                <Typography align="left" variant="subtitle1">
                                    {feature.id}
                                </Typography>
                                {feature.notes && feature.notes.length > 0 && (
                                    <Typography
                                        align="left"
                                        variant="subtitle1"
                                    >
                                        <strong> Anotações: </strong>
                                        {feature.notes}
                                    </Typography>
                                )}
                            </div> */}

                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <Typography
                                        align="left"
                                        variant="subtitle1"
                                    >
                                        <strong> Área: </strong>
                                        {calculateAreainHecatares(
                                            feature.coordinates
                                        )}
                                    </Typography>
                                    <Typography align="left" variant="caption">
                                        Id: {feature.id}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    {feature.id && (
                                        <DeleteDialog
                                            info={feature}
                                            area={calculateAreainHecatares(
                                                feature.coordinates
                                            )}
                                        />
                                    )}
                                </Grid>

                                {feature.notes && feature.notes.length > 0 && (
                                    <Grid item xs={12}>
                                        <Typography
                                            align="left"
                                            variant="subtitle1"
                                        >
                                            <strong> Anotações: </strong>
                                            {feature.notes}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    ))
                ) : (
                    <Typography align="left" variant="subtitle1">
                        <strong> Não há registros </strong>
                    </Typography>
                )}
            </div>
        </div>
    );
}
