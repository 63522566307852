import { db } from '../../../firebase/firebase';

export const getFormMetadata = (formId: string) => {
    const docRef = db.collection('forms').doc(formId);

    return docRef
        .get()
        .then((doc) => {
            if (doc.exists) {
                const formData = doc.data();

                return formData;
            } else {
                // doc.data() will be undefined in this case
                console.log('No such document!');
            }
        })
        .catch((error) => {
            console.log('Error getting document:', error);
        });
};
