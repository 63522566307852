const disableErrors = () => {
    const temp = console.error;
    console.error = (error: any) => {
        if (error.code === 'MISSING_TRANSLATION') {
            return;
        }
        if (error.code === 'FORMAT_ERROR') {
            return;
        }
        temp(error);
    };
};

disableErrors();

export {};
