import React, { useState, useEffect } from 'react';

import LanguageSelect from 'translations/LanguageSelect';

//Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';

//Types
import { IOrganizationPublic } from '../../types/organization';

//Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: theme.palette.background.paper,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            position: 'fixed',
            bottom: 0,
            width: '100%',
        },
    })
);

export default function OrganizationCopyrightComponent() {
    const classes = useStyles();
    const orgPublicInfo: IOrganizationPublic = useSelector(
        (state: RootState) => state.organization.publicInfo
    );

    const [fullCompanyName, setfullCompanyName] = useState(
        'COOPERATIVA DE PEQUENOS AGRICULTORES DE SANTANA DA VARGEM'
    );

    useEffect(() => {
        if (orgPublicInfo.fullOrganizationName !== '') {
            setfullCompanyName(orgPublicInfo.fullOrganizationName);
        }
    }, [orgPublicInfo.fullOrganizationName]);

    return (
        <footer className={classes.footer}>
            {/* <Typography variant="h6" align="center" gutterBottom>
      Footer
    </Typography>
    <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
      Something here to give the footer a purpose!
    </Typography> */}
            <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                noWrap
            >
                {'Copyright © '}
                {new Date().getFullYear()}
                {'.  '}
                {/* <Link color="inherit" href={"www.NONG.com.br"} > */}
                {fullCompanyName}
                {/* </Link>{' '} */}
            </Typography>
            <LanguageSelect />
        </footer>
    );
}
