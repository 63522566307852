import { storePhase1Registry } from './../firebase/utils/storePhase1Registry';
import { Registry } from '../types/registry';
import { FormFieldTypes, FormField } from './../types/formField';
import { useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';

//Typescript
import { RootState } from '../../store/configureStore';
import { useActions } from '../customHooks/useActions';
import { useEffect, useState } from 'react';

export const usePhase1RegistryState = () => {
    const {
        setPhase1RegistryState,
        finishPhase1RegistryState,
        startPhase1RegistryState,
    } = useActions();

    const userId: string | null = useSelector(
        (state: RootState) => state.auth.userId
    );

    const orgId: string = useSelector(
        (state: RootState) => state.organization.orgId
    );
    // const formId = useSelector((state: RootState) => selectFormId(state));
    const formFields = useSelector(
        (state: RootState) =>
            // selectFormFields(state, { formId })
            state.phase1.formFields
    );
    const registryState = useSelector(
        (state: RootState) => state.phase1.registryState
    );

    const [ableToStart, setAbleToStart] = useState(false);

    useEffect(() => {
        if (formFields !== 'not_initialized') {
            setAbleToStart(true);
        }
    }, [formFields]);

    const handleChange = (fieldId: string, value: FormFieldTypes) => {
        setPhase1RegistryState({ fieldId, value });
    };

    const start = (initialRegistry: Registry | undefined) => {
        // startRegistryState({ formId, formFields, initialRegistry });
        if (formFields !== 'not_initialized') {
            startPhase1RegistryState({ formFields, initialRegistry });
        }
    };

    // const history = useHistory();
    const finish = () => {
        finishPhase1RegistryState(undefined);
        // console.log('phase1Id =>');
        // history.push(`/organizationId/${orgId}/phase1Id/ddd`);
        // // history.push(`/organizationId/${orgId}/phase1Id/${phase1Id}`);
    };

    const save = async () => {
        if (registryState === null) {
            return alert(
                'Error! Not creating a registry, impossible to fire save()!'
            );
        }

        let registry: Registry = {};

        //@ts-ignore
        formFields.forEach((field: FormField) => {
            registry[field.fieldId] = registryState[field.fieldId].value;
        });

        if (registry.createdAt === null) {
            registry.createdAt = new Date();
        }

        if (userId) {
            registry.createdBy = userId;
        }

        return storePhase1Registry(
            orgId,
            registry,
            registryState.phase1Id
        ).then((docId) => {
            finish();
            return docId;
        });
    };

    // return { state: registryState, handleChange, start, finish, save };
    return {
        state: registryState,
        start,
        handleChange,
        finish,
        save,
        ableToStart,
    };
};
