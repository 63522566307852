import React, { FunctionComponent } from 'react';
import { useTilesets } from '../../hooks/useTilesets';
import {
    ProcessWatcherProps,
    TiffUploadProcessWatcher,
} from './TiffUploadProcessWatcher';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fullWidth: {
            width: '100%',
        },
        root: {
            width: '100%',
            //marginBottom: theme.spacing(2),
            //flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    })
);

type Props = {
    processWatchers: ProcessWatcherProps[];
    removeWatcher: (watcher: any) => void;
};

export const Watchers: FunctionComponent<Props> = ({
    processWatchers,
    removeWatcher,
}) => {
    const classes = useStyles();
    useTilesets(); // downloads tilesets

    return (
        <div className={classes.root}>
            {processWatchers.map((processWatcher) => (
                <TiffUploadProcessWatcher
                    {...processWatcher}
                    removeWatcher={removeWatcher}
                    key={processWatcher.tilesetDocId}
                />
            ))}

            {processWatchers.length > 0 && (
                <Box paddingBottom={2}>
                    <Divider />
                </Box>
            )}
        </div>
        //             <div className={classes.fullWidth}>
        // </div>
    );
};
