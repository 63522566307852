import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SliceState = { locale: string };

const initialState: SliceState = {
    locale: 'pt',
};

const translationsSlice = createSlice({
    name: 'translationsSlice',
    initialState,
    reducers: {
        setLocale: (state, action: PayloadAction<string>) => {
            state.locale = action.payload;
        },
    },
});

export const actions = translationsSlice.actions;
export const { setLocale } = translationsSlice.actions;

export default translationsSlice.reducer;
