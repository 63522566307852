import { PhotoData } from './../../types/photoData';

const startPhotosMarkersSource = (map: any) => {
    let emptyGeojson = {
        type: 'FeatureCollection',
        features: [],
    };

    return new Promise((resolve: any) => {
        map.loadImage(
            // 'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
            'https://firebasestorage.googleapis.com/v0/b/drone-report-beta.appspot.com/o/iconImages%2Foie_transparent_1.png?alt=media',
            function (error: any, image: any) {
                if (error) throw error;
                map.addImage('custom-marker', image);
                // Add a GeoJSON source with 2 points
                map.addSource('photosMarkers', {
                    type: 'geojson',
                    data: emptyGeojson,
                });

                // Add a symbol layer
                map.addLayer({
                    id: 'photosMarkers',
                    type: 'symbol',
                    source: 'photosMarkers',
                    layout: {
                        'icon-image': 'custom-marker',
                        'icon-anchor': 'bottom',
                        // 'text-variable-anchor': 'top',
                        // get the title name from the source's "title" property
                        // 'text-field': ['get', 'title'],
                        // 'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                        // 'text-offset': [0, 1.25],
                        // 'text-anchor': 'top',
                        'text-variable-anchor': [
                            'top',
                            'bottom',
                            'left',
                            'right',
                        ],
                        'text-radial-offset': 2,
                        'text-justify': 'auto',
                        // 'icon-image': ['get', 'icon'],
                    },
                });

                resolve(true);
            }
        );
    });
};

const photosToFeatures = (photos: PhotoData[]) => {
    const features: any = [];

    photos.forEach((p) => {
        if (p.locationOption !== 'manual' && p.locationOption !== 'automatic') {
            return;
        }

        let coordinates = [];

        if (p.locationOption === 'manual') {
            let { lat, lng } = p.chosenImagePosition!;
            coordinates = [lng, lat];
        } else {
            let { lat, lng } = p.position!;
            coordinates = [lng, lat];
        }

        console.log(p.gdriveUrl);

        features.push({
            // feature for Mapbox SF
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates,
            },
            properties: {
                // title: 'Mapbox SF',
                gdriveUrl: p.gdriveUrl,
                icon: 'music-15',
            },
        });
    });

    return features;
};

const setMarkersUrlLinksListener = (map: any, photos: PhotoData[]) => {
    map.on('click', (e: any) => {
        var features = map.queryRenderedFeatures(e.point, {
            layers: ['photosMarkers'],
        });

        features.slice(0, 1).forEach((f: any) => {
            window.open(f.properties.gdriveUrl, '_blanket');
        });
    });

    let previousCursor = '';

    map.on('mouseenter', 'photosMarkers', (e: any) => {
        previousCursor = map.getCanvas().style.cursor;
        map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', 'photosMarkers', (e: any) => {
        map.getCanvas().style.cursor = previousCursor;
    });
};

export const setPhotosMarkersLayer = async (map: any, photos: PhotoData[]) => {
    const photosMarkersSource = map.getSource('photosMarkers');

    setMarkersUrlLinksListener(map, photos);

    if (!photosMarkersSource) {
        await startPhotosMarkersSource(map);
    }

    const photosFeatures = photosToFeatures(photos);

    map.getSource('photosMarkers').setData({
        type: 'FeatureCollection',
        features: photosFeatures,
    });
};
