import React from 'react';

//MaterialUI
// import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LayersIcon from '@material-ui/icons/Layers';
import PhotoSizeSelectActualIcon from '@material-ui/icons/PhotoSizeSelectActual';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import AttachmentIcon from '@material-ui/icons/Attachment';

interface DateObj {
    dateObj: {
        date: Date;
        maps: boolean;
        photos: boolean;
        annotations: boolean;
        attachments: boolean;
        id: number;
    };
}

interface Item {
    id: number;
    element: JSX.Element;
}

export default function IconList({ dateObj }: DateObj) {
    const [jsxList, setjsxList] = React.useState<Item[]>([]);

    React.useEffect(() => {
        if (dateObj.maps) {
            setjsxList((jsxList) => [
                ...jsxList,
                {
                    id: 1,
                    element: <LayersIcon />,
                },
            ]);
        }
        if (dateObj.photos) {
            setjsxList((jsxList) => [
                ...jsxList,
                {
                    id: 2,
                    element: <PhotoSizeSelectActualIcon />,
                },
            ]);
        }
        if (dateObj.annotations) {
            setjsxList((jsxList) => [
                ...jsxList,
                {
                    id: 3,
                    element: <FormatShapesIcon />,
                },
            ]);
        }
        if (dateObj.attachments) {
            setjsxList((jsxList) => [
                ...jsxList,
                {
                    id: 4,
                    element: <AttachmentIcon />,
                },
            ]);
        }
    }, [dateObj]);

    return (
        <React.Fragment>
            {jsxList.map((e) => {
                return <ListItemIcon key={e.id}>{e.element}</ListItemIcon>;
            })}
        </React.Fragment>
    );
}
