import { PhotoData } from './../../types/photoData';
import { LinkData } from './../../types/link';
import { Annotation } from './../../types/annotation';
import { Tileset } from './../../types/tileset';
import { nanoid } from 'nanoid';

export const makeFixturesByDate = (
    tilesets: Tileset[] /*, photos, annotations */,
    annotations: Annotation[],
    links: LinkData[],
    photos: PhotoData[]
) => {
    // const fixturesByDate: any = {};

    const fixturesByDate: any = {};

    tilesets.forEach((ts) => {
        let date = new Date(ts.mappedOn.getTime());
        const key: number = date.setHours(0, 0, 0, 0);

        if (!fixturesByDate[key]) {
            fixturesByDate[key] = {
                maps: true,
                photos: false,
                annotations: false,
                attachments: false,
                id: nanoid(8),
            };
        } else {
            fixturesByDate[key].maps = true;
        }
    });

    annotations.forEach((a) => {
        let date = new Date(a.date.getTime());
        const key: number = date.setHours(0, 0, 0, 0);

        if (!fixturesByDate[key]) {
            fixturesByDate[key] = {
                maps: false,
                photos: false,
                annotations: true,
                attachments: false,
                id: nanoid(8),
            };
        } else {
            fixturesByDate[key].annotations = true;
        }
    });

    links.forEach((link) => {
        let date = new Date(link.date.getTime());
        const key: number = date.setHours(0, 0, 0, 0);

        if (!fixturesByDate[key]) {
            fixturesByDate[key] = {
                maps: false,
                photos: false,
                annotations: false,
                attachments: true,
                id: nanoid(8),
            };
        } else {
            fixturesByDate[key].attachments = true;
        }
    });

    photos.forEach((photo) => {
        let date = new Date(photo.date.getTime());
        const key: number = date.setHours(0, 0, 0, 0);

        if (!fixturesByDate[key]) {
            fixturesByDate[key] = {
                maps: false,
                photos: true,
                annotations: false,
                attachments: false,
                id: nanoid(8),
            };
        } else {
            fixturesByDate[key].photos = true;
        }
    });

    const datesObjectList: Array<any> = [];

    for (const key in fixturesByDate) {
        datesObjectList.push({
            ...fixturesByDate[key],
            date: new Date(Number(key)),
        });
    }

    const sortedDatesObjectsList = datesObjectList.sort(
        (a, b) => b.date - a.date
    );

    return sortedDatesObjectsList;
};
