// @ts-nocheck
import React, { Fragment, useContext } from 'react';

//Hooks
import { useFieldblocksRegistries } from '../../formfy/API/useFieldblocksRegistries';
import { useFieldblocksFormFields } from '../../formfy/API/useFieldblocksFormFields';

//Components
import { FieldblockCard } from './FieldblockCard';

//Material UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { Phase2PageContext } from '../../contexts/Phase2PageContext';
import { CalculateCenterCoordinates } from '../annotations/CalculateCenterCoordinates';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1, 1, 1) /* top | horizontal | bottom */,
        },
        gridPaperContent: {
            flexGrow: 1,
        },
        gridItemMoreVertIcon: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        paperNoProjects: {
            padding: theme.spacing(1),
        },
    })
);

type Params = {
    phase1Id: string;
    phase2Id: string;
};

export default function ListOfFieldblocksRegistries() {
    const classes = useStyles();
    const { phase1Id, phase2Id }: Params = useParams();
    const { fieldblocksRegistries } = useFieldblocksRegistries(
        phase1Id,
        phase2Id
    );
    const { fieldblocksFieldIdsToNames } = useFieldblocksFormFields();
    const fieldblocksFeatures = useSelector(
        (state: RootState) => state.fieldblocks.features
    );
    const isMapLoaded = useSelector(
        (state: RootState) => state.map.isMapLoaded
    );
    const { mapClass } = useContext(Phase2PageContext);

    const [noProjects, setnoProjects] = React.useState(false);
    React.useEffect(() => {
        if (fieldblocksRegistries.length === 0) {
            setnoProjects(true);
        } else {
            setnoProjects(false);
        }
    }, [setnoProjects, fieldblocksRegistries.length]);

    return (
        <Fragment>
            <CssBaseline />
            {fieldblocksRegistries !== 'not_initialized' &&
                fieldblocksRegistries.map((registry: any) => {
                    let onFieldblockClick = () => {};

                    if (fieldblocksFeatures !== 'not_initialized') {
                        const feature = fieldblocksFeatures.find(
                            (feature) => feature.id === registry.fieldblockId
                        );

                        if (feature && isMapLoaded === true) {
                            onFieldblockClick = () => {
                                const center = CalculateCenterCoordinates({
                                    feature,
                                });
                                mapClass.map.flyTo({
                                    center,
                                    essential: true,
                                    zoom: 17,
                                });
                            };
                        }
                    }

                    return (
                        <div className={classes.paper}>
                            <FieldblockCard
                                fieldblocksFieldIdsToNames={
                                    fieldblocksFieldIdsToNames
                                }
                                fieldblockRegistry={registry}
                                onFieldblockClick={onFieldblockClick}
                            />
                        </div>
                    );
                })}

            {noProjects && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={10} sm={10} md={8} lg={6} xl={6}>
                            <Paper
                                variant="outlined"
                                className={classes.paperNoProjects}
                            >
                                <Typography
                                    variant="h6"
                                    align="center"
                                    gutterBottom
                                >
                                    <FormattedMessage
                                        id="app.noFieldblockCreated"
                                        defaultMessage="Você ainda não criou nenhum talhão"
                                    />
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Fragment>
    );
}
