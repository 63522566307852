import { startFieldblocksFormSpecialFields } from './../firebase/utils/startFieldblocksFormSpecialFields';
// import { OrgUser } from '../../types/organization';
import { downloadFieldblocksFormFields } from '../firebase/utils/downloadFieldblocksFormFields';
import { FormField } from '../types/formField';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

//Typescript
import { RootState } from '../../store/configureStore';
import { useActions } from '../customHooks/useActions';

export const useFieldblocksFormFields = () => {
    const { setFieldblocksFormFields } = useActions();
    const [fieldblocksFieldIdsToNames, setIdsToNames] = useState({});
    const [fieldblocksFieldIdsToTypes, setIdsToTypes] = useState({});

    const orgId: string = useSelector(
        (state: RootState) => state.organization.orgId
    );
    const fieldblocksFormFields: FormField[] | 'not_initialized' = useSelector(
        (state: RootState) => state.fieldblocks.formFields
    );
    useEffect(() => {
        if (fieldblocksFormFields !== 'not_initialized') {
            const idsToNames: { [key: string]: string } = {};
            const idsToTypes: { [key: string]: string } = {};

            fieldblocksFormFields.forEach((field) => {
                idsToNames[field.fieldId] = field.name;
                idsToTypes[field.fieldId] = field.type;
            });
            setIdsToNames(idsToNames);
            setIdsToTypes(idsToTypes);
        }
    }, [fieldblocksFormFields]);

    useEffect(() => {
        if (
            fieldblocksFormFields !== 'not_initialized' &&
            fieldblocksFormFields.length === 0
        ) {
            console.log('eu deveria rodar uma vez por orgId no máximo!');
            startFieldblocksFormSpecialFields(orgId);
        }
    }, [fieldblocksFormFields]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (orgId) {
            downloadFieldblocksFormFields(setFieldblocksFormFields, orgId);
        }
    }, [orgId]); // eslint-disable-line react-hooks/exhaustive-deps

    return {
        fieldblocksFormFields,
        fieldblocksFieldIdsToNames,
        fieldblocksFieldIdsToTypes,
    };
};

export type fieldblocksFormFieldsType = {
    fieldblocksFormFields: FormField[];
    fieldblocksFieldIdsToNames: { [key: string]: string };
};
