export const awaitMapLoad = (mapClass: any) => {
    return new Promise((resolve, reject) => {
        const timer = setInterval(() => {
            if (mapClass.map) {
                clearInterval(timer);
                resolve(mapClass.map);
            }
        }, 200);
    });
};
