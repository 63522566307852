import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router';

//RealtimeUpdates
import RealtimeUpdatesOrgUsers from '../features/members/RealtimeUpdatesOrgUsers';

//Components
import NavbarOrganization from '../features/navbars/NavbarOrganization';
import OrganizationCopyrightComponent from '../features/organizations/OrganizationCopyrightComponent';
import MembersTablePaper from '../features/sharing/MembersTablePaper';
import AddMemberBoard from '../features/members/AddMemberBoard';

//Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { FormattedMessage } from 'react-intl';
// import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        containerRoot: {
            padding: theme.spacing(0, 0, 10) /* top | horizontal | bottom */,
        },
        rootGrid: {
            padding: theme.spacing(8, 0, 0) /* top | horizontal | bottom */,
        },
        header: {
            padding: theme.spacing(0, 0, 3) /* top | horizontal | bottom */,
        },
        paper: {
            padding: theme.spacing(1, 1, 1) /* top | horizontal | bottom */,
        },
    })
);

type URLParams = {
    orgId: string;
};

export default function MembersManagementPage() {
    const classes = useStyles();
    const { orgId }: URLParams = useParams();
    // const orgUsers = useAppSelector(selectOrgUsers);
    return (
        <div>
            <RealtimeUpdatesOrgUsers />
            <CssBaseline />
            <NavbarOrganization />
            <Container maxWidth="lg" className={classes.containerRoot}>
                <Grid container className={classes.rootGrid}>
                    <Grid
                        container
                        item
                        className={classes.header}
                        justify="space-between"
                        alignItems="center"
                        spacing={1}
                        xs={12}
                    >
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/organizationId/${orgId}`}
                            >
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    <FormattedMessage
                                        id="app.start"
                                        defaultMessage="Início"
                                    />
                                </Typography>
                            </Link>

                            <Box component="span" marginX={0.5}>
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    /
                                </Typography>
                            </Box>

                            <Typography
                                variant="h5"
                                color="inherit"
                                display="inline"
                            >
                                <FormattedMessage
                                    id="app.members"
                                    defaultMessage="Membros"
                                />
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            justify="flex-end"
                            alignItems="center"
                            spacing={1}
                            xs={12}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                        >
                            {/* <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
                                <ProjectMoreOptionsButton textButton />
                            </Grid> */}
                            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
                                {/* {accessLevel !== 'Guest' && ( */}
                                <AddMemberBoard />
                                {/* )} */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box paddingTop={1} paddingBottom={1}>
                                <Divider />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <MembersTablePaper />
                    </Grid>
                </Grid>
            </Container>
            <OrganizationCopyrightComponent />
        </div>
    );
}
