import React, { useState } from 'react';
import AddButton from './ui/AddButton';
import CancelButton from './ui/CancelButton';
import { TextField, Button, Paper } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        // width: '100%',
        // height: '100%',
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(1),
        maxWidth: 250,
        '& > *': {
            margin: theme.spacing(1),
            // padding: theme.spacing(1),
            // marginBottom: 'auto',
        },
    },
}));

export const SetListSelectionItems = ({ setFieldItems }) => {
    const classes = useStyles();
    const [listSelectionItems, setListSelectionItems] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [newListSelectionItemName, setNewListSelectionItemName] =
        useState('');

    const toggleShowForm = () => {
        setShowForm(!showForm);
    };

    const addListSelectionItem = () => {
        if (!newListSelectionItemName.trim()) {
            alert('Name cannot be empty!');
            return;
        }
        if (listSelectionItems.includes(newListSelectionItemName)) {
            alert('Item already included');
            return;
        }
        let currentItems = [
            ...listSelectionItems,
            newListSelectionItemName.toUpperCase(),
        ];
        setListSelectionItems(currentItems);
        setFieldItems(currentItems);
        setNewListSelectionItemName('');
        // setShowAddChecklistItemField(!showAddChecklistItemField)
        toggleShowForm();
    };

    return (
        <Paper className={classes.root}>
            {listSelectionItems.map((item, index) => (
                <div key={index}>
                    <ArrowRightIcon />
                    <label htmlFor={index}> {item}</label>
                </div>
            ))}
            {showForm && (
                <>
                    <TextField
                        variant="filled"
                        label={'Nome do Item'}
                        // fullWidth
                        size={'small'}
                        value={newListSelectionItemName}
                        onChange={(e) => {
                            // visitContext.updateValue(field.fieldId, e.target.value)
                            setNewListSelectionItemName(e.target.value);
                        }}
                        inputProps={{
                            style: { textTransform: 'capitalize' },
                        }}
                    />
                </>
            )}
            {showForm ? (
                <div>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={addListSelectionItem}
                    >
                        Salvar Item
                    </Button>
                    <CancelButton label={'cancelar'} onClick={toggleShowForm} />
                </div>
            ) : (
                <AddButton label={'adicionar item'} onClick={toggleShowForm} />
            )}
        </Paper>
    );
};
