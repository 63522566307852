// import { useState, useEffect } from 'react';
import { db } from '../../../firebase/firebase';

export const getFormRegistryVisitDate = (formRegistry: any) => {
    const { formId } = formRegistry;

    // const [visitDate, setVisitDate] = useState<Date | null>(null);

    return db
        .collection('formFields')
        .where('formId', '==', formId)
        .get()
        .then((querySnapshot) => {
            const fields: any = [];

            querySnapshot.forEach((doc) => {
                const docData = doc.data();
                docData.docId = doc.id;
                fields.push(docData);
            });

            const visitDateField = fields.find((field: any) => {
                return field.name === 'Data da visita';
            });

            if (visitDateField) {
                console.log('i found');

                // setVisitDate(formRegistry[visitDateField.docId].toDate());
                // const visitDate = formRegistry[visitDateField.docId].toDate()
                // formRegistry.visitDate = visitDate
                const visitDate = formRegistry[visitDateField.docId]?.toDate();
                return visitDate;
            }
        });
};
