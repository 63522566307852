import { isTimestamp } from './downloadTilesets';
import { Annotation } from './../../types/annotation';
import { setAnnotationsType } from './../../features/annotations/annotationsSlice';
import { db } from '../firebase';

export const downloadAnnotations = (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    setAnnotations: setAnnotationsType
) => {
    return db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('annotations')
        .onSnapshot((querySnapshot) => {
            const annotations: Annotation[] = [];

            querySnapshot.forEach((doc: any) => {
                const data: any = doc.data();

                Object.entries(data).forEach((e) => {
                    if (isTimestamp(e[1])) {
                        // @ts-ignore
                        data[e[0]] = e[1].toDate();
                    }
                });

                data.annotationDocId = doc.id;
                annotations.push(data);
            });

            setAnnotations(annotations);
        });
};
