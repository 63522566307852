import { storage } from '../firebase';

export const getAvatarDownloadUrl = (produtorId) => {
    const avatarRef = storage.ref(`avatars/${produtorId}.jpg`);
    return avatarRef
        .getDownloadURL()
        .then((url) => url)
        .catch((error) => {
            if (error.code === 'storage/object-not-found') {
                console.log('No avatar found!');
            } else {
                console.log(error.message);
            }
            let placeholderUrl =
                'https://firebasestorage.googleapis.com/v0/b/novo-coopasvtech.appspot.com/o/avatars%2Fno-img.png?alt=media&token=02cbe43e-0a47-4636-a742-be5baa3f22e2';
            return placeholderUrl;
        });
};
