import React, { useState, useEffect } from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { moveFieldDown } from '../../firebase/utils/moveField';
// import { moveFieldDown } from '../../firebase/utils/updateField';

// const useStyles = makeStyles((theme) => ({
//   margin: {
//     margin: theme.spacing(1),
//   },
//   button: {
//     textTransform: 'none',
//   },
// }));

export const MoveFieldDown = ({ field, closePopover, belowField, orgId }) => {
    // const classes = useStyles();
    const [pending, setPending] = useState(false);

    useEffect(() => {
        if (pending) setTimeout(() => setPending(false), 1000);
    }, [pending]);

    const moveDown = () => {
        moveFieldDown(field, belowField, orgId);
        closePopover();
    };

    return (
        // <div>
        <IconButton
            size="small"
            // className={`${classes.margin} ${classes.button} `}
            // startIcon={<ArrowDownwardIcon />}
            onClick={moveDown}
            disabled={!belowField}
            color="primary"
        >
            {/* Mover para baixo */}
            <ArrowDownwardIcon />
        </IconButton>
        // </div>
    );
};

const formIdToName = (formId) => {
    switch (formId) {
        case 'phase1FormFields':
            return 'phase1';
        case 'phase2FormFields':
            return 'phase2';
        case 'fieldblocksFormFields':
            return 'fieldblocks';
        default:
            return '';
    }
};

const selectBelowField = (state, idx, ownProps) => {
    // return idx < state.length - 1 ? state.formFields[idx + 1] : undefined
    const formName = formIdToName(ownProps.formId);
    return state[formName].formFields[idx + 1];
};

const selectOrgId = (state) => state.organization.orgId;

const mapStateToProps = (state, ownProps) => ({
    belowField: selectBelowField(state, ownProps.field.idx, ownProps),
    orgId: selectOrgId(state),
});

export default connect(mapStateToProps)(MoveFieldDown);
