import * as turf from '@turf/turf';

type Geojson = {
    type: 'FeatureCollection';
    features: any[];
};

type LineString = {
    type: 'Feature';
    geometry: {
        type: 'LineString';
        coordinates: any[];
    };
};

export const makeDistanceMeasurerListener = (
    map: any,
    onFinishCallback: any,
    setRawDistance: (distance: number) => void,
    setLinestringFeature: (feature: LineString) => void
) => {
    // GeoJSON object to hold our measurement features
    var geojson: Geojson = {
        type: 'FeatureCollection',
        features: [],
    };

    // Used to draw a line between points
    var linestring: LineString = {
        type: 'Feature',
        geometry: {
            type: 'LineString',
            coordinates: [],
        },
    };

    map.addSource('lineStringGeojson', {
        type: 'geojson',
        data: geojson,
    });

    // Add styles to the map
    map.addLayer({
        id: 'measure-points',
        type: 'circle',
        source: 'lineStringGeojson',
        paint: {
            'circle-radius': 5,
            'circle-color': '#11f',
        },
        filter: ['in', '$type', 'Point'],
    });

    map.addLayer({
        id: 'measure-lines',
        type: 'line',
        source: 'lineStringGeojson',
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': 'blue',
            'line-width': 2.5,
        },
        filter: ['in', '$type', 'LineString'],
    });

    const distanceMeasurerListener = (e: any) => {
        if (!map.distanceMeasurerTurnedOn) return;

        var features = map.queryRenderedFeatures(e.point, {
            layers: ['measure-points'],
        });

        const newGeojson: Geojson = {
            ...map.getSource('lineStringGeojson')._data,
        };

        // Remove the linestring from the group
        // So we can redraw it based on the points collection
        if (newGeojson.features.length > 1) newGeojson.features.pop();

        // If a feature was clicked, remove it from the map
        if (features.length) {
            var id = features[0].properties.id;
            newGeojson.features = newGeojson.features.filter(function (point) {
                return point.properties.id !== id;
            });

            // linestring.geometry.coordinates = newGeojson.features.map(function (
            //   point
            // ) {
            //   return point.geometry.coordinates;
            // });

            // //geojson.features.push(linestring);
            // onFinishCallback(linestring);
            // newGeojson.features.length = 0;
            // return;
        } else {
            var point = {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [e.lngLat.lng, e.lngLat.lat],
                },
                properties: {
                    id: String(new Date().getTime()),
                },
            };

            newGeojson.features.push(point);
        }

        if (newGeojson.features.length > 1) {
            linestring.geometry.coordinates = newGeojson.features.map(function (
                point
            ) {
                return point.geometry.coordinates;
            });

            newGeojson.features.push(linestring);

            const rawDistance = turf.length(linestring);
            setRawDistance(rawDistance);
            setLinestringFeature(linestring);
        }

        map.getSource('lineStringGeojson').setData(newGeojson);
    };

    return distanceMeasurerListener;
};
