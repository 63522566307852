import { Registry } from './../../types/registry';
import { FormField } from '../../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormFieldTypes, FormFieldTypesNames } from './../../types/formField';

//types
import { Phase1Info } from '../../types/phase1';

type SetPhase1FormFields = {
    phase1FormFields: FormField[];
};

type SetPhase1Registries = {
    phase1Registries: Registry[];
};

type SetPhase1RegistryState = {
    fieldId: string;
    value: FormFieldTypes;
};

type StartPhase1RegistryState = {
    formFields: FormField[];
    initialRegistry: Registry | undefined;
};

type RegistryStateField = {
    value: FormFieldTypes | null;
    type: FormFieldTypesNames;
};

type Phase1RegistryState =
    | ({
          [key: string]: RegistryStateField;
      } & {
          phase1Id?: string; // used to check editMode vs addMode
      })
    | null;

type SliceState = {
    formFields: FormField[] | 'not_initialized';
    registries: Registry[] | 'not_initialized';
    registryState: Phase1RegistryState;
    selectedPhase1: null | Phase1Info;
};

const initialState: SliceState = {
    formFields: 'not_initialized',
    registries: 'not_initialized',
    registryState: null,
    selectedPhase1: null,
};

function isEmpty(obj: object) {
    return Object.keys(obj).length === 0;
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

// export const setPhase1NameAndCreatedAt = createAsyncThunk(
//   'phase1Slice/getPhase1NameAndCreateAt',
//   async ({ orgId, phase1Id }: Phase1Params) => {
//     const response = await getPhase1NameAndCreateAt({ orgId, phase1Id });
//     // The value we return becomes the `fulfilled` action payload
//     return response;
//   }
// );

export const phase1Slice = createSlice({
    name: 'phase1Slice',
    initialState,
    reducers: {
        setPhase1FormFields: (
            state,
            action: PayloadAction<SetPhase1FormFields>
        ) => {
            const { phase1FormFields } = action.payload;
            state.formFields = phase1FormFields;
        },
        setPhase1Registries: (
            state,
            action: PayloadAction<SetPhase1Registries>
        ) => {
            const { phase1Registries } = action.payload;
            state.registries = phase1Registries;
        },
        setPhase1RegistryState: (
            state,
            action: PayloadAction<SetPhase1RegistryState>
        ) => {
            const { fieldId, value } = action.payload;
            if (state.registryState) {
                state.registryState[fieldId].value = value;
            }
        },
        startPhase1RegistryState: (
            state,
            action: PayloadAction<StartPhase1RegistryState>
        ) => {
            let { formFields, initialRegistry } = action.payload;

            let initialStateObj: Phase1RegistryState = {};

            if (initialRegistry !== undefined) {
                // edit case
                formFields.forEach((field: FormField) => {
                    // @ts-ignore: Object is possibly 'undefined'.
                    let value = initialRegistry[field.fieldId] || null;
                    let fieldState: RegistryStateField = {
                        type: field.type,
                        value,
                    };
                    // @ts-ignore: Object is possibly 'null'.
                    initialStateObj[field.fieldId] = { ...fieldState };
                });
            } else {
                // add case
                formFields.forEach((field: FormField) => {
                    let fieldState: RegistryStateField = {
                        type: field.type,
                        value: null,
                    };
                    // @ts-ignore: Object is possibly 'null'.
                    initialStateObj[field.fieldId] = { ...fieldState };
                });
            }

            state.registryState = initialStateObj;

            if (initialRegistry !== undefined && !isEmpty(initialRegistry))
                // @ts-ignore: Object is possibly 'null'.
                //state[formId].registryState.phase1Id = initialRegistry.phase1Id;
                state.registryState.phase1Id = initialRegistry.phase1Id;
        },
        finishPhase1RegistryState: (state, action) => {
            state.registryState = null;
        },
        setPhase1NameAndCreatedAt: (
            state,
            action: PayloadAction<Phase1Info | null>
        ) => {
            state.selectedPhase1 = action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //   builder.addCase(setPhase1NameAndCreatedAt.fulfilled, (state, action) => {
    //     // console.log('action.payload =>', action.payload);
    //     state.selectedPhase1 = action.payload;
    //   });
    // },
});

export const actions = phase1Slice.actions;

export const {
    setPhase1FormFields,
    setPhase1Registries,
    startPhase1RegistryState,
    finishPhase1RegistryState,
    setPhase1NameAndCreatedAt,
} = phase1Slice.actions;

export type setPhase1FormFieldsType = typeof setPhase1FormFields;
export type setPhase1RegistriesType = typeof setPhase1Registries;
export type startPhase1RegistryStateType = typeof startPhase1RegistryState;
export type finishPhase1RegistryStateType = typeof finishPhase1RegistryState;
export type setPhase1NameAndCreatedAtType = typeof setPhase1NameAndCreatedAt;

export default phase1Slice.reducer;
