import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { useParams } from 'react-router';
import { uploadLink } from '../firebase/utils/uploadLink';
import { useLinks } from '../hooks/useLinks';
import { Links } from '../features/links/Links';
import DatePicker from '../features/ui/DatePicker';

//Components
import NavbarOrganization from '../features/navbars/NavbarOrganization';

//MaterialUI
import {
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    TextField,
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';

import { useQuery } from '../hooks/useQuery';
import { usePhase1NameAndCreatedAt } from '../formfy/customHooks/usePhase1NameAndCreatedAt';
import { usePhase2NameAndCreatedAt } from '../formfy/customHooks/usePhase2NameAndCreatedAt';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divRoot: {
            minHeight: '100vh',
        },
        rootGrid: {
            padding: theme.spacing(8, 0, 4) /* top | horizontal | bottom */,
        },
        header: {
            padding: theme.spacing(0, 0, 3) /* top | horizontal | bottom */,
        },
    })
);

type Params = {
    orgId: string;
    phase1Id: string;
    phase2Id: string;
};

export function ManageLinksPage() {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id } = useParams<Params>();

    const { phase1Info } = usePhase1NameAndCreatedAt();
    const { phase2Info } = usePhase2NameAndCreatedAt();

    const [link, setLink] = React.useState('');
    const [linkName, setLinkName] = React.useState('');
    const selectedDateInMilis = useQuery('selectedDate');
    const [date, setDate] = React.useState(
        selectedDateInMilis ? new Date(Number(selectedDateInMilis)) : new Date()
    );
    const [inAdd, setInAdd] = React.useState(false);

    const { links } = useLinks(orgId, phase1Id, phase2Id);

    function handleClickOpen() {
        setInAdd(true);
    }

    const reset = () => {
        setLink('');
        setLinkName('');
        setDate(new Date());
        setInAdd(false);
    };

    const onSave = () => {
        uploadLink(orgId, phase1Id, phase2Id, { link, name: linkName, date });
        reset();
    };

    return (
        <div className={classes.divRoot}>
            {/* <NavbarManagePages /> */}
            <CssBaseline />
            <NavbarOrganization />
            <Container maxWidth="md">
                <Grid container className={classes.rootGrid}>
                    <Grid
                        container
                        item
                        className={classes.header}
                        justify="space-between"
                        alignItems="center"
                        spacing={1}
                        xs={12}
                    >
                        <Grid item xs={12} sm={8}>
                            <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/organizationId/${orgId}`}
                            >
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    <FormattedMessage
                                        id="app.start"
                                        defaultMessage="Início"
                                    />
                                </Typography>
                            </Link>

                            <Box component="span" marginX={0.5}>
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    /
                                </Typography>
                            </Box>

                            <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/organizationId/${orgId}/phase1Id/${phase1Id}`}
                            >
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    {phase1Info?.name}
                                </Typography>
                            </Link>

                            <Box component="span" marginX={0.5}>
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    /
                                </Typography>
                            </Box>

                            <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/organizationId/${orgId}/phase1Id/${phase1Id}/phase2Id/${phase2Id}`}
                            >
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    {phase2Info?.name}
                                </Typography>
                            </Link>

                            <Box component="span" marginX={0.5}>
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    /
                                </Typography>
                            </Box>

                            <Typography
                                variant="h5"
                                color="inherit"
                                display="inline"
                            >
                                Links
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Typography variant="h5" align="left" color="textPrimary">
                Links
              </Typography>
            </Grid> */}
                        <Grid
                            item
                            container
                            justify="flex-end"
                            alignItems="center"
                            spacing={1}
                            xs={12}
                            sm={4}
                        >
                            {/* <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
                                <ProjectMoreOptionsButton textButton />
              </Grid> */}
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    startIcon={<AddIcon />}
                                    onClick={handleClickOpen}
                                    fullWidth
                                >
                                    <FormattedMessage
                                        id="app.addLink"
                                        defaultMessage="Adicionar Link"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box paddingTop={1} paddingBottom={1}>
                                <Divider />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Links links={filteredLinks} /> */}
                            <Links links={links} />
                        </Grid>
                    </Grid>
                </Grid>

                {inAdd && (
                    <Dialog
                        open={inAdd}
                        onClose={() => setInAdd(false)}
                        aria-labelledby="manage-links"
                        maxWidth="xs"
                        fullWidth
                    >
                        <DialogTitle id="form-dialog-title">
                            <FormattedMessage
                                id="app.newLink"
                                defaultMessage="Novo Link"
                            />
                        </DialogTitle>
                        <DialogContent>
                            <Box paddingY={1}>
                                <TextField
                                    id="filled-basic"
                                    label="Link"
                                    variant="outlined"
                                    value={link}
                                    onChange={(e) => setLink(e.target.value)}
                                    fullWidth
                                />
                            </Box>
                            <Box paddingY={1}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="component-outlined">
                                        <FormattedMessage
                                            id="app.linkName"
                                            defaultMessage="Nome do Link"
                                        />
                                    </InputLabel>
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={linkName}
                                        onChange={(e) =>
                                            setLinkName(e.target.value)
                                        }
                                        // label='E-mail'
                                        label="Nome do Link"
                                    />
                                </FormControl>
                            </Box>
                            <Box paddingY={1}>
                                <DatePicker
                                    //@ts-ignore
                                    label={
                                        <FormattedMessage
                                            id="app.date"
                                            defaultMessage="Data"
                                        />
                                    }
                                    selectedDate={date}
                                    setSelectedDate={setDate}
                                    pickerId={'linksDatePicker'}
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setInAdd(false)}
                                variant="outlined"
                                color="primary"
                            >
                                <FormattedMessage
                                    id="app.cancel"
                                    defaultMessage="Cancelar"
                                />
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onSave}
                            >
                                <FormattedMessage
                                    id="app.save"
                                    defaultMessage="Salvar"
                                />
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Container>
        </div>
    );
}
