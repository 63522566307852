import { db } from './../firebase';
type Change = { docId: string; index: number };

export const storeTilesetsIndexesUpdates = (
    changes: Change[],
    orgId: string,
    phase1Id: string,
    phase2Id: string
) => {
    //  console.log(changes);

    const batch = db.batch();

    const tilesetsCollectionRef = db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('tilesets');

    changes.forEach((change) => {
        const docRef = tilesetsCollectionRef.doc(change.docId);
        batch.update(docRef, { index: change.index });
    });

    return batch.commit();
};
