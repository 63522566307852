import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    MenuItem,
    TextField,
} from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { useFieldblocksRegistryState } from '../../formfy/API/useFieldblocksRegistryState';
import { Registry } from '../../formfy/types';

type Props = {
    fieldblockRegistry: Registry;
    handleOptionsClose: any;
};

type Params = {
    phase1Id: string;
    phase2Id: string;
};

export const FieldblockCardRenameItem: FunctionComponent<Props> = ({
    fieldblockRegistry,
    handleOptionsClose,
}) => {
    const [newName, setNewName] = useState(fieldblockRegistry.name);
    const [open, setOpen] = useState(false);
    const { phase1Id, phase2Id }: Params = useParams();
    const fieldblocksRegistryState = useFieldblocksRegistryState(
        phase1Id,
        phase2Id
    );

    const updateFieldblockRegistryName = () => {
        fieldblocksRegistryState.save();
    };

    const onNameSave = () => {
        updateFieldblockRegistryName();
        setOpen(false);

        handleOptionsClose();
        //reset();
    };

    useEffect(() => {
        if (open) {
            const { ableToStart, state } = fieldblocksRegistryState;

            if (ableToStart && !state) {
                fieldblocksRegistryState.start(fieldblockRegistry);
            }
        } else {
            fieldblocksRegistryState.finish();
        }
    }, [open]); //eslint-disable-line

    useEffect(() => {
        fieldblocksRegistryState.handleChange('name', newName!);
    }, [newName]); //eslint-disable-line

    const onCancelClick = () => {
        setOpen(false);
    };

    return (
        <div>
            <MenuItem onClick={() => setOpen(true)}>
                <FormattedMessage id="app.rename" defaultMessage="Renomear" />
            </MenuItem>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    <TextField
                        id="filled-basic"
                        label="Novo Nome"
                        variant="filled"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Box flexGrow={1}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={onNameSave}
                        >
                            <FormattedMessage
                                id="app.save"
                                defaultMessage="Salvar"
                            />
                        </Button>
                    </Box>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onCancelClick}
                    >
                        <FormattedMessage
                            id="app.cancel"
                            defaultMessage="Cancelar"
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
