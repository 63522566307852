import React from 'react';

import { Phase2PageContext } from '../../contexts/Phase2PageContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';

//Types
import { Annotation } from '../../types/annotation';

//Components
import AnnotationItemMoreOptionsButtonAndMenu from './AnnotationItemMoreOptionsButtonAndMenu';
import { CalculateCenterCoordinates } from './CalculateCenterCoordinates';

//MaterialUI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(1, 1, 1, 1),
            margin: theme.spacing(1, 0, 1, 0),
        },
        switchDiv: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        gridAnnotationName: {
            '&:hover': {
                cursor: 'pointer',
            },
            // backgroundColor: 'purple',
        },
        divAnnotationComments: {
            padding: theme.spacing(1),
            backgroundColor: '#ecf0f1',
        },
        divCenterName: {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            // backgroundColor: 'yellow',
        },
    })
);

type Params = {
    annotation: Annotation;
};

export default function AnnotationListItemSideBarComponent({
    annotation,
}: Params) {
    const classes = useStyles();

    const { mapClass } = React.useContext(Phase2PageContext);
    const map = mapClass.getMap();
    const [centerCoordinates] = React.useState(() => {
        const initialState = CalculateCenterCoordinates(annotation);
        return initialState;
    });
    const accessLevel = useSelector((state: RootState) => {
        const orgUser = state.organization.orgUser;
        if (orgUser && orgUser !== 'not_initialized') {
            return orgUser.organizationAccessLevel;
        }
    });

    return (
        <Paper className={classes.paper}>
            <Grid container alignItems="center">
                <Grid
                    item
                    xs={11}
                    className={classes.gridAnnotationName}
                    zeroMinWidth
                    onClick={() => {
                        map.flyTo({ center: centerCoordinates, zoom: 19 });
                    }}
                >
                    <div className={classes.divCenterName}>
                        <Typography align="left" variant="subtitle1" noWrap>
                            <strong>{annotation.title}</strong>
                        </Typography>
                    </div>

                    {annotation.description && (
                        <Paper
                            elevation={0}
                            className={classes.divAnnotationComments}
                        >
                            <Typography align="left" variant="subtitle2">
                                {annotation.description}
                            </Typography>
                        </Paper>
                    )}
                </Grid>

                {accessLevel !== 'Guest' && accessLevel !== 'Temp' && (
                    <Grid item xs={1}>
                        <AnnotationItemMoreOptionsButtonAndMenu
                            annotation={annotation}
                        />
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
}
