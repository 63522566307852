import { PhotoData } from './../../types/photoData';
import { db } from '../firebase';

export const deletePhoto = (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    photo: PhotoData
) => {
    return db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('photos')
        .doc(photo.photoDocId)
        .delete()
        .then((doc) => console.log('Photo correctly deleted from firestore!'));
};
