import React from 'react';

import AreaAnnotationToolButtonComponent from '../annotationTools/AreaAnnotationToolButtonComponent';
import DistanceAnnotationToolButtonComponent from '../annotationTools/DistanceAnnotationToolButtonComponent';

//MaterialUI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import BuildIcon from '@material-ui/icons/Build';
import { FormattedMessage } from 'react-intl';
import { PointAnnotationToolButtonComponent } from '../annotationTools/PointAnnotationToolButtonComponent';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            padding: theme.spacing(0.25, 0.25, 1),
            backgroundColor: theme.palette.primary.main,
        },
        root: {
            flexGrow: 1,
        },
        button: {
            margin: theme.spacing(0),
        },
    })
);

export default function AnnotationsToolsSidebarMenu() {
    const classes = useStyles();

    const [OnGoingAddArea, setOnGoingAddArea] = React.useState(false);
    const [onGoingAddDistance, setonGoingAddDistance] = React.useState(false);
    const [onGoingAddPoint, setOnGoingAddPoint] = React.useState(false);

    return (
        <React.Fragment>
            <Paper variant="outlined" className={classes.paperRoot}>
                <div className={classes.root}>
                    <Grid container spacing={0}>
                        <Grid container item xs={12}>
                            <Grid item xs={1}>
                                <Box alignItems="center">
                                    <BuildIcon style={{ color: 'white' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={11}>
                                <Typography
                                    variant="h6"
                                    style={{ color: 'white' }}
                                >
                                    <FormattedMessage
                                        id="app.tools"
                                        defaultMessage="Ferramentas"
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box marginTop={1} marginBottom={1}>
                                <Divider />
                            </Box>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            spacing={1}
                            justify="space-evenly"
                        >
                            <AreaAnnotationToolButtonComponent
                                OnGoingAddArea={OnGoingAddArea}
                                setOnGoingAddArea={setOnGoingAddArea}
                                onGoingAddDistance={onGoingAddDistance}
                                onGoingAddPoint={onGoingAddPoint}
                            />
                            <DistanceAnnotationToolButtonComponent
                                OnGoingAddArea={OnGoingAddArea}
                                setonGoingAddDistance={setonGoingAddDistance}
                                onGoingAddDistance={onGoingAddDistance}
                                onGoingAddPoint={onGoingAddPoint}
                            />
                            <PointAnnotationToolButtonComponent
                                OnGoingAddArea={OnGoingAddArea}
                                setOnGoingAddPoint={setOnGoingAddPoint}
                                onGoingAddDistance={onGoingAddDistance}
                                onGoingAddPoint={onGoingAddPoint}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </React.Fragment>
    );
}
