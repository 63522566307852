import { setPhotosMarkersLayer } from './../photos/setPhotosMarkersLayer';
import { PhotoData } from './../../types/photoData';
import { handleTilesetUnrenderOnRemove } from './handleTilesetUnrenderOnRemove';
import { storeTilesetsIndexesUpdates } from './../../firebase/utils/storeTilesetsIndexesUpdates';
import { dateToMilis } from './../../utils/index';
import { setAnnotationsLayer } from './../annotations/setAnnotationsLayer';
import { MapClass } from './MapClass';
import { Annotation } from './../../types/annotation';
import { Tileset } from './../../types/tileset';
import { RootState } from '../../store/configureStore';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { retifyTilesetsIndexes } from './retifyTilesetsIndexes';
import { useParams } from 'react-router';

type Params = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

// export const useMapLayers = (map: MapType) => {
export const useMapLayers = (mapClass: MapClass) => {
    const { orgId, phase1Id, phase2Id }: Params = useParams();

    const selectedDate = useSelector(
        (state: RootState) => state.timeline.selectedDate
    );
    const isMapLoaded = useSelector(
        (state: RootState) => state.map.isMapLoaded
    );
    const tilesets = useSelector((state: RootState) => state.tilesets.tilesets);
    const annotations = useSelector(
        (state: RootState) => state.annotations.annotations
    );
    const photos = useSelector((state: RootState) => state.photos.photos);
    // const { map } = useContext(Phase2PageContext);
    const [filteredAnnotations, setFilteredAnnotations] = useState<
        Annotation[]
    >([]);
    const [filteredPhotos, setFilteredPhotos] = useState<PhotoData[]>([]);
    const [filteredTilesets, setFilteredTilesets] = useState<Tileset[]>([]);
    const [oldFilteredTilesets, setOldFilteredTilesets] = useState<Tileset[]>(
        []
    );

    const [ableToStart, setAbleToStart] = useState(false);

    // useEffect(() => {
    //   console.log('ótimo teste!');
    // }, [mapClass.started]);

    useEffect(() => {
        if (
            tilesets !== 'not_initialized' &&
            annotations !== 'not_initialized' &&
            isMapLoaded &&
            isMapLoaded !== 'not_sure' &&
            selectedDate !== 'not_sure'
        ) {
            setAbleToStart(true);
        }
    }, [mapClass, isMapLoaded, tilesets, selectedDate, annotations]);

    useEffect(() => {
        if (ableToStart && !mapClass.started) {
            //@ts-ignore
            const filteredTilesets = tilesets.filter(
                //@ts-ignore
                (ts) => dateToMilis(ts.mappedOn) === dateToMilis(selectedDate)
            );

            //@ts-ignore
            const filteredAnnotations = annotations.filter(
                //@ts-ignore
                (a) => dateToMilis(a.date) === dateToMilis(selectedDate)
            );

            //@ts-ignore
            const filteredPhotos = photos.filter(
                //@ts-ignore
                (p) => dateToMilis(p.date) === dateToMilis(selectedDate)
            );

            retifyTilesetsIndexes(filteredTilesets, orgId, phase1Id, phase2Id)
                .then((newFilteredTilesets) => {
                    mapClass.startTilesetLayers(newFilteredTilesets);

                    setFilteredTilesets(newFilteredTilesets);
                    setFilteredAnnotations(filteredAnnotations);
                    setFilteredPhotos(filteredPhotos);
                })
                .catch((error) => {
                    console.log(error);
                    alert(error.message);
                });
        }
    }, [ableToStart, selectedDate, tilesets, annotations]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (tilesets !== 'not_initialized') {
            // checks remove case
            handleTilesetUnrenderOnRemove(mapClass, filteredTilesets, tilesets);
        }
    }, [tilesets]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mapClass.started) {
            //@ts-ignore
            const filtered = tilesets.filter(
                //@ts-ignore
                (ts) => dateToMilis(ts.mappedOn) === dateToMilis(selectedDate)
            );

            setOldFilteredTilesets(filteredTilesets);
            setFilteredTilesets(filtered);
        }
    }, [tilesets, selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mapClass.started) {
            removeAllTilesetLayers();
            setTilesetLayers();
        }
    }, [filteredTilesets, ableToStart]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mapClass.started) {
            //@ts-ignore
            const filteredAnnotations = annotations.filter(
                //@ts-ignore
                (a) => dateToMilis(a.date) === dateToMilis(selectedDate)
            );

            setFilteredAnnotations(filteredAnnotations);
        }
    }, [annotations, selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mapClass.started) {
            setAnnotationsLayer(mapClass.map, filteredAnnotations);
        }
    }, [filteredAnnotations]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mapClass.started) {
            //@ts-ignore
            const filteredPhotos = photos.filter(
                //@ts-ignore
                (p) => dateToMilis(p.date) === dateToMilis(selectedDate)
            );

            setFilteredPhotos(filteredPhotos);
        }
    }, [photos, selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mapClass.started) {
            setPhotosMarkersLayer(mapClass.map, filteredPhotos);
        }
    }, [filteredPhotos]); // eslint-disable-line react-hooks/exhaustive-deps

    const storeMoveUp = (tileset: any, aboveTS: any) => {
        const changes = [
            { docId: tileset.tilesetDocId, index: tileset.index - 1 },
            { docId: aboveTS.tilesetDocId, index: aboveTS.index + 1 },
        ];

        storeTilesetsIndexesUpdates(changes, orgId, phase1Id, phase2Id);
    };

    const storeMoveDown = (tileset: any, belowTS: any) => {
        const changes = [
            { docId: tileset.tilesetDocId, index: tileset.index + 1 },
            { docId: belowTS.tilesetDocId, index: belowTS.index - 1 },
        ];

        storeTilesetsIndexesUpdates(changes, orgId, phase1Id, phase2Id);
    };

    const removeAllTilesetLayers = () => {
        const map = mapClass.getMap();

        if (!map) return;

        oldFilteredTilesets.forEach((ts) => {
            const layerId = `raster-layer-${ts.tilesetId}`;

            map.setLayoutProperty(layerId, 'visibility', 'none');
        });
    };

    const setTilesetLayers = () => {
        const map = mapClass.map;

        filteredTilesets.reverse().forEach((ts) => {
            const layerId = `raster-layer-${ts.tilesetId}`;

            if (map.getLayer(layerId)) {
                map.setLayoutProperty(layerId, 'visibility', 'visible');
            } else {
                map.addLayer(
                    {
                        id: layerId,
                        type: 'raster',
                        source: {
                            type: 'raster',
                            tiles: [
                                `https://api.mapbox.com/v4/${ts.tilesetId}/{z}/{x}/{y}.png`,
                            ],
                        },
                        layout: {
                            visibility: 'visible',
                            // Mapbox Style Specification layout properties
                        },
                    },
                    'tunnel-oneway-arrow-blue'
                );
            }
        });
    };

    return { filteredTilesets, storeMoveUp, storeMoveDown };
};
