import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SetSelectedDate = Date | 'not_sure';

type SliceState = {
    selectedDate: Date | 'not_sure';
};

const initialState: SliceState = {
    selectedDate: 'not_sure',
};

const timelineSlice = createSlice({
    name: 'timelineSlice',
    initialState,
    reducers: {
        setSelectedDate: (state, action: PayloadAction<SetSelectedDate>) => {
            state.selectedDate = action.payload;
        },
    },
});

export const actions = timelineSlice.actions;
export const { setSelectedDate } = timelineSlice.actions;
export type setSelectedDateType = typeof setSelectedDate;

export default timelineSlice.reducer;
