import React, { FunctionComponent } from 'react';
// import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// import { FormattedMessage } from 'react-intl';
import { Phase2CardRenameItem } from './Phase2CardRenameItem';
import { Registry } from '../../formfy/types';
import { Phase2CardDeleteItem } from './Phase2CardDeleteItem';

type Props = {
    phase2Registry: Registry;
};

export const Phase2CardOptions: FunctionComponent<Props> = ({
    phase2Registry,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<any>(null);

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <div>
                <IconButton
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    size="small"
                    aria-label="delete"
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <Phase2CardRenameItem
                        phase2Registry={phase2Registry}
                        handleOptionsClose={handleClose}
                    />
                    <Phase2CardDeleteItem
                        phase2Registry={phase2Registry}
                        handleOptionsClose={handleClose}
                    />
                </Menu>
            </div>
        </div>
    );
};
