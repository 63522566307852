import React, { useRef, useEffect, useContext, useState } from 'react';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
// eslint-disable-next-line
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import set3DSupport from './set3DSupport.js';

//MaterialUI
import { makeStyles } from '@material-ui/core';
import { Phase2PageContext } from '../../contexts/Phase2PageContext';
import { useActions } from '../../hooks/useActions';
import { useSelector } from 'react-redux';

mapboxgl.workerClass = MapboxWorker;
// mapboxgl.accessToken =
//'pk.eyJ1Ijoibm9uZ3RpbGVzZXRzMDEiLCJhIjoiY2p6Yndod2tuMDF3MDNjb2Ftb3FkYno5ayJ9.ZZO7ty0-wXQIxwGW11kduQ';
// 'pk.eyJ1IjoiZGltaXRyZW9ydHQiLCJhIjoiY2s2b2Jla2M1MTlyZDNtdTk0YzQyNm90aSJ9.9rd9-evI7fQ0zJAAN8JQvA';

const useStyles = makeStyles(() => ({
    mapContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        // height: '100%',
        // width: '100%',
    },
    button: {
        position: 'absolute',
        top: 500,
        right: 500,
        zIndex: 2,
    },
}));

export default function Map() {
    const classes = useStyles();
    const mapContainer = useRef(null);
    const mapRef = useRef(null);

    const { mapClass, setDraw } = useContext(Phase2PageContext);
    const { setIsMapLoaded } = useActions();
    const tilesets = useSelector((state) => state.tilesets.tilesets);
    const { mapboxDefaultPublicToken } = useSelector(
        (state) => state.organization.publicInfo
    );
    const [messageShown, setMessageShown] = useState(false);

    useEffect(() => {
        if (tilesets === 'not_initialized') {
            return;
        }

        let initialMapState = {
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/satellite-streets-v11',
            center: [0, 0],
            zoom: 2,
        };

        if (tilesets.length > 0) {
            const ts = [...tilesets].sort((a, b) => b.mappeOn - a.mappeOn)[0];
            // const [lg, lt, num] = ts.center;

            let lg, lt;
            if (ts.center) [lg, lt] = ts.center;
            else [lg, lt] = [undefined, undefined];

            initialMapState.center = [lg, lt];
            initialMapState.zoom = 15;

            if (lg === undefined || lt === undefined) {
                if (!messageShown) {
                    // alert(
                    //   'Houve um erro na criação de um mapa. Estamos trabalhando nisso.'
                    // );
                    setMessageShown(true);
                }
                initialMapState.center = [-50, -15];
                initialMapState.zoom = 3;
            }
        }

        if (mapboxDefaultPublicToken && !mapRef.current) {
            mapboxgl.accessToken = mapboxDefaultPublicToken;

            const map = new mapboxgl.Map(initialMapState);
            mapRef.current = map;

            const draw = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    polygon: true,
                    trash: true,
                },
            });

            map.addControl(draw);
            setDraw(draw);

            map.on('load', () => {
                set3DSupport(map);
                mapClass.setMap(map);
                // setLayers(map, tilesets);
                setIsMapLoaded(true);
                //console.log('set map was loaded!');
            });

            map.on('error', (e) => {
                // Hide those annoying non-error errors
                if (e && e.error !== 'Error: Not Found') console.error(e);
            });

            map.on('error', (e) => {
                // Hide those annoying non-error errors
                if (e && e.error !== 'Error: Not Found') console.error(e);
            });
        }

        return () => {
            mapClass.reset();
            setIsMapLoaded(false);
        };
    }, [mapboxDefaultPublicToken, tilesets]); // eslint-disable-line react-hooks/exhaustive-deps

    return <div className={classes.mapContainer} ref={mapContainer} />;
}
