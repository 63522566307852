import React from 'react';

//Redux
import { useAppSelector } from '../../store/reduxCustomHooks';
import { selectOrgUsers } from '../../features/members/membersSlice';

//Components
import PaperItemMember from './PaperItemMember';

//Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paperRoot: {
            padding: theme.spacing(1),
        },
        paperMember: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    })
);

export default function MembersTablePaper() {
    const classes = useStyles();
    const orgUsers = useAppSelector(selectOrgUsers);
    return (
        <Paper className={classes.paperRoot}>
            <div className={classes.root}>
                <Grid container spacing={0}>
                    <Grid container item xs={12}>
                        <Grid item xs={1}>
                            <Box paddingLeft={1}>
                                <Typography variant="h6" align="left" noWrap>
                                    <FormattedMessage
                                        id="app.name"
                                        defaultMessage="Nome"
                                    />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box paddingLeft={1}>
                                <Typography variant="h6" align="left" noWrap>
                                    <FormattedMessage
                                        id="app.email"
                                        defaultMessage="E-mail"
                                    />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box paddingLeft={0.5}>
                                <Typography variant="h6" align="left" noWrap>
                                    <FormattedMessage
                                        id="app.accessLevel"
                                        defaultMessage="Nível de acessso"
                                    />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box paddingLeft={0.5}>
                                <Typography variant="h6" align="left" noWrap>
                                    <FormattedMessage
                                        id="app.password"
                                        defaultMessage="Senha"
                                    />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="h6" align="center" noWrap>
                                <FormattedMessage
                                    id="app.areasWithAccess"
                                    defaultMessage="Áreas com acesso"
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {orgUsers.map((user) => {
                            return (
                                <PaperItemMember key={user.id} user={user} />
                            );
                        })}
                    </Grid>
                </Grid>
            </div>
        </Paper>
    );
}
