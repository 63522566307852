import React, { useState } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles, Button } from '@material-ui/core';
import { EditFieldForm } from './EditFieldForm';
import { connect } from 'react-redux';
import { updateField } from '../../firebase/utils/updateField';
// import { updateField } from './formsFieldsSlice'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    button: {
        textTransform: 'none',
    },
}));

export const EditField = ({ field, closePopover, orgId }) => {
    const classes = useStyles();
    const [showForm, setShowForm] = useState(false);

    const toggleShowForm = () => {
        setShowForm(!showForm);
    };

    const submitFieldInfo = (fieldInfo) => {
        // updateField({ updates: fieldInfo, idx: field.idx })
        console.log(field);
        console.log(fieldInfo);
        updateField(
            field.formId,
            field.fieldId,
            { ...fieldInfo, idx: field.idx },
            orgId
        );
        toggleShowForm();
        closePopover();
    };

    return (
        <div>
            <Button
                size="small"
                className={`${classes.margin} ${classes.button} `}
                startIcon={<SettingsIcon color="primary" />}
                onClick={toggleShowForm}
            >
                Editar este campo
            </Button>
            {showForm && (
                <EditFieldForm
                    field={field}
                    toggleShowForm={toggleShowForm}
                    showForm={showForm}
                    submitFieldInfo={submitFieldInfo}
                />
            )}
        </div>
    );
};

const mapDispatchToProps = {
    // updateField,
};

const selectOrgId = (state) => state.organization.orgId;

const mapStateToProps = (state, ownProps) => ({
    orgId: selectOrgId(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditField);
