import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { FormField, FormFieldTypes } from '../../formfy/types';
import { TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

type Props = {
    field: FormField;
    value: FormFieldTypes | null;
    onChange: (value: FormFieldTypes) => void;
};

export const CPFFormField: FunctionComponent<Props> = ({
    field,
    value,
    onChange,
}) => {
    const [cpfMask] = useState('999.999.999-99');
    const [text, setText] = useState<string>(
        typeof value === 'string' ? value : ''
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newText: string = event.target.value;

        setText(newText);
        onChange(newText);
    };

    return (
        <InputMask
            mask={cpfMask}
            disabled={false}
            // @ts-ignore
            maskChar={' '}
            onChange={handleChange}
            value={text}
        >
            {() => (
                <TextField label={field.name} variant="outlined" fullWidth />
            )}
        </InputMask>
    );
};
