import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: theme.spacing(1),
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const FieldTypes = [
    'Texto Curto',
    'Texto Longo',
    'CheckBox',
    'Data',
    'Data e Hora',
    'Email',
    'Seleção de Lista',
    'Número de Telefone',
    'Anexo',
    'CPF',
    'CNPJ',
];

export const SelectFieldType = ({ fieldType, setFieldType }) => {
    const classes = useStyles();

    const handleChange = (event) => {
        setFieldType(event.target.value);
    };

    return (
        <>
            <FormControl
                variant="filled"
                // style={{ minWidth: 220 }}
                className={classes.formControl}
            >
                <InputLabel id="demo-simple-select-filled-label">
                    Tipo de Campo
                </InputLabel>
                <Select
                    variant="filled"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={fieldType}
                    onChange={handleChange}
                    fullWidth
                >
                    {FieldTypes.map((fieldType) => (
                        <MenuItem value={fieldType} key={fieldType}>
                            {fieldType}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};
