import { FormField } from '../../types/formField';
import db from '../../../firebase/firebase';

const swapFields = (field: FormField, otherField: FormField, orgId: string) => {
    let batch = db.batch();

    let otherFieldRef = db
        .collection('organizations')
        .doc(orgId)
        .collection(field.formId)
        .doc(otherField.fieldId);

    let fieldRef = db
        .collection('organizations')
        .doc(orgId)
        .collection(field.formId)
        .doc(field.fieldId);

    batch.update(otherFieldRef, 'idx', field.idx);
    batch.update(fieldRef, 'idx', otherField.idx);

    return batch
        .commit()
        .catch(() => alert('Error!! Could not change fields positions!'));
};

export const moveFieldUp = (
    field: FormField,
    aboveField: FormField,
    orgId: string
) => {
    return swapFields(field, aboveField, orgId);
};

export const moveFieldDown = (
    field: FormField,
    belowField: FormField,
    orgId: string
) => {
    return swapFields(field, belowField, orgId);
};
