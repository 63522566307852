import { db } from '../firebase';
import { UserHasAcessPhase2Path } from '../../types/user';
import firebase from 'firebase/compat/app';

export const addPhase2UserAcces = ({
    orgId,
    userId,
    phase1Id,
    phase2Id,
}: UserHasAcessPhase2Path) => {
    const { FieldValue } = firebase.firestore;

    return new Promise<void>((resolve, reject) => {
        const docRef = db.doc(`organizations/${orgId}/users/${userId}/`);

        docRef
            .update({
                phase1UserHasAccess: FieldValue.arrayUnion(phase1Id),
                phase2UserHasAccess: FieldValue.arrayUnion(phase2Id),
                phase2UserHasAccessPath: FieldValue.arrayUnion({
                    orgId,
                    phase1Id,
                    phase2Id,
                }),
            })
            .then((doc) => {
                console.log('Document successfully updated!', doc);
                resolve();
            })
            .catch((error) => {
                console.log('Error updating document: ', error);
                reject(error);
            });
    });
};
