import { Button, Dialog, MenuItem } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
// import { usePhase2RegistryState } from '../../formfy/API/usePhase2RegistryState';
import { deletePhase2Registry } from '../../formfy/firebase/utils/deletePhase2Registry';
import { Registry } from '../../formfy/types';

type Props = {
    phase2Registry: Registry;
    handleOptionsClose: any;
};

type Params = {
    orgId: string;
    phase1Id: string;
};

export const Phase2CardDeleteItem: FunctionComponent<Props> = ({
    phase2Registry,
    handleOptionsClose,
}) => {
    const [open, setOpen] = useState(false);
    const { orgId, phase1Id }: Params = useParams();
    const onRemove = () => {
        if (phase2Registry && phase2Registry.phase2Id) {
            //@ts-ignore
            deletePhase2Registry(orgId, phase1Id, phase2Registry.phase2Id);
        }
    };

    const onCancelClick = () => {
        setOpen(false);
    };

    return (
        <div>
            <MenuItem onClick={() => setOpen(true)}>
                <FormattedMessage id="app.remove" defaultMessage="Remover" />
            </MenuItem>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <FormattedMessage
                    id="app.remove-registry"
                    defaultMessage="Remover Registro?"
                />
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={onRemove}
                >
                    <FormattedMessage
                        id="app.remove"
                        defaultMessage="Remover"
                    />
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onCancelClick}
                >
                    <FormattedMessage
                        id="app.cancel"
                        defaultMessage="Cancelar"
                    />
                </Button>
            </Dialog>
        </div>
    );
};
