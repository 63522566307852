import db from '../../../firebase/firebase';

export const deletePhase2Registry = (
    orgId: string,
    phase1Id: string | undefined,
    phase2Id: string | undefined
) => {
    return db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .delete()
        .catch((err) => {
            console.log(err);
            alert('Error!! Não foi possível remover o registro!');
            return Promise.reject(err);
        });
};
