//Types
import { Phase1Info } from '../../formfy/types/phase1';
import { Phase2Info } from '../../formfy/types/phase2';

import { FunctionComponent, useEffect, useState } from 'react';
import {
    emphasize,
    withStyles,
    createStyles,
    makeStyles,
    Theme,
} from '@material-ui/core/styles';
// import { fade } from '@material-ui/core/styles/colorManipulator';
import { alpha } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import HomeIcon from '@material-ui/icons/Home';
import Print from '@material-ui/icons/Print';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { useHistory, useParams } from 'react-router';
// import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        //@ts-ignore
        root: (props) => {
            //@ts-ignore
            if (props.position === 'relative')
                return {
                    height: '100%',
                    top: 0,
                    bottom: 0,
                };
            //@ts-ignore
            else if (props.position === 'absolute')
                return {
                    position: 'absolute',
                    zIndex: 1,
                    left: 0,
                    right: 0,
                    top: 50,
                    display: 'flex',
                    justifyContent: 'center',
                };
        },
        content: {
            padding: theme.spacing(1.3),
            backgroundColor: (props) =>
                //@ts-ignore
                props.position === 'absolute'
                    ? alpha(theme.palette.primary.main, 0.7)
                    : theme.palette.primary.main,

            borderRadius: 6,
            // height: '100%',
            // flexDirection: 'column',
            // flexGrow: 1,
        },
        noPadding: {
            paddingRight: 0,

        },
       
    })
);

const useMyChipStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '&.Mui-disabled': {
                // color: theme.palette.primary.main,
                // backgroundColor: theme.palette.primary.main,
                opacity: 0.7,
            },
        },
        noPadding: {
            '& .MuiChip-label': {
                paddingRight: 0,
            },
          
        },
    })
);

const makeStyledBreadcrumb = ({
    activeLabel,
    myChipClasses,
}: {
    activeLabel: string;
    myChipClasses: any;
}) => {
    const MyChip = (props: any) => {
        let newProps = { ...props };

        // if (newProps.label && newProps.label.length > 12) {
        //     const newLabel = newProps.label.slice(0, 12) + '...';
        //     newProps.label = newLabel;
        // }

        return (
            <Chip
                {...newProps}
                className={clsx(
                    myChipClasses.root,
                    !props.label && myChipClasses.noPadding
                )}
                disabled={props.label === activeLabel}
            />
        );
    };

    //@ts-ignore
    const StyledBreadcrumb = withStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.grey[300],
            height: theme.spacing(3),
            color: theme.palette.grey[800],
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover, &:focus': {
                color: theme.palette.grey[900],
                backgroundColor: theme.palette.grey[600],
            },
            '&:active': {
                boxShadow: theme.shadows[1],
                backgroundColor: emphasize(theme.palette.grey[300], 0.12),
            },
          
        },
    }))(MyChip) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

    return StyledBreadcrumb;
};

type Params = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

type Props = {
    // activeLabel: string;
    position: string;
};

// export const BreadCrumb: FunctionComponent<Props> = ({ activeLabel }) => {
export const BreadCrumb: FunctionComponent<Props> = ({ position }) => {
    const classes = useStyles({ position });
    const myChipClasses = useMyChipStyles();
    const { orgId, phase1Id, phase2Id }: Params = useParams();
    const history = useHistory();
    const [activeLabel, setActiveLabel] = useState('');
    const StyledBreadcrumb = makeStyledBreadcrumb({
        activeLabel,
        myChipClasses,
    });
    // const { windowWidth } = useWindowDimensions();

    const accessLevel = useSelector((state: RootState) => {
        const orgUser = state.organization.orgUser;
        if (orgUser && orgUser !== 'not_initialized') {
            return orgUser.organizationAccessLevel;
        }
    });

    const phase1Info: Phase1Info | null = useSelector((state: RootState) => {
        return state.phase1.selectedPhase1;
    });

    const phase2Info: Phase2Info | null = useSelector((state: RootState) => {
        return state.phase2.selectedPhase2;
    });

    useEffect(() => {
        if (phase2Id && phase2Info) setActiveLabel(phase2Info.name);
        else if (phase1Id && phase1Info) setActiveLabel(phase1Info.name);
        else setActiveLabel('Home');
    }, [phase1Info, phase2Info]); //eslint-disable-line

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Breadcrumbs aria-label="breadcrumb" >
                    {accessLevel !== 'Temp' && (
                        <StyledBreadcrumb
                            component="a"
                            label={undefined}
                            // label={windowWidth > 500 ? 'Home' : undefined}
                            icon={<HomeIcon fontSize="small" />}
                            onClick={() =>
                                history.push(`/organizationId/${orgId}`)
                            }
                            className={classes.noPadding}
                        />
                    )}
                    {accessLevel !== 'Temp' && phase1Id && (
                        <StyledBreadcrumb
                            component="a"
                            label={
                                phase1Info?.name ? phase1Info.name : 'Projeto'
                            }
                            onClick={() =>
                                history.push(
                                    `/organizationId/${orgId}/phase1Id/${phase1Id}`
                                )
                            }
                        />
                    )}
                    {phase2Id && (
                        <StyledBreadcrumb
                            label={phase2Info?.name ? phase2Info.name : 'Área'}
                            icon={undefined}
                        />
                    )}
                     {accessLevel !== 'Temp' && (
                        <StyledBreadcrumb
                            component="a"
                            label={undefined}
                            // label={windowWidth > 500 ? 'Home' : undefined}
                            icon={<Print fontSize="small" />}
                            onClick={() => {
                                alert(
                                  "Dica: O mapa fica melhor impresso na horizontal, ou seja, no modo paisagem. Além disso, use o navegodor Google Chrome para imprimir o mapa. Outros navegadores como Firefox ou Edge costumam não mostrar o mapa corretamente."
                                );
                                window.print();
                              }}
                            className={classes.noPadding}
                        />
                    )}
                </Breadcrumbs>
            </div>
        </div>
    );
};
