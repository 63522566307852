import React from 'react';
import { SelectFieldType } from './FieldTypeSelector';
import NextButton from './ui/NextButton';
import { SetChecklistItems } from './SetChecklistItems';
import { SetListSelectionItems } from './SetListSelectionItems';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
    createStyles({
        right: {
            marginLeft: 'auto',
        },
        flex: {
            display: 'flex',
            // justify: 'center',
        },
        dialog: {
            width: 400,
        },
    })
);

export const SetFieldType = ({
    nextStep,
    fieldType,
    setFieldType,
    setFieldItems,
    field,
    setShowAddForm,
    inEdit,
}) => {
    const classes = useStyles();
    const handleClickNext = () => {
        !!fieldType ? nextStep() : alert('Please choose a field type!');
    };

    const inEditMode = !!field;

    return (
        <>
            {/* Escolha um campo */}
            <SelectFieldType
                fieldType={fieldType ? fieldType : ''}
                setFieldType={setFieldType}
            />
            {fieldType === 'CheckBox' && (
                <SetChecklistItems
                    setFieldItems={setFieldItems}
                    currentItems={inEditMode ? field.checklistItems : []}
                />
            )}
            {fieldType === 'Seleção de Lista' && (
                <SetListSelectionItems setFieldItems={setFieldItems} />
            )}
            <div className={classes.flex}>
                {!!nextStep && (
                    <NextButton onClick={handleClickNext} label={'Próximo'} />
                )}

                {!inEdit && (
                    <Button
                        className={classes.right}
                        variant="outlined"
                        color="default"
                        onClick={() => setShowAddForm(false)}
                    >
                        Cancelar
                    </Button>
                )}
            </div>
        </>
    );
};
