import React from 'react';
import './index.css';
import './setup.ts';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import AppRouter from './routers/AppRouter';
import { Provider } from 'react-redux';
import { store } from './store/configureStore';
// import { IntlProvider } from 'react-intl';

//MaterialUI
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './materialUI/theme';
// import messages_pt from './translations/pt.json';
// import messages_en from './translations/en.json';

// const messages = {
//     pt: messages_pt,
//     en: messages_en,
// };
// const language = navigator.language.split(/[-_]/)[0]; // language without region code

ReactDOM.render(
    <Provider store={store}>
        {/* <IntlProvider
            defaultLocale="pt-BR"
            locale={language}
            //@ts-ignore
            messages={messages[language]}
        > */}
        <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <AppRouter />
        </ThemeProvider>
        {/* </IntlProvider> */}
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
