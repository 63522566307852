import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
    FormControl,
    Typography,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    Box,
    Button,
    createStyles,
    makeStyles,
    Theme,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        lightPrimary: {
            backgroundColor: theme.palette.primary.light,
        },
        checkCircleIcon: {
            color: theme.palette.primary.light,
            height: '100%',
            '& .svg': {
                fontSize: 74,
            },
        },
    })
);

type Props = {
    locationOption: any;
    handleChange: any;
    filePosition: any;
    chosenImagePosition: any;
    handleChoosePosition: any;
};

export const AddPhotoStep1: FunctionComponent<Props> = ({
    locationOption,
    handleChange,
    filePosition,
    chosenImagePosition,
    handleChoosePosition,
}) => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="body1">
                <FormattedMessage
                    id="app.wishToAddPosition"
                    defaultMessage="Deseja adicionar a posição da imagem no mapa?"
                />
            </Typography>
            <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">
                    <FormattedMessage
                        id="app.position"
                        defaultMessage="Posição"
                    />
                </FormLabel>
                <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={locationOption}
                    onChange={handleChange}
                >
                    <FormControlLabel
                        value="automatic"
                        control={<Radio />}
                        label={
                            <FormattedMessage
                                id="app.automatic"
                                defaultMessage="Automático"
                            />
                        }
                        disabled={!filePosition}
                    />
                    <FormControlLabel
                        value="dontShow"
                        control={<Radio />}
                        label={
                            <FormattedMessage
                                id="app.dontAdd"
                                defaultMessage="Não Adicionar"
                            />
                        }
                    />
                    <FormControlLabel
                        value="manual"
                        control={<Radio />}
                        label="Manual"
                    />
                </RadioGroup>
            </FormControl>
            {locationOption === 'manual' && (
                <>
                    {chosenImagePosition ? (
                        <div>
                            <Box
                                display="flex"
                                alignItems="center"
                                // justifyContent='center'
                            >
                                <CheckCircleIcon
                                    className={classes.checkCircleIcon}
                                />
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleChoosePosition}
                                    className={classes.lightPrimary}
                                    startIcon={<LocationOnIcon />}
                                >
                                    <FormattedMessage
                                        id="app.chooseLocation"
                                        defaultMessage="Alterar"
                                    />
                                </Button>
                            </Box>
                        </div>
                    ) : (
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleChoosePosition}
                            className={classes.lightPrimary}
                            startIcon={<LocationOnIcon />}
                        >
                            <FormattedMessage
                                id="app.chooseLocation"
                                defaultMessage="Escolher posição"
                            />
                        </Button>
                    )}
                </>
            )}
        </>
    );
};
