import { RootState } from '../store/configureStore';
import { useSelector } from 'react-redux';
import { useActions } from './useActions';
import { downloadPhotos } from './../firebase/utils/downloadPhotos';
import { useEffect } from 'react';

export const usePhotos = (
    orgId: string,
    phase1Id: string,
    phase2Id: string
) => {
    const { setPhotos } = useActions();
    const photos = useSelector((state: RootState) => state.photos.photos);

    useEffect(() => {
        if (photos === 'not_initialized') {
            downloadPhotos(orgId, phase1Id, phase2Id, setPhotos);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return { photos };
};
