import { setPhase2FormFieldsType } from './../../features/phase2/phase2Slice';
import { FormField } from './../../types/formField';
import { db } from './../../../firebase/firebase';

export const downloadPhase2FormFields = (
    setPhase2FormFields: setPhase2FormFieldsType,
    orgId: string
) => {
    db.collection('organizations')
        .doc(orgId)
        .collection('phase2FormFields')
        .onSnapshot((snapshot) => {
            let fields: Array<FormField> = [];

            snapshot.forEach((docRef) => {
                let data: any = docRef.data();
                const field: FormField = data;
                field.fieldId = docRef.id;
                fields.push(field);
                fields.sort((a, b) => a.idx - b.idx);
            });

            setPhase2FormFields({ phase2FormFields: fields });
        });
};
