import { storeTilesetsIndexesUpdates } from './../../firebase/utils/storeTilesetsIndexesUpdates';
import { Tileset } from '../../types/tileset';

export const retifyTilesetsIndexes = async (
    filteredTilesets: Tileset[],
    orgId: string,
    phase1Id: string,
    phase2Id: string
) => {
    const changesToBeStored: Array<{ docId: string; index: number }> = [];

    const firstSet: Array<Tileset> = [];
    let secondSet: Array<Tileset> = [];

    let needsToStoreUpdates = false;

    filteredTilesets.forEach((ts) => {
        if (ts.index !== -1) {
            firstSet.push(ts);
        } else {
            secondSet.push(ts);
        }
    });

    firstSet.sort((a, b) => a.index! - b.index!);

    const newFirst: any = [];
    if (
        firstSet.length > 0 &&
        firstSet.length !== firstSet[firstSet.length - 1].index + 1
    ) {
        needsToStoreUpdates = true;
        firstSet.forEach((ts, index: number) => {
            newFirst.push({ ...ts, index });

            //Object.defineProperty(ts, 'index', index);
            // ts.index = index;
            changesToBeStored.push({ docId: ts.tilesetDocId!, index });
        });
    }

    const newSecond: any = [];

    if (secondSet.length > 0) {
        needsToStoreUpdates = true;

        const firstLength = firstSet.length;
        secondSet.forEach((ts, index) => {
            newSecond.push({ ...ts, index: firstLength + index });
            //ts.index = firstLength + index;
            //Object.defineProperty(ts, 'index', firstLength + index);

            changesToBeStored.push({
                docId: ts.tilesetDocId!,
                index: firstLength + index,
            });
        });
    }

    // console.log('newFirst', newFirst);
    // console.log('newSecond', newSecond);
    // console.log('changesToBeStored', changesToBeStored);

    let newFilteredSets: any = [];

    if (!needsToStoreUpdates) {
        newFilteredSets = firstSet;
    } else if (newFirst.length > 0 && newSecond.length > 0) {
        newFilteredSets = [...newFirst, ...newSecond];
    } else if (newFirst.length > 0 && newSecond.length <= 0) {
        newFilteredSets = newFirst;
    } else if (newFirst.length <= 0 && newSecond.length > 0) {
        newFilteredSets = [...firstSet, ...newSecond];
    }

    if (needsToStoreUpdates) {
        await storeTilesetsIndexesUpdates(
            changesToBeStored,
            orgId,
            phase1Id,
            phase2Id
        );
    }

    //console.log('newFilteredSets', newFilteredSets);
    newFilteredSets.sort((ts1: any, ts2: any) => ts1.index - ts2.index);

    return newFilteredSets;
};
