import { Phase2Params, Phase2Info } from './../../types/phase2';
import { db } from './../../../firebase/firebase';

type Params = Phase2Params & {
    setPhase2NameAndCreatedAt: (info: Phase2Info | null) => void;
};

export const downloadPhase2NameAndCreateAt = ({
    orgId,
    phase1Id,
    phase2Id,
    setPhase2NameAndCreatedAt,
}: Params) => {
    const docRef = db.doc(
        `organizations/${orgId}/phase1/${phase1Id}/phase2/${phase2Id}`
    );

    docRef.onSnapshot((doc) => {
        if (doc.exists) {
            // console.log('Document data:', doc.data());
            const data = doc.data();
            if (data) {
                if (data.name && data.createdAt) {
                    setPhase2NameAndCreatedAt({
                        name: data.name,
                        createdAt: new Date(data.createdAt),
                        gdriveFolderId: data.gdriveFolderId,
                    });
                }
            }
        } else {
            // doc.data() will be undefined in this case
            console.log('No such document!');
            // reject('No such document!');
        }
    });
};
