import { Feature } from './../../types/feature';

import * as turf from '@turf/turf';

function addSource(map: any, feature: any) {
    const sourceUniqueId = `id-${feature.id}`;
    const fillUniqueId = `fill-id-${feature.id}`;
    const lineUniqueId = `line-id-${feature.id}`;
    const sourceTitleUniqueId = `source-title-id-${feature.id}`;
    const titleUniqueId = `title-id-${feature.id}`;
    // console.log('feature.properties.title', feature.properties.title);
    let fillColor = '#ffff00';
    if (
        feature.properties.title === 'Reserva Legal' ||
        feature.properties.title === 'APP'
    ) {
        fillColor = '#00ff00';
    }
    // Add a data source containing GeoJSON data.
    map.addSource(sourceUniqueId, {
        type: 'geojson',
        data: {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                // These coordinates outline Maine.
                coordinates: feature.geometry.coordinates,
            },
        },
    });

    // Add a new layer to visualize the polygon.
    map.addLayer({
        id: fillUniqueId,
        type: 'fill',
        source: sourceUniqueId, // reference the data source
        layout: {},
        paint: {
            'fill-color': fillColor, // blue color fill
            'fill-opacity': 0.8, // 100% opaque
            'fill-opacity-transition': { duration: 250 }, // 500 milliseconds = 1/2 seconds
        },
    });
    // Add a black outline around the polygon.
    map.addLayer({
        id: lineUniqueId,
        type: 'line',
        source: sourceUniqueId,
        layout: {},
        paint: {
            'line-color': '#000',
            'line-width': 3,
        },
    });

    // const centerOfMass = CalculateCenterCoordinates({ feature });
    const polygon = turf.polygon([feature.geometry.coordinates[0]]);
    const centroid = turf.centerOfMass(polygon); //Centroid of a polygon
    const centroidCoords = centroid.geometry.coordinates;
    // console.log(centroidCoords, 'centroidCoords');

    map.addSource(sourceTitleUniqueId, {
        type: 'geojson',
        data: {
            type: 'Feature',
            geometry: {
                coordinates: centroidCoords,
                type: 'Point',
            },
            properties: {
                title: feature.properties.title,
            },
        },
    });

    // Add a polygons layer
    map.addLayer({
        id: titleUniqueId,
        type: 'symbol',
        source: sourceTitleUniqueId,
        layout: {
            'text-field': ['get', 'title'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            // "text-offset": [0, 0.6],
            // "text-anchor": "top",
            'text-size': 24,
        },
        paint: {
            'text-color': 'black',
        },
    });

    // Increase opacity on mouse enter
    map.on('mouseenter', fillUniqueId, function () {
        map.setPaintProperty(fillUniqueId, 'fill-opacity', 0.0);
        map.setPaintProperty(titleUniqueId, 'text-color', 'white');
    });

    // Reset opacity on mouse leave
    map.on('mouseleave', fillUniqueId, function () {
        map.setPaintProperty(fillUniqueId, 'fill-opacity', 0.8);
        map.setPaintProperty(titleUniqueId, 'text-color', 'black');
    });
}

export const setLayersForGleineExample = (map: any, features: Feature[]) => {
    // startFieldBlocksTitlesSource(map);
    features.forEach((feature) => {
        const coordinates = JSON.parse(feature.geometry.coordinates);
        addSource(map, {
            ...feature,
            geometry: { ...feature.geometry, coordinates },
        });
    });

    // const titleFeatures = makeTitlesFeatures(features);
    // map.getSource('fieldblocksTitles').setData({
    //     type: 'FeatureCollection',
    //     features: titleFeatures,
    // });
};
