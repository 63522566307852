import React from 'react';

//Components
import AnnotationItemDeleteOption from './AnnotationItemDeleteOption';

// import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FormattedMessage } from 'react-intl';

export default function AnnotationItemMoreOptionsButtonAndMenu(props: any) {
    const [anchorEl, setAnchorEl] = React.useState<any>(null);

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
                aria-label="delete"
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem disabled onClick={() => alert('TO-do Editar')}>
                    <FormattedMessage id="app.edit" defaultMessage="Editar" />
                </MenuItem>
                <AnnotationItemDeleteOption annotation={props.annotation} />
            </Menu>
        </div>
    );
}
