import { setOrgUserType } from './../organizations/organizationSlice';
// import firebase from 'firebase/app'
import db, { auth } from './../../firebase/firebase';
// import { setOrgUserIdType } from './authSlice';

export const orgLogin = async (
    email: string,
    password: string,
    orgId: string,
    // setOrgUserId: setOrgUserIdType,
    setOrgUser: setOrgUserType
) => {
    try {
        const user = await auth
            .signInWithEmailAndPassword(email, password)
            .then((userCredential) => userCredential.user);

        // console.log(user?.uid);

        const orgUserDoc = await db
            .collection('organizations')
            .doc(orgId)
            .collection('users')
            .doc(user?.uid)
            .get()
            .then((doc) => doc);

        if (orgUserDoc.exists) {
            // console.log('Is an org user!');
            // setOrgUserId(orgUser.id);
            // console.log(orgUserDoc.data());
            const data: any = orgUserDoc.data();
            setOrgUser({ ...data });
        } else {
            alert(`O cadastro informado não foi encontrado!`);
        }
    } catch (error) {
        // alert(error.message);
        throw error;
    }
};
