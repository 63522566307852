import React from 'react';
import { useParams } from 'react-router';
import { db } from './../../firebase/firebase';
import { UserHasAccess } from '../../types/user';
import { useAppDispatch } from '../../store/reduxCustomHooks';
import { setOrgUsers } from '../members/membersSlice';

interface URLParams {
    orgId: string;
}

export default function RealtimeUpdatesOrgUsers() {
    const { orgId }: URLParams = useParams();
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        const dbRef = db
            .collection('organizations')
            .doc(orgId)
            .collection('users');
        const unsubscribe = dbRef.onSnapshot((querySnapshot) => {
            const usersList: UserHasAccess[] = [];
            querySnapshot.forEach((doc) => {
                const user = doc.data();
                usersList.push({
                    email: user.email,
                    id: user.id,
                    name: user.name,
                    organizationAccessLevel: user.organizationAccessLevel,
                    phase1UserHasAccess: user.phase1UserHasAccess,
                    phase2UserHasAccess: user.phase2UserHasAccess,
                    phase2UserHasAccessPath: user.phase2UserHasAccessPath,
                    temporaryPassword: user.temporaryPassword,
                });
            });
            // console.log('Current usersList: ', usersList);
            dispatch(setOrgUsers(usersList));
        });

        return () => unsubscribe();
    });

    return null;
}
