import { SoilAnalysisLinkData } from './../../types/link';
import { db } from '../firebase';

export const uploadSoilAnalysisLink = (
    linkData: Omit<SoilAnalysisLinkData, 'linkDocId'>
) => {
    db.collection('soilAnalysis')
        .add(linkData)
        .then((doc) => console.log('Link correctly uploaded'));
};
