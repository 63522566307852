import React, { FunctionComponent, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    TextField,
} from '@material-ui/core';
import { Tileset } from '../../types/tileset';
import { updateTileset } from '../../firebase/utils/updateTileset';
import { useParams } from 'react-router';
import { deleteTileset } from '../../firebase/utils/deleteTileset';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatSizeUnits } from '../../utils';
import IconButton from '@material-ui/core/IconButton';
import DatePicker from '../ui/DatePicker';
import clsx from 'clsx';
//internationalization
import { FormattedDate, FormattedMessage } from 'react-intl';

type Props = {
    tileset: Tileset;
};

type Params = {
    orgId: string;
    phase1Id: string;
    phase2Id: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContent: {
            paddingBottom: 0,
        },
        cardActions: {
            '& > *': {
                textAlign: 'center',
            },
        },
        iconButton: {
            margin: 0,
            //padding: 0,
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
    })
);

export const TilesetCard: FunctionComponent<Props> = ({ tileset }) => {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id } = useParams<Params>();
    const [inRename, setInRename] = useState(false);
    const [inChangeDate, setInChangeDate] = useState(false);
    const [inRemove, setInRemove] = useState(false);
    const [newName, setNewName] = useState('');
    const [newDate, setNewDate] = useState<Date>(tileset.mappedOn);
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const reset = () => {
        setNewName('');
        setInRename(false);
        setInChangeDate(false);
        setInRemove(false);
    };

    const onSave = () => {
        updateTileset(orgId, phase1Id, phase2Id, tileset.tilesetDocId!, {
            mapType: newName,
        });

        reset();
    };

    const onDateSave = () => {
        updateTileset(orgId, phase1Id, phase2Id, tileset.tilesetDocId!, {
            // mappedOn: newDate.toDate(),
            mappedOn: newDate,
        });

        reset();
    };

    const onRemove = () => {
        deleteTileset(orgId, phase1Id, phase2Id, tileset.tilesetDocId!);
        reset();
    };

    return (
        <>
            <Card key={tileset.tilesetId}>
                <CardContent className={classes.cardContent}>
                    <Grid container justify="space-between" spacing={2}>
                        <Grid item>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                            >
                                {`${tileset.mapType}`}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Grid container alignItems="flex-end">
                                <Grid item>
                                    <DateRangeIcon fontSize="small" />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                    >
                                        {/* {`${tileset.mappedOn.toLocaleDateString()}`} */}
                                        <FormattedDate
                                            value={tileset.mappedOn}
                                            year="numeric"
                                            month="numeric"
                                            day="numeric"
                                            //weekday="long"
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Typography variant="body2">
                        <strong>
                            <FormattedMessage
                                id="app.size"
                                defaultMessage="Tamanho"
                            />
                            :
                        </strong>{' '}
                        {formatSizeUnits(tileset.fileSize)}
                    </Typography>

                    <Box paddingTop={1} paddingBottom={1}>
                        <Divider />
                    </Box>

                    <Typography variant="body2">
                        <strong>
                            <FormattedMessage
                                id="app.fileType"
                                defaultMessage="Tipo de arquivo"
                            />
                            :
                        </strong>{' '}
                        {`${tileset.fileType}`}
                    </Typography>

                    <Box paddingTop={1} paddingBottom={1}>
                        <Divider />
                    </Box>

                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Typography variant="body2">
                            <strong>
                                <FormattedMessage
                                    id="app.originalFile"
                                    defaultMessage="Arquivo original"
                                />
                                :
                            </strong>{' '}
                            {`${tileset.originalFileName}`}
                        </Typography>

                        <Box paddingTop={1} paddingBottom={1}>
                            <Divider />
                        </Box>

                        <Typography variant="body2">
                            <strong>
                                <FormattedMessage
                                    id="app.registryDate"
                                    defaultMessage="Data do Cadastro"
                                />
                                :
                            </strong>{' '}
                            {`${tileset.createdAt.toLocaleDateString()}`}
                        </Typography>

                        <Box paddingTop={1} paddingBottom={1}>
                            <Divider />
                        </Box>

                        <Typography variant="body2" gutterBottom>
                            <strong>
                                <FormattedMessage
                                    id="app.centerCoordinates"
                                    defaultMessage="Coordenadas do Centro"
                                />
                            </strong>{' '}
                            {`${tileset.center.join(' ')}`}
                        </Typography>
                    </Collapse>

                    <Grid container justify="center">
                        <Grid item>
                            <IconButton
                                className={clsx(
                                    classes.expand,
                                    {
                                        [classes.expandOpen]: expanded,
                                    },
                                    classes.iconButton
                                )}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions className={classes.cardActions}>
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => setInRename(true)}
                    >
                        <FormattedMessage
                            id="app.rename"
                            defaultMessage="Renomear"
                        />
                    </Button>
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => setInChangeDate(true)}
                    >
                        <FormattedMessage
                            id="app.changeDate"
                            defaultMessage="Mudar Data"
                        />
                    </Button>
                    <Button
                        size="small"
                        //variant='contained'
                        color="secondary"
                        onClick={() => setInRemove(true)}
                    >
                        <FormattedMessage
                            id="app.remove"
                            defaultMessage="Remover"
                        />
                    </Button>
                </CardActions>
            </Card>
            {inRemove && (
                <Dialog open={inRemove} onClose={() => setInRemove(false)}>
                    <DialogTitle>
                        <FormattedMessage
                            id="app.removeMap"
                            defaultMessage="Remover Mapa"
                        />
                        ?
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={reset}
                        >
                            <FormattedMessage
                                id="app.cancel"
                                defaultMessage="Cancelar"
                            />
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={onRemove}
                        >
                            <FormattedMessage
                                id="app.remove"
                                defaultMessage="Remover"
                            />
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {inRename && (
                <Dialog open={inRename} onClose={() => setInRename(false)}>
                    <DialogTitle>
                        <FormattedMessage
                            id="app.rename"
                            defaultMessage="Renomear"
                        />
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            id="filled-basic"
                            label={
                                <FormattedMessage
                                    id="app.newName"
                                    defaultMessage="Novo Nome"
                                />
                            }
                            variant="filled"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={reset}
                        >
                            <FormattedMessage
                                id="app.cancel"
                                defaultMessage="Cancelar"
                            />
                        </Button>
                        <Box flexGrow={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={onSave}
                            >
                                <FormattedMessage
                                    id="app.save"
                                    defaultMessage="Salvar"
                                />
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            )}
            {inChangeDate && (
                <Dialog
                    open={inChangeDate}
                    onClose={() => setInChangeDate(false)}
                >
                    <DialogTitle>
                        <FormattedMessage
                            id="app.changeDate"
                            defaultMessage="Mudar Data"
                        />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container justify="space-around">
                            <DatePicker
                                label={
                                    <FormattedMessage
                                        id="app.imageDate"
                                        defaultMessage="Data da Imagem"
                                    />
                                }
                                selectedDate={newDate}
                                setSelectedDate={setNewDate}
                                pickerId={'tiffUploaderDatePicker'}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={reset}
                        >
                            <FormattedMessage
                                id="app.cancel"
                                defaultMessage="Cancelar"
                            />
                        </Button>
                        <Box flexGrow={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={onDateSave}
                            >
                                <FormattedMessage
                                    id="app.save"
                                    defaultMessage="Salvar"
                                />
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};
