import React from 'react';
import { useParams } from 'react-router';

//Hooks
import { usePhase2FormFields } from '../../formfy/API/usePhase2FormFields';
import { usePhase2Registries } from '../../formfy/API/usePhase2Registries';

//Components
import { Phase2Card } from './Phase2Card';

//Material UI
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';

type Params = {
    phase1Id: string;
    orgId: string;
};

export default function ListOfPhase2Registries() {
    const params: Params = useParams();
    const { phase2Registries } = usePhase2Registries(params.phase1Id);
    const { phase2FieldIdsToNames } = usePhase2FormFields(params.phase1Id);

    return (
        <React.Fragment>
            <CssBaseline />
            {phase2Registries !== 'not_initialized' &&
                phase2Registries.map((registry: any) => (
                    <Grid
                        item
                        key={registry.phase2Id}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                    >
                        <Phase2Card
                            phase2FieldIdsToNames={phase2FieldIdsToNames}
                            phase2Registry={registry}
                        />
                    </Grid>
                ))}
        </React.Fragment>
    );
}
