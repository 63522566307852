import React from 'react';

//Components
import UsernameButtonAndMenuComponent from './UsernameButtonAndMenuComponent';
// import LanguageSelect from 'translations/LanguageSelect';
// import SettingsButtonAndMenuComponent from './SettingsButtonAndMenuComponent';

//Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';

//Types
import { IOrganizationPublic } from '../../types/organization';

//MaterialUI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            // marginBottom: theme.spacing(3),
        },
        title: {
            flexGrow: 1,
        },
    })
);

export default function NavbarOrganization() {
    const classes = useStyles();

    const orgPublicInfo: IOrganizationPublic = useSelector(
        (state: RootState) => state.organization.publicInfo
    );

    return (
        <div className={classes.root}>
            {/* <BreadCrumb /> */}
            <AppBar position="fixed">
                <Toolbar variant="dense">
                    <Typography
                        variant="h6"
                        color="inherit"
                        aria-label="Organization_Name"
                        className={classes.title}
                        noWrap
                    >
                        {orgPublicInfo.shortOrganizationName}
                    </Typography>
                    {/* <LanguageSelect /> */}
                    <UsernameButtonAndMenuComponent />
                    {/* <Button variant="outlined" color="inherit" size="small">
            Gerenciar membros
          </Button> */}
                    {/* <SettingsButtonAndMenuComponent /> */}
                </Toolbar>
            </AppBar>
        </div>
    );
}
