import React from 'react';
import { db } from './../../firebase/firebase';
import { useParams } from 'react-router';

//Types
import { UserHasAccess } from '../../types/user';

type Props = {
    user: UserHasAccess;
};

interface URLParams {
    orgId: string;
}

interface Phase2NamesList {
    phase2Name: string;
    phase2Id: string;
    phase1Id: string;
    orgId: string;
}

interface FinalListComplete {
    phase1Name: string;
    phase2Name: string;
    phase2Id: string;
    phase1Id: string;
    orgId: string;
}

export default function useGetPhase1AndPhase2Names({ user }: Props) {
    const { orgId }: URLParams = useParams();
    const { phase2UserHasAccessPath } = user;

    console.log(user);

    const [listWithPhase1And2Names, setlistWithPhase1And2Names] =
        React.useState<FinalListComplete[] | null>(null);

    React.useEffect(() => {
        function getPhase2Name() {
            const premisesArray = [];
            for (const element of phase2UserHasAccessPath) {
                const promise = db
                    .doc(
                        `organizations/${element.orgId}/phase1/${element.phase1Id}/phase2/${element.phase2Id}`
                    )
                    .get();
                premisesArray.push(promise);
            }
            return Promise.all(premisesArray);
        }

        function getPhase1Name(phase2NamesList: any) {
            const premisesArray = [];

            // for (const element of phase2NamesList) {

            //crazily adding this absolute legacy code here 01/07/2021 09:32 SP - SP - Brazil
            const extendedPhase2NamesList = [
                ...phase2NamesList,
                ...user.phase1UserHasAccess.map((phase1Id) => ({
                    orgId,
                    phase1Id,
                })),
            ];

            console.log(extendedPhase2NamesList);
            for (const element of extendedPhase2NamesList) {
                const promise = db
                    .doc(
                        `organizations/${element.orgId}/phase1/${element.phase1Id}/`
                    )
                    .get();
                premisesArray.push(promise);
            }

            return Promise.all(premisesArray);
        }

        function generateCompleteList({
            phase2NamesList,
            phase1NamesList,
        }: any) {
            return new Promise<FinalListComplete[]>((resolve, reject) => {
                const finalListComplete: any[] = [];

                console.log(phase2NamesList, phase1NamesList);

                phase2NamesList.forEach((phase2Doc: Phase2NamesList) => {
                    const found = phase1NamesList.find(
                        (phase1Doc: { phase1Id: string }) =>
                            phase1Doc.phase1Id === phase2Doc.phase1Id
                    );

                    if (found) {
                        finalListComplete.push({
                            orgId: phase2Doc.orgId,
                            phase2Name: phase2Doc.phase2Name,
                            phase2Id: phase2Doc.phase2Id,
                            phase1Id: phase2Doc.phase1Id,
                            phase1Name: found.phase1Name,
                        });
                    }
                });

                phase1NamesList.forEach(
                    (phase1Doc: { phase1Id: string; phase1Name: string }) => {
                        const item = finalListComplete.find(
                            (item: any) => item.phase1Id === phase1Doc.phase1Id
                        );

                        if (!item) {
                            finalListComplete.push({
                                orgId,
                                phase2Name: '',
                                phase2Id: '',
                                phase1Id: phase1Doc.phase1Id,
                                phase1Name: phase1Doc.phase1Name,
                            });
                        }
                    }
                );
                // console.log('finalListComplete =>', finalListComplete);
                resolve(finalListComplete);
            });
        }

        if (
            user.organizationAccessLevel !== 'Admin' &&
            phase2UserHasAccessPath
        ) {
            const phase2NamesList: any[] = [];
            const phase1NamesList: any[] = [];
            getPhase2Name()
                .then((res) => {
                    res.forEach((doc) => {
                        const data: any = doc.data();
                        // console.log('data =>', data);
                        const phase1Doc = doc.ref.parent.parent;
                        if (data.name && phase1Doc) {
                            // console.log('phase1Doc.id =>', phase1Doc.id);
                            phase2NamesList.push({
                                phase2Name: data.name,
                                phase2Id: doc.id,
                                phase1Id: phase1Doc.id,
                                orgId: orgId,
                            });
                        }
                    });
                    // console.log('phase2NamesList =>', phase2NamesList);
                    return getPhase1Name(phase2NamesList);
                })
                .then((res) => {
                    res.forEach((doc) => {
                        const data: any = doc.data();
                        if (data?.name) {
                            phase1NamesList.push({
                                phase1Name: data.name,
                                phase1Id: doc.id,
                            });
                        }
                    });
                    // console.log('phase1NamesList =>', phase1NamesList);
                    return generateCompleteList({
                        phase2NamesList,
                        phase1NamesList,
                    });
                })
                .then((finalListComplete) => {
                    setlistWithPhase1And2Names(finalListComplete);
                });
        }
    }, [user.organizationAccessLevel, phase2UserHasAccessPath, orgId]); // eslint-disable-line

    return listWithPhase1And2Names;
}
