//@ts-nocheck
import React from 'react';
import { FormattedDate } from 'react-intl';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useParams } from 'react-router';
import { useForms } from '../../formfy/customHooks/useForms';
import { Box } from '@material-ui/core';
import { useUrls } from '../../hooks/useUrls';
import { isTimestamp } from '../../firebase/firebase';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            margin: theme.spacing(1, 0, 0, 0),
            padding: theme.spacing(0.5),
        },
    })
);

type Params = {
    phase1Id: string;
    phase2Id: string;
};

export const ListOfRelatoriosSideBarComponent = () => {
    const classes = useStyles();
    const { phase1Id, phase2Id }: Params = useParams();
    const { forms: formsList } = useForms();
    const { urls } = useUrls();
    // const [formsList, setformsList] = React.useState([]);
    // console.log('formsList =>', formsList);

    // React.useEffect(() => {

    // }, [phase2Id]);

    return (
        <Grid container item xs={12}>
            {formsList !== 'not_initialized' &&
                formsList.map((formRegistry) => {
                    return (
                        <Grid item xs={12} key={formRegistry.docId}>
                            <Paper className={classes.paperRoot}>
                                <Grid container spacing={0}>
                                    <Grid item xs={10} container>
                                        <Box width="100%">
                                            <Typography variant="h6">
                                                {formRegistry.formName}
                                            </Typography>
                                        </Box>
                                        <div>
                                            <Typography
                                                variant="body1"
                                                align="center"
                                            >
                                                Data da criação:{' '}
                                                {formRegistry.createdAt && (
                                                    <FormattedDate
                                                        value={
                                                            isTimestamp(
                                                                formRegistry.createdAt
                                                            )
                                                                ? formRegistry.createdAt.toDate()
                                                                : formRegistry.createdAt
                                                        }
                                                        year="numeric"
                                                        month="numeric"
                                                        day="numeric"
                                                    />
                                                )}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2} container>
                                        <IconButton
                                            onClick={() => {
                                                window.open(
                                                    // `https://coopasvtech-formfy.web.app/visualizar/${formRegistry.docId}`,
                                                    // `http://localhost:3000/${phase1Id}/${phase2Id}/visualizar/${formRegistry.docId}`,
                                                    // `https://coopasvtech-drb-formfy.web.app/${phase1Id}/${phase2Id}/visualizar/${formRegistry.docId}`,
                                                    `${urls.formfy}/${phase1Id}/${phase2Id}/visualizar/${formRegistry.docId}`,
                                                    '_blank'
                                                );
                                            }}
                                            //   className={classes.button}
                                            //   onClick={this.handleClickOpenMenu}
                                        >
                                            {/* <FilterCenterFocusIcon /> */}
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    );
                })}
        </Grid>
    );
};
