import { PhotoData } from './../../types/photoData';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SetPhotos = PhotoData[] | 'not_initialized';

// type AddProcessWatcher =

type SliceState = {
    // tilesets: Tileset[] | 'not_initialized';
    photos: PhotoData[] | 'not_initialized';
};

const initialState: SliceState = {
    photos: 'not_initialized',
};

const photosSlice = createSlice({
    name: 'photosSlice',
    initialState,
    reducers: {
        setPhotos: (state, action: PayloadAction<SetPhotos>) => {
            state.photos = action.payload;
        },
    },
});

export const actions = photosSlice.actions;
export const { setPhotos } = photosSlice.actions;
export type setPhotosType = typeof setPhotos;

export default photosSlice.reducer;
