import { getFormRegistryVisitDate } from '../firebase/utils/getFormRegistryVisitDate';
import { useParams } from 'react-router-dom';
import { useActions } from '../customHooks/useActions';
import { RootState } from './../../store/configureStore';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { downloadForms } from '../firebase/utils/downloadForms';

type Params = {
    phase2Id: string;
};

export const useForms = () => {
    const forms = useSelector((state: RootState) => state.forms);
    const [formsWithVisitDates, setFormsWithVisitDates] = useState<any>([]);
    const { setForms } = useActions();
    const { phase2Id }: Params = useParams();

    useEffect(() => {
        if (forms === 'not_initialized') {
            downloadForms(phase2Id, setForms);
        }
    }, [phase2Id]); //eslint-disable-line

    useEffect(() => {
        if (forms !== 'not_initialized') {
            //@ts-ignore
            let promises = forms.map((formRegistry: any) => {
                return getFormRegistryVisitDate(formRegistry).then(
                    (visitDate) => visitDate
                );
            });

            // console.log('oi');

            Promise.all(promises).then((visitDates) => {
                console.log(visitDates);
                // const newForms = [...forms];
                const newForms: any = [];

                //@ts-ignore
                forms.forEach((form: any) => {
                    const newFormRegistry = { ...form };
                    newForms.push(newFormRegistry);
                });

                visitDates.forEach((visitDate, index) => {
                    if (!newForms[index].createdAt) {
                        //@ts-ignore
                        newForms[index].createdAt = visitDate;
                    }
                });

                console.log(newForms);

                setFormsWithVisitDates(newForms);
            });
        }
    }, [forms]);

    return { forms: formsWithVisitDates };
};
