import mapboxgl from 'mapbox-gl';

export function getElevation(lng: number, lat: number, next: any) {
    // Construct the API request
    var query = `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${lng},${lat}.json?layers=contour&limit=50&access_token=${mapboxgl.accessToken}`;

    fetch(query)
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            var allFeatures = data.features;

            var elevations = [];
            for (let i = 0; i < allFeatures.length; i++) {
                elevations.push(allFeatures[i].properties.ele);
            }
            var highestElevation = Math.max(...elevations);
            // console.log(highestElevation, 'highestElevation');
            next(highestElevation);
        });
}
