import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useSelector } from 'react-redux';
import { RootState } from '../store/configureStore';
import { IOrganizationPublic } from '../types/organization';
import { Typography } from '@material-ui/core';
import { orgLogin } from '../features/auth/orgLogin';
import { useActions } from '../hooks/useActions';
import { AuthErrorDialog } from '../features/auth/AuthErrorDialog';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    img: {
        maxWidth: '100%',
        borderRadius: '50%',
    },
}));

export function OrganizationLoginPage() {
    const classes = useStyles();
    const { setOrgUser } = useActions();
    const orgId: string = useSelector(
        (state: RootState) => state.organization.orgId
    );
    const orgPublicInfo: IOrganizationPublic = useSelector(
        (state: RootState) => state.organization.publicInfo
    );
    const [loginError, setError] = useState<Error | undefined>(undefined);
    const [inInvalidPassword, setInInvalidPassword] = useState(false);

    const onFormSubmit = (e: any) => {
        e.preventDefault();

        let inputTags = e.target.getElementsByTagName('input');
        const email = inputTags[0].value;
        const password = inputTags[1].value;

        orgLogin(email, password, orgId, setOrgUser).catch((error) => {
            if (error.code === 'auth/wrong-password') {
                setInInvalidPassword(true);
            } else {
                setError(error);
            }
        });
    };

    return (
        <Fragment>
            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                    <img
                        className={classes.img}
                        src={orgPublicInfo.organizationLogoUrl}
                        alt="company-logo"
                    ></img>
                    <Typography variant="h5">
                        {orgPublicInfo.shortOrganizationName}
                    </Typography>
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={onFormSubmit}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={
                                window.navigator.language === 'pt-BR'
                                    ? 'Senha'
                                    : 'Password'
                            }
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            helperText={inInvalidPassword && 'Senha incorreta!'}
                            onChange={() => setInInvalidPassword(false)}
                            error={inInvalidPassword}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            <FormattedMessage
                                id="app.joinPlatform"
                                defaultMessage="ENTRAR NA PLATAFORMA"
                            />
                        </Button>
                    </form>
                </div>
            </Container>
            {!!loginError && (
                <AuthErrorDialog loginError={loginError} setError={setError} />
            )}
        </Fragment>
    );
}
