import { setPhase2RegistriesType } from './../../features/phase2/phase2Slice';
import { Registry } from './../../types/registry';
import db, {
    Timestamp,
    FirebaseQuery,
    CollectionReference,
} from './../../../firebase/firebase';
import { OrgUser } from './../../../types/organization';

export const isTimestamp = (value: any) => value instanceof Timestamp;

const getPhase2Documents = async (query: FirebaseQuery) => {
    return query.get().then((snapshot) => {
        let registries: Array<Registry> = [];

        snapshot.forEach((doc) => {
            const data: any = doc.data();
            const registry: Registry = data;

            Object.entries(registry).forEach((e) => {
                if (isTimestamp(e[1])) {
                    // @ts-ignore
                    registry[e[0]] = e[1].toDate();
                    // registry[e[0]] = e[1].toMillis()
                }
            });

            registry.phase2Id = doc.id;
            registries.push(registry);
        });

        return registries;
    });
};

const getAllPhase2Documents = (phase2Ref: CollectionReference) => {
    return getPhase2Documents(phase2Ref);
};

const getVisiblePhase2Documents = (
    phase2Ref: CollectionReference,
    visible: Array<string>
) => {
    let batches: any = [];
    while (visible.length) {
        let batch = visible.splice(0, 10);
        batches.push(
            getPhase2Documents(phase2Ref.where('__name__', 'in', batch))
        );
    }

    return Promise.all(batches).then((registriesArrays) => {
        return registriesArrays.flat();
    });
    // return getPhase1Documents(phase1Ref.where('__name__', 'in', visible));
};

export const downloadPhase2Registries = async (
    orgId: string,
    orgUser: OrgUser,
    phase1Id: string,
    setPhase2Registries: setPhase2RegistriesType
) => {
    const phase2Ref = db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2');

    const isAdmin = orgUser.organizationAccessLevel === 'Admin';

    let registries: any = [];

    if (isAdmin) {
        registries = await getAllPhase2Documents(phase2Ref);
    } else {
        let visible = [...orgUser.phase2UserHasAccess];
        if (visible.length > 0) {
            registries = await getVisiblePhase2Documents(phase2Ref, visible);
        }
    }

    const filtered = registries.filter((registry: any) => {
        return !registry.isDeleted;
    });

    setPhase2Registries({ phase2Registries: filtered });
};
