import { Registry } from './../../types/registry';
import db from '../../../firebase/firebase';

export const storePhase1Registry = (
    orgId: string,
    registry: Registry,
    phase1Id: string | undefined
) => {
    const collectionRef = db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1');

    const docRef = phase1Id ? collectionRef.doc(phase1Id) : collectionRef.doc();

    return docRef
        .set(registry)
        .then(() => docRef.id)
        .catch((err) => {
            console.log(err);
            alert('Error!! Não foi possível salvar o registro!');
            return Promise.reject(err);
        });
};
