import React from 'react';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as turf from '@turf/turf';

import { useParams } from 'react-router-dom';
import { Phase2PageContext } from '../../contexts/Phase2PageContext';
import { uploadAnnotation } from '../../firebase/utils/uploadAnnotation';
import { DatePicker } from '../ui/DatePicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';

//MaterialUI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Card, CardActions, CardContent, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '8px',
        },
        rootItem: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        paperAreaSize: {
            padding: theme.spacing(1),
            backgroundColor: '#ecf0f1',
        },
        formControl: {
            margin: theme.spacing(0, 1),
            minWidth: 175,
        },
    })
);

interface Props {
    OnGoingAddArea: boolean;
    setOnGoingAddArea: React.Dispatch<React.SetStateAction<boolean>>;
    onGoingAddDistance: boolean;
    onGoingAddPoint: boolean;
}

export default function AreaAnnotationToolButtonComponent({
    OnGoingAddArea,
    setOnGoingAddArea,
    onGoingAddDistance,
    onGoingAddPoint,
}: Props) {
    const classes = useStyles();

    const { orgId, phase1Id, phase2Id }: any = useParams();
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const { mapClass, draw } = React.useContext(Phase2PageContext);
    const [isDrawSetup, setIsDrawSetup] = React.useState(false);
    const [rawArea, setRawArea] = React.useState<number | null>(null);
    const [currentVisualArea, setCurrentVisualArea] = React.useState<
        number | null
    >(null);
    const [measureUnit, setMeasureUnit] = React.useState('Hectares');
    const [annotationFeature, setAnnotationFeature] = React.useState<any>();
    const selectedDate = useSelector(
        (state: RootState) => state.timeline.selectedDate
    );
    const [newAnnotationDate, setNewAnnotationDate] = React.useState(
        selectedDate !== 'not_sure' ? selectedDate : new Date()
    );

    React.useEffect(() => {
        if (selectedDate !== 'not_sure') setNewAnnotationDate(selectedDate);
    }, [selectedDate]);

    const toggleInAdd = () => {
        setOnGoingAddArea(!OnGoingAddArea);
    };

    const startAdd = () => {
        toggleInAdd();
        startDraw();
    };

    const setupDraw = () => {
        const map = mapClass.getMap();

        map.on('draw.create', (e: any) => {
            const feature = e.features[0];
            // setAnnotationCoords(feature.geometry.coordinates);
            setAnnotationFeature(feature);

            const data = turf.polygon(feature.geometry.coordinates);
            const area = turf.area(data);

            setRawArea(area);
        });

        map.on('draw.update', (e: any) => {
            const feature = e.features[0];
            // setAnnotationCoords(feature.geometry.coordinates);
            setAnnotationFeature(feature);

            const data = turf.polygon(feature.geometry.coordinates);
            const area = turf.area(data);

            setRawArea(area);
        });

        setIsDrawSetup(true);
    };

    const setMapCursor = (type: string) => {
        const map = mapClass.getMap();
        const style = map.getCanvas().style;
        // style.cursor = style.cursor === 'crosshair' ? 'pointer' : style.cursor;
        style.cursor = type;
    };

    const startDraw = () => {
        if (!isDrawSetup) {
            setupDraw();
        }

        setCurrentVisualArea(0);
        draw.changeMode('draw_polygon');
        setMapCursor('crosshair');
    };

    const reset = () => {
        setCurrentVisualArea(0);
        //setAnnotationCoords([]);
        setAnnotationFeature(undefined);
        setMeasureUnit('Hectares');
        setTitle('');
        setDescription('');
        setOnGoingAddArea(false);
        draw.trash();
        draw.deleteAll();
        setMapCursor('pointer');
    };

    const onSave = () => {
        if (!title) {
            return alert('Título é obrigatório.');
        }

        uploadAnnotation(orgId, phase1Id, phase2Id, {
            title,
            description,
            // coordinates: annotationCoords,
            feature: annotationFeature,
            date: newAnnotationDate,
        });

        reset();
    };
    const onCancel = () => {
        reset();
    };

    React.useEffect(() => {
        if (rawArea !== null) {
            if (measureUnit === 'Acres') {
                setCurrentVisualArea(rawArea * 0.00024711);
            }
            if (measureUnit === 'Hectares') {
                setCurrentVisualArea(rawArea / 10000);
            }
            if (measureUnit === 'Quilômetros quadrados') {
                setCurrentVisualArea(rawArea / 100000);
            }
            if (measureUnit === 'Metros quadrados') {
                setCurrentVisualArea(rawArea);
            }
            if (measureUnit === 'Milhas quadradas') {
                setCurrentVisualArea(rawArea * 0.00000038610215855);
            }
        }
    }, [rawArea, setCurrentVisualArea, measureUnit]);

    return (
        <React.Fragment>
            {!(OnGoingAddArea || onGoingAddDistance || onGoingAddPoint) && (
                <Grid item xs={3}>
                    <Paper className={classes.paper}>
                        <Typography
                            variant="subtitle2"
                            display="block"
                            align="center"
                        >
                            <FormattedMessage
                                id="app.area"
                                defaultMessage="Área"
                            />
                        </Typography>

                        <div className={classes.rootItem}>
                            <Fab
                                size="small"
                                color="primary"
                                onClick={startAdd}
                            >
                                <FormatShapesIcon />
                            </Fab>
                        </div>
                    </Paper>
                </Grid>
            )}

            {OnGoingAddArea && (
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Box marginBottom={1}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="subtitle2"
                                                display="block"
                                                align="center"
                                            >
                                                <FormattedMessage
                                                    id="app.area"
                                                    defaultMessage="Área"
                                                />
                                            </Typography>

                                            <div className={classes.rootItem}>
                                                <Fab
                                                    size="small"
                                                    color="primary"
                                                >
                                                    <FormatShapesIcon />
                                                </Fab>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body1"
                                                align="left"
                                            >
                                                <FormattedMessage
                                                    id="app.area"
                                                    defaultMessage="Área"
                                                />
                                            </Typography>
                                            <Paper
                                                variant="outlined"
                                                className={
                                                    classes.paperAreaSize
                                                }
                                            >
                                                <Typography
                                                    display="inline"
                                                    variant="body1"
                                                    align="left"
                                                    gutterBottom
                                                >
                                                    {currentVisualArea &&
                                                        `${currentVisualArea.toFixed(
                                                            2
                                                        )} ${measureUnit}`}
                                                </Typography>
                                            </Paper>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <InputLabel id="demo-simple-select-outlined-label">
                                                    <FormattedMessage
                                                        id="app.measureUnit"
                                                        defaultMessage="Unidade de medida"
                                                    />
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={measureUnit}
                                                    onChange={(event: any) => {
                                                        setMeasureUnit(
                                                            event.target.value
                                                        );
                                                    }}
                                                    label="Unit of measurement"
                                                >
                                                    <MenuItem value="Acres">
                                                        <FormattedMessage
                                                            id="app.acres"
                                                            defaultMessage="Acres"
                                                        />
                                                    </MenuItem>
                                                    <MenuItem value="Hectares">
                                                        <FormattedMessage
                                                            id="app.hectares"
                                                            defaultMessage="Hectares"
                                                        />
                                                    </MenuItem>
                                                    <MenuItem value="Quilômetros quadrados">
                                                        <FormattedMessage
                                                            id="app.squareKM"
                                                            defaultMessage="Quilômetros quadrados"
                                                        />
                                                    </MenuItem>
                                                    <MenuItem value="Metros quadrados">
                                                        <FormattedMessage
                                                            id="app.squareMeter"
                                                            defaultMessage="Metros quadrados"
                                                        />
                                                    </MenuItem>
                                                    <MenuItem value="Milhas quadradas">
                                                        <FormattedMessage
                                                            id="app.squareMiles"
                                                            defaultMessage="Milhas quadradas"
                                                        />
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Box marginY={1}>
                                        <TextField
                                            value={title}
                                            label={
                                                <FormattedMessage
                                                    id="app.title"
                                                    defaultMessage="Título"
                                                />
                                            }
                                            onChange={(e) =>
                                                setTitle(e.target.value)
                                            }
                                            required
                                            fullWidth
                                        />
                                    </Box>

                                    <Box marginY={1}>
                                        <TextField
                                            value={description}
                                            label={
                                                <FormattedMessage
                                                    id="app.description"
                                                    defaultMessage="Descrição"
                                                />
                                            }
                                            multiline
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                            fullWidth
                                        />
                                    </Box>

                                    <Box marginY={1}>
                                        <DatePicker
                                            //@ts-ignore
                                            label={
                                                <FormattedMessage
                                                    id="app.date"
                                                    defaultMessage="Data associada"
                                                />
                                            }
                                            selectedDate={newAnnotationDate}
                                            setSelectedDate={
                                                setNewAnnotationDate
                                            }
                                            pickerId={'annotationsDatePicker'}
                                        />
                                    </Box>
                                </CardContent>
                                <CardActions>
                                    <Box marginX={1} component="span">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            onClick={onCancel}
                                        >
                                            <FormattedMessage
                                                id="app.cancel"
                                                defaultMessage="Cancelar"
                                            />
                                        </Button>
                                    </Box>
                                    {/* {accessLevel !== 'Guest' && accessLevel !== 'Temp' && ( */}

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={onSave}
                                        fullWidth
                                        // disabled={accessLevel === 'Guest' || accessLevel === 'Temp'}
                                    >
                                        <FormattedMessage
                                            id="app.save"
                                            defaultMessage="Salvar"
                                        />
                                    </Button>
                                </CardActions>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box marginTop={0} marginBottom={0.5}>
                            <Divider />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
}
