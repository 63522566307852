import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { signOut } from './signOut';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    dialog: {
        maxWidth: 300,
    },
    marginBottom: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
}));

export const SignOutTempUserDialog = () => {
    const classes = useStyles();

    const [open, setOpen] = useState(true);

    return (
        <Dialog
            onClose={() => setOpen(false)}
            aria-labelledby="simple-dialog-title"
            open={open}
            classes={{ paper: classes.dialog }}
        >
            <DialogTitle id="simple-dialog-title">
                <FormattedMessage
                    id="app.accessedAsTemp"
                    defaultMessage="Você acessou como usuário temporário"
                />
            </DialogTitle>
            <DialogContent className={classes.marginBottom}>
                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="app.loginAgain"
                        defaultMessage="Por favor, faça o login novamente"
                    />
                </Typography>
                <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={signOut}
                >
                    <FormattedMessage id="app.signOut" defaultMessage="Sair" />
                </Button>
            </DialogContent>
        </Dialog>
    );
};
