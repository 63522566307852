import React from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Button } from '@material-ui/core';

export const NextButton = (props) => {
    return (
        <>
            <Button
                // variant="contained"
                variant="contained"
                color="primary"
                onClick={props.onClick}
                // size="small"
                endIcon={<NavigateNextIcon />}
                // fullWidth
            >
                {props.label}
            </Button>
        </>
    );
};

export default NextButton;
