import { Tileset } from '../../types/tileset';
import { db } from '../firebase';

export const uploadTileset = (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    tileset: Tileset
) => {
    return db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('tilesets')
        .add(tileset)
        .then((doc) => doc.id);
    // .catch((err) => console.log(err));
};
