import { IOrganizationPublic, OrgUser, Urls } from './../../types/organization';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SliceState = {
    publicInfo: IOrganizationPublic;
    orgId: string;
    orgUser: OrgUser | null | 'not_initialized';
    urls: Urls | 'not_initialized';
};

const initialState: SliceState = {
    orgId: '',
    publicInfo: {
        organizationLogoUrl: '',
        organizationLogoWidth: 0,
        fullOrganizationName: '',
        shortOrganizationName: '',
        // defaultPublicToken: '',
        mapboxDefaultPublicToken: '',
    },
    orgUser: 'not_initialized',
    urls: 'not_initialized',
};

const orgSlice = createSlice({
    name: 'orgSlice',
    initialState,
    reducers: {
        setOrgPublicInfo: (
            state,
            action: PayloadAction<IOrganizationPublic>
        ) => {
            const publicInfo = action.payload;
            state.publicInfo = publicInfo;
        },
        resetOrgPublicInfo: (state, action) => {
            state.publicInfo = initialState.publicInfo;
        },
        setOrgId: (state, action: PayloadAction<string>) => {
            const orgId = action.payload;
            state.orgId = orgId;
        },
        setOrgUser: (state, action: PayloadAction<OrgUser | null>) => {
            const orgUser = action.payload;
            state.orgUser = orgUser;
        },
        setUrls: (state, action: PayloadAction<Urls | null>) => {
            const urls = action.payload;
            if (urls !== null) {
                state.urls = urls;
            } else {
                state.urls = 'not_initialized';
            }
        },
    },
});

export const actions = orgSlice.actions;
export const { setOrgPublicInfo, resetOrgPublicInfo, setOrgUser, setUrls } =
    orgSlice.actions;
export type setOrgPublicInfoType = typeof setOrgPublicInfo;
export type resetOrgPublicInfoType = typeof resetOrgPublicInfo;
export type setOrgUserType = typeof setOrgUser;
export type setUrlsType = typeof setUrls;

export default orgSlice.reducer;
