import { Feature } from './../../types/feature';
import { CalculateCenterCoordinates } from './../annotations/CalculateCenterCoordinates';

export const makeTitlesFeatures = (features: Feature[]) => {
    return features.map((feature) => {
        //@ts-ignore
        const centerOfMass = CalculateCenterCoordinates({ feature });

        return {
            type: 'Feature',
            geometry: {
                coordinates: centerOfMass,
                type: 'Point',
            },
            properties: {
                title: feature.properties.title,
            },
        };
    });
};
