// import { OrgUser } from '../../types/organization';
import { downloadPhase2FormFields } from '../firebase/utils/downloadPhase2FormFields';
import { FormField } from '../types/formField';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

//Typescript
import { RootState } from '../../store/configureStore';
import { useActions } from '../customHooks/useActions';

export const usePhase2FormFields = (phase1Id: string | undefined) => {
    const [phase2FieldIdsToNames, setIdsToNames] = useState({});

    const { setPhase2FormFields, setPhase1Id } = useActions();

    const orgId: string = useSelector(
        (state: RootState) => state.organization.orgId
    );
    const phase2FormFields: FormField[] | 'not_initialized' = useSelector(
        (state: RootState) => state.phase2.formFields
    );

    useEffect(() => {
        if (phase2FormFields !== 'not_initialized') {
            const idsToNames: { [key: string]: string } = {};
            phase2FormFields.forEach((field) => {
                idsToNames[field.fieldId] = field.name;
            });

            setIdsToNames(idsToNames);
        }
    }, [phase2FormFields]);

    useEffect(() => {
        if (phase1Id) {
            setPhase1Id(phase1Id);
        }
    }, [phase1Id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (orgId) {
            downloadPhase2FormFields(setPhase2FormFields, orgId);
        }
    }, [orgId]); // eslint-disable-line react-hooks/exhaustive-deps

    return { phase2FormFields, phase2FieldIdsToNames };
};

export type phase2FormFieldsType = {
    phase2FormFields: FormField[];
    phase2FieldIdsToNames: { [key: string]: string };
};
