import { Registry } from './../../types/registry';
import { FormField } from '../../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormFieldTypes, FormFieldTypesNames } from './../../types/formField';

//types
import { Phase2Info } from '../../types/phase2';

type SetPhase2FormFields = {
    phase2FormFields: FormField[];
};

type SetPhase2Registries = {
    phase2Registries: Registry[] | 'not_initialized';
};

type SetPhase2RegistryState = {
    fieldId: string;
    value: FormFieldTypes;
};

type StartPhase2RegistryState = {
    formFields: FormField[];
    initialRegistry: Registry | undefined;
};

type RegistryStateField = {
    value: FormFieldTypes | null;
    type: FormFieldTypesNames;
};

type Phase2RegistryState =
    | ({
          [key: string]: RegistryStateField;
      } & {
          phase2Id?: string; // used to check editMode vs addMode
      })
    | null;

type SliceState = {
    formFields: FormField[] | 'not_initialized';
    registries: Registry[] | 'not_initialized';
    registryState: Phase2RegistryState;
    phase1Id: string | null;
    selectedPhase2: null | Phase2Info;
};

const initialState: SliceState = {
    formFields: 'not_initialized',
    registries: 'not_initialized',
    registryState: null,
    phase1Id: null,
    selectedPhase2: null,
};

function isEmpty(obj: object) {
    return Object.keys(obj).length === 0;
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

// export const setPhase2NameAndCreatedAt = createAsyncThunk(
//   'formFields/getPhase2NameAndCreateAt',
//   async ({ orgId, phase1Id, phase2Id }: Phase2Params) => {
//     const response = await getPhase2NameAndCreateAt({
//       orgId,
//       phase1Id,
//       phase2Id,
//     });
//     // The value we return becomes the `fulfilled` action payload
//     return response;
//   }
// );

export const formFieldsSlice = createSlice({
    name: 'formFields',
    initialState,
    reducers: {
        setPhase2FormFields: (
            state,
            action: PayloadAction<SetPhase2FormFields>
        ) => {
            const { phase2FormFields } = action.payload;
            state.formFields = phase2FormFields;
        },
        setPhase2Registries: (
            state,
            action: PayloadAction<SetPhase2Registries>
        ) => {
            const { phase2Registries } = action.payload;
            state.registries = phase2Registries;
        },
        setPhase2RegistryState: (
            state,
            action: PayloadAction<SetPhase2RegistryState>
        ) => {
            const { fieldId, value } = action.payload;
            if (state.registryState) {
                state.registryState[fieldId].value = value;
            }
        },
        startPhase2RegistryState: (
            state,
            action: PayloadAction<StartPhase2RegistryState>
        ) => {
            let { formFields, initialRegistry } = action.payload;

            let initialStateObj: Phase2RegistryState = {};

            if (initialRegistry !== undefined) {
                // edit case
                formFields.forEach((field: FormField) => {
                    // @ts-ignore: Object is possibly 'undefined'.
                    let value = initialRegistry[field.fieldId] || null;
                    let fieldState: RegistryStateField = {
                        type: field.type,
                        value,
                    };
                    // @ts-ignore: Object is possibly 'null'.
                    initialStateObj[field.fieldId] = { ...fieldState };
                });
            } else {
                // add case
                formFields.forEach((field: FormField) => {
                    let fieldState: RegistryStateField = {
                        type: field.type,
                        value: null,
                    };
                    // @ts-ignore: Object is possibly 'null'.
                    initialStateObj[field.fieldId] = { ...fieldState };
                });
            }

            state.registryState = initialStateObj;

            if (initialRegistry !== undefined && !isEmpty(initialRegistry)) {
                // @ts-ignore: Object is possibly 'null'.
                state.registryState.phase1Id = initialRegistry.phase1Id;
                // @ts-ignore: Object is possibly 'null'.
                state.registryState.phase2Id = initialRegistry.phase2Id;
            }
        },
        finishPhase2RegistryState: (state, action) => {
            state.registryState = null;
        },
        setPhase1Id: (state, action: PayloadAction<string>) => {
            state.phase1Id = action.payload;
        },
        setPhase2NameAndCreatedAt: (
            state,
            action: PayloadAction<Phase2Info | null>
        ) => {
            state.selectedPhase2 = action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //   builder.addCase(setPhase2NameAndCreatedAt.fulfilled, (state, action) => {
    //     // console.log(
    //     //   'setPhase2NameAndCreatedAt action.payload =>',
    //     //   action.payload
    //     // );
    //     state.selectedPhase2 = action.payload;
    //   });
    // },
});

export const actions = formFieldsSlice.actions;

export const {
    setPhase2FormFields,
    setPhase2Registries,
    startPhase2RegistryState,
    finishPhase2RegistryState,
    setPhase2RegistryState,
    setPhase1Id,
    setPhase2NameAndCreatedAt,
} = formFieldsSlice.actions;

export type setPhase2FormFieldsType = typeof setPhase2FormFields;
export type setPhase2RegistriesType = typeof setPhase2Registries;
export type startPhase2RegistryStateType = typeof startPhase2RegistryState;
export type finishPhase2RegistryStateType = typeof finishPhase2RegistryState;
export type setPhase2RegistryStateType = typeof setPhase2RegistryState;
export type setPhase1IdType = typeof setPhase1Id;
export type setPhase2NameAndCreatedAtType = typeof setPhase2NameAndCreatedAt;

export default formFieldsSlice.reducer;
