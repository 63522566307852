import React from 'react';

// Components
import HeaderSidebarMenu from '../sidebarMenu/HeaderSidebarMenu';
import AddAPPsAndReservaLegalOnSideBarMenu from 'features/apps-and-reserva-legal/AddAPPsAndReservaLegalOnSideBarMenu';
import ListAPPsAndReservaLegalOnSideBarMenu from 'features/apps-and-reserva-legal/ListAPPsAndReservaLegalOnSideBarMenu';

//Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Drawer, Box } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divList: {
            width: 350,
        },
    })
);

type Props = {
    setisDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isDrawerOpen: boolean;
    isAnyDrawerOpen: boolean;
};

export default function APPsAndReservaLegalDrawer({
    isDrawerOpen,
    setisDrawerOpen,
}: Props) {
    const classes = useStyles();

    const toggleDrawer =
        (openBoolean: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            setisDrawerOpen(openBoolean);
        };

    return (
        <Drawer
            variant="persistent"
            anchor="right"
            open={isDrawerOpen}
            onClick={() => {
                toggleDrawer(false);
            }}
        >
            <div className={classes.divList}>
                <HeaderSidebarMenu toggleDrawer={toggleDrawer} />
                <Box paddingX={1}>
                    <AddAPPsAndReservaLegalOnSideBarMenu />
                </Box>
                <Box paddingX={1}>
                    <ListAPPsAndReservaLegalOnSideBarMenu />
                </Box>
            </div>
        </Drawer>
    );
}
