import 'date-fns';
import React, { FunctionComponent, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import SnoozeIcon from '@material-ui/icons/Snooze';
import AlarmIcon from '@material-ui/icons/AddAlarm';
import { IconButton, InputAdornment } from '@material-ui/core';
import { FormFieldTypes } from '../../formfy/types';

type Props = {
    // field: FormField;
    value: FormFieldTypes | null;
    onChange: (value: FormFieldTypes) => void;
};

export const DateTimeFormField: FunctionComponent<Props> = ({
    value,
    onChange,
}) => {
    const [selectedDate, handleDateChange] = useState<Date | null>(
        value && value instanceof Date ? value : new Date()
    );

    const handleChange = (newDateAndTime: any) => {
        handleDateChange(newDateAndTime);
        onChange(newDateAndTime);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
                <DateTimePicker
                    fullWidth
                    autoOk
                    disableFuture
                    hideTabs
                    ampm={false}
                    value={selectedDate}
                    onChange={handleChange}
                    allowKeyboardControl={false}
                    minDate={new Date('2018-01-01')}
                    helperText="Escolha uma data e horário"
                    leftArrowIcon={<AlarmIcon />}
                    leftArrowButtonProps={{ 'aria-label': 'Prev month' }}
                    rightArrowButtonProps={{ 'aria-label': 'Next month' }}
                    rightArrowIcon={<SnoozeIcon />}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <AlarmIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
};
