import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
// import { phase1Type } from '../../API/usePhase1';
import { storePhase1Field } from '../../firebase/utils/storePhase1Field';
import { FormField } from '../../types';
import { AddField } from '../formFields/AddField';

type Props = {
    phase1FormFields: FormField[] | 'not_initialized';
};

export const AddPhase1FormField: FunctionComponent<Props> = ({
    phase1FormFields,
}) => {
    const orgId: string = useSelector(
        (state: RootState) => state.organization.orgId
    );

    const storeField = (fieldInfo: any) => {
        return storePhase1Field(fieldInfo, orgId);
    };

    return (
        <div>
            <AddField
                fieldsArraySize={phase1FormFields.length}
                formId={'phase1FormFields'}
                storeField={storeField}
            />
        </div>
    );
};
