import React, { useState } from 'react';
import AddButton from './ui/AddButton';
import CancelButton from './ui/CancelButton';
import { TextField, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export const SetChecklistItems = ({ setFieldItems, currentItems }) => {
    const [checklistItems, setChecklistItems] = useState(
        currentItems ? [...currentItems] : []
    );
    const [showForm, setShowForm] = useState(false);
    const [newChecklistItemName, setNewChecklistItemName] = useState('');

    const toggleShowForm = () => {
        setShowForm(!showForm);
    };

    const addCheckListItem = () => {
        if (!newChecklistItemName.trim()) {
            alert('Name cannot be empty!');
            return;
        }
        if (checklistItems.includes(newChecklistItemName)) {
            alert('Item already included');
            return;
        }
        let currentItems = [
            ...checklistItems,
            newChecklistItemName.toUpperCase(),
        ];
        setChecklistItems(currentItems);
        setFieldItems(currentItems);
        setNewChecklistItemName('');
        // setShowAddChecklistItemField(!showAddChecklistItemField)
        toggleShowForm();
    };

    const removeItem = (item) => {
        let filteredList = checklistItems.filter(
            (listItem) => listItem !== item
        );
        setChecklistItems(filteredList);
        setFieldItems(filteredList);
    };
    // console.log(checklistItems);
    return (
        <div>
            {checklistItems.map((item, index) => (
                <div key={index}>
                    <input
                        type="checkbox"
                        id={index}
                        name={item}
                        value={item}
                        checked={false}
                    ></input>
                    <label htmlFor={index}> {item}</label>
                    <IconButton
                        color="secodary"
                        aria-label="edit field"
                        component="span"
                        onClick={() => removeItem(item)}
                    >
                        <HighlightOffIcon
                            fontSize={'small'}
                            color="secondary"
                        />
                    </IconButton>
                </div>
            ))}
            {showForm && (
                <>
                    <TextField
                        variant="filled"
                        label={'Nome do Item'}
                        // fullWidth
                        size={'small'}
                        value={newChecklistItemName}
                        onChange={(e) => {
                            // visitContext.updateValue(field.fieldId, e.target.value)
                            setNewChecklistItemName(e.target.value);
                        }}
                        inputProps={{
                            style: { textTransform: 'capitalize' },
                        }}
                    />
                </>
            )}
            {showForm ? (
                <div>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={addCheckListItem}
                    >
                        Salvar Item
                    </Button>
                    <CancelButton label={'cancelar'} onClick={toggleShowForm} />
                </div>
            ) : (
                <AddButton label={'adicionar item'} onClick={toggleShowForm} />
            )}
        </div>
    );
};
