import React from 'react';
import { useParams } from 'react-router';

import { createShareLink } from '../../firebase/utils/createShareLink';

//Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';

//Types
import { Phase1Info } from '../../formfy/types/phase1';
import { Phase2Info } from '../../formfy/types/phase2';

//MaterialUI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinkIcon from '@material-ui/icons/Link';
import Grid from '@material-ui/core/Grid';
// import ShareIcon from '@material-ui/icons/Share';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Typography from '@material-ui/core/Typography';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import Slider from '@material-ui/core/Slider';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(1),
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
        },
        progressDiv: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
        paperLinkCreated: {
            padding: theme.spacing(2, 1),
            overflow: 'auto',
        },
    })
);

type Params = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

export default function PublicLinkDialog() {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id }: Params = useParams();

    const [linkURL, setlinkURL] = React.useState('');

    const phase1Info: Phase1Info | null = useSelector((state: RootState) => {
        return state.phase1.selectedPhase1;
    });

    const phase2Info: Phase2Info | null = useSelector((state: RootState) => {
        return state.phase2.selectedPhase2;
    });
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [renderState, setRenderState] = React.useState('GetLink');

    const [linkExpDays, setlinkExpDays] = React.useState(10);

    const [onCreating, setonCreating] = React.useState(false);
    const GetLinkUI = () => {
        return (
            <React.Fragment>
                <DialogContent dividers>
                    <Box padding={1}>
                        <Typography variant="body1" gutterBottom>
                            <FormattedMessage
                                id="app.whatIsQuickLink"
                                defaultMessage="O que é um Link Rápido?"
                            />
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <FormattedMessage
                                id="app.quickLinkIs"
                                defaultMessage="É uma forma rápida de compartilhar uma área, pois não é necessário fazer login."
                            />
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <FormattedMessage
                                id="app.quickLinkExplanation"
                                defaultMessage="Ao abrir o link, é mostrado uma versão que permite apenas visualizar os dados da área. Ou seja, não é possível deletar nem
                adiconar nenhuma informação."
                            />
                        </Typography>
                    </Box>
                    <Paper variant="outlined" className={classes.paper}>
                        {linkExpDays === 0 ? (
                            <Typography
                                variant="subtitle2"
                                gutterBottom
                                align="left"
                            >
                                <FormattedMessage
                                    id="app.linkExpires1Day"
                                    defaultMessage="Link vai expirar em (1) dia"
                                />
                            </Typography>
                        ) : (
                            <Typography
                                variant="subtitle2"
                                gutterBottom
                                align="left"
                            >
                                <FormattedMessage
                                    id="app.linkExpiresNDays"
                                    defaultMessage="Link vai expirar em ({linkExpDays}) dias"
                                    values={{ linkExpDays }}
                                />
                                {/* Link vai expirar em ({linkExpDays}) dias */}
                            </Typography>
                        )}

                        <Slider
                            value={linkExpDays}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'number') {
                                    setlinkExpDays(newValue);
                                }
                            }}
                            aria-labelledby="discrete-slider-small-steps"
                            step={5}
                            marks
                            min={0}
                            max={30}
                            disabled={onCreating}
                            // valueLabelDisplay="auto"
                        />
                    </Paper>
                </DialogContent>
                <DialogActions>
                    {!onCreating ? (
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    onClick={handleClose}
                                    color="primary"
                                >
                                    <FormattedMessage
                                        id="app.cancel"
                                        defaultMessage="Cancelar"
                                    />
                                </Button>
                            </Grid>
                            <Grid item xs={8}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={onCreateClick}
                                    color="primary"
                                >
                                    <FormattedMessage
                                        id="app.generateQuickLink"
                                        defaultMessage="Gerar Link Rápido"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <div className={classes.progressDiv}>
                            <LinearProgress />
                        </div>
                    )}
                </DialogActions>
            </React.Fragment>
        );
    };

    const [copySuccess, setcopySuccess] = React.useState(false);
    const handleCloseSnackbar = (
        event?: React.SyntheticEvent,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const LinkCreatedUI = () => {
        return (
            <React.Fragment>
                <DialogContent dividers>
                    <CheckCircleIcon color="primary" />
                    &nbsp;
                    <Typography variant="h6" gutterBottom display="inline">
                        <FormattedMessage
                            id="app.linkSuccessfullyGenerated"
                            defaultMessage="Link gerado com sucesso!"
                        />
                    </Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper
                                variant="outlined"
                                className={classes.paperLinkCreated}
                            >
                                <Typography variant="subtitle2" gutterBottom>
                                    {linkURL}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                endIcon={<FileCopyIcon />}
                                onClick={() => {
                                    navigator.clipboard.writeText(linkURL);
                                    setcopySuccess(true);
                                }}
                            >
                                <FormattedMessage
                                    id="app.copyLink"
                                    defaultMessage="Copiar Link"
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        color="primary"
                    >
                        <FormattedMessage
                            id="app.close"
                            defaultMessage="Fechar"
                        />
                    </Button>
                    <Snackbar
                        open={copySuccess}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="success">
                            <FormattedMessage
                                id="app.linkCopied"
                                defaultMessage="Link copiado"
                            />
                        </Alert>
                    </Snackbar>
                    {/* <Button
            variant="contained"
            color="primary"
            endIcon={<FileCopyIcon />}
            onClick={() => {
              navigator.clipboard.writeText(linkURL);
              setcopySuccess(true);
            }}
          >
            Copy link
          </Button> */}
                </DialogActions>
            </React.Fragment>
        );
    };

    function onCreateClick() {
        setonCreating(true);
        if (linkExpDays === 0) {
            const OneDay = 1;
            createShareLink(
                orgId,
                phase1Id,
                phase2Id,
                OneDay,
                window.location.origin
            )
                .then((link: string) => {
                    setlinkURL(link);
                    // alert('your link is: ' + link + ', valid for ' + linkExpDays + ' days');
                    setRenderState('LinkCreated');
                })
                .catch((e) => {
                    console.log('impossible to create shareLink');
                    alert(e);
                });
        } else {
            createShareLink(
                orgId,
                phase1Id,
                phase2Id,
                linkExpDays,
                window.location.origin
            )
                .then((link: string) => {
                    setlinkURL(link);
                    // alert('your link is: ' + link + ', valid for ' + linkExpDays + ' days');
                    setRenderState('LinkCreated');
                })
                .catch((e) => {
                    console.log('impossible to create shareLink');
                    alert(e);
                });
        }
    }

    return (
        <React.Fragment>
            <Button
                endIcon={<LinkIcon />}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleClickOpen}
                size="large"
            >
                <FormattedMessage
                    id="app.quickLink"
                    defaultMessage="Link Rápido"
                />
            </Button>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    <LinkIcon />
                    &nbsp;
                    <Typography variant="h6" gutterBottom display="inline">
                        <FormattedMessage
                            id="app.quickLink"
                            defaultMessage="Link Rápido"
                        />
                        {': '}&nbsp;
                    </Typography>
                    <Typography variant="h6" gutterBottom display="inline">
                        {phase1Info?.name} =&gt; {phase2Info?.name}
                    </Typography>
                </DialogTitle>

                <div className={classes.root}>
                    {
                        {
                            GetLink: GetLinkUI(),
                            LinkCreated: LinkCreatedUI(),
                        }[renderState]
                    }
                </div>
            </Dialog>
        </React.Fragment>
    );
}
