import React from 'react';
import { Button } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

export const CancelButton = ({ onClick, label, customProps }) => {
    return (
        <div>
            <Button
                // variant="contained"
                variant="outlined"
                color="secondary"
                onClick={onClick}
                size="small"
                startIcon={<CancelIcon />}
                {...customProps}
                // fullWidth
            >
                {label}
            </Button>
        </div>
    );
};

export default CancelButton;
