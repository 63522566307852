import { db } from '../firebase';

type Updates = {
    name?: string;
    date?: Date;
};

export const updateLink = (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    linkDocId: string,
    updates: Updates
) => {
    console.log(updates, linkDocId);

    db.collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('links')
        .doc(linkDocId)
        .update(updates)
        .then((doc) => console.log('Link correctly updated'));
};
