import React from 'react';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../store/configureStore';
import { TilesetCard } from './TilesetCard';
import { nanoid } from 'nanoid';
import { useTilesets } from '../../hooks/useTilesets';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fullWidth: {
            width: '100%',
        },
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        media: {
            height: 140,
        },
    })
);

export const Tilesets = () => {
    const classes = useStyles();
    // const tilesets = useSelector((state: RootState) => state.tilesets.tilesets);
    const { tilesets } = useTilesets();

    return (
        <div className={classes.fullWidth}>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    {tilesets !== 'not_initialized' &&
                        tilesets.map((tileset) => {
                            return (
                                <Grid item xs={12} sm={4}>
                                    <TilesetCard
                                        tileset={tileset}
                                        key={nanoid(4)}
                                    />
                                </Grid>
                            );
                        })}
                </Grid>
            </div>
        </div>
    );
};
