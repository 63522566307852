import { ReservaLegalOuAreaPreservacaoPermanente } from 'formfy/types/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SliceState = {
    reservaLegal: ReservaLegalOuAreaPreservacaoPermanente[] | 'not_initialized';
    areaPreservacaoPermanente:
        | ReservaLegalOuAreaPreservacaoPermanente[]
        | 'not_initialized';
};

const initialState: SliceState = {
    reservaLegal: 'not_initialized',
    areaPreservacaoPermanente: 'not_initialized',
};

const APPsAndReservaLegalSlice = createSlice({
    name: 'APPsAndReservaLegalSlice',
    initialState,
    reducers: {
        setReservaLegal: (
            state,
            action: PayloadAction<ReservaLegalOuAreaPreservacaoPermanente[]>
        ) => {
            state.reservaLegal = action.payload;
        },
        setAreaPreservacaoPermanente: (
            state,
            action: PayloadAction<ReservaLegalOuAreaPreservacaoPermanente[]>
        ) => {
            state.areaPreservacaoPermanente = action.payload;
        },
    },
});

export const actions = APPsAndReservaLegalSlice.actions;
export const { setReservaLegal, setAreaPreservacaoPermanente } =
    APPsAndReservaLegalSlice.actions;
export type setReservaLegalType = typeof setReservaLegal;
export type setAreaPreservacaoPermanenteType =
    typeof setAreaPreservacaoPermanente;

export default APPsAndReservaLegalSlice.reducer;
