import { useActions } from './useActions';
import { setOrgUserType } from '../features/organizations/organizationSlice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/configureStore';
import { db } from '../firebase/firebase';

export const useOrgUser = (orgId: string) => {
    const userId = useSelector((state: RootState) => state.auth.userId);
    const actions = useActions();
    const setOrgUser: setOrgUserType = actions.setOrgUser;

    const orgUser = useSelector(
        (state: RootState) => state.organization.orgUser
    );

    useEffect(() => {
        let hasRun = false;
        try {
            if (userId !== null) {
                db.collection('organizations')
                    .doc(orgId)
                    .collection('users')
                    .doc(userId)
                    .onSnapshot((docRef) => {
                        if (docRef.exists) {
                            const docData: any = docRef.data();
                            setOrgUser(docData);
                        } else {
                            setOrgUser(null);
                        }
                        hasRun = true;
                    });
            }
        } catch (error) {
            setOrgUser(null);
        }

        return () => {
            if (hasRun) {
                // console.log('vou setar nulo2');
                setOrgUser(null);
            }
        };
    }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

    return [orgUser];
};
