// import { OrgUser } from '../../types/organization';
import { downloadPhase1FormFields } from '../firebase/utils/downloadPhase1FormFields';
import { FormField } from '../types/formField';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

//Typescript
import { RootState } from '../../store/configureStore';
import { useActions } from '../customHooks/useActions';

export const usePhase1FormFields = () => {
    const { setPhase1FormFields } = useActions();
    const [phase1FieldIdsToNames, setIdsToNames] = useState({});

    const orgId: string = useSelector(
        (state: RootState) => state.organization.orgId
    );
    const phase1FormFields: FormField[] | 'not_initialized' = useSelector(
        (state: RootState) => state.phase1.formFields
    );
    useEffect(() => {
        if (phase1FormFields !== 'not_initialized') {
            const idsToNames: { [key: string]: string } = {};
            phase1FormFields.forEach((field) => {
                idsToNames[field.fieldId] = field.name;
            });

            setIdsToNames(idsToNames);
        }
    }, [phase1FormFields]);

    useEffect(() => {
        if (orgId) {
            downloadPhase1FormFields(setPhase1FormFields, orgId);
        }
    }, [orgId]); // eslint-disable-line react-hooks/exhaustive-deps

    return { phase1FormFields, phase1FieldIdsToNames };
};

export type phase1FormFieldsType = {
    phase1FormFields: FormField[];
    phase1FieldIdsToNames: { [key: string]: string };
};
