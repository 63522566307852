import React, { Fragment, useState } from 'react';

//MaterialUI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
    FormControl,
    InputLabel,
    OutlinedInput,
    Typography,
} from '@material-ui/core';
import { signIn } from '../features/auth/signIn';
import { FormattedMessage } from 'react-intl';
import { AuthErrorDialog } from '../features/auth/AuthErrorDialog';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    img: {
        maxWidth: '100%',
        borderRadius: '50%',
    },
}));

export const GeneralLoginPage = () => {
    const classes = useStyles();
    const [inInvalidPassword, setInInvalidPassword] = useState(false);
    const [loginError, setError] = useState<Error | undefined>(undefined);

    const onFormSubmit = (e: any) => {
        e.preventDefault();

        let inputTags = e.target.getElementsByTagName('input');
        const email = inputTags[0].value;
        const password = inputTags[1].value;

        signIn(email, password).catch((error) => {
            if (error.code === 'auth/wrong-password') {
                setInInvalidPassword(true);
            } else {
                setError(error);
            }
        });
    };

    console.log(window.navigator);

    return (
        <Fragment>
            <CssBaseline />
            <Container maxWidth="xs">
                <div className={classes.paper}>
                    <Typography variant="h5">COOPASVTech</Typography>
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={onFormSubmit}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="email">
                                <FormattedMessage
                                    id="app.email"
                                    defaultMessage="E-mail"
                                />
                            </InputLabel>
                            <OutlinedInput
                                required
                                label="E-mail"
                                name="email"
                                id="email"
                                // margin='normal'
                                autoComplete="email"
                                autoFocus
                                fullWidth
                            />
                        </FormControl>
                        {/* <TextField
              variant='outlined'
              required
              label='E-mail'
              name='email'
              id='email'
              margin='normal'
              autoComplete='email'
              autoFocus
              fullWidth
            /> */}

                        <TextField
                            variant="outlined"
                            margin="normal"
                            // required
                            fullWidth
                            name="password"
                            label={
                                window.navigator.language === 'pt-BR'
                                    ? 'Senha'
                                    : 'Password'
                            }
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            helperText={inInvalidPassword && 'Senha incorreta!'}
                            onChange={() => setInInvalidPassword(false)}
                            error={inInvalidPassword}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            <FormattedMessage
                                id="app.login-submit"
                                defaultMessage="ENTRAR NA PLATAFORMA"
                                description="Submit button in login page"
                            />
                        </Button>
                    </form>
                </div>
            </Container>
            {!!loginError && (
                <AuthErrorDialog loginError={loginError} setError={setError} />
            )}
        </Fragment>
    );
};
