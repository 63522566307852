import React from 'react';
import { useParams } from 'react-router';

//Redux
import { useAppSelector, useAppDispatch } from '../../store/reduxCustomHooks';
import {
    selectOrgUsers,
    ActionAddPhase2UserAcces,
} from '../../features/members/membersSlice';

//Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    })
);

interface MemberMenuItem {
    userId: string;
    orgId: string;
    phase1Id: string;
    phase2Id: string;
    userName: string;
    email: string;
    userAlreadyHasAccess: boolean;
}

type URLParams = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

export default function ButtonAndDialogAddUserAccessPhase2() {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id }: URLParams = useParams();
    const [open, setOpen] = React.useState(false);
    const [select, setSelect] = React.useState('');
    const dispatch = useAppDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setSelect('');
        setOpen(false);
    };

    const handleGiveAccess = () => {
        if (select) {
            dispatch(
                ActionAddPhase2UserAcces({
                    orgId,
                    userId: select,
                    phase1Id,
                    phase2Id,
                })
            );
            setSelect('');
            setOpen(false);
        }
    };

    const handleChangeSelect = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setSelect(event.target.value as string);
    };

    const [membersList, setmembersList] = React.useState<MemberMenuItem[]>([]);
    const orgUsers = useAppSelector(selectOrgUsers);

    React.useEffect(() => {
        const list: MemberMenuItem[] = [];
        for (const user of orgUsers) {
            if (
                user.organizationAccessLevel !== 'Admin' &&
                user.organizationAccessLevel !== 'Temp'
            ) {
                if (user.phase2UserHasAccess) {
                    const found = user.phase2UserHasAccess.find(
                        (e) => e === phase2Id
                    );
                    if (found) {
                        list.push({
                            userId: user.id,
                            orgId: orgId,
                            phase1Id: phase1Id,
                            phase2Id: phase2Id,
                            userName: user.name,
                            email: user.email,
                            userAlreadyHasAccess: true,
                        });
                    } else {
                        list.push({
                            userId: user.id,
                            orgId: orgId,
                            phase1Id: phase1Id,
                            phase2Id: phase2Id,
                            userName: user.name,
                            email: user.email,
                            userAlreadyHasAccess: false,
                        });
                    }
                }
            }
        }
        setmembersList(list);
    }, [orgUsers, orgId, phase1Id, phase2Id]);

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<AddIcon />}
                fullWidth
                onClick={handleClickOpen}
            >
                {' '}
                <FormattedMessage
                    id="app.giveAccess"
                    defaultMessage="Dar Acesso"
                />
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">
                    <FormattedMessage id="app.access" defaultMessage="Acesso" />
                </DialogTitle>
                <DialogContent dividers>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            <FormattedMessage
                                id="app.members"
                                defaultMessage="Membros"
                            />
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={select}
                            onChange={handleChangeSelect}
                        >
                            {membersList.map((user) => {
                                return (
                                    <MenuItem
                                        key={user.userId}
                                        disabled={user.userAlreadyHasAccess}
                                        value={user.userId}
                                    >
                                        {`${user.userName} / ${user.email}`}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Button
                                fullWidth
                                onClick={handleClose}
                                variant="outlined"
                                color="primary"
                            >
                                <FormattedMessage
                                    id="app.cancel"
                                    defaultMessage="Cancelar"
                                />
                            </Button>
                        </Grid>
                        <Grid item xs={8}>
                            <Button
                                fullWidth
                                onClick={handleGiveAccess}
                                variant="contained"
                                color="primary"
                                disabled={!select}
                            >
                                <FormattedMessage
                                    id="app.giveAccess"
                                    defaultMessage="Dar Acesso"
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}
