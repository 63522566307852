import React, { useEffect, useState } from 'react';

//Components
import Map from '../features/mapboxGl/Map';
import MapNavbar from '../features/mapboxGl/MapNavbar';
import { Tileset } from '../types/tileset';
// import { Timeline } from '../features/timeline/Timeline';
import { useParams } from 'react-router';
import { useActions } from '../hooks/useActions';
import { useActions as useFormfyActions } from '../formfy/customHooks/useActions';
import { useTilesets } from '../hooks/useTilesets';
import { useMapLayers } from '../features/mapboxGl/useMapLayers';
import { Phase2PageContext } from '../contexts/Phase2PageContext';
import { MapClass } from '../features/mapboxGl/MapClass';

import { useLinks } from '../hooks/useLinks';
import { LinkData } from '../types/link';
import { useAnnotations } from '../hooks/useAnnotations';
import { Annotation } from '../types/annotation';
import { useRenderFieldblocks } from '../features/fieldBlocks/useRenderFieldblocks';
import { usePhase1NameAndCreatedAt } from '../formfy/customHooks/usePhase1NameAndCreatedAt';
import { usePhase2NameAndCreatedAt } from '../formfy/customHooks/usePhase2NameAndCreatedAt';
import { PhotoData } from '../types/photoData';
import { usePhotos } from '../hooks/usePhotos';

// Components
import RenderAPPsAndReservaLegalLayers from 'features/apps-and-reserva-legal/RenderAPPsAndReservaLegalLayers';

type Params = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

export const Phase2Page = () => {
    const { orgId, phase1Id, phase2Id }: Params = useParams();
    const {
        setSelectedDate,
        setLinks,
        setTilesets,
        setAnnotations,
        setPhotos,
    } = useActions();

    const {
        setFeatures,
        setFieldblocksRegistries,
        setFieldblocksFormFields,
        setForms,
    } = useFormfyActions();

    const { tilesets } = useTilesets(); // download tilesets
    const { links } = useLinks(orgId, phase1Id, phase2Id); // download links
    const { annotations } = useAnnotations(orgId, phase1Id, phase2Id); // download annotations
    const { photos } = usePhotos(orgId, phase1Id, phase2Id); // download photos
    const [mapClass, setMapClass] = React.useState<any>(new MapClass());
    const [draw, setDraw] = React.useState<any>();

    const { filteredTilesets, storeMoveUp, storeMoveDown } =
        useMapLayers(mapClass); // sets all layers

    const [didSetInitialSelectedDate, setDidSetInitialSelectedDate] =
        useState(false);

    useRenderFieldblocks(mapClass);
    // const { phase1Info } = usePhase1NameAndCreatedAt();
    usePhase1NameAndCreatedAt();
    usePhase2NameAndCreatedAt();

    // React.useEffect(() => {
    //   dispatch(
    //     setPhase2NameAndCreatedAt({
    //       orgId: orgId,
    //       phase1Id: phase1Id,
    //       phase2Id: phase2Id,
    //     })
    //   );
    // }, [orgId, phase1Id, phase2Id, dispatch]);

    useEffect(() => {
        if (didSetInitialSelectedDate) return;

        let newest = new Date(0);

        if (
            tilesets !== 'not_initialized' &&
            links !== 'not_initialized' &&
            photos !== 'not_initialized' &&
            annotations !== 'not_initialized'
        ) {
            tilesets.forEach((ts: Tileset) => {
                if (ts.mappedOn > newest) {
                    newest = ts.mappedOn;
                }
            });

            links.forEach((link: LinkData) => {
                if (link.date > newest) {
                    newest = link.date;
                }
            });

            annotations.forEach((annotations: Annotation) => {
                if (annotations.date > newest) {
                    newest = annotations.date;
                }
            });

            photos.forEach((photo: PhotoData) => {
                if (photo.date > newest) {
                    newest = photo.date;
                }
            });

            const selectedDate = newest.getTime() === 0 ? new Date() : newest;

            setSelectedDate(selectedDate);
            setDidSetInitialSelectedDate(true);
        }
    }, [tilesets, links, annotations, photos]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => {
            setLinks('not_initialized');
            setTilesets('not_initialized');
            setAnnotations('not_initialized');
            setSelectedDate('not_sure');
            setFeatures('not_initialized');
            setPhotos('not_initialized');
            setFieldblocksFormFields({
                fieldblocksFormFields: 'not_initialized',
            });
            setFieldblocksRegistries({
                fieldblocksRegistries: 'not_initialized',
            });
            setForms('not_initialized');
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Phase2PageContext.Provider
                // value={{ map, setMap, draw, setDraw, filteredTilesets }}
                value={{
                    mapClass,
                    setMapClass,
                    draw,
                    setDraw,
                    filteredTilesets,
                    storeMoveUp,
                    storeMoveDown,
                }}
            >
                <MapNavbar />
                <Map />
                <RenderAPPsAndReservaLegalLayers />
                {/* <Timeline /> */}
            </Phase2PageContext.Provider>
        </div>
    );
};
