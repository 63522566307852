import React, { FunctionComponent, useEffect, useState } from 'react';

import {
    Button,
    Card,
    CardContent,
    makeStyles,
    CardActions,
    CardHeader,
} from '@material-ui/core';
// import { FormField, FormFieldTypes } from '../../formfy/types';
// import { RenderFormField } from '../ui/RenderFormField';
import { FormattedMessage } from 'react-intl';
import { usePhase1FormFields } from '../../API/usePhase1FormFields';
import { RenderFormField } from '../../../features/ui/RenderFormField';
import { FormField } from '../../types';

const useStyles = makeStyles((theme) => ({
    marginTop: {
        marginTop: theme.spacing(2),
    },
    title: {
        fontSize: 14,
    },
}));

type Props = {};

export const Phase1FormManagePreview: FunctionComponent<Props> = () => {
    const { phase1FormFields } = usePhase1FormFields();

    const classes = useStyles();

    // [stateField]

    const [stateFieldsMock, setStateFieldsMock] = useState<any>();
    const [localFormFields, setLocalFormFields] = useState<FormField[]>([]);

    useEffect(() => {
        if (phase1FormFields !== 'not_initialized') {
            let stateFields: any = {};
            phase1FormFields.forEach((field) => {
                stateFields[field.fieldId] = {
                    type: field.type,
                    value: null,
                };
            });

            setStateFieldsMock(stateFields);
            setLocalFormFields(phase1FormFields);
        }
    }, [phase1FormFields]);

    return (
        <Card className={classes.marginTop}>
            <CardHeader
                title={
                    <div>
                        <i>Preview </i> do formulário de criação de novos
                        projetos
                    </div>
                }
                subheaderTypographyProps={{ variant: 'subtitle2' }}
                subheader={
                    <div>
                        <p>Este formulário não criará projetos novos</p>
                    </div>
                }
            ></CardHeader>
            <CardContent>
                {phase1FormFields !== 'not_initialized' &&
                    localFormFields.map((field: FormField) => {
                        if (field.fieldId === 'createdAt') {
                            return; //eslint-disable-line
                        }

                        const onChange = (value: any) => {
                            let newMock = {
                                ...stateFieldsMock,
                            };

                            newMock[field.fieldId].value = value;

                            setStateFieldsMock(newMock);
                        };
                        if (stateFieldsMock) {
                            return (
                                <div className={classes.marginTop}>
                                    <RenderFormField
                                        key={field.fieldId}
                                        stateField={
                                            stateFieldsMock[field.fieldId]
                                        }
                                        formField={field}
                                        onChange={onChange}
                                    />
                                </div>
                            );
                        } else {
                            return <></>;
                        }
                    })}
            </CardContent>
            <CardActions>
                <Button
                    // onClick={onSaveClick1}
                    variant="contained"
                    color="primary"
                    size="medium"
                >
                    <FormattedMessage
                        id="app.create-new-phase1-button"
                        defaultMessage="Criar"
                    />
                </Button>
                <Button
                    // component={RouterLink}
                    // to={`/organizationId/${orgId}`}
                    variant="outlined"
                    color="primary"
                    size="medium"
                >
                    <FormattedMessage
                        id="app.cancel"
                        defaultMessage="Cancelar"
                    />
                </Button>
            </CardActions>
        </Card>
    );
};
