import { PhotoData } from './../../types/photoData';

export const removePhotoFromDrive = async (photo: PhotoData) => {
    // const formData = new FormData();

    // formData.append('gdriveFileId', photo.gdriveId);
    // console.log(photo.gdriveId);
    const serverUrl =
        // 'https://us-central1-coopasvtech-drb-dcaa7.cloudfunctions.net/removeFile';
        'https://us-central1-novo-coopasvtech.cloudfunctions.net/removeFile';

    // const response = await axios.post(
    //   'http://localhost:5001/coopasvtech-drb-dcaa7/us-central1/removeFile',
    //   // 'https://us-central1-coopasvtech-drb-dcaa7.cloudfunctions.net/removeFile',
    //   { gdriveFileId: photo.gdriveId },
    //   {
    //     headers: {
    //       // Accept: 'application/json',
    //       'Content-type': `text/plain;`,
    //     },
    //   }
    // );

    const response = await fetch(serverUrl, {
        method: 'POST',
        body: JSON.stringify({ gdriveFileId: photo.gdriveId }),
    }).then((res) => res);

    // console.log(response);

    return response;
};
