import axios from 'axios';

export const setCenterAndBounds = async (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    tilesetId: string,
    tilesetDocId: string
) => {
    const data = {
        orgId,
        phase1Id,
        phase2Id,
        tilesetId,
        tilesetDocId,
    };

    const headers = {
        'Content-Type': 'text/plain',
    };

    const response = await axios.post(
        // 'https://us-central1-coopasvtech-drb-dcaa7.cloudfunctions.net/setCenterAndBounds',
        'https://us-central1-novo-coopasvtech.cloudfunctions.net/setCenterAndBounds',
        data,
        { headers }
    );

    return response;
};
