import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { TextField } from '@material-ui/core';
import isEmail from 'validator/lib/isEmail';
import { FormField, FormFieldTypes } from '../../formfy/types';

type Props = {
    field: FormField;
    value: FormFieldTypes | null;
    onChange: (value: FormFieldTypes) => void;
};

export const EmailFormField: FunctionComponent<Props> = ({
    field,
    value,
    onChange,
}) => {
    const [text, setText] = useState<string>(
        typeof value === 'string' ? value : ''
    );
    const [helperText, setHelperText] = useState('');

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newText: string = event.target.value;
        // if (!isEmail(newText) && newText.indexOf('@') != -1) {
        if (!isEmail(newText) && newText.length >= 10) {
            setHelperText('Email inválido');
        } else {
            setHelperText('');
        }
        setText(newText);
        onChange(newText);
    };

    return (
        <TextField
            helperText={helperText}
            error={!!helperText}
            value={text}
            label={field.name}
            onChange={handleChange}
            variant="filled"
            fullWidth
        />
    );
};
