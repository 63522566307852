import { db } from '../../firebase/firebase';
import { UserOrgs } from '../../types/user';

interface OrgPublicInfo {
    orgId: string;
    defaultPublicToken: string;
    fullOrganizationName: string;
    mapboxDefaultPublicToken: string;
    organizationLogoUrl: string;
    organizationLogoWidth: number;
    shortOrganizationName: string;
}

const getOrgPublicInfo = (userOrgs: UserOrgs) => {
    const premisesArray = [];

    for (const UserOrgDoc of userOrgs) {
        const promise = db
            .doc(
                `organizations/${UserOrgDoc.orgId}/organizationInfo/publicInfo`
            )
            .get();
        premisesArray.push(promise);
    }

    return Promise.all(premisesArray);
};

// then-catch asynchronous call
export const functionGetOrgPublicInfo = function (userOrgs: UserOrgs) {
    return getOrgPublicInfo(userOrgs)
        .then((res) => {
            const results: OrgPublicInfo[] = [];
            res.forEach((orgPublicInfo) => {
                const data: any = orgPublicInfo.data();
                const parentCollection = orgPublicInfo.ref.parent.parent;
                if (!parentCollection) {
                    return;
                }
                if (parentCollection.parent.id === 'organizations') {
                    const orgId = parentCollection.id;
                    results.push({
                        ...data,
                        orgId: orgId,
                    });
                }
            });
            // console.log('results =>', results);
            return results;
        })
        .catch((e) => {
            console.error(e);
            return Promise.reject(e);
        });
};
