import { OrgUser } from './../../types/organization';
import { downloadPhase1Registries } from './../firebase/utils/downloadPhase1Registries';
import { Registry } from '../types/registry';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

//Typescript
import { RootState } from '../../store/configureStore';
import { useActions } from '../customHooks/useActions';

export const usePhase1Registries = () => {
    const { setPhase1Registries } = useActions();

    const orgId: string = useSelector(
        (state: RootState) => state.organization.orgId
    );
    const orgUser: OrgUser | null | 'not_initialized' = useSelector(
        (state: RootState) => state.organization.orgUser
    );
    const phase1Registries: Registry[] | 'not_initialized' = useSelector(
        (state: RootState) => state.phase1.registries
    );

    useEffect(() => {
        // console.log(orgId, orgUser);
        if (
            orgId &&
            orgUser &&
            orgUser !== 'not_initialized' &&
            phase1Registries === 'not_initialized'
        ) {
            downloadPhase1Registries(orgId, orgUser, setPhase1Registries);
        }
    }, [orgId, orgUser]); // eslint-disable-line react-hooks/exhaustive-deps

    return { phase1Registries };
};

export type phase1RegistriesType = {
    phase1Registries: Registry[];
};
