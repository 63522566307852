import { useParams } from 'react-router-dom';
import { RootState } from '../store/configureStore';
import { useSelector } from 'react-redux';
import { useActions } from './useActions';
import { downloadTilesets } from './../firebase/utils/downloadTilesets';
import { useEffect } from 'react';
// import { Tileset } from './../types/tileset';

type Params = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

export const useTilesets = () => {
    const { orgId, phase1Id, phase2Id }: Params = useParams();
    // const [tilesets, setTilesets] = useState<Tileset[]>();
    const { setTilesets } = useActions();
    const tilesets = useSelector((state: RootState) => state.tilesets);

    useEffect(() => {
        if (tilesets.tilesets === 'not_initialized') {
            downloadTilesets(orgId, phase1Id, phase2Id, setTilesets);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return tilesets;
};
