import { RootState } from '../store/configureStore';
import { useSelector } from 'react-redux';
import { useActions } from './useActions';
import { downloadAnnotations } from './../firebase/utils/downloadAnnotations';
import { useEffect } from 'react';

export const useAnnotations = (
    orgId: string,
    phase1Id: string,
    phase2Id: string
) => {
    const { setAnnotations } = useActions();
    const annotations = useSelector(
        (state: RootState) => state.annotations.annotations
    );

    useEffect(() => {
        if (annotations === 'not_initialized') {
            downloadAnnotations(orgId, phase1Id, phase2Id, setAnnotations);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return { annotations };
};
