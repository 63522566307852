import React, { FunctionComponent } from 'react';

import { Link as RouterLink, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

//Types
import { Phase1Info } from '../../formfy/types/phase1';
import { Registry } from '../../formfy/types';

//MaterialUI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { Phase2CardOptions } from './Phase2CardOptions';
import { Divider } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1, 1, 1) /* top | horizontal | bottom */,
        },
        gridPaperContent: {
            flexGrow: 1,
        },
        gridItemMoreVertIcon: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        paperNoProjects: {
            padding: theme.spacing(1),
        },
        flex: {
            display: 'flex',
            paddingTop: theme.spacing(1),
        },
    })
);

type Params = {
    phase1Id: string;
};

type Props = {
    phase2Registry: Registry;
    phase2FieldIdsToNames: { [key: string]: string };
};

export const Phase2Card: FunctionComponent<Props> = ({ phase2Registry }) => {
    const classes = useStyles();
    const location = useLocation();
    const { phase1Id }: Params = useParams();

    const accessLevel = useSelector((state: RootState) => {
        const orgUser = state.organization.orgUser;
        if (orgUser !== 'not_initialized') {
            return orgUser?.organizationAccessLevel;
        }
    });

    const [phase2CreatedAt, setphase2CreatedAt] = React.useState<any>(null);
    React.useEffect(() => {
        if (phase2Registry.createdAt) {
            setphase2CreatedAt(phase2Registry.createdAt);
        }
    }, [phase2Registry, phase2CreatedAt]);

    const phase1Info: Phase1Info | null = useSelector((state: RootState) => {
        return state.phase1.selectedPhase1;
    });

    console.log(phase2Registry);

    function cleanUppercaseAndHyphenate(input: string): string {
        // Remove special characters using a regex that keeps only letters and numbers and spaces
        const noSpecialChars = input.replace(/[^a-zA-Z0-9 ]/g, '');
        // Convert the resulting string to uppercase
        const upperCaseString = noSpecialChars.toUpperCase();
        // Replace spaces with hyphens
        return upperCaseString.replace(/ /g, '-');
    }

    const propriedadeName = cleanUppercaseAndHyphenate(
        phase2Registry.name?.toString() ?? '-'
    );
    const produtorName = cleanUppercaseAndHyphenate(phase1Info?.name ?? '-');
    const kmlFileName = `KML-${produtorName}-${propriedadeName}-${phase1Id}-${phase2Registry.phase2Id}.kml`;
    const [fetchLoading, setfetchLoading] = React.useState<any>(false);
    const fetchKMLEndPoint = () => {
        setfetchLoading(true);
        const url = `https://cloud-run-microservice-template-python-p26aacx7eq-uc.a.run.app/get-kml/${phase1Id}-${phase2Registry.phase2Id}`;
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', kmlFileName);
                document.body.appendChild(link);
                link.click();
                setfetchLoading(false);
            });
    };

    return (
        <Paper elevation={1} className={classes.paper}>
            <Grid
                container
                className={classes.gridPaperContent}
                alignItems="center"
            >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} zeroMinWidth>
                    <Typography
                        variant="h6"
                        align="left"
                        color="textPrimary"
                        gutterBottom
                        noWrap
                    >
                        <Box paddingRight={1} component="span">
                            <LocationOnIcon />
                        </Box>
                        {phase2Registry.name}
                    </Typography>
                    {/* <Divider />
          <Typography variant='body1' align='left' color='textSecondary' noWrap>
            <FormattedMessage
              id='app.created-at'
              defaultMessage='Cadastro realizado em:'
            />{' '}
            <FormattedDate
              value={phase2CreatedAt}
              year='numeric'
              month='numeric'
              day='numeric'
              //weekday="long"
            />
          </Typography> */}
                    <Divider />
                    <Typography
                        variant="body1"
                        align="left"
                        color="textSecondary"
                        noWrap
                    >
                        <FormattedMessage
                            id="app.memberName"
                            defaultMessage="Produtor"
                        />
                        : {phase1Info?.name ? phase1Info.name : '...'}
                    </Typography>
                    <Divider />
                    <Typography
                        variant="body1"
                        align="left"
                        color="textSecondary"
                        noWrap
                    >
                        <FormattedMessage
                            id="app.community"
                            defaultMessage="Comunidade"
                        />
                        : {phase2Registry.comunidade}
                    </Typography>
                    <Divider />
                    <Box display="flex">
                        <Box paddingRight={1}>
                            <Typography
                                variant="body1"
                                align="left"
                                color="textSecondary"
                            >
                                <FormattedMessage
                                    id="app.county"
                                    defaultMessage="Município"
                                />
                                : {phase2Registry.municipio}
                            </Typography>
                        </Box>
                        {/* <Box paddingRight={1}>
              <Typography variant='body1' align='left' color='textSecondary'>
                Estado: {phase2Registry.estado};
              </Typography>
            </Box> */}
                        {/* <Typography variant='body1' align='left' color='textSecondary'>
              País: {phase2Registry.pais}
            </Typography> */}
                    </Box>
                    <Divider />
                    <Typography
                        variant="body1"
                        align="left"
                        color="textSecondary"
                        noWrap
                    >
                        <FormattedMessage
                            id="app.certification"
                            defaultMessage="Certificação"
                        />
                        : {phase2Registry.certification}
                    </Typography>
                    <Divider />
                    <Typography
                        variant="body1"
                        align="left"
                        color="textSecondary"
                        noWrap
                    >
                        <FormattedMessage
                            id="app.verificationCode"
                            defaultMessage=" Código de verificação"
                        />
                        : {phase2Registry.verificationCode}
                    </Typography>
                    <Divider />

                    {/* <Typography variant='body1' align='left' color='textSecondary' noWrap>
            CPF: {phase2Registry.cpfCliente}
          </Typography>
          <Typography
            variant='body1'
            align='left'
            color='textSecondary'
            gutterBottom
            noWrap
          >
            Telefone: {phase2Registry.telefoneCliente}
          </Typography> */}
                    {/* <Divider /> */}
                    {/* <Typography variant='body1' align='left' color='textSecondary'>
            ID da Propriedade: {phase2Registry.phase2Id}
          </Typography>
          <Divider />
          <Typography variant='body1' align='left' color='textSecondary'>
            ID do Produtor: {phase2Registry.produtorId}
          </Typography> */}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} zeroMinWidth>
                    <div className={classes.flex}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            component={RouterLink}
                            to={`${location.pathname.replace(
                                /\/$/,
                                ''
                            )}/phase2Id/${phase2Registry.phase2Id}`}
                        >
                            <FormattedMessage
                                id="app.open"
                                defaultMessage="Abrir"
                            />
                        </Button>
                        {accessLevel === 'Admin' && (
                            <Box marginLeft={2} marginRight={0}>
                                <Phase2CardOptions
                                    phase2Registry={phase2Registry}
                                />
                            </Box>
                        )}
                    </div>
                    <Box marginTop={1}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => {
                                window.open(
                                    'https://coopasvtech-pdf-report.web.app/talhoes?produtor-id=' +
                                        phase1Id +
                                        '&propriedade-id=' +
                                        phase2Registry.phase2Id,
                                    '_blank'
                                );
                            }}
                        >
                            <FormattedMessage
                                id="app.pdfReport"
                                defaultMessage="Relatório em PDF"
                            />
                        </Button>
                    </Box>
                    <Box marginTop={1}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={fetchKMLEndPoint}
                            disabled={fetchLoading}
                        >
                            <FormattedMessage
                                id="app.KMLReport"
                                defaultMessage="Exportar em KML"
                            />
                        </Button>
                        {fetchLoading && <LinearProgress />}
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};
