import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLinks } from '../../hooks/useLinks';
import { RootState } from '../../store/configureStore';
import { useSelector } from 'react-redux';
import { LinkData } from './../../types/link';
import { dateToMilis } from '../../utils';

//MaterialUI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
// import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1, 1, 1, 1),
            margin: theme.spacing(0.5, 0, 0.5, 0),
        },
        // paper: {
        //   padding: theme.spacing(0.25),
        // },
        gridLinkName: {
            '&:hover': {
                cursor: 'pointer',
            },
            // backgroundColor: 'purple',
        },
        divCenterName: {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            // backgroundColor: 'yellow',
        },
    })
);

export const LinksListSidebar = () => {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id }: any = useParams();
    const { links } = useLinks(orgId, phase1Id, phase2Id);

    const selectedDate = useSelector(
        (state: RootState) => state.timeline.selectedDate
    );

    const [filteredLinks, setFilteredLinks] = useState<LinkData[]>([]);

    useEffect(() => {
        if (selectedDate !== 'not_sure' && links !== 'not_initialized') {
            const filtered = links.filter(
                (link) => dateToMilis(link.date) === dateToMilis(selectedDate)
            );

            setFilteredLinks(filtered);
        }
    }, [selectedDate, links]); //eslint-disable-line

    return (
        <React.Fragment>
            {filteredLinks.map((link) => (
                <Grid key={link.linkDocId} item xs={12}>
                    {/* <Box my={0.25}> */}
                    <Paper className={classes.paper}>
                        <Grid container>
                            <Grid
                                className={classes.gridLinkName}
                                item
                                xs={11}
                                onClick={() => {
                                    if (link.link.substr(0, 4) !== 'http') {
                                        window.open(
                                            'http://' + link.link,
                                            '_blank'
                                        );
                                    } else {
                                        window.open(link.link, '_blank');
                                    }
                                }}
                            >
                                <div className={classes.divCenterName}>
                                    <Typography variant="body1">
                                        <strong>{link.name}</strong>
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    size="small"
                                    aria-label="Open Link in new Tab"
                                    onClick={() => {
                                        if (link.link.substr(0, 4) !== 'http') {
                                            window.open(
                                                'http://' + link.link,
                                                '_blank'
                                            );
                                        } else {
                                            window.open(link.link, '_blank');
                                        }
                                    }}
                                >
                                    <OpenInNewIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Paper>
                    {/* </Box> */}
                </Grid>
            ))}
        </React.Fragment>
    );
};
