import { Box, Typography } from '@material-ui/core';
import { Upload } from '@aws-sdk/lib-storage';
import React, {
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import { round } from '../../utils';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        centered: {
            height: '100%',
            textAlign: 'center',
            alignItems: 'center',
            // backgroundColor: theme.palette.primary.main,
        },
    })
);

type Props = {
    s3Upload: Upload;
    file: File;
    uploadStatus: number;
    setUploadStatus: Dispatch<SetStateAction<number>>;
};

export const S3Watcher: FunctionComponent<Props> = ({
    s3Upload,
    file,
    uploadStatus,
    setUploadStatus,
}) => {
    const classes = useStyles();
    const [s3UploadProgress, setS3UploadProgress] = useState(0);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        // setStatus(`Uploading ${file.name} to S3...`);
        setUploadStatus(1);

        s3Upload.on('httpUploadProgress', (progress) => {
            console.log(progress);
            // @ts-ignore
            setS3UploadProgress(progress.loaded / progress.total);
        });

        s3Upload
            .done()
            .then((result) => {
                console.log(result);
                // setStatus(`Upload of ${file.name} successfully finished!`);
                setUploadStatus(2);
                setLoaded(true);
            })
            .catch((err) => {
                console.log(err);
                // setStatus(`Upload of ${file.name} failed!`);
                setUploadStatus(2);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {uploadStatus === 0 && (
                <div className={classes.centered}>
                    <div>
                        <CircularProgress />
                    </div>
                    <Typography variant="h4">Aguarde</Typography>
                </div>
            )}
            {uploadStatus === 1 && (
                <div className={classes.centered}>
                    <CircularProgressWithLabel value={s3UploadProgress} />
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="app.loadingToS3"
                            defaultMessage="Carregando o arquivo <strong>{name}</strong> para a plataforma
              de armazenamento..."
                            values={{
                                strong: (chunks: any) => (
                                    <strong>{chunks}</strong>
                                ),
                                name: file.name,
                            }}
                        />
                    </Typography>
                </div>
            )}
            {(uploadStatus === 2 || loaded) && (
                <div className={clsx(classes.centered)}>
                    <DoneIcon fontSize="large" color="primary" />
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="app.successfullyLoadedToS3"
                            defaultMessage="Arquivo <strong>{name}</strong> carregado com sucesso!"
                            values={{
                                strong: (chunks: any) => (
                                    <strong>{chunks}</strong>
                                ),
                                name: file.name,
                            }}
                        />
                    </Typography>
                </div>
            )}
            {uploadStatus === 3 && (
                <div className={clsx(classes.centered)}>
                    <ClearIcon fontSize="large" color="secondary" />
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="app.fileUploadFailed"
                            defaultMessage="O carregamento do arquivo {name} falhou!"
                            values={{
                                strong: (chunks: any) => (
                                    <strong>{chunks}</strong>
                                ),
                                name: file.name,
                            }}
                        />
                    </Typography>
                </div>
            )}
            {/* <Typography variant='h5'>{`${round(
        s3UploadProgress * 100,
        2
      )}%...`}</Typography> */}
        </>
    );
};

type CustomCircularProgressProps = {
    value: number;
};

const CircularProgressWithLabel: FunctionComponent<
    CustomCircularProgressProps
> = ({ value }) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                size={50}
                value={round(value * 100, 2)}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                >{`${round(value * 100, 2)}%`}</Typography>
            </Box>
        </Box>
    );
};
