//@ts-ignore
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import { Button, Dialog, makeStyles, Theme } from '@material-ui/core';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Phase2PageContext } from '../../contexts/Phase2PageContext';
import { Position } from '../../types/photoData';
import { awaitMapLoad } from '../../utils';

const useStyles = makeStyles((theme: Theme) => {
    // console.log(theme, theme.palette.primary.main);
    return {
        root: {
            minWidth: 136,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
                maxWidth: 72,
            },

            maxHeight: 136,
            '& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper': {
                margin: 0,
            },
        },
    };
});

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
    setAddPhotoOpen: (open: boolean) => void;
    setImagePosition: (position: Position) => void;
};

export const AddPhotoPositionDialog: FunctionComponent<Props> = ({
    open,
    setOpen,
    setAddPhotoOpen,
    setImagePosition,
}) => {
    const classes = useStyles();
    const { mapClass } = useContext(Phase2PageContext);

    useEffect(() => {
        awaitMapLoad(mapClass).then((map: any) => {
            if (!map.photoPositionListenerStarted) {
                map.photoPositionListenerStarted = true;
                map.on('click', (e: any) => {
                    if (!map.inAddPhotoPositionDialog) return;

                    if (map.choosePositionMarker) {
                        map.choosePositionMarker.remove();
                    }

                    map.choosePositionMarker = new mapboxgl.Marker()
                        .setLngLat([e.lngLat.lng, e.lngLat.lat])
                        .addTo(map);
                });
            }
        });
    }, []); //eslint-disable-line

    useEffect(() => {
        awaitMapLoad(mapClass).then((map) => {
            mapClass.map.inAddPhotoPositionDialog = open;

            mapClass.map.getCanvas().style.cursor = open ? 'crosshair' : '';
        });
    }, [open]); //eslint-disable-line

    const clearPositionMarker = () => {
        if (mapClass.map && mapClass.map.choosePositionMarker) {
            mapClass.map.choosePositionMarker.remove();
            mapClass.map.choosePositionMarker = undefined;
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onCancel = () => {
        clearPositionMarker();
        setOpen(false);
        setAddPhotoOpen(true);
    };

    const onConfirm = () => {
        if (!mapClass.map || !mapClass.map.choosePositionMarker) {
            return alert('Por favor selecione uma posição');
        }

        const { lng, lat } = mapClass.map.choosePositionMarker._lngLat;
        setImagePosition({ lng, lat });

        clearPositionMarker();
        setOpen(false);
        setAddPhotoOpen(true);
    };

    return (
        <Dialog
            className={classes.root}
            open={open}
            onClose={handleClose}
            style={{
                top: '75%',
                left: '80%',
            }}
            disableBackdropClick
            hideBackdrop
        >
            <Button variant="contained" color="primary" onClick={onConfirm}>
                <FormattedMessage id="app.confirm" defaultMessage="Confirmar" />
            </Button>
            <Button variant="outlined" color="primary" onClick={onCancel}>
                <FormattedMessage id="app.cancel" defaultMessage="Cancelar" />
            </Button>
        </Dialog>
    );
};
