import { db } from '../firebase';

export const deleteAnnotation = (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    annotationDocId: string
) => {
    db.collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('annotations')
        .doc(annotationDocId)
        .delete()
        .then((doc) => console.log('Annotation correctly deleted!'));
};
