import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    MenuItem,
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
// import { useFieldblockRegistryState } from '../../formfy/API/useFieldblockRegistryState';
import { deleteFieldblockRegistry } from '../../formfy/firebase/utils/deleteFieldblockRegistry';
import { Registry } from '../../formfy/types';

type Props = {
    fieldblockRegistry: Registry;
    handleOptionsClose: any;
};

type Params = {
    orgId: string;
    phase1Id: string;
    phase2Id: string;
};

export const FieldblockCardDeleteItem: FunctionComponent<Props> = ({
    fieldblockRegistry,
    handleOptionsClose,
}) => {
    const [open, setOpen] = useState(false);
    const { orgId, phase1Id, phase2Id }: Params = useParams();
    const onRemove = () => {
        // console.log(fieldblockRegistry);

        if (fieldblockRegistry && fieldblockRegistry.fieldblockId) {
            deleteFieldblockRegistry(
                orgId,
                phase1Id,
                phase2Id,
                //@ts-ignore
                fieldblockRegistry.fieldblockId
            ).then(() => {
                handleOptionsClose();
                setOpen(false);
            });
        }
    };

    const onCancelClick = () => {
        setOpen(false);
    };

    return (
        <div>
            <MenuItem onClick={() => setOpen(true)}>
                <FormattedMessage id="app.remove" defaultMessage="Remover" />
            </MenuItem>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    <FormattedMessage
                        id="app.remove-registry"
                        defaultMessage="Remover Registro?"
                    />
                </DialogTitle>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onRemove}
                    >
                        <FormattedMessage
                            id="app.remove"
                            defaultMessage="Remover"
                        />
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onCancelClick}
                    >
                        <FormattedMessage
                            id="app.cancel"
                            defaultMessage="Cancelar"
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
