import { actions as authActions } from '../features/auth/authSlice';
import { actions as organizationActions } from '../features/organizations/organizationSlice';
import { actions as membersActions } from '../features/members/membersSlice';
import { actions as tilesetsActions } from '../features/tilesets/tilesetsSlice';
import { actions as timelineActions } from '../features/timeline/timelineSlice';
import { actions as mapActions } from '../features/mapboxGl/mapSlice';
import { actions as linksActions } from '../features/links/linksSlice';
import { actions as photosActions } from '../features/photos/photosSlice';
import { actions as annotationsActions } from '../features/annotations/annotationsSlice';
import { actions as soilAnalysisActions } from 'features/soilAnalysis/soilAnalysisSlice';
import { actions as translationsActions } from 'translations/translationsSlice';

export const actionCreators = {
    ...authActions,
    ...organizationActions,
    ...membersActions,
    ...tilesetsActions,
    ...timelineActions,
    ...mapActions,
    ...linksActions,
    ...annotationsActions,
    ...photosActions,
    ...soilAnalysisActions,
    ...translationsActions,
};
