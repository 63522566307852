import { setFieldblocksRegistriesType } from './../../features/fieldblocks/fieldblocksSlice';
import { Registry } from './../../types/registry';
import db, { Timestamp } from './../../../firebase/firebase';
// import { OrgUser } from './../../../types/organization';

export const isTimestamp = (value: any) => value instanceof Timestamp;

export const downloadFieldblocksRegistries = (
    orgId: string,
    // orgUser: OrgUser,
    phase1Id: string,
    phase2Id: string,
    setFieldblocksRegistries: setFieldblocksRegistriesType
) => {
    db.collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('fieldblocks')
        .onSnapshot((snapshot) => {
            let registries: Array<Registry> = [];

            snapshot.forEach((doc) => {
                const data: any = doc.data();
                if (data.registry && !data.isDeleted) {
                    const registry: Registry = data.registry;

                    Object.entries(registry).forEach((e) => {
                        if (isTimestamp(e[1])) {
                            // @ts-ignore
                            registry[e[0]] = e[1].toDate();
                            // registry[e[0]] = e[1].toMillis()
                        }
                    });

                    registry.fieldblockId = doc.id;

                    registry.area = data.feature?.area;
                    registries.push(registry);
                }
            });

            setFieldblocksRegistries({ fieldblocksRegistries: registries });
        });
};
