import { Button, Dialog, MenuItem, TextField } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { usePhase2RegistryState } from '../../formfy/API/usePhase2RegistryState';
import { Registry } from '../../formfy/types';

type Props = {
    phase2Registry: Registry;
    handleOptionsClose: any;
};

export const Phase2CardRenameItem: FunctionComponent<Props> = ({
    phase2Registry,
    handleOptionsClose,
}) => {
    const [newName, setNewName] = useState(phase2Registry.name);
    const [open, setOpen] = useState(false);
    const phase2RegistryState = usePhase2RegistryState();

    const updatePhase2RegistryName = () => {
        phase2RegistryState.save();
    };

    const onNameSave = () => {
        updatePhase2RegistryName();
        setOpen(false);
        console.log('here');
        handleOptionsClose();
        //reset();
    };

    useEffect(() => {
        if (open) {
            const { ableToStart, state } = phase2RegistryState;

            if (ableToStart && !state) {
                phase2RegistryState.start(phase2Registry);
            }
        } else {
            phase2RegistryState.finish();
        }
    }, [open]); //eslint-disable-line

    useEffect(() => {
        phase2RegistryState.handleChange('name', newName!);
    }, [newName]); //eslint-disable-line

    return (
        <div>
            <MenuItem onClick={() => setOpen(true)}>
                <FormattedMessage id="app.rename" defaultMessage="Renomear" />
            </MenuItem>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <TextField
                    id="filled-basic"
                    label="Novo Nome"
                    variant="filled"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onNameSave}
                >
                    <FormattedMessage id="app.save" defaultMessage="Salvar" />
                </Button>
            </Dialog>
        </div>
    );
};
