import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { useParams } from 'react-router';
import { uploadSoilAnalysisLink } from '../firebase/utils/uploadSoilAnalysisLink';
// To-do change firebase folder name to avoid problem when importing. Webpack thinks you are importing the npm package when using baseURL dynamic import
import { RootState } from '../store/configureStore';
import { useSelector } from 'react-redux';

//Components
import NavbarOrganization from 'features/navbars/NavbarOrganization';
import RealtimeUpdatesSoilAnalysesByPhase2Id from 'features/soilAnalysis/RealtimeUpdatesSoilAnalysesByPhase2Id';
import LinkCard from 'features/soilAnalysis/LinkCard';

//MaterialUI
import {
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    TextField,
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';

import { usePhase1NameAndCreatedAt } from 'formfy/customHooks/usePhase1NameAndCreatedAt';
import { usePhase2NameAndCreatedAt } from 'formfy/customHooks/usePhase2NameAndCreatedAt';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divRoot: {
            minHeight: '100vh',
        },
        rootGrid: {
            padding: theme.spacing(8, 0, 4) /* top | horizontal | bottom */,
        },
        header: {
            padding: theme.spacing(0, 0, 3) /* top | horizontal | bottom */,
        },
    })
);

type Params = {
    orgId: string;
    phase1Id: string;
    phase2Id: string;
};

export default function SoilAnalysisManageLinksPage() {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id } = useParams<Params>();

    const { phase1Info } = usePhase1NameAndCreatedAt();
    const { phase2Info } = usePhase2NameAndCreatedAt();

    const [link, setLink] = React.useState('');
    const [linkName, setLinkName] = React.useState('');

    const [inAdd, setInAdd] = React.useState(false);

    const links = useSelector((state: RootState) => state.soilAnalysis.links);

    function handleClickOpen() {
        setInAdd(true);
    }

    const reset = () => {
        setLink('');
        setLinkName('');
        // setDate(new Date());
        setInAdd(false);
    };

    const onSave = () => {
        uploadSoilAnalysisLink({
            link,
            name: linkName,
            createdAt: new Date(),
            orgId,
            phase1Id,
            phase2Id,
        });
        reset();
    };

    return (
        <div className={classes.divRoot}>
            <RealtimeUpdatesSoilAnalysesByPhase2Id />
            <CssBaseline />
            <NavbarOrganization />
            <Container maxWidth="md">
                <Grid container className={classes.rootGrid}>
                    <Grid
                        container
                        item
                        className={classes.header}
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                        xs={12}
                    >
                        <Grid item xs={12} sm={8}>
                            <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/organizationId/${orgId}`}
                            >
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    <FormattedMessage
                                        id="app.start"
                                        defaultMessage="Início"
                                    />
                                </Typography>
                            </Link>

                            <Box component="span" marginX={0.5}>
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    /
                                </Typography>
                            </Box>

                            <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/organizationId/${orgId}/phase1Id/${phase1Id}`}
                            >
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    {phase1Info?.name}
                                </Typography>
                            </Link>

                            <Box component="span" marginX={0.5}>
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    /
                                </Typography>
                            </Box>

                            <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/organizationId/${orgId}/phase1Id/${phase1Id}/phase2Id/${phase2Id}`}
                            >
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    {phase2Info?.name}
                                </Typography>
                            </Link>

                            <Box component="span" marginX={0.5}>
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    /
                                </Typography>
                            </Box>

                            <Typography
                                variant="h5"
                                color="inherit"
                                display="inline"
                            >
                                Análises de Solo
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={1}
                            xs={12}
                            sm={4}
                        >
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    startIcon={<AddIcon />}
                                    onClick={handleClickOpen}
                                    fullWidth
                                >
                                    <FormattedMessage
                                        id="app.addLink"
                                        defaultMessage="Adicionar Link"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box paddingTop={1} paddingBottom={1}>
                                <Divider />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                {links !== 'not_initialized' &&
                                    links.map((link) => {
                                        return (
                                            <Box py={1} key={link.linkDocId}>
                                                <LinkCard
                                                    key={link.linkDocId}
                                                    linkData={link}
                                                    type="SideBar"
                                                />
                                            </Box>
                                        );
                                    })}
                                {links !== 'not_initialized' &&
                                    links.length === 0 && (
                                        <h1>Nenhum link cadastrado</h1>
                                    )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                {inAdd && (
                    <Dialog
                        open={inAdd}
                        onClose={() => setInAdd(false)}
                        aria-labelledby="manage-links"
                        maxWidth="xs"
                        fullWidth
                    >
                        <DialogTitle id="form-dialog-title">
                            <FormattedMessage
                                id="app.newLink"
                                defaultMessage="Novo Link"
                            />
                        </DialogTitle>
                        <DialogContent dividers>
                            <Box paddingY={1}>
                                <TextField
                                    id="filled-basic"
                                    label="Link"
                                    variant="outlined"
                                    value={link}
                                    onChange={(e) => setLink(e.target.value)}
                                    fullWidth
                                />
                            </Box>
                            <Box paddingY={1}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="component-outlined">
                                        <FormattedMessage
                                            id="app.linkName"
                                            defaultMessage="Nome do Link"
                                        />
                                    </InputLabel>
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={linkName}
                                        onChange={(e) =>
                                            setLinkName(e.target.value)
                                        }
                                        label="Nome do Link"
                                    />
                                </FormControl>
                            </Box>
                            {/* <Box paddingY={1}>
                                <DatePicker
                                    //@ts-ignore
                                    label={
                                        <FormattedMessage
                                            id="app.date"
                                            defaultMessage="Data"
                                        />
                                    }
                                    selectedDate={date}
                                    setSelectedDate={setDate}
                                    pickerId={'linksDatePicker'}
                                />
                            </Box> */}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setInAdd(false)}
                                variant="outlined"
                                color="primary"
                            >
                                <FormattedMessage
                                    id="app.cancel"
                                    defaultMessage="Cancelar"
                                />
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onSave}
                            >
                                <FormattedMessage
                                    id="app.save"
                                    defaultMessage="Salvar"
                                />
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Container>
        </div>
    );
}
