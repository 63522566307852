import { Dialog, DialogContent, makeStyles, Theme } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme: Theme) => {
    // console.log(theme, theme.palette.primary.main);
    return {
        root: {
            // minWidth: 136,
            // [theme.breakpoints.down('sm')]: {
            //   width: '100%',
            // },
            // [theme.breakpoints.up('sm')]: {
            //   maxWidth: 72,
            // },
            // maxHeight: 136,
            // '& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper': {
            //   margin: 0,
            // },
        },
    };
});

type Props = {
    open: boolean;
};

export const UploadingSpinnerDialog: FunctionComponent<Props> = ({ open }) => {
    const classes = useStyles();

    const handleClose = () => {};

    return (
        <Dialog
            className={classes.root}
            open={open}
            onClose={handleClose}
            disableBackdropClick
            // hideBackdrop
        >
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        </Dialog>
    );
};
