import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    makeStyles,
    CardActions,
    CardHeader,
    Box,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useFieldblocksFormFields } from '../../API/useFieldblocksFormFields';
import { RenderFormField } from '../../../features/ui/RenderFormField';
import { FormField } from '../../types';

const useStyles = makeStyles((theme) => ({
    marginTop: {
        marginTop: theme.spacing(2),
    },
    title: {
        fontSize: 14,
    },
}));

type Props = {};

export const FieldblocksFormManagePreview: FunctionComponent<Props> = () => {
    const { fieldblocksFormFields } = useFieldblocksFormFields();

    const classes = useStyles();

    const [stateFieldsMock, setStateFieldsMock] = useState<any>();
    const [localFormFields, setLocalFormFields] = useState<FormField[]>([]);

    useEffect(() => {
        if (fieldblocksFormFields !== 'not_initialized') {
            let stateFields: any = {};
            fieldblocksFormFields.forEach((field) => {
                stateFields[field.fieldId] = {
                    type: field.type,
                    value: null,
                };
            });

            setStateFieldsMock(stateFields);
            setLocalFormFields(fieldblocksFormFields);
        }
    }, [fieldblocksFormFields]);

    return (
        <Card className={classes.marginTop}>
            <CardHeader
                title={
                    <div>
                        <i>Preview </i> do formulário de criação de novos
                        talhões
                    </div>
                }
                subheaderTypographyProps={{ variant: 'subtitle2' }}
                subheader={
                    <div>
                        <p>Este formulário não criará talhões novos</p>
                    </div>
                }
            ></CardHeader>
            <CardContent>
                {fieldblocksFormFields !== 'not_initialized' &&
                    localFormFields.map((field: FormField) => {
                        if (field.fieldId === 'createdAt') {
                            return; //eslint-disable-line
                        }

                        const onChange = (value: any) => {
                            let newMock = {
                                ...stateFieldsMock,
                            };

                            newMock[field.fieldId].value = value;

                            setStateFieldsMock(newMock);
                        };

                        console.log(
                            stateFieldsMock,
                            fieldblocksFormFields,
                            field
                        );
                        // console.log(fieldblocksFormFields);
                        // console.log(field);
                        // console.log(stateFieldsMock[field.fieldId]);

                        if (stateFieldsMock) {
                            return (
                                <div className={classes.marginTop}>
                                    <RenderFormField
                                        key={field.fieldId}
                                        stateField={
                                            stateFieldsMock[field.fieldId]
                                        }
                                        formField={field}
                                        onChange={onChange}
                                    />
                                </div>
                            );
                        } else {
                            return <></>;
                        }
                    })}
            </CardContent>
            <CardActions>
                <Box flexGrow={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        fullWidth
                    >
                        <FormattedMessage
                            id="app.create-new-fieldblocks-button"
                            defaultMessage="Criar"
                        />
                    </Button>
                </Box>
                <Button variant="outlined" color="primary" size="medium">
                    <FormattedMessage
                        id="app.cancel"
                        defaultMessage="Cancelar"
                    />
                </Button>
            </CardActions>
        </Card>
    );
};
