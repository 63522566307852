//MaterialUI
import {
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

// Only include variant, size, and color
type ButtonBaseProps = Pick<
    MuiButtonProps,
    | 'variant'
    | 'size'
    | 'color'
    | 'children'
    | 'onClick'
    | 'fullWidth'
    | 'disabled'
    | 'className'
>;

// Use all except disableRipple
// type ButtonBaseProps = Omit<MuiButtonProps, "disableRipple">;

export interface ButtonProps extends ButtonBaseProps {
    component?: typeof RouterLink;
    to?: string;
}

export const Button = ({ children, ...rest }: ButtonProps) => (
    <MuiButton {...rest}>{children}</MuiButton>
);
