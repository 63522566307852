import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { LinksListSidebar } from '../links/LinksListSidebar';

//MaterialUI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import PhotoSizeSelectActualIcon from '@material-ui/icons/PhotoSizeSelectActual';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import LinkIcon from '@material-ui/icons/Link';
import { RootState } from '../../store/configureStore';
import { useSelector } from 'react-redux';
import { dateToMilis } from '../../utils';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            padding: theme.spacing(0.25),
            backgroundColor: theme.palette.primary.main,
        },
        root: {
            flexGrow: 1,
        },
        button: {
            margin: theme.spacing(0),
        },
    })
);

type Params = {
    orgId: string;
    phase1Id: string;
    phase2Id: string;
};

export const LinksSideBarMenu = () => {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id }: Params = useParams();
    const accessLevel = useSelector((state: RootState) => {
        const orgUser = state.organization.orgUser;
        if (orgUser && orgUser !== 'not_initialized') {
            return orgUser.organizationAccessLevel;
        }
    });

    const selectedDate = useSelector(
        (state: RootState) => state.timeline.selectedDate
    );

    return (
        <React.Fragment>
            <Paper variant="outlined" className={classes.paperRoot}>
                <div className={classes.root}>
                    <Grid container spacing={0}>
                        <Grid container item xs={12}>
                            <Grid item xs={1}>
                                <Box alignItems="center">
                                    <LinkIcon style={{ color: 'white' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography
                                    variant="h6"
                                    style={{ color: 'white' }}
                                >
                                    <FormattedMessage
                                        id="app.links"
                                        defaultMessage="Links"
                                    />
                                </Typography>
                            </Grid>
                            {accessLevel !== 'Guest' &&
                                accessLevel !== 'Temp' && (
                                    <Grid item xs={4}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="default"
                                            size="small"
                                            className={classes.button}
                                            startIcon={<AddIcon />}
                                            component={RouterLink}
                                            target="_blank"
                                            // to='/organizationId/8G0NXG7ynP12FuHUOuwG/phase1Id/bDhWxiqPwmNUzCEZgiQX/phase2Id/KjRlWeggMLyb4kCQ3FS2/managemaps'
                                            to={`/organizationId/${orgId}/phase1Id/${phase1Id}/phase2Id/${phase2Id}/manage-links?selectedDate=${dateToMilis(
                                                selectedDate !== 'not_sure'
                                                    ? selectedDate
                                                    : new Date()
                                            )}`}
                                        >
                                            <FormattedMessage
                                                id="app.add"
                                                defaultMessage="Adicionar"
                                            />
                                        </Button>
                                    </Grid>
                                )}
                        </Grid>
                        <Grid item xs={12}>
                            <Box marginTop={1} marginBottom={1}>
                                <Divider />
                            </Box>
                        </Grid>

                        <Grid container item xs={12} spacing={0}>
                            <LinksListSidebar />
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </React.Fragment>
    );
};
