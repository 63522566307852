import axios from 'axios';

export const uploadPhotoToDrive = async (data: any) => {
    const formData = new FormData();

    for (const attr in data) {
        formData.append(attr, data[attr]);
    }

    // data.append('file', this.state.selectedFile);

    console.log(data);
    console.log(formData);

    const response = await axios.post(
        // 'http://localhost:5001/coopasvtech-drb-dcaa7/us-central1/uploadFile',
        // 'https://us-central1-coopasvtech-drb-dcaa7.cloudfunctions.net/uploadFile',
        'https://us-central1-novo-coopasvtech.cloudfunctions.net/uploadFile',
        formData,
        {
            headers: {
                // Accept: 'application/json',
                'Content-type': `multipart/form-data;`,
            },
        }
    );

    return response;
};
