import { Tileset } from './../../types/tileset';

const unrenderRemovedTilesets = (
    mapClass: any,
    filteredTilesets: Tileset[],
    tilesets: Tileset[]
) => {
    // caso em que um tileset é removido do banco
    const diff = filteredTilesets.filter((fts) => {
        // return true if fts not in tilesets
        const found = tilesets.find((ts: Tileset) => {
            return ts.tilesetDocId === fts.tilesetDocId;
        });

        return !found;
    });

    diff.forEach((ts) => {
        const layerId = `raster-layer-${ts.tilesetId}`;

        mapClass.map.setLayoutProperty(layerId, 'visibility', 'none');
    });
};

export const awaitMapClassStarted = (mapClass: any) => {
    return new Promise((resolve, reject) => {
        let times = 0;
        const intervalId = setInterval(() => {
            if (++times === 100) {
                reject();
                clearInterval(intervalId);
            }

            if (mapClass.started) {
                resolve(true);
                clearInterval(intervalId);
            }
        }, 300);
    });
};

export const handleTilesetUnrenderOnRemove = (
    mapClass: any,
    filteredTilesets: Tileset[],
    tilesets: Tileset[]
) => {
    awaitMapClassStarted(mapClass)
        .then(() => {
            unrenderRemovedTilesets(mapClass, filteredTilesets, tilesets);
        })
        .catch(() => {
            console.log('Unable to unrender removed tileset!');
        });
};
