import { combineReducers } from 'redux';
import authReducers from '../features/auth/authSlice';
import organizationReducers from '../features/organizations/organizationSlice';
import membersReducers from '../features/members/membersSlice';
import tilesetsReducers from '../features/tilesets/tilesetsSlice';
import timelineReducers from '../features/timeline/timelineSlice';
import mapReducers from '../features/mapboxGl/mapSlice';
import linksReducers from '../features/links/linksSlice';
import photosReducers from '../features/photos/photosSlice';
import annotationsReducers from '../features/annotations/annotationsSlice';
import formFyReducers from '../formfy/reducers/formFyReducers';
import soilAnalysisSlice from 'features/soilAnalysis/soilAnalysisSlice';
import translationsSlice from 'translations/translationsSlice';
import APPsAndReservaLegalSlice from 'features/apps-and-reserva-legal/APPsAndReservaLegalSlice';

export default combineReducers({
    auth: authReducers,
    organization: organizationReducers,
    members: membersReducers,
    tilesets: tilesetsReducers,
    timeline: timelineReducers,
    map: mapReducers,
    links: linksReducers,
    photos: photosReducers,
    annotations: annotationsReducers,
    soilAnalysis: soilAnalysisSlice,
    translations: translationsSlice,
    appsAndReservaLegal: APPsAndReservaLegalSlice,
    ...formFyReducers,
});
