import db from '../../../firebase/firebase';

export const deleteFieldblockRegistry = (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    fieldblockId: string
) => {
    console.log('in deleteFieldblockRegistry');
    console.log(orgId, phase1Id, phase2Id, fieldblockId);

    return db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('fieldblocks')
        .doc(fieldblockId)
        .delete()
        .catch((err) => {
            console.log(err);
            alert('Error!! Não foi possível remover o registro de fieldblock!');
            return Promise.reject(err);
        });
};
