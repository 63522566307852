import { actions as phase1Actions } from '../features/phase1/phase1Slice';
import { actions as phase2Actions } from '../features/phase2/phase2Slice';
import { actions as fieldblocksActions } from '../features/fieldblocks/fieldblocksSlice';
import { actions as formActions } from '../features/forms/formsSlice';

export const actionCreators = {
    ...phase1Actions,
    ...phase2Actions,
    ...fieldblocksActions,
    ...formActions,
};
