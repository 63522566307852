import React, { useContext, useEffect, useState } from 'react';

//MaterialUI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TimelineIcon from '@material-ui/icons/Timeline';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Phase2PageContext } from '../../contexts/Phase2PageContext';
import { uploadAnnotation } from '../../firebase/utils/uploadAnnotation';
import { makeDistanceMeasurerListener } from './makeDistanceMeasurerListener';
import { makeMousemoveListener } from './makeMousemoveListener';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { DatePicker } from '../ui/DatePicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { useParams } from 'react-router';
import * as turf from '@turf/turf';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '8px',
        },
        rootItem: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        paperAreaSize: {
            padding: theme.spacing(1),
            backgroundColor: '#ecf0f1',
        },
        formControl: {
            margin: theme.spacing(0, 1),
            minWidth: 175,
        },
        helperBox: {
            border: '1px solid',
            borderRadius: 10,
            borderColor: theme.palette.primary.light,
            padding: theme.spacing(0.5),
        },
    })
);

interface Props {
    OnGoingAddArea: boolean;
    setonGoingAddDistance: React.Dispatch<React.SetStateAction<boolean>>;
    onGoingAddDistance: boolean;
    onGoingAddPoint: boolean;
}

export default function DistanceAnnotationToolButtonComponent({
    OnGoingAddArea,
    setonGoingAddDistance,
    onGoingAddDistance,
    onGoingAddPoint,
}: Props) {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id }: any = useParams();
    const { mapClass } = useContext(Phase2PageContext);
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [turnedOn, setTurnedOn] = useState(false);
    const [rawDistance, setRawDistance] = React.useState<number | null>(null);
    const [listenerStarted, setListenerStarted] = useState(false);
    const [currentVisualDistance, setCurrentVisualDistance] =
        React.useState<number>(0);
    const [measureUnit, setMeasureUnit] = React.useState('Metros');
    const selectedDate = useSelector(
        (state: RootState) => state.timeline.selectedDate
    );

    const [annotationFeature, setAnnotationFeature] = React.useState<any>();
    const [newAnnotationDate, setNewAnnotationDate] = React.useState(
        selectedDate !== 'not_sure' ? selectedDate : new Date()
    );

    useEffect(() => {
        if (onGoingAddDistance) {
            turnOnDistanceMeasurer();
        } else {
            turnOffDistanceMeasurer();
        }
    }, [onGoingAddDistance]); // eslint-disable-line

    useEffect(() => {
        if (selectedDate !== 'not_sure') {
            setNewAnnotationDate(selectedDate);
        }
    }, [selectedDate]);

    const onFinishCallback = (lineStringFeature: any) => {
        setAnnotationFeature(lineStringFeature);

        const rawDistance = turf.length(lineStringFeature);
        setRawDistance(rawDistance);
    };

    const turnOnDistanceMeasurer = () => {
        if (mapClass.started) {
            const map = mapClass.map;
            if (!listenerStarted) {
                map.on(
                    'click',
                    makeDistanceMeasurerListener(
                        mapClass.map,
                        onFinishCallback,
                        setRawDistance,
                        setAnnotationFeature
                    )
                );
                map.on('mousemove', makeMousemoveListener(mapClass.map));
                setListenerStarted(true);
            }

            map.distanceMeasurerTurnedOn = true;
            setTurnedOn(true);
        }
    };

    const turnOffDistanceMeasurer = () => {
        if (mapClass.started) {
            mapClass.map.distanceMeasurerTurnedOn = false;

            if (turnedOn) {
                const style = mapClass.map.getCanvas().style;
                style.cursor =
                    style.cursor === 'crosshair' ? 'pointer' : style.cursor;
            }

            setTurnedOn(false);
        }
    };

    React.useEffect(() => {
        if (rawDistance !== null) {
            if (measureUnit === 'Metros') {
                setCurrentVisualDistance(rawDistance * 1000);
            } else if (measureUnit === 'Kilômetros') {
                setCurrentVisualDistance(rawDistance);
            } else if (measureUnit === 'Milhas') {
                setCurrentVisualDistance(rawDistance * 0.621371);
            }
        }
    }, [rawDistance, setCurrentVisualDistance, measureUnit]);

    const clearLinestringSource = () => {
        mapClass.map.getSource('lineStringGeojson').setData({
            type: 'FeatureCollection',
            features: [],
        });
    };

    const reset = () => {
        setAnnotationFeature(undefined);
        setMeasureUnit('Metros');
        setTitle('');
        setDescription('');
        setonGoingAddDistance(false);
        clearLinestringSource();
        setRawDistance(null);
        setCurrentVisualDistance(0);
    };

    const onCancel = () => {
        reset();
    };

    const onSave = () => {
        if (!title) {
            return alert('Título é obrigatório.');
        }

        if (rawDistance === null) {
            return alert('Finalize o desenho clicando em um ponto do traçado.');
        }

        uploadAnnotation(orgId, phase1Id, phase2Id, {
            title,
            description,
            // coordinates: annotationCoords,
            feature: annotationFeature,
            date: newAnnotationDate,
        });

        reset();
    };

    return (
        <React.Fragment>
            {!(OnGoingAddArea || onGoingAddDistance || onGoingAddPoint) && (
                <Grid item xs={3}>
                    <Paper className={classes.paper}>
                        <Typography
                            variant="subtitle2"
                            display="block"
                            align="center"
                        >
                            <FormattedMessage
                                id="app.distance"
                                defaultMessage="Distância"
                            />
                        </Typography>

                        <div className={classes.rootItem}>
                            <Fab
                                size="small"
                                color="primary"
                                // onClick={startAdd}
                                onClick={() => setonGoingAddDistance(true)}
                            >
                                <TimelineIcon />
                            </Fab>
                        </div>
                    </Paper>
                </Grid>
            )}
            {onGoingAddDistance && (
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Box marginBottom={1}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="subtitle2"
                                                display="block"
                                                align="center"
                                            >
                                                <FormattedMessage
                                                    id="app.distance"
                                                    defaultMessage="Distância"
                                                />
                                            </Typography>

                                            <div className={classes.rootItem}>
                                                <Fab
                                                    size="small"
                                                    color="primary"
                                                >
                                                    <TimelineIcon />
                                                </Fab>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body1"
                                                align="left"
                                            >
                                                <FormattedMessage
                                                    id="app.distance"
                                                    defaultMessage="Distância"
                                                />
                                                :
                                            </Typography>
                                            <Paper
                                                variant="outlined"
                                                className={
                                                    classes.paperAreaSize
                                                }
                                            >
                                                <Typography
                                                    display="inline"
                                                    variant="body1"
                                                    align="left"
                                                    gutterBottom
                                                >
                                                    {currentVisualDistance &&
                                                        `${currentVisualDistance.toFixed(
                                                            2
                                                        )} ${measureUnit}`}
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <InputLabel id="demo-simple-select-outlined-label">
                                                    <FormattedMessage
                                                        id="app.measureUnit"
                                                        defaultMessage="Unidade de Medida"
                                                    />
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={measureUnit}
                                                    onChange={(event: any) => {
                                                        setMeasureUnit(
                                                            event.target.value
                                                        );
                                                    }}
                                                    label="Unit of measurement"
                                                >
                                                    <MenuItem value="Metros">
                                                        <FormattedMessage
                                                            id="app.meters"
                                                            defaultMessage="Metros"
                                                        />
                                                    </MenuItem>
                                                    <MenuItem value="Kilômetros">
                                                        <FormattedMessage
                                                            id="app.kilometers"
                                                            defaultMessage="Kilômetros"
                                                        />
                                                    </MenuItem>
                                                    <MenuItem value="Milhas">
                                                        <FormattedMessage
                                                            id="app.miles"
                                                            defaultMessage="Milhas"
                                                        />
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Box marginY={1}>
                                        <TextField
                                            value={title}
                                            label={
                                                <FormattedMessage
                                                    id="app.title"
                                                    defaultMessage="Título"
                                                />
                                            }
                                            onChange={(e) =>
                                                setTitle(e.target.value)
                                            }
                                            required
                                            fullWidth
                                        />
                                    </Box>

                                    <Box marginY={1}>
                                        <TextField
                                            value={description}
                                            label={
                                                <FormattedMessage
                                                    id="app.description"
                                                    defaultMessage="Descrição"
                                                />
                                            }
                                            multiline
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                            fullWidth
                                        />
                                    </Box>

                                    <Box marginY={1}>
                                        <DatePicker
                                            //@ts-ignore
                                            label={
                                                <FormattedMessage
                                                    id="app.date"
                                                    defaultMessage="Data associada"
                                                />
                                            }
                                            selectedDate={newAnnotationDate}
                                            setSelectedDate={
                                                setNewAnnotationDate
                                            }
                                            pickerId={'annotationsDatePicker'}
                                        />
                                    </Box>
                                </CardContent>
                                <CardActions>
                                    <Box marginX={1} component="span">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            onClick={onCancel}
                                        >
                                            <FormattedMessage
                                                id="app.cancel"
                                                defaultMessage="Cancelar"
                                            />
                                        </Button>
                                    </Box>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={onSave}
                                        fullWidth
                                        // disabled={accessLevel === 'Guest' || accessLevel === 'Temp'}
                                    >
                                        <FormattedMessage
                                            id="app.save"
                                            defaultMessage="Salvar"
                                        />
                                    </Button>
                                </CardActions>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box marginTop={0} marginBottom={0.5}>
                            <Divider />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
}
