import React, { FunctionComponent } from 'react';
import { AnnotationsListSidebar } from '../annotations/AnnotationsListSidebar';

//MaterialUI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            padding: theme.spacing(0.25),
            backgroundColor: theme.palette.primary.main,
        },
        root: {
            flexGrow: 1,
        },
        button: {
            margin: theme.spacing(0),
        },
        paperAreaSize: {
            padding: theme.spacing(1),
            backgroundColor: '#ecf0f1',
        },
        formControl: {
            margin: theme.spacing(0, 1),
            minWidth: 175,
        },
    })
);

type Props = {};

export const AnnotationsSidebarMenu: FunctionComponent<Props> = () => {
    const classes = useStyles();

    return (
        <div>
            <React.Fragment>
                <Paper variant="outlined" className={classes.paperRoot}>
                    <div className={classes.root}>
                        <Grid container spacing={0}>
                            <Grid container item xs={12}>
                                <Grid item xs={1}>
                                    <Box alignItems="center">
                                        <RateReviewIcon
                                            style={{ color: 'white' }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography
                                        variant="h6"
                                        style={{ color: 'white' }}
                                    >
                                        <FormattedMessage
                                            id="app.annotations"
                                            defaultMessage="Anotações"
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Box marginTop={1} marginBottom={1}>
                                    <Divider />
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <AnnotationsListSidebar />
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            </React.Fragment>
        </div>
    );
};
