import { isTimestamp } from './downloadTilesets';
import { LinkData } from './../../types/link';
import { setLinksType } from './../../features/links/linksSlice';
import { db } from '../firebase';

export const downloadLinks = (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    setLinks: setLinksType
) => {
    return db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('links')
        .onSnapshot((querySnapshot) => {
            const links: LinkData[] = [];

            querySnapshot.forEach((doc: any) => {
                const data: any = doc.data();

                Object.entries(data).forEach((e) => {
                    if (isTimestamp(e[1])) {
                        // @ts-ignore
                        data[e[0]] = e[1].toDate();
                    }
                });

                data.linkDocId = doc.id;
                links.push(data);
            });

            setLinks(links);
        });
};
