import { Registry } from './../../types/registry';
import db from '../../../firebase/firebase';

export const storePhase2Registry = (
    orgId: string,
    registry: Registry,
    phase1Id: string,
    phase2Id: string | undefined
) => {
    const collectionRef = db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2');

    const docRef = phase2Id ? collectionRef.doc(phase2Id) : collectionRef.doc();

    console.log('phase2 iwh');

    return docRef
        .set(registry)
        .then(() => docRef.id)
        .catch((err) => {
            console.log(err);
            alert('Error!! Não foi possível salvar o registro de Phase2!');
        });
};
