import React from 'react';

// Components
import HeaderSidebarMenu from '../sidebarMenu/HeaderSidebarMenu';
import { ReportsSession } from 'features/fieldBlocks/ReportsSession';

//Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divList: {
            width: 350,
        },
    })
);

type Props = {
    setisDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isDrawerOpen: boolean;
};

const FormsSideBar = ({ isDrawerOpen, setisDrawerOpen }: Props) => {
    const classes = useStyles();

    const toggleDrawer =
        (openBoolean: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            setisDrawerOpen(openBoolean);
        };

    return (
        <Drawer
            variant="persistent"
            anchor="right"
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
        >
            <div className={classes.divList}>
                <HeaderSidebarMenu toggleDrawer={toggleDrawer} />
                <ReportsSession />
            </div>
        </Drawer>
    );
};

export default FormsSideBar;
