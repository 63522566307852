import React from 'react';

//Types
import { UserHasAccess } from '../../types/user';

//Components
import Phase2UserHasAccessMembersPage from './Phase2UserHasAccessMembersPage';

//Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
// import IconButton from '@material-ui/core/IconButton';
// import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            padding: theme.spacing(1),
            '&:hover': {
                borderWidth: theme.spacing(0.25),
                borderColor: 'black',
            },
        },
    })
);

type Props = {
    user: UserHasAccess;
};

export default function PaperItemMember({ user }: Props) {
    const classes = useStyles();

    if (user.organizationAccessLevel === 'Temp') {
        return null;
    }

    return (
        <div>
            <Box my={1}>
                <Paper variant="outlined" className={classes.paperRoot}>
                    <Grid container spacing={0}>
                        <Grid item xs={1}>
                            <Typography variant="body1" noWrap>
                                {user.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" noWrap>
                                {user.email}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" noWrap align="left">
                                {user.organizationAccessLevel}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Box mx={1}>
                                <Paper className={classes.paperRoot}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body1"
                                                noWrap
                                                align="left"
                                            >
                                                {user.temporaryPassword}
                                            </Typography>
                                        </Grid>
                                        {/* <Grid item xs={2}>
                      <IconButton
                        size="small"
                        aria-label="copy"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${user.temporaryPassword}`
                          );
                          alert('Senha copiada com sucesso!');
                        }}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Grid> */}
                                    </Grid>
                                </Paper>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Phase2UserHasAccessMembersPage user={user} />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </div>
    );
}
