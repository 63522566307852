// import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#185a2a',
        },
        secondary: {
            main: '#c62828',
        },
        success: {
            main: '#ffffff',
        },
        background: {
            default: '#fafafa',
        },
    },
});

export default theme;
