import React, { useState } from 'react';
import { SetFieldType } from './SetFieldType';
import { SetFieldName } from './SetFieldName';
import { SetFieldSetup } from './SetFieldSetup';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        center: {
            marginLeft: 'auto',
        },
        flex: {
            display: 'flex',
            // justify: 'center',
        },
        dialog: {
            width: 400,
        },
        marginBottom: {
            marginBottom: theme.spacing(1),
        },
    })
);

export const AddFieldForm = ({ submitFieldInfo, setShowAddForm }) => {
    const classes = useStyles();
    const [fieldType, setFieldType] = useState();
    const [fieldName, setFieldName] = useState();
    const [fieldItems, setFieldItems] = useState([]);
    const [fieldSetup, setFieldSetup] = useState({
        isDescription: false,
        isHelpText: false,
        isRequired: false,
    });
    const [step, setStep] = useState(1);

    const nextStep = () => {
        let newStep = (step + 1) % 4;
        setStep(newStep);

        if (newStep === 0) {
            let fieldInfo = { type: fieldType, name: fieldName, ...fieldSetup };
            if (fieldType === 'CheckBox') {
                fieldInfo.checklistItems = [...fieldItems];
            } else if (fieldType === 'Seleção de Lista') {
                fieldInfo.selectItems = [...fieldItems];
            }
            submitFieldInfo(fieldInfo);
        }
    };

    return (
        <div>
            <Dialog
                onClose={() => setShowAddForm(false)}
                aria-labelledby="simple-dialog-title"
                open={true}
                classes={{ paper: classes.dialog }}
            >
                <DialogTitle id="simple-dialog-title">
                    Configurar novo campo
                </DialogTitle>
                <DialogContent className={classes.marginBottom}>
                    {step === 1 && (
                        <SetFieldType
                            nextStep={nextStep}
                            fieldType={fieldType}
                            setFieldType={setFieldType}
                            setFieldItems={setFieldItems}
                            setShowAddForm={setShowAddForm}
                        />
                    )}
                    {step === 2 && (
                        <SetFieldName
                            nextStep={nextStep}
                            fieldName={fieldName}
                            setFieldName={setFieldName}
                            setShowAddForm={setShowAddForm}
                        />
                    )}
                    {step === 3 && (
                        <SetFieldSetup
                            nextStep={nextStep}
                            fieldSetup={fieldSetup}
                            setFieldSetup={setFieldSetup}
                            setShowAddForm={setShowAddForm}
                        />
                    )}

                    {/* <div className={classes.flex}>
            <Button
              className={classes.center}
              variant='outlined'
              color='default'
              onClick={() => setShowAddForm(false)}
            >
              Cancelar
            </Button>
          </div> */}
                </DialogContent>
            </Dialog>
        </div>
    );
};
