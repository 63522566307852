import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router';

//Componets
import PublicLinkDialog from './PublicLinkDialog';

//MaterialUI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ShareIcon from '@material-ui/icons/Share';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import LinkIcon from '@material-ui/icons/Link';
import LockIcon from '@material-ui/icons/Lock';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingBottom: theme.spacing(2),
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    })
);

type URLParams = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

export default function ShareButtonAndDialog() {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id }: URLParams = useParams();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button
                endIcon={<ShareIcon />}
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                onClick={handleClickOpen}
            >
                <FormattedMessage
                    id="app.share-button-phase2"
                    defaultMessage="compartilhar"
                    description="Share button on phase2Page"
                />
            </Button>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="share-dialog-title"
            >
                <DialogTitle id="share-dialog-title">
                    {' '}
                    <FormattedMessage
                        id="app.sharing"
                        defaultMessage="Compartilhamento"
                    />
                </DialogTitle>
                <DialogContent>
                    <div className={classes.root}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Button
                                    endIcon={<LockIcon />}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    component={RouterLink}
                                    to={`/organizationId/${orgId}/phase1Id/${phase1Id}/phase2Id/${phase2Id}/manageMembers`}
                                    size="large"
                                >
                                    <FormattedMessage
                                        id="app.manageAccess"
                                        defaultMessage="Gerenciar acesso"
                                    />
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <PublicLinkDialog />
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
