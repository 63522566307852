import { OrgUser } from './../../types/organization';
import { db } from './../../firebase/firebase';

export const templateUser = {
    organizationAccessLevel: '',
    name: '',
    phase1UserHasAccess: [],
    phase2UserHasAccess: [],
    phase2UserHasAccessPath: [],
    email: '',
    language: '',
    otherLanguage: '',
    prefersOtherLanguage: false,
    id: '',
    temporaryPassword: '',
};

export const uploadOrgUser = (
    role: string,
    orgId: string,
    email: string,
    userId: string,
    name: string,
    password: string
) => {
    const user: OrgUser = templateUser;
    user.email = email;
    user.organizationAccessLevel = role;
    user.id = userId;
    user.name = name;
    if (role !== 'Admin') {
        user.temporaryPassword = password;
    }

    return db
        .collection('organizations')
        .doc(orgId)
        .collection('users')
        .doc(userId)
        .set(user)
        .catch((error) => {
            alert(error.message);

            return error;
        });
};
