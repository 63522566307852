import React, { Fragment, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { signOut } from '../features/auth/signOut';
import { useUserOrgs } from '../hooks/useUserOrgs';
import { UserOrgs, UserOrgsWithPublicInfo } from '../types/user';
import { functionGetOrgPublicInfo } from '../features/organizations/functionGetOrgPublicInfo';
// import OrganizationLogoComponent from '../features/organizations/OrganizationLogoComponent';
import OrganizationCopyrightComponent from 'features/organizations/OrganizationCopyrightComponent';

//MaterialUI
import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import { FormattedMessage } from 'react-intl';
// import { useOrgUser } from '../hooks/useOrgUser';
import { SignOutTempUserDialog } from '../features/auth/SignOutTempUserDialog';
import { useUrls } from '../hooks/useUrls';

// UI Components
import { Button as StorybookButton } from '../ui-components/button/Button';

const useStyles = makeStyles((theme) => ({
    headerRoot: {
        flexGrow: 1,
        backgroundColor: 'white',
        // height: "10vh",
    },
    paper: {
        padding: theme.spacing(1),
    },
    headerGrid: {
        padding: theme.spacing(0, 0, 0, 0),
    },
    bodyGrid: {
        padding: theme.spacing(2, 0, 2, 0),
    },
    paperItem: {
        padding: theme.spacing(1, 1, 1, 1),
        marginBottom: theme.spacing(4),
    },
    item: {
        // backgroundColor: '#eee',
        // padding: theme.spacing(1),
    },
    alignCenter: {
        textAlign: 'center',
    },
}));

export const UserDashboardPage = () => {
    const classes = useStyles();
    //const userId: string = useSelector((state: RootState) => state.auth.userId);
    const userOrgs: UserOrgs = useUserOrgs();
    const { urls } = useUrls();
    const [isTempUser, setIsTempUser] = useState(false);

    const [userOrgsWithPublicInfo, setuserOrgsWithPublicInfo] =
        React.useState<UserOrgsWithPublicInfo>([]);

    useEffect(() => {
        if (userOrgsWithPublicInfo.length === 1) {
            if (
                userOrgsWithPublicInfo[0].userDoc.organizationAccessLevel ===
                'Temp'
            ) {
                setIsTempUser(true);
            }
        }
    }, [userOrgsWithPublicInfo]);

    React.useEffect(() => {
        (async function qualquer() {
            if (userOrgs.length > 0) {
                const response = await functionGetOrgPublicInfo(userOrgs);
                const userOrgsWithPublicInfoArray: UserOrgsWithPublicInfo = [];
                userOrgs.forEach((doc) => {
                    const found = response.find(
                        (element) => element.orgId === doc.orgId
                    );
                    if (found) {
                        userOrgsWithPublicInfoArray.push({
                            orgId: doc.orgId,
                            userDoc: doc.userDoc,
                            orgPublicInfo: found,
                        });
                    }
                });
                setuserOrgsWithPublicInfo(userOrgsWithPublicInfoArray);
            }
        })();
    }, [userOrgs]);

    const onSignOutClick = () => {
        signOut();
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <div className={classes.headerRoot}>
                {urls.homepageImage && (
                    <img
                        src={urls.homepageImage}
                        alt="company-Logo"
                        width="100%"
                        height="auto"
                    />
                )}
            </div>
            <Container maxWidth="lg">
                <Grid container spacing={1} className={classes.bodyGrid}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h5" align="left">
                            <FormattedMessage
                                id="app.dashboard-greeting"
                                defaultMessage="Bem-vindos ao COOPASVTech!"
                                description="Greeting message in dashboard page"
                            />
                        </Typography>
                    </Grid>

                    {/* <Grid item xs={12} sm={12}>
                        <LanguageSelect />
                    </Grid> */}
                    <Grid item xs={8} sm={6} md={3}>
                        <Paper className={classes.paper} elevation={1}>
                            {userOrgsWithPublicInfo.length > 0 ? (
                                <Fragment>
                                    <Typography
                                        variant="body1"
                                        align="left"
                                        gutterBottom
                                    >
                                        <FormattedMessage
                                            id="app.yourAccount"
                                            defaultMessage="Você está acessando a plataforma com a seguinte conta"
                                        />
                                        :
                                    </Typography>
                                    <Typography variant="body1" align="left">
                                        E-mail:{' '}
                                        <strong>
                                            {
                                                userOrgsWithPublicInfo[0]
                                                    .userDoc.email
                                            }
                                        </strong>
                                    </Typography>
                                    {userOrgsWithPublicInfo[0].userDoc.name && (
                                        <Typography
                                            variant="body1"
                                            align="left"
                                        >
                                            <FormattedMessage
                                                id="app.name"
                                                defaultMessage="Nome"
                                            />
                                            :{' '}
                                            {
                                                userOrgsWithPublicInfo[0]
                                                    .userDoc.name
                                            }
                                        </Typography>
                                    )}

                                    <Box py={1}>
                                        <StorybookButton
                                            variant="outlined"
                                            color="primary"
                                            onClick={onSignOutClick}
                                            size="small"
                                        >
                                            <FormattedMessage
                                                id="app.sign-out-account"
                                                defaultMessage="SAIR dessa conta"
                                                description="sign out message"
                                            />
                                        </StorybookButton>
                                    </Box>
                                </Fragment>
                            ) : (
                                <div>
                                    <Skeleton variant="rect" height={100} />
                                </div>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
                <Box py={1}>
                    <Divider variant="fullWidth" />
                </Box>
                {/* <Typography variant='h6' align='left' gutterBottom>
          <FormattedMessage
            id='app.organizations-introducing'
            // defaultMessage='Essa conta tem acesso às seguintes organizações:'
            defaultMessage='Aplicações da Coopasvtech:'
            description='organizations-introducing'
          />
        </Typography> */}
                <Grid container spacing={1}>
                    {userOrgsWithPublicInfo.length === 0 && (
                        <Grid item xs={12}>
                            <Paper className={classes.paperItem} elevation={6}>
                                <Skeleton variant="rect" height={130} />
                            </Paper>
                        </Grid>
                    )}
                    {userOrgsWithPublicInfo.map((doc) => {
                        return (
                            <Grid key={doc.orgId} item xs={12} sm={12} md={6}>
                                <Paper
                                    key={doc.orgId}
                                    className={classes.paperItem}
                                    elevation={6}
                                >
                                    <Grid container spacing={1}>
                                        {/* <Grid item xs={12}>
                      <Typography variant='subtitle2' align='left'>
                        <FormattedMessage id='app.name' defaultMessage='Nome' />
                        : {doc.orgPublicInfo.fullOrganizationName}
                      </Typography>
                    </Grid> */}
                                        {/* <Grid item xs={12}>
                      <Typography variant='subtitle2' align='left'>
                        Id: {doc.orgId}
                      </Typography>
                    </Grid> */}
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                            >
                                                <FormattedMessage
                                                    id="app.yourAccessLevel"
                                                    defaultMessage="Seu nível de acesso"
                                                />
                                                :{' '}
                                                {
                                                    doc.userDoc
                                                        .organizationAccessLevel
                                                }
                                                .
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <StorybookButton
                                                variant="contained"
                                                color="primary"
                                                component={RouterLink}
                                                to={`/organizationId/${doc.orgId}`}
                                                fullWidth
                                            >
                                                <FormattedMessage
                                                    id="app.coopasvtechPlatform"
                                                    defaultMessage="Plataforma Coopasvtech"
                                                />
                                            </StorybookButton>
                                            <Box marginTop={1}>
                                                <StorybookButton
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        window.open(
                                                            // `https://coopasvtech-produtores.web.app/`,
                                                            urls.growersDashboard,
                                                            '_blank'
                                                        );
                                                    }}
                                                    fullWidth
                                                >
                                                    <FormattedMessage
                                                        id="app.meetOurFarmers"
                                                        defaultMessage="Conheça nossos produtores"
                                                    />
                                                </StorybookButton>
                                            </Box>
                                            <Box marginTop={1}>
                                                <StorybookButton
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        window.open(
                                                            // `https://coopasvtech-drb-formfy.web.app/`,
                                                            urls.formfy,
                                                            '_blank'
                                                        );
                                                    }}
                                                    fullWidth
                                                >
                                                    <FormattedMessage
                                                        id="app.forms"
                                                        defaultMessage="Formulários"
                                                    />
                                                </StorybookButton>
                                            </Box>
                                            <Box marginTop={1}>
                                                <StorybookButton
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    className={
                                                        classes.alignCenter
                                                    }
                                                    onClick={() => {
                                                        window.open(
                                                            urls.bi,
                                                            '_blank'
                                                        );
                                                    }}
                                                >
                                                    <FormattedMessage
                                                        id="app.businessIntelligence"
                                                        defaultMessage="Inteligência
                                                        Empresarial"
                                                    />
                                                </StorybookButton>
                                            </Box>
                                            <Box marginTop={1}>
                                                <StorybookButton
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    className={
                                                        classes.alignCenter
                                                    }
                                                    onClick={() => {
                                                        window.open(
                                                            urls.forms,
                                                            '_blank'
                                                        );
                                                    }}
                                                >
                                                    <FormattedMessage
                                                        id="app.newForms"
                                                        defaultMessage="Novos Formulários"
                                                    />
                                                </StorybookButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
            {isTempUser && <SignOutTempUserDialog />}
            <OrganizationCopyrightComponent />
        </React.Fragment>
    );
};
