import { Box, LinearProgress, Typography } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import theme from '../../materialUI/theme';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { Tileset } from '../../types/tileset';
import DoneIcon from '@material-ui/icons/Done';
import { FormattedMessage } from 'react-intl';

type Props = {
    hasMapboxUploadFinished: boolean;
    tilesetDocId: string;
    processWatcher: any;
    setUploadStatus: (status: number) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            textAlign: 'center',
        },
        disabled: {
            opacity: 0.5,
        },
    })
);

export const MapboxProcessingWatcher: FunctionComponent<Props> = ({
    hasMapboxUploadFinished,
    tilesetDocId,
    processWatcher,
    setUploadStatus,
}) => {
    const classes = useStyles();
    const [processingFinished, setProcessingFinished] = useState(false);
    const uploadingTileset = useSelector((state: RootState) => {
        if (state.tilesets.tilesets !== 'not_initialized') {
            return state.tilesets.tilesets.find(
                (tileset: Tileset) => tileset.tilesetDocId === tilesetDocId
            );
        }
    });

    useEffect(() => {
        if (uploadingTileset?.uploadId) {
            processWatcher.setUploadId(uploadingTileset.uploadId);
            processWatcher.start();
        }
    }, [uploadingTileset]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (processWatcher.complete) {
            setProcessingFinished(true);
            setUploadStatus(5);
        }
    }, [processWatcher.complete]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className={clsx(
                hasMapboxUploadFinished || processingFinished
                    ? [classes.root]
                    : [classes.root, classes.disabled]
            )}
        >
            {!hasMapboxUploadFinished ? (
                <>
                    <Typography variant="h6">
                        <FormattedMessage
                            id="app.mapProcessing"
                            defaultMessage="Processamento do Mapa"
                        />
                    </Typography>
                    <Box marginTop={theme.spacing(0.3)}></Box>
                    <LinearProgress variant="determinate" value={0} />
                </>
            ) : !processingFinished ? (
                <>
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="app.mapProcessingToMbxImages"
                            defaultMessage="Processando o mapa em imagens do <i>Mapbox</i>"
                            values={{
                                i: (chunks: any) => <i>{chunks}</i>,
                            }}
                        />
                    </Typography>
                    <Box marginTop={theme.spacing(0.3)}></Box>
                    <LinearProgress />
                </>
            ) : (
                <>
                    <DoneIcon fontSize="large" color="primary" />
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="app.mbxProcessingFinished"
                            defaultMessage="Processamento do mapa no <i>Mapbox</i> concluído!"
                            values={{
                                i: (chunks: any) => <i>{chunks}</i>,
                            }}
                        />
                    </Typography>
                </>
            )}
        </div>
    );
};
