import React from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

//redux
import { useSelector } from 'react-redux';
import { RootState } from '../store/configureStore';

//Types
import { Phase1Params } from '../formfy/types/phase1';

//Components
import ListOfPhase2Registries from '../features/phase2/ListOfPhase2Registries';
import NavbarOrganization from '../features/navbars/NavbarOrganization';
import OrganizationCopyrightComponent from '../features/organizations/OrganizationCopyrightComponent';

//Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, CssBaseline, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { FormattedMessage } from 'react-intl';
import { BreadCrumb } from '../features/navbars/BreadCrumb';
import { usePhase1NameAndCreatedAt } from '../formfy/customHooks/usePhase1NameAndCreatedAt';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divRoot: {
            minHeight: '100vh',
        },
        rootGrid: {
            padding: theme.spacing(8, 0, 10) /* top | horizontal | bottom */,
        },
        header: {
            padding: theme.spacing(0, 0, 3) /* top | horizontal | bottom */,
        },
        marginTop: {
            marginTop: theme.spacing(2),
        },
        form: {
            margin: theme.spacing(1),
            maxWidth: '500px',
        },
        card: {
            margin: theme.spacing(1),
            width: '300px',
        },
        flex: {
            display: 'flex',
            flexWrap: 'wrap',
        },
    })
);

export const Phase1Page = () => {
    const classes = useStyles();
    const { orgId, phase1Id }: Phase1Params = useParams();

    // const { phase1Info } = usePhase1NameAndCreatedAt();
    usePhase1NameAndCreatedAt();

    const accessLevel: string = useSelector((state: RootState) => {
        const orgUser = state.organization.orgUser;
        if (orgUser && orgUser !== 'not_initialized') {
            return orgUser.organizationAccessLevel;
        } else {
            return 'Guest';
        }
    });

    return (
        <div className={classes.divRoot}>
            <CssBaseline />
            <NavbarOrganization />

            <Container maxWidth="lg">
                <Grid container className={classes.rootGrid}>
                    <Grid
                        container
                        item
                        className={classes.header}
                        justify="space-between"
                        alignItems="center"
                        spacing={1}
                        xs={12}
                    >
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <BreadCrumb position={'relative'} />
                        </Grid>
                        <Grid
                            item
                            container
                            justify="flex-end"
                            alignItems="center"
                            spacing={1}
                            xs={12}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                        >
                            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
                                {accessLevel !== 'Guest' && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        startIcon={<AddIcon />}
                                        component={RouterLink}
                                        to={`/organizationId/${orgId}/phase1Id/${phase1Id}/create-new-phase2`}
                                        fullWidth
                                    >
                                        <FormattedMessage
                                            id="app.add-area-button"
                                            defaultMessage="Nova área"
                                        />
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box paddingTop={1} paddingBottom={1}>
                                <Divider />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={4}>
                        <ListOfPhase2Registries />
                    </Grid>
                </Grid>
            </Container>
            <OrganizationCopyrightComponent />
        </div>
    );
};
