import React, { Fragment, useEffect, useState } from 'react';

//Hooks
import { usePhase1Registries } from '../../formfy/API/usePhase1Registries';
import { usePhase1FormFields } from '../../formfy/API/usePhase1FormFields';

//Components
import { NewPhase1Card } from './NewPhase1Card';

//Material UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1, 1, 1) /* top | horizontal | bottom */,
        },
        gridPaperContent: {
            flexGrow: 1,
        },
        gridItemMoreVertIcon: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        paperNoProjects: {
            padding: theme.spacing(1),
        },
    })
);

export default function ListOfPhase1Registries() {
    const classes = useStyles();

    const { phase1Registries } = usePhase1Registries();
    const { phase1FieldIdsToNames } = usePhase1FormFields();

    const [sortedP1Registries, setSortedP1Registries] = useState<any>([]);
    const [noProjects, setnoProjects] = React.useState(false);

    React.useEffect(() => {
        if (phase1Registries.length === 0) {
            setnoProjects(true);
        } else {
            setnoProjects(false);
        }
    }, [setnoProjects, phase1Registries.length]);

    useEffect(() => {
        let sorted: any = [];
        if (phase1Registries !== 'not_initialized') {
            sorted = [...phase1Registries].sort((a: any, b: any) => {
                return a.name.localeCompare(b.name);
            });
        }

        console.log(sorted.map((a: any) => a.name));

        setSortedP1Registries(sorted);
    }, [phase1Registries]);

    return (
        <Fragment>
            <CssBaseline />
            {phase1Registries !== 'not_initialized' &&
                sortedP1Registries.map((registry: any) => (
                    <Grid
                        item
                        key={registry.phase1Id}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={3}
                    >
                        {/* <Phase1Card
                            phase1FieldIdsToNames={phase1FieldIdsToNames}
                            phase1Registry={registry}
                        /> */}
                        <NewPhase1Card
                            phase1FieldIdsToNames={phase1FieldIdsToNames}
                            phase1Registry={registry}
                        />
                    </Grid>
                ))}

            {noProjects && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={10} sm={10} md={8} lg={6} xl={6}>
                            <Paper
                                variant="outlined"
                                className={classes.paperNoProjects}
                            >
                                <Typography
                                    variant="h6"
                                    align="center"
                                    gutterBottom
                                >
                                    <FormattedMessage
                                        id="app.noProjectCreated"
                                        defaultMessage="Você ainda não criou nenhum projeto"
                                    />
                                </Typography>
                                <Typography
                                    variant="h6"
                                    align="center"
                                    color="textSecondary"
                                    gutterBottom
                                >
                                    <FormattedMessage
                                        id="app.startFirstProject"
                                        defaultMessage='Comece agora apertando no botão "NOVO PROJETO".'
                                    />
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Fragment>
    );
}
