import { Tileset } from './../../types/tileset';
export class MapClass {
    map: any;
    started: boolean;

    constructor() {
        this.started = false;
    }

    setMap(map: any) {
        if (!this.map) {
            this.map = map;
        }
    }

    reset() {
        // console.log('in reset!');
        //this.map = undefined;
        this.started = false;
    }

    getMap() {
        return this.map;
    }

    startTilesetLayers(filteredTilesets: Tileset[]) {
        if (!this.map) {
            console.log(
                // 'Aqui ta o caso que nunca deve acontecer, mapLoaded mas map === undefined'
                'Undefined behavior!'
            );
            return;
        }

        filteredTilesets.reverse().forEach((ts) => {
            const layerId = `raster-layer-${ts.tilesetId}`;

            this.map.addLayer(
                {
                    id: layerId,
                    type: 'raster',
                    source: {
                        type: 'raster',
                        tiles: [
                            `https://api.mapbox.com/v4/${ts.tilesetId}/{z}/{x}/{y}.png`,
                        ],
                    },
                    layout: {
                        visibility: 'visible',
                        // Mapbox Style Specification layout properties
                    },
                },
                'tunnel-oneway-arrow-blue'
            );
        });

        this.started = true;
    }
}
