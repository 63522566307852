import { Box, LinearProgress, Typography } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import theme from '../../materialUI/theme';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { Tileset } from '../../types/tileset';
import DoneIcon from '@material-ui/icons/Done';
import { FormattedMessage } from 'react-intl';

type Props = {
    hasS3UploadFinished: boolean;
    tilesetDocId: string;
    processWatcher: any;
    setUploadStatus: (status: number) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            textAlign: 'center',
            // alignItems: 'center',
            // margin: 'auto',
            //      justifyContent: 'center',
            // backgroundColor: theme.palette.primary.main,
            //    margin: 'auto',
            //  alignItems: 'center',
        },
        disabled: {
            opacity: 0.5,
        },
    })
);

export const MapboxUploadWatcher: FunctionComponent<Props> = ({
    hasS3UploadFinished,
    tilesetDocId,
    processWatcher,
    setUploadStatus,
}) => {
    const classes = useStyles();
    const [uploadedToMapbox, setUploadedToMapbox] = useState(false);
    const uploadingTileset = useSelector((state: RootState) => {
        if (state.tilesets.tilesets !== 'not_initialized') {
            return state.tilesets.tilesets.find(
                (tileset: Tileset) => tileset.tilesetDocId === tilesetDocId
            );
        }
    });

    useEffect(() => {
        if (uploadingTileset?.uploadId) {
            setUploadedToMapbox(true);
            setUploadStatus(4);
        }
    }, [uploadingTileset]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className={clsx(
                hasS3UploadFinished || uploadedToMapbox
                    ? [classes.root]
                    : [classes.root, classes.disabled]
            )}
        >
            {!hasS3UploadFinished ? (
                <>
                    <Typography variant="h5">
                        <FormattedMessage
                            id="app.transportToMbx"
                            defaultMessage="Transporte para o <i>Mapbox</i>"
                            values={{
                                i: (chunks: any) => <i>{chunks}</i>,
                            }}
                        />
                    </Typography>
                    <Box marginTop={theme.spacing(0.3)}></Box>
                    <LinearProgress variant="determinate" value={0} />
                </>
            ) : !uploadedToMapbox ? (
                <>
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="app.transportingToMbx"
                            defaultMessage="Transportando o mapa para o <i>Mapbox</i>"
                            values={{
                                i: (chunks: any) => <i>{chunks}</i>,
                            }}
                        />
                    </Typography>
                    <Box marginTop={theme.spacing(0.3)}></Box>
                    <LinearProgress />
                </>
            ) : (
                <>
                    <DoneIcon fontSize="large" color="primary" />
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="app.correctlyTransportedToMbx"
                            defaultMessage="Mapa corretamente transportado para o <i>Mapbox</i>!"
                            values={{
                                i: (chunks: any) => <i>{chunks}</i>,
                            }}
                        />
                    </Typography>
                </>
            )}
        </div>
    );
};
