import { Phase1Params, Phase1Info } from './../../types/phase1';
import { db } from './../../../firebase/firebase';

type Params = Phase1Params & {
    setPhase1NameAndCreatedAt: (info: Phase1Info | null) => void;
};

export const downloadPhase1NameAndCreateAt = ({
    orgId,
    phase1Id,
    setPhase1NameAndCreatedAt,
}: Params) => {
    const docRef = db.doc(`organizations/${orgId}/phase1/${phase1Id}`);

    docRef.onSnapshot((doc) => {
        if (doc.exists) {
            //console.log('Document data:', doc.data());
            const data = doc.data();
            if (data) {
                if (data.name && data.createdAt) {
                    setPhase1NameAndCreatedAt({
                        name: data.name,
                        // createdAt: data.createdAt.toDate(),
                        createdAt: new Date(data.createdAt),
                    });
                }
            }
        } else {
            // doc.data() will be undefined in this case
            console.log('No such document!');
            // reject('No such document!');
        }
    });
};
