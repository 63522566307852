import React, { useState } from 'react';
// import { uploadTiff } from '../features/tilesets/S3Upload';
// import { TiffUploader } from '../features/tilesets/TiffUploader';
// import { Tilesets } from '../features/tilesets/Tilesets';

import mbxClient from '@mapbox/mapbox-sdk';
import mbxTilesets from '@mapbox/mapbox-sdk/services/tilesets';
import mbxUploads from '@mapbox/mapbox-sdk/services/uploads';
// import { useParams } from 'react-router';
// import { useTilesets } from '../hooks/useTilesets';

const accessToken =
    'sk.eyJ1IjoiZGltaXRyZW9ydHQiLCJhIjoiY2tuZnNkZHd4MDVlbjJ3czNpY3YzOG9pbiJ9.ut7s_WHUQiO4fkCvb2t8aA';

const baseClient = mbxClient({ accessToken });
const uploadsClient = mbxUploads(baseClient);
const tilesetsService = mbxTilesets(baseClient);

export const TestUploadPage = () => {
    const [uploaders, setUploaders] = useState([0]);
    // const { phase1Id, phase2Id }: any = useParams();
    // const tilesets = useTilesets(orgId, phase1Id, phase2Id);

    const addUploader = () => {
        setUploaders([...uploaders, uploaders.length]);
    };

    const onClick = async () => {
        const tilesetId = 'dimitreortt.tjuugens';

        const tilesetInfo = await tilesetsService
            .tileJSONMetadata({
                tilesetId,
            })
            .send()
            .then((response: any) => {
                console.log(response);
                const { bounds, center, filesize, upload_id } = response.body;
                return {
                    bounds,
                    center,
                    fileSize: filesize,
                    uploadId: upload_id,
                };
            });

        console.log({ tilesetInfo });
    };

    const onUp = () => {
        const uploadId = 'cknl00rl016gk24rorjdkbqzg';
        uploadsClient
            .getUpload({
                uploadId,
            })
            .send()
            .then((response: any) => {
                // const status = response.body;
                console.log(response);
                //const a = setTimeInterval(() => {}, 1000);
            });
    };

    return (
        <div>
            <div>
                <button onClick={onClick}>oi</button>
                <button onClick={onUp}>up</button>
            </div>
            {/* {uploaders.map((item) => (
        <TiffUploader
          key={item}
          // phase1Id={phase1Id}
          // phase2Id={phase2Id}
          // onSaveCallback={() => {}}
          // tilesets={tilesets}
        />
      ))} */}
            <button onClick={addUploader}>+</button>
            <div>{/* <Tilesets /> */}</div>
        </div>
    );
};
