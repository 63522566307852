import { ReservaLegalOuAreaPreservacaoPermanente } from './../../types/index';
import db from '../../../firebase/firebase';

export const storeReservaLegalOuAreaPreservacaoPermanente = (
    data: ReservaLegalOuAreaPreservacaoPermanente,
    collection: 'reserva-legal' | 'area-preservacao-permanente'
) => {
    return db
        .collection(collection)
        .add(data)
        .then((docRef) => docRef.id)
        .catch((err) => console.log(err));
};
