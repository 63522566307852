import React, { useState, useEffect } from 'react';
import { makeStyles, Dialog, DialogContent } from '@material-ui/core';
import { SetFieldType } from './SetFieldType';
import { SetFieldName } from './SetFieldName';
import { SetFieldSetup } from './SetFieldSetup';
import { SubmitButton } from './ui/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));

export const EditFieldForm = ({ field, toggleShowForm, submitFieldInfo }) => {
    const classes = useStyles();
    const [fieldType, setFieldType] = useState(field.type);
    const [fieldName, setFieldName] = useState(field.name);
    const [fieldItems, setFieldItems] = useState([]);
    const [fieldSetup, setFieldSetup] = useState({
        isDescription: field.isDescription || false,
        isHelpText: field.isHelpText || false,
        isRequired: field.isRequired || false,
    });

    useEffect(() => {
        if (field && field.checklistItems) {
            setFieldItems(field.checklistItems);
        } else if (field && field.selectItems) {
            setFieldItems(field.selectItems);
        }
    }, [field]);

    const handleSubmit = () => {
        if (!fieldName) {
            alert('Field Name cannot be empty!');
        }
        let fieldInfo = { type: fieldType, name: fieldName, ...fieldSetup };
        if (fieldType === 'CheckBox') {
            fieldInfo.checklistItems = fieldItems;
        } else if (fieldType === 'Seleção de Lista') {
            fieldInfo.selectItems = fieldItems;
        }
        submitFieldInfo(fieldInfo);
    };

    return (
        <div>
            <Dialog
                onClose={toggleShowForm}
                aria-labelledby="simple-dialog-title"
                open={true}
                className={classes.root}
            >
                <DialogContent className={classes.root}>
                    <SetFieldType
                        fieldType={fieldType}
                        setFieldType={setFieldType}
                        field={field}
                        setFieldItems={setFieldItems}
                        inEdit={true}
                    />
                    <SetFieldName
                        fieldName={fieldName}
                        setFieldName={setFieldName}
                        inEdit={true}
                    />
                    <SetFieldSetup
                        fieldSetup={fieldSetup}
                        setFieldSetup={setFieldSetup}
                        inEdit={true}
                    />
                    <SubmitButton onClick={handleSubmit} />
                </DialogContent>
            </Dialog>
        </div>
    );
};
