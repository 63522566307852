import 'date-fns';
import React, { FunctionComponent, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import enUSLocale from 'date-fns/locale/en-US';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

type Props = {
    // label: string | HTMLElement | Element;
    label: any;
    selectedDate: Date;
    setSelectedDate: (date: Date) => void;
    pickerId: string;
};

export const DatePicker: FunctionComponent<Props> = ({
    label,
    selectedDate,
    setSelectedDate,
    pickerId,
}) => {
    // The first commit of Material-UI
    // const [selectedDate, setSelectedDate] = useState(new Date())
    const [locale, setLocale] = useState('pt-BR');

    const handleDateChange = (date: any) => {
        setSelectedDate(date);
    };

    useEffect(() => {
        if (window.navigator.language === 'en') {
            setLocale('en');
        }
    }, []);

    return (
        <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={
                window.navigator.language === 'pt-BR' ? ptBRLocale : enUSLocale
            }
        >
            <Grid container justify="space-around">
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format={locale === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
                    margin="normal"
                    id={pickerId}
                    autoOk={true}
                    label={label}
                    value={selectedDate}
                    // value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    fullWidth
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
};

export default DatePicker;
