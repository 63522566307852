import { setUrlsType } from './../../features/organizations/organizationSlice';
import { db } from '../firebase';

export const downloadUrls = (setUrls: setUrlsType) => {
    return db
        .collection('organizations')
        .doc('6PSvdx8CYfJJnLGoEMqv')
        .onSnapshot((doc) => {
            const orgData: any = doc.data();
            const { urls } = orgData;

            setUrls(urls);
        });
};
