import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PhotoData } from '../../types/photoData';
import { storage } from '../../firebase/firebase';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        thumbnail: {
            width: '100%',
        },
    })
);

type Params = {
    photo: PhotoData;
};

export const Photo: React.FC<Params> = ({ photo }) => {
    const classes = useStyles();

    const [imageSrc, setImageSrc] = useState(
        'https://www.madeireiraestrela.com.br/images/joomlart/demo/default.jpg'
    );

    useEffect(() => {
        storage
            .ref(`propertiesPhotos/${photo.thumbnailNameInStorage}.jpg`)
            .getDownloadURL()
            .then((url) => {
                setImageSrc(url);
            });
    });

    return <img className={classes.thumbnail} src={imageSrc} alt=""></img>;
};
