import { setFieldblocksFormFieldsType } from './../../features/fieldblocks/fieldblocksSlice';
import { FormField } from './../../types/formField';
import { db } from './../../../firebase/firebase';

export const downloadFieldblocksFormFields = (
    setFieldblocksFormFields: setFieldblocksFormFieldsType,
    orgId: string
) => {
    db.collection('organizations')
        .doc(orgId)
        .collection('fieldblocksFormFields')
        .onSnapshot((snapshot) => {
            let fields: Array<FormField> = [];

            snapshot.forEach((docRef) => {
                let data: any = docRef.data();
                const field: FormField = data;
                field.fieldId = docRef.id;
                fields.push(field);
                fields.sort((a, b) => a.idx - b.idx);
            });

            setFieldblocksFormFields({ fieldblocksFormFields: fields });
        });
};
