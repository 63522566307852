import { PhotoData } from './../../types/photoData';
import { storage } from './../../firebase/firebase';

export const removeThumbnail = async (photo: PhotoData) => {
    const storageRef = storage.ref();
    var photoRef = storageRef.child(
        `propertiesPhotos/${photo.thumbnailNameInStorage}.jpg`
    );

    // Delete the file
    return photoRef
        .delete()
        .then(() => {
            // File deleted successfully
            console.log('File deleted successfully');
            return { status: 205 };
        })
        .catch((error) => {
            console.log(error.message);
            return { status: 400 };
        });
};
