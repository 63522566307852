import React, { FunctionComponent } from 'react';
import { LinkCard } from './LinkCard';
// import { LinkData } from './linksSlice';
import { LinkData } from '../../types/link';
// import { nanoid } from 'nanoid';
import Box from '@material-ui/core/Box';

type Props = {
    links: LinkData[] | 'not_initialized';
};

export const Links: FunctionComponent<Props> = ({ links }) => {
    //const { links } = useLinks(orgId, phase1Id, phase2Id);

    console.log(links);

    return (
        <div>
            {links !== 'not_initialized' &&
                links.map((link) => {
                    return (
                        <Box py={1} key={link.linkDocId}>
                            <LinkCard linkData={link} />
                        </Box>
                    );
                })}
        </div>
    );
};
