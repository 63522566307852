import { Annotation } from './../../types/annotation';
import { db } from '../firebase';

export const uploadAnnotation = (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    annotation: Annotation
) => {
    //const { coordinates, ...rest } = annotation;
    const { feature, ...rest } = annotation;

    const newFeature: any = feature;

    newFeature.geometry.coordinates = JSON.stringify(
        feature.geometry.coordinates
    );

    const adaptedAnnotation = {
        // coordinates: JSON.stringify(coordinates),
        feature: newFeature,
        ...rest,
    };

    db.collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('annotations')
        .add(adaptedAnnotation)
        .then((doc) => console.log('Annotation correctly uploaded'));
};
