import React from 'react';
import { ListOfRelatoriosSideBarComponent } from './ListOfRelatoriosSideBarComponent';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { useParams } from 'react-router';
import { useUrls } from '../../hooks/useUrls';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
        },
        root: {
            flexGrow: 1,
        },
        margin: {
            marginLeft: theme.spacing(2),
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(0),
            color: 'white',
        },
    })
);

type Params = {
    phase1Id: string;
    phase2Id: string;
};

export function ReportsSession() {
    const classes = useStyles();
    const { phase1Id, phase2Id }: Params = useParams();
    const { urls } = useUrls();

    return (
        <React.Fragment>
            <Paper variant="outlined" className={classes.paperRoot}>
                <div className={classes.root}>
                    <Grid container spacing={0}>
                        <Grid container item xs={12}>
                            <Grid item xs={1}>
                                <AssignmentIcon style={{ color: 'white' }} />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography
                                    variant="h6"
                                    style={{ color: 'white' }}
                                    gutterBottom
                                    display="inline"
                                >
                                    <FormattedMessage
                                        id="app.formsSidebar"
                                        defaultMessage="Formulários"
                                    />
                                </Typography>
                                <Fab
                                    variant="round"
                                    size="medium"
                                    color="primary"
                                    className={classes.margin}
                                    onClick={() => {
                                        window.open(
                                            // `https://coopasvtech-formfy.web.app/`,
                                            // `http://localhost:3000/${phase1Id}/${phase2Id}`,
                                            // `https://coopasvtech-drb-formfy.web.app/${phase1Id}/${phase2Id}`,
                                            `${urls.formfy}/${phase1Id}/${phase2Id}`,
                                            '_blank'
                                        );
                                    }}
                                    // onClick={this.handleShowListOfAreasisShowing}
                                    //   onClick={this.handleOpen}
                                    // component={RouterLink}
                                    // to="/relatorios/produtorid/id/propriedadeid/id"
                                    // target="_blank"
                                >
                                    <AddIcon />
                                </Fab>
                            </Grid>
                        </Grid>
                        <ListOfRelatoriosSideBarComponent />
                    </Grid>
                </div>
            </Paper>
        </React.Fragment>
    );
}
