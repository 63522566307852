import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router';

//Components
import NavbarOrganization from '../features/navbars/NavbarOrganization';
import OrganizationCopyrightComponent from '../features/organizations/OrganizationCopyrightComponent';
import ButtonAndDialogAddUserAccessPhase2 from '../features/members/ButtonAndDialogAddUserAccessPhase2';
import RealtimeUpdatesOrgUsers from '../features/members/RealtimeUpdatesOrgUsers';
import Phase2MembersTablePaper from '../features/members/Phase2MembersTablePaper';

//Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { usePhase1NameAndCreatedAt } from '../formfy/customHooks/usePhase1NameAndCreatedAt';
import { usePhase2NameAndCreatedAt } from '../formfy/customHooks/usePhase2NameAndCreatedAt';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootGrid: {
            padding: theme.spacing(8, 0, 0) /* top | horizontal | bottom */,
        },
        header: {
            padding: theme.spacing(0, 0, 3) /* top | horizontal | bottom */,
        },
        paper: {
            padding: theme.spacing(1, 1, 1) /* top | horizontal | bottom */,
        },
    })
);

type URLParams = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

export default function Phase2ManageMembersPage() {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id }: URLParams = useParams();

    const { phase1Info } = usePhase1NameAndCreatedAt();
    const { phase2Info } = usePhase2NameAndCreatedAt();

    return (
        <div>
            <RealtimeUpdatesOrgUsers />
            <CssBaseline />
            <NavbarOrganization />
            <Container maxWidth="lg">
                <Grid container className={classes.rootGrid}>
                    <Grid
                        container
                        item
                        className={classes.header}
                        justify="space-between"
                        alignItems="center"
                        spacing={1}
                        xs={12}
                    >
                        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                            <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/organizationId/${orgId}`}
                            >
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    <FormattedMessage
                                        id="app.start"
                                        defaultMessage="Início"
                                    />
                                </Typography>
                            </Link>

                            <Box component="span" marginX={0.5}>
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    /
                                </Typography>
                            </Box>

                            <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/organizationId/${orgId}/phase1Id/${phase1Id}`}
                            >
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    {phase1Info?.name}
                                </Typography>
                            </Link>

                            <Box component="span" marginX={0.5}>
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    /
                                </Typography>
                            </Box>

                            <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/organizationId/${orgId}/phase1Id/${phase1Id}/phase2Id/${phase2Id}`}
                            >
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    {phase2Info?.name}
                                </Typography>
                            </Link>

                            <Box component="span" marginX={0.5}>
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    display="inline"
                                >
                                    /
                                </Typography>
                            </Box>

                            <Typography
                                variant="h5"
                                color="inherit"
                                display="inline"
                            >
                                <FormattedMessage
                                    id="app.access"
                                    defaultMessage="Acesso"
                                />
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            justify="flex-end"
                            alignItems="center"
                            spacing={1}
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                        >
                            {/* <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
                                <ProjectMoreOptionsButton textButton />
                            </Grid> */}
                            <Grid item xs={12}>
                                {/* {accessLevel !== 'Guest' && ( */}
                                <ButtonAndDialogAddUserAccessPhase2 />

                                {/* )} */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box paddingTop={1} paddingBottom={1}>
                                <Divider />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={6}>
                        <Phase2MembersTablePaper />
                    </Grid>
                </Grid>
            </Container>
            <OrganizationCopyrightComponent />
        </div>
    );
}
