import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import exifr from 'exifr';
import {
    Box,
    createStyles,
    makeStyles,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Step,
    Stepper,
    StepLabel,
    StepContent,
    Paper,
    Theme,
} from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { usePhotoUploader } from './usePhotoUploader';
import { Position } from '../../types/photoData';
import { AddPhotoPositionDialog } from './AddPhotoPositionDialog';
import { imageGPSToPosition } from './imageGPSToPosition';
import { AddPhotoStep0 } from './AddPhotoStep0';
import { AddPhotoStep1 } from './AddPhotoStep1';
import { UploadingSpinnerDialog } from './UploadingSpinnerDialog';
import { CustomAlert } from './CustomAlert';
import { makeId } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootDialog: {
            // width: 400,
            '& .MuiPaper-root': {
                width: 400,
            },
        },
        stepperRoot: {
            width: '100%',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        checkCircleIcon: {
            color: theme.palette.primary.light,
            height: '100%',
            '& .svg': {
                fontSize: 74,
            },
        },
        successfulUpload: {
            fontSize: '2rem',
        },
    })
);

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
};

export const AddPhotoDialog: FunctionComponent<Props> = ({ open, setOpen }) => {
    const classes = useStyles();
    const { uploadPhoto } = usePhotoUploader();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const [alertMessage, setAlertMessage] = useState<Element | null>(null);

    const [file, setFile] = useState<any>();
    const [uploading, setUploading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState<string | null>(null);
    const [inAddPosition, setInAddPosition] = useState(false);
    const [filePosition, setFilePosition] = useState<Position | undefined>(
        undefined
    );

    const [chosenImagePosition, setChosenImagePosition] = useState<
        Position | undefined
    >(undefined);

    const selectedDate = useSelector(
        (state: RootState) => state.timeline.selectedDate
    );

    const [photoDate, setPhotoDate] = useState(
        selectedDate instanceof Date ? selectedDate : new Date()
    );

    useEffect(() => {
        if (selectedDate !== 'not_sure') {
            setPhotoDate(selectedDate);
        }
    }, [selectedDate]);

    const [locationOption, setLocationOption] = React.useState<
        'manual' | 'dontShow' | 'automatic'
    >('manual');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocationOption(
            (event.target as HTMLInputElement).value as
                | 'manual'
                | 'dontShow'
                | 'automatic'
        );
    };

    const handleChoosePosition = () => {
        setInAddPosition(true);
        setOpen(false);
    };

    const handleNext = () => {
        if (activeStep === 0 && !file) {
            // return alert();
            return setAlertMessage(
                //@ts-ignore
                <FormattedMessage
                    id="app.pleaseUploadFile"
                    defaultMessage="Por favor, escolha um arquivo!"
                />
            );
        }

        if (
            activeStep === 1 &&
            locationOption === 'manual' &&
            !chosenImagePosition
        ) {
            return setAlertMessage(
                //@ts-ignore
                <FormattedMessage
                    id="app.pleaseChoosePosition"
                    defaultMessage="Por favor, escolha uma posição manualmente!"
                />
            );
        }

        if (activeStep === steps.length - 1) {
            setUploading(true);

            uploadPhoto(
                file,
                filePosition || { lat: 0, lng: 0 },
                chosenImagePosition || { lat: 0, lng: 0 },
                photoDate,
                locationOption
            ).then((result: string | void) => {
                setUploading(false);
                if (!result) {
                    setUploadStatus(null);
                } else {
                    setUploadStatus(result);
                }
            });
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setFile(null);
        setPhotoDate(selectedDate instanceof Date ? selectedDate : new Date());
        setLocationOption('manual');
        setFilePosition(undefined);
        setChosenImagePosition(undefined);
        setUploadStatus(null);
    };

    // console.log(filePosition);

    const onFileChange = (event: any) => {
        const file = event.target.files[0];
        console.log(file);

        exifr
            .parse(file)
            .then((fileMetadata) => {
                console.log(fileMetadata);

                if (
                    fileMetadata.GPSLatitude.length === 3 &&
                    fileMetadata.GPSLongitude.length === 3
                ) {
                    setFilePosition(imageGPSToPosition(fileMetadata));
                }
            })
            .catch((error) => {
                console.log(
                    'Impossible to extract metadata from ' + file.name,
                    error.message
                );
                setFilePosition(undefined);
            });

        setFile(file);
    };

    const onCancel = () => {
        handleReset();
        setOpen(false);
    };

    function getSteps() {
        return [
            <FormattedMessage
                id="app.chooseFile"
                defaultMessage="Escolher arquivo"
            />,
            <FormattedMessage
                id="app.chooseLocation"
                defaultMessage="Escolher localização"
            />,
            <FormattedMessage id="app.review" defaultMessage="Resumo" />,
        ];
    }

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <AddPhotoStep0
                        file={file}
                        onFileChange={onFileChange}
                        photoDate={photoDate}
                        setPhotoDate={setPhotoDate}
                    />
                );
            case 1:
                return (
                    <AddPhotoStep1
                        locationOption={locationOption}
                        handleChange={handleChange}
                        filePosition={filePosition}
                        chosenImagePosition={chosenImagePosition}
                        handleChoosePosition={handleChoosePosition}
                    />
                );
            case 2:
                return (
                    <div>
                        <Box
                            display="flex"
                            alignItems="center"
                            marginBottom={1}
                            // justifyContent='center'
                        >
                            <LocationOnIcon
                                className={classes.checkCircleIcon}
                            />
                            <Typography variant="h6">
                                {locationOption === 'manual' ? (
                                    <>
                                        <div>Manual</div>
                                        {/* Manual */}
                                    </>
                                ) : locationOption === 'automatic' ? (
                                    <>
                                        <FormattedMessage
                                            id="app.automatic"
                                            defaultMessage="Automático"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <FormattedMessage
                                            id="app.none"
                                            defaultMessage="Nenhuma"
                                        />
                                    </>
                                )}
                            </Typography>
                        </Box>
                        {locationOption === 'manual' ? (
                            <Box width={'400px'}>
                                <Typography variant="body1">
                                    <strong>
                                        {`${chosenImagePosition?.lng},`}
                                        <br />
                                        {`
                      ${chosenImagePosition?.lat}`}
                                    </strong>
                                </Typography>
                            </Box>
                        ) : locationOption === 'automatic' ? (
                            <Typography variant="body1">
                                <strong>
                                    {`${filePosition?.lng}`}
                                    <br />
                                    {`${filePosition?.lat}`}
                                </strong>
                            </Typography>
                        ) : (
                            <div></div>
                        )}
                    </div>
                );
            default:
                return 'Unknown step';
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={onCancel}
                className={classes.rootDialog}
            >
                <DialogTitle>
                    <FormattedMessage
                        id="app.addPhoto"
                        defaultMessage="Adicionar Foto"
                    />
                </DialogTitle>
                <div className={classes.stepperRoot}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                            <Step key={makeId(6)}>
                                <StepLabel>{label}</StepLabel>
                                <StepContent>
                                    {getStepContent(index)}
                                    <div className={classes.actionsContainer}>
                                        <div>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.button}
                                            >
                                                <FormattedMessage
                                                    id="app.back"
                                                    defaultMessage="Anterior"
                                                />
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                                className={classes.button}
                                            >
                                                {activeStep ===
                                                steps.length - 1 ? (
                                                    'Upload'
                                                ) : (
                                                    <FormattedMessage
                                                        id="app.next"
                                                        defaultMessage="Próximo"
                                                    />
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length && (
                        <Paper
                            square
                            elevation={0}
                            className={classes.resetContainer}
                        >
                            {uploadStatus === 'success' ? (
                                <>
                                    <Box display="flex">
                                        <CheckCircleIcon
                                            className={classes.successfulUpload}
                                        />
                                        <Typography variant="h6">
                                            Photo successfully uploaded!
                                        </Typography>
                                    </Box>
                                </>
                            ) : uploadStatus === 'error' ? (
                                <>
                                    <Box display="flex">
                                        <CancelIcon />
                                        <Typography variant="h6">
                                            There was an error uploading the
                                            photo!
                                        </Typography>
                                    </Box>
                                </>
                            ) : (
                                <></>
                            )}
                        </Paper>
                    )}
                </div>

                <DialogActions>
                    {!uploadStatus ? (
                        <Box flexGrow={1}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={onCancel}
                                fullWidth
                            >
                                <FormattedMessage
                                    id="app.cancel"
                                    defaultMessage="Cancelar"
                                />
                            </Button>
                        </Box>
                    ) : (
                        <Box flexGrow={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onCancel}
                                fullWidth
                            >
                                <FormattedMessage
                                    id="app.return"
                                    defaultMessage="Voltar"
                                />
                            </Button>
                        </Box>
                    )}
                </DialogActions>
            </Dialog>
            <AddPhotoPositionDialog
                open={inAddPosition}
                setOpen={setInAddPosition}
                setAddPhotoOpen={setOpen}
                setImagePosition={setChosenImagePosition}
            />
            <UploadingSpinnerDialog open={uploading} />
            {alertMessage && (
                <CustomAlert
                    message={alertMessage}
                    onClose={() => {
                        setAlertMessage(null);
                    }}
                />
            )}
        </>
    );
};
