import React, { Fragment } from 'react';

import { signOut } from '../../features/auth/signOut';

//Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';

//Types
import { OrgUser } from '../../types/organization';

//Material UI
import Button from '@material-ui/core/Button';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

export default function UsernameButtonAndMenuComponent(props: any) {
    const history = useHistory();
    const orgUserInfo: OrgUser | null | 'not_initialized' = useSelector(
        (state: RootState) => state.organization.orgUser
    );

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleLogout() {
        signOut();
        history.push('/');
    }

    const [username, setusername] = React.useState('');
    React.useEffect(() => {
        if (orgUserInfo !== 'not_initialized' && orgUserInfo?.name) {
            function truncate(str: string, n: number) {
                return str.length > n ? str.substr(0, n - 1) + '...' : str;
            }
            setusername(truncate(orgUserInfo?.name, 12));
        }
        // return () => {}
    }, [orgUserInfo]);

    return (
        <Fragment>
            {!props.onlyIcon ? (
                <Button
                    variant="outlined"
                    color="inherit"
                    size="small"
                    endIcon={<AccountCircleIcon />}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    {username}
                </Button>
            ) : (
                <IconButton
                    edge="end"
                    aria-label="profile-menu"
                    color="inherit"
                    size="small"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <AccountCircleIcon />
                </IconButton>
            )}
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {/* <MenuItem disabled onClick={handleClose}>
          <FormattedMessage id='app.myAccount' defaultMessage='Minha conta' />
        </MenuItem>
        <MenuItem disabled onClick={handleClose}>
          <FormattedMessage id='app.language' defaultMessage='Idioma' />
        </MenuItem> */}
                <MenuItem onClick={handleLogout}>
                    <FormattedMessage id="app.signOut" defaultMessage="Sair" />
                </MenuItem>
            </Menu>
        </Fragment>
    );
}
