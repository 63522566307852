import React from 'react';
// import { useParams } from 'react-router-dom';

//Components
import ShareButtonAndDialog from '../sharing/ShareButtonAndDialog';

//MaterialUI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            padding: theme.spacing(1),
            //   backgroundColor: theme.palette.primary.main,
        },
        root: {
            flexGrow: 1,
        },
        button: {
            margin: theme.spacing(0),
        },
    })
);

// type Params = {
//   orgId: string;
//   phase1Id: string;
//   phase2Id: string;
// };

export default function ShareSidebarMenu() {
    const classes = useStyles();
    //   const { orgId, phase1Id, phase2Id }: Params = useParams();
    return (
        <React.Fragment>
            <Paper variant="outlined" className={classes.paperRoot}>
                <div className={classes.root}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <ShareButtonAndDialog />
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </React.Fragment>
    );
}
