import React from 'react';
import { Button, TextField } from '@material-ui/core';
import NextButton from './ui/NextButton';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        right: {
            marginLeft: 'auto',
        },
        flex: {
            marginTop: theme.spacing(1),
            display: 'flex',
            // justify: 'center',
        },
        dialog: {
            width: 400,
        },
        marginBottom: {
            marginBottom: theme.spacing(1),
        },
    })
);

export const SetFieldName = ({
    nextStep,
    fieldName,
    setFieldName,
    setShowAddForm,
    inEdit,
}) => {
    const classes = useStyles();
    const handleClickNext = () => {
        !!fieldName ? nextStep() : alert('Field name cannot be empty!');
    };
    return (
        <div>
            <TextField
                label="Nome do campo"
                variant="outlined"
                value={fieldName || ''}
                fullWidth
                onChange={(event) => setFieldName(event.target.value)}
            />

            <div className={classes.flex}>
                {!!nextStep && (
                    <NextButton onClick={handleClickNext} label={'Próximo'} />
                )}

                {!inEdit && (
                    <Button
                        className={classes.right}
                        variant="outlined"
                        color="default"
                        onClick={() => setShowAddForm(false)}
                    >
                        Cancelar
                    </Button>
                )}
            </div>
        </div>
    );
};
