import { auth } from './../../firebase/firebase';
import { createOrganizationDefaultTemporaryUser } from './../../firebase/utils/createOrganizationDefaultTemporaryUser';

const signIn = (orgId: string) => {
    return auth
        .signInWithEmailAndPassword(orgId + '@mail.com', orgId)
        .then((credential) => {
            if (credential.user) {
                console.log(
                    'signInAsTempUser. The user has been successfully signed in! ' +
                        credential.user.uid
                );
                return credential.user.uid;
            } else {
                throw Error('No credential');
            }
        });
};

export const signInAsOrganizationDefaultTemporaryUser = async (
    orgId: string
) => {
    const tempUserId = await auth
        .createUserWithEmailAndPassword(orgId + '@mail.com', orgId)
        .then((credential) => {
            console.log(credential.user?.uid);
            return credential.user?.uid;
        })
        .catch((error) => {
            if (error.code === 'auth/email-already-in-use') {
                return signIn(orgId);
            } else {
                throw error;
            }
        });

    if (!tempUserId) throw Error('Unable to sign in temporary user!');

    await createOrganizationDefaultTemporaryUser(orgId, tempUserId);

    return tempUserId;
    // .catch((error) => {
    //   console.log(`Error: ${error.message}`);
    // });
};
