import React from 'react';

//Material UI
import {
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { ReservaLegalOuAreaPreservacaoPermanente } from 'formfy/types/index';

import { deleteAPPorReservaLegal } from '../../firebase/utils/deleteAPPorReservaLegal';

export default function DeleteDialog({
    info,
    area,
}: {
    info: ReservaLegalOuAreaPreservacaoPermanente;
    area: string | null;
}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        if (info.id) {
            if (
                info.type === 'area-preservacao-permanente' ||
                info.type === 'reserva-legal'
            ) {
                await deleteAPPorReservaLegal(info.id, info.type);
                setOpen(false);
            }
        }
    };

    // console.log('info =>', info);

    return (
        <div>
            <IconButton
                onClick={handleClickOpen}
                aria-label="delete"
                size="small"
            >
                <DeleteIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Certeza que deseja excluir?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="h6" display="block" gutterBottom>
                            {info.type === 'area-preservacao-permanente' &&
                                'Área de Preservação Permanente'}
                            {info.type === 'reserva-legal' && 'Reserva Legal'}
                        </Typography>
                        <Typography align="left" variant="subtitle1">
                            <strong> Área: </strong>
                            {area}
                        </Typography>
                        <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                        >
                            Id: {info.id}
                        </Typography>

                        {info.notes && (
                            <Typography
                                variant="body2"
                                display="block"
                                gutterBottom
                            >
                                Notes: {info.notes}
                            </Typography>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button fullWidth onClick={handleClose} color="primary">
                        Cancelar.
                    </Button>
                    <Button fullWidth onClick={handleDelete} color="secondary">
                        Sim, excluir.
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
