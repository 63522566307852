import React, { FunctionComponent } from 'react';
import {
    FormField,
    FormFieldTypes,
    RegistryStateField,
} from '../../formfy/types';

// import DatePicker from '../../formfy/components/DatePicker';
import { TextField } from '@material-ui/core';
import { ChecklistFormField } from './ChecklistFormField';
import { DateTimeFormField } from './DateTimeFormField';
import { EmailFormField } from './EmailFormField';
import { ListSelectionFormField } from './ListSelectionFormField';
import { PhoneFormField } from './PhoneFormField';
import { CPFFormField } from './CPFFormField';
import { CNPJFormField } from './CNPJFormField';
// import { DateFormField } from './DateFormField';
import { DatePicker } from './DatePicker';

type Props = {
    stateField: RegistryStateField;
    formField: FormField;
    onChange: (value: FormFieldTypes) => void;
};

export const RenderFormField: FunctionComponent<Props> = ({
    formField,
    stateField,
    onChange,
}) => {
    //const value = stateField.value;

    return <div>{parseRender(stateField, formField, onChange)}</div>;
};

const parseRender = (
    stateField: RegistryStateField,
    field: FormField,
    onChange: (value: FormFieldTypes) => void
) => {
    const value = stateField.value;

    // console.log('typeof value', typeof value);

    switch (stateField.type) {
        case 'Data':
            if (value === null) {
                onChange(new Date());
            }

            return (
                <DatePicker
                    label={field.name}
                    selectedDate={value instanceof Date ? value : new Date()}
                    // selectedDate={selectedDate}
                    setSelectedDate={onChange}
                    pickerId={field.name}
                />
                //<DateFormField field={field} value={value} onChange={onChange} />
            );

        case 'Data e Hora':
            return (
                <div>
                    <DateTimeFormField value={value} onChange={onChange} />
                </div>
            );

        case 'Texto Curto':
            return (
                <TextField
                    label={field.name}
                    value={value || ''}
                    onChange={(e) => onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                />
            );

        case 'Texto Longo':
            return (
                <TextField
                    label={field.name}
                    multiline
                    rows={4}
                    value={value || ''}
                    onChange={(e) => onChange(e.target.value)}
                    variant="filled"
                    fullWidth
                />
            );

        case 'Email':
            return (
                <EmailFormField
                    field={field}
                    value={value}
                    onChange={onChange}
                />
            );

        case 'CheckBox':
            return (
                <ChecklistFormField
                    field={field}
                    value={value}
                    onChange={onChange}
                />
            );

        case 'Seleção de Lista':
            return (
                <ListSelectionFormField
                    field={field}
                    value={value}
                    onChange={onChange}
                />
            );

        case 'Número de Telefone':
            return (
                <PhoneFormField
                    field={field}
                    value={value}
                    onChange={onChange}
                />
            );

        case 'CPF':
            return (
                <CPFFormField field={field} value={value} onChange={onChange} />
            );

        case 'CNPJ':
            return (
                <CNPJFormField
                    field={field}
                    value={value}
                    onChange={onChange}
                />
            );

        default:
            return <div>default, probably not implemented type</div>;
    }
};
