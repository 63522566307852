import React from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { useParams } from 'react-router-dom';
import { Phase2PageContext } from '../../contexts/Phase2PageContext';
import { CalculateCenterCoordinates } from './CalculateCenterCoordinates';

//internationalization
// import { FormattedDate } from 'react-intl';

//Functions
import { deleteAnnotation } from '../../firebase/utils/deleteAnnotation';

//Types
import { Annotation } from '../../types/annotation';

//MaterialUI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import { FormattedMessage } from 'react-intl';

type Params = {
    annotation: Annotation;
};

export default function AnnotationItemDeleteOption({ annotation }: Params) {
    const { orgId, phase1Id, phase2Id }: any = useParams();

    const [open, setOpen] = React.useState(false);

    const { mapClass } = React.useContext(Phase2PageContext);
    const map = mapClass.getMap();

    const [centerCoordinates] = React.useState(() => {
        const initialState = CalculateCenterCoordinates(
            // @ts-ignore
            //annotation.feature.geometry.coordinates,
            annotation
        );
        return initialState;
    });

    const handleClickOpen = () => {
        map.flyTo({ center: centerCoordinates, zoom: 19 });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onRemove = (annotation: Annotation) => {
        deleteAnnotation(
            orgId,
            phase1Id,
            phase2Id,
            annotation.annotationDocId!
        );
        setOpen(false);
    };

    const accessLevel: string = useSelector((state: RootState) => {
        const orgUser = state.organization.orgUser;
        if (orgUser && orgUser !== 'not_initialized') {
            return orgUser.organizationAccessLevel;
        } else {
            return 'Guest';
        }
    });

    return (
        <div>
            <MenuItem
                onClick={handleClickOpen}
                disabled={
                    accessLevel !== 'Admin' && accessLevel !== 'Staff/Partner'
                }
            >
                <FormattedMessage id="app.delete" defaultMessage="Deletar" />
            </MenuItem>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <FormattedMessage
                        id="app.sureDeleteItem"
                        defaultMessage="Você tem certeza que deseja deletar?"
                    />
                    {/* Você tem certeza que deseja deletar? */}
                </DialogTitle>
                <DialogTitle id="alert-dialog-title">
                    <FormattedMessage
                        id="app.annotation"
                        defaultMessage="Anotação"
                    />
                    {': '}
                    {annotation.title}
                </DialogTitle>

                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        color="primary"
                    >
                        <FormattedMessage
                            id="app.cancel"
                            defaultMessage="Cancelar"
                        />
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => onRemove(annotation)}
                        color="secondary"
                        autoFocus
                    >
                        {/* Deletar */}
                        <FormattedMessage
                            id="app.delete"
                            defaultMessage="Deletar"
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
