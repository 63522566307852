import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Typography,
    TextField,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from '@material-ui/core';
import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

import { RootState } from '../../store/configureStore';
import { serverTimestamp } from 'firebase/firestore';

import { useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { storeReservaLegalOuAreaPreservacaoPermanente } from 'formfy/firebase/utils/storeReservaLegalOuAreaPreservacaoPermanente';
import { Phase2PageContext } from 'contexts/Phase2PageContext';
import { getLatestCreatedDrawing } from 'utils/mapbox/getLatestCreatedDrawing';
import { getElevation } from 'features/fieldBlocks/getElevation';
import { ReservaLegalOuAreaPreservacaoPermanente } from 'formfy/types/index';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        disabled: {
            pointerEvents: 'none',
            opacity: 0.5,
        },
    })
);

type Params = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

type Collection = 'reserva-legal' | 'area-preservacao-permanente';

const AddAPPsAndReservaLegalOnSideBarMenu = () => {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id }: Params = useParams();
    const [inAddFieldBlock, setInAddFieldBlock] = useState(false);
    const [inDraw, setInDraw] = useState(false);
    const [hasDrawed, setHasDrawed] = useState(false);
    const [isOnDrawCreateStarted, setIsOnDrawCreateStarted] = useState(false);
    const [drawnFeature, setDrawnFeature] = useState<any>(undefined);
    const [fbArea, setArea] = useState<any>(null);
    const [fbElevation, setElevation] = useState(null);
    console.log('fbArea=>', fbArea);
    console.log('fbElevation=>', fbElevation);
    const { draw, mapClass } = useContext(Phase2PageContext);
    const organizationAccessToken = useSelector((state: RootState) => {
        return state.organization.publicInfo.mapboxDefaultPublicToken;
    });
    // const [inShowForm, setInShowForm] = useState(false);

    const openAdd = () => {
        setInAddFieldBlock(true);
    };

    const closeAdd = () => {
        // const draw = getDraw();
        draw.deleteAll();
        setInDraw(false);
        setHasDrawed(false);
        setInAddFieldBlock(false);
        // setInShowForm(false);
        setArea(null);
        setElevation(null);
        setNotes('');
    };

    const startDraw = () => {
        // const draw = getDraw();
        draw.changeMode('draw_polygon');
        setInDraw(true);
    };

    const cancelDraw = () => {
        // const draw = getDraw();
        draw.changeMode('simple_select');
        draw.trash();
        setInDraw(false);
    };

    const [notes, setNotes] = useState('');

    const save = () => {
        const reservaLegal: ReservaLegalOuAreaPreservacaoPermanente = {
            type: 'Polygon',
            orgId,
            phase1Id,
            phase2Id,
            coordinates: JSON.stringify(drawnFeature.geometry.coordinates),
            notes,
            createdAt: serverTimestamp(),
        };

        storeReservaLegalOuAreaPreservacaoPermanente(
            reservaLegal,
            selectValue
        ).then((docId) => {
            // console.log('docId', docId);
            closeAdd();
        });
    };

    const bufferArea = () => {
        const feature = getLatestCreatedDrawing(draw);
        if (!feature) return alert('Error in AddFieldBlock! No feature!');

        const data = turf.polygon(feature.geometry.coordinates);
        var area = turf.area(data);
        // restrict to area to 2 decimal points
        var hectares = area / 10000;
        var rounded_hectares = Math.round(hectares * 100) / 100;
        // console.log('rounded_area', rounded_hectares);
        setArea(rounded_hectares);
    };

    const bufferAltitude = () => {
        const feature = getLatestCreatedDrawing(draw);
        var polygon = turf.polygon(feature.geometry.coordinates);
        const centerOfMass = turf.centerOfMass(polygon);
        // console.log(centerOfMass);
        const [lng, lat] = centerOfMass.geometry.coordinates;
        mapboxgl.accessToken = organizationAccessToken;
        getElevation(lng, lat, (elevation: any) => {
            // console.log('elevation', elevation);
            setElevation(elevation);
        });
    };

    const extractFeature = () => {
        bufferArea();
        bufferAltitude();

        const features = draw.getAll().features;
        if (!(features.length > 0)) {
            alert('Erro ao processar o desenho!');
            closeAdd();
        }

        setDrawnFeature(features[0]);
    };

    const onDrawCreate = () => {
        // console.log('onDrawCreate');
        bufferArea();
        bufferAltitude();
        extractFeature();

        setInDraw(false);
        setHasDrawed(true);
    };

    const onDrawUpdate = () => {
        // console.log('onDrawUpdate');

        extractFeature();
    };

    const [selectValue, setselectValue] =
        React.useState<Collection>('reserva-legal');

    useEffect(() => {
        if (inDraw) {
            // const map = getMap();
            const map = mapClass.map;
            if (map && !isOnDrawCreateStarted) {
                map.on('draw.create', (e: any) => onDrawCreate());
                map.on('draw.update', (e: any) => onDrawUpdate());
                setIsOnDrawCreateStarted(true);
            }
        }
    }, [inDraw]); // eslint-disable-line

    return (
        <>
            {inAddFieldBlock && (
                <>
                    <Box padding={1}>
                        <Typography variant="h6">
                            Registrar APP ou Reserva Legal
                        </Typography>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="app.firstDrawAMap"
                                defaultMessage="1º Escolha se é uma APP ou Reserva Legal."
                            />
                        </Typography>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="app.firstDrawAMap"
                                defaultMessage="2º Desenhe a área no mapa."
                            />
                        </Typography>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="app.secondFillForm"
                                defaultMessage="3º Caso necessário, adicione anotações sobre essa área."
                            />
                        </Typography>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="app.secondFillForm"
                                defaultMessage="4º Salvar"
                            />
                        </Typography>
                    </Box>
                    <Box padding={0.5}>
                        <Box sx={{ py: 2 }}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Escolha o tipo de registro
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={selectValue}
                                    onChange={(e) =>
                                        setselectValue(
                                            e.target.value as Collection
                                        )
                                    }
                                    label="Escolha o tipo de registro"
                                >
                                    <MenuItem value="reserva-legal">
                                        Reserva Legal
                                    </MenuItem>
                                    <MenuItem value="area-preservacao-permanente">
                                        APP
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        {!inDraw ? (
                            <div className={hasDrawed ? classes.disabled : ''}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={startDraw}
                                    fullWidth
                                >
                                    <FormattedMessage
                                        id="app.drawFieldblock"
                                        defaultMessage="Iniciar desenho no mapa"
                                    />
                                </Button>
                            </div>
                        ) : (
                            <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                fullWidth
                                onClick={cancelDraw}
                            >
                                <FormattedMessage
                                    id="app.cancelDraw"
                                    defaultMessage="Cancelar desenho"
                                />
                            </Button>
                        )}
                    </Box>

                    <Box sx={{ px: 0.5, py: 2 }}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Anotações"
                            multiline
                            minRows={4}
                            variant="outlined"
                            fullWidth
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            disabled={!hasDrawed}
                        />
                    </Box>

                    <Box padding={0.5}>
                        <div
                            className={!inAddFieldBlock ? classes.disabled : ''}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={save}
                                fullWidth
                                disabled={!hasDrawed}
                            >
                                <FormattedMessage
                                    id="app.discardRegistry"
                                    defaultMessage="Salvar"
                                />
                            </Button>
                        </div>
                    </Box>

                    <Box padding={0.5}>
                        <div
                            className={!inAddFieldBlock ? classes.disabled : ''}
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                size="large"
                                onClick={closeAdd}
                                fullWidth
                            >
                                <FormattedMessage
                                    id="app.discardRegistry"
                                    defaultMessage="Descartar registro"
                                />
                            </Button>
                        </div>
                    </Box>
                </>
            )}

            {!inAddFieldBlock && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={openAdd}
                        fullWidth
                    >
                        <FormattedMessage
                            id="app.addFieldblock"
                            defaultMessage="Registrar APP ou Reserva Legal"
                        />
                    </Button>
                </Box>
            )}
        </>
    );
};

export default AddAPPsAndReservaLegalOnSideBarMenu;
