import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Card, CardContent, CardActions, Box } from '@material-ui/core';
import { FormField, FormFieldTypes } from '../../formfy/types';
import { RenderFormField } from '../ui/RenderFormField';
import { FormattedMessage } from 'react-intl';
import { useFieldblocksRegistryState } from '../../formfy/API/useFieldblocksRegistryState';
import { useFieldblocksFormFields } from '../../formfy/API/useFieldblocksFormFields';

type Props = {
    onSaveCallback: (fbId: string) => void;
    onCancel: () => void;
    drawnFeature: any;
};

type Params = {
    phase1Id: string;
    phase2Id: string;
};

export const FieldblocksForm: FunctionComponent<Props> = ({
    onSaveCallback,
    onCancel,
    drawnFeature,
}) => {
    const { phase1Id, phase2Id }: Params = useParams();
    const { fieldblocksFormFields } = useFieldblocksFormFields();
    const fieldblocksRegistryState = useFieldblocksRegistryState(
        phase1Id,
        phase2Id
    );

    const start = () => {
        fieldblocksRegistryState.start(undefined);
    };

    const finish = () => {
        fieldblocksRegistryState.finish();
    };

    const handleChange = (fieldId: string, value: FormFieldTypes) => {
        fieldblocksRegistryState.handleChange(fieldId, value);
    };

    const onSaveClick = () => {
        fieldblocksRegistryState
            .save()
            //@ts-ignore
            .then((fieldblockDocId: string | undefined) => {
                if (fieldblockDocId) {
                    onSaveCallback(fieldblockDocId);
                } else {
                    alert('Não foi possível registrar o talhão');
                }
            });
    };

    useEffect(() => {
        if (fieldblocksRegistryState.ableToStart) {
            start();
        }

        return () => {
            finish();
        };
    }, [fieldblocksRegistryState.ableToStart]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        // <Card className={classes.marginTop}>
        <Card>
            <CardContent>
                {fieldblocksRegistryState.state !== null &&
                    fieldblocksFormFields !== 'not_initialized' &&
                    fieldblocksFormFields.map((field: FormField) => {
                        if (field.fieldId === 'createdAt') {
                            return; //eslint-disable-line
                        }

                        if (fieldblocksRegistryState.state !== null) {
                            const stateField =
                                fieldblocksRegistryState.state[field.fieldId];

                            const onChange = (value: FormFieldTypes) =>
                                handleChange(field.fieldId, value);

                            return (
                                <Box marginBottom={1}>
                                    <RenderFormField
                                        key={field.fieldId}
                                        stateField={stateField}
                                        formField={field}
                                        onChange={onChange}
                                    />
                                </Box>
                            );
                        } else {
                            return <></>;
                        }
                    })}
            </CardContent>
            <CardActions>
                <Box flexGrow={1}>
                    <Button
                        onClick={onSaveClick}
                        variant="contained"
                        color="primary"
                        size="medium"
                        fullWidth
                    >
                        <FormattedMessage
                            id="app.save"
                            defaultMessage="Salvar"
                        />
                    </Button>
                </Box>
                <Button
                    onClick={onCancel}
                    variant="outlined"
                    color="primary"
                    size="medium"
                >
                    <FormattedMessage
                        id="app.cancel"
                        defaultMessage="Cancelar"
                    />
                </Button>
            </CardActions>
        </Card>
    );
};
