import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { Annotation } from '../../types/annotation';
import { dateToMilis } from '../../utils';

//Components
import AnnotationListItemSideBarComponent from './AnnotationListItemSideBarComponent';

//MaterialUI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
    })
);

export const AnnotationsListSidebar = () => {
    const classes = useStyles();
    const annotations = useSelector(
        (state: RootState) => state.annotations.annotations
    );

    const selectedDate = useSelector(
        (state: RootState) => state.timeline.selectedDate
    );

    const [filteredAnnotations, setFilteredAnnotations] = React.useState<
        Annotation[]
    >([]);

    React.useEffect(() => {
        if (annotations !== 'not_initialized' && selectedDate !== 'not_sure') {
            const filtered = annotations.filter(
                (a) => dateToMilis(a.date) === dateToMilis(selectedDate)
            );
            setFilteredAnnotations(filtered);
        }
    }, [annotations, selectedDate]); //eslint-disable-line

    return (
        <div className={classes.root}>
            {filteredAnnotations.map((a) => (
                <AnnotationListItemSideBarComponent
                    key={a.annotationDocId}
                    annotation={a}
                />
            ))}
        </div>
    );
};
