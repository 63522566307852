import 'date-fns';
import {
    Button,
    Card,
    CardContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    makeStyles,
    Radio,
    RadioGroup,
    TextField,
    useMediaQuery,
} from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent, useRef, useState } from 'react';
import { Upload } from '@aws-sdk/lib-storage';
import { uploadTiff } from './S3Upload';
import { Input } from '@material-ui/core';

import { uploadTileset } from '../../firebase/utils/uploadTileset';
import { Tileset } from '../../types/tileset';
import { useParams } from 'react-router';
import { ProcessWatcherProps } from './TiffUploadProcessWatcher';
import DatePicker from '../ui/DatePicker';
import { makeId } from '../../utils';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(0),
        marginTop: -theme.spacing(1),
        width: 280,
    },
    largerDialog: {
        width: 350,
    },
}));

type Props = {
    onSaveCallback: () => void;
    addWatcher: (watcher: ProcessWatcherProps) => void;
};

export const TiffUploader: FunctionComponent<Props> = ({
    onSaveCallback,
    addWatcher,
}) => {
    const classes = useStyles();
    const screenBiggerThan500 = useMediaQuery('(min-width:500px)');
    const { orgId, phase1Id, phase2Id }: any = useParams();
    const fileRef = useRef<any>();
    const [selectedDate, setSelectedDate] = useState<any>(new Date());
    const [mapType, setMapType] = useState('RGB');
    const [otherMapType, setOtherMapType] = useState('');

    const onSaveClick = async () => {
        const file = fileRef.current;

        if (!file) {
            return alert('Por favor, selecione um arquivo');
        }

        const finalType = mapType === 'Other' ? otherMapType : mapType;

        const tileset: Tileset = {
            originalFileName: file.name,
            fileType: file.type,
            createdAt: new Date(),
            mapType: finalType,
            mappedOn: selectedDate,
            bounds: [],
            center: [],
            fileSize: file.size,
            uploadId: '',
            tilesetId: '',
            isProcessingComplete: false,
            index: -1,
        };

        const tilesetrandomid = makeId(8);

        const tilesetDocId = await uploadTileset(
            orgId,
            phase1Id,
            phase2Id,
            tileset
        );

        const metadata = {
            orgId,
            phase1Id,
            phase2Id,
            tilesetDocId,
            tilesetrandomid,
        };

        console.log(metadata);

        const s3Upload: Upload = await uploadTiff(file, metadata);

        addWatcher({ s3Upload, file, tilesetDocId });
        onSaveCallback();
    };

    const onFileChange = (event: any) => {
        const file = event.target.files[0];

        fileRef.current = file;
    };

    const handleMapType = (event: ChangeEvent<HTMLInputElement>) => {
        setMapType(event.target.value);
    };

    return (
        <Card
            className={clsx(
                classes.root,
                screenBiggerThan500 && classes.largerDialog
            )}
        >
            <CardContent>
                <div>
                    <Input type="file" onChange={onFileChange} fullWidth />
                </div>
                <div>
                    <DatePicker
                        label={
                            <FormattedMessage
                                id="app.imageDate"
                                defaultMessage="Data da Imagem"
                            />
                        }
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        pickerId={'tiffUploaderDatePicker'}
                    />
                </div>
                <div>
                    <FormControl component="fieldset">
                        <FormLabel>
                            <FormattedMessage
                                id="app.mapType"
                                defaultMessage="Tipo de Mapa"
                            />
                        </FormLabel>
                        <RadioGroup
                            aria-label="language"
                            name="language1"
                            value={mapType}
                            onChange={handleMapType}
                        >
                            {[
                                'RGB',
                                'TGI',
                                'VARI',
                                'NDVI',
                                'NDRE',
                                'Other',
                            ].map((mapType: string) => (
                                <FormControlLabel
                                    key={mapType}
                                    value={mapType}
                                    control={<Radio />}
                                    label={mapType}
                                />
                            ))}
                        </RadioGroup>
                        {mapType === 'Other' && (
                            <TextField
                                label={
                                    <FormattedMessage
                                        id="app.otherMapType"
                                        defaultMessage="Outro Tipo de Mapa"
                                    />
                                }
                                value={otherMapType}
                                onChange={(e) =>
                                    setOtherMapType(e.target.value)
                                }
                                variant="outlined"
                                color="primary"
                                fullWidth
                            />
                        )}
                    </FormControl>
                </div>
                <div>
                    <Button
                        onClick={onSaveClick}
                        variant="contained"
                        fullWidth
                        color="primary"
                    >
                        <FormattedMessage
                            id="app.save"
                            defaultMessage="Salvar"
                        />
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};
