import { RootState } from '../store/configureStore';
import { useSelector } from 'react-redux';
import { useActions } from './useActions';
import { downloadUrls } from './../firebase/utils/downloadUrls';
import { useEffect } from 'react';

const emptyUrls: any = {};

export const useUrls = () => {
    const { setUrls } = useActions();
    const urls = useSelector((state: RootState) => state.organization.urls);

    useEffect(() => {
        if (urls === 'not_initialized') {
            downloadUrls(setUrls);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return { urls: urls !== 'not_initialized' ? urls : emptyUrls };
};
