import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
} from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
    loginError: any;
    setError: Function;
};

const useStyles = makeStyles((theme) => ({
    floatRight: {
        float: 'right',
    },
}));

export const AuthErrorDialog: FunctionComponent<Props> = ({
    loginError,
    setError,
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [errorMessageComponent, setErrorMessageComponent] = useState<any>(
        <div></div>
    );

    const handleClose = () => {
        setOpen(false);
        setError(undefined);
    };

    useEffect(() => {
        // if (loginError.code === 'auth/wrong-password') {}
        setErrorMessageComponent(
            <FormattedMessage
                id="app.authErrorMessage"
                defaultMessage="Desculpe, não foi possível realizar o login"
            />
        );
    }, [loginError]);

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                <FormattedMessage
                    id="app.authErrorDialogTitle"
                    defaultMessage="Houve um problema"
                />
            </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1">
                    {errorMessageComponent}
                </Typography>
                <Box marginBottom={2} paddingTop={2}>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        className={classes.floatRight}
                    >
                        <FormattedMessage
                            id="app.close"
                            defaultMessage="Voltar"
                        />
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
