import React, { FunctionComponent } from 'react';
// import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// import { FormattedMessage } from 'react-intl';
import { FieldblockCardRenameItem } from './FieldblockCardRenameItem';
import { Registry } from '../../formfy/types';
import { FieldblockCardDeleteItem } from './FieldblockCardDeleteItem';

type Props = {
    fieldblockRegistry: Registry;
};

export const FieldblockCardOptions: FunctionComponent<Props> = ({
    fieldblockRegistry,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<any>(null);

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
                aria-label="delete"
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <FieldblockCardRenameItem
                    fieldblockRegistry={fieldblockRegistry}
                    handleOptionsClose={handleClose}
                />
                <FieldblockCardDeleteItem
                    fieldblockRegistry={fieldblockRegistry}
                    handleOptionsClose={handleClose}
                />
            </Menu>
        </>
    );
};
