import { OrgUser } from './../../types/organization';
import { downloadFieldblocksRegistries } from './../firebase/utils/downloadFieldblocksRegistries';
import { Registry } from '../types/registry';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

//Typescript
import { RootState } from '../../store/configureStore';
import { useActions } from '../customHooks/useActions';

export const useFieldblocksRegistries = (
    phase1Id: string,
    phase2Id: string
) => {
    const { setFieldblocksRegistries } = useActions();

    const orgId: string = useSelector(
        (state: RootState) => state.organization.orgId
    );
    const orgUser: OrgUser | null | 'not_initialized' = useSelector(
        (state: RootState) => state.organization.orgUser
    );
    const fieldblocksRegistries: Registry[] | 'not_initialized' = useSelector(
        (state: RootState) => state.fieldblocks.registries
    );

    useEffect(() => {
        if (
            orgId &&
            orgUser &&
            orgUser !== 'not_initialized' &&
            fieldblocksRegistries === 'not_initialized' &&
            phase1Id &&
            phase2Id
        ) {
            downloadFieldblocksRegistries(
                orgId,
                phase1Id,
                phase2Id,
                setFieldblocksRegistries
            );
        }
    }, [orgId, orgUser]); // eslint-disable-line react-hooks/exhaustive-deps

    return { fieldblocksRegistries };
};

export type fieldblocksRegistriesType = {
    fieldblocksRegistries: Registry[];
};
