import React, { useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/Add';
import { PhotosListSidebar } from '../photos/PhotosListSidebar';

//MaterialUI
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import PhotoIcon from '@material-ui/icons/Photo';
import { RootState } from '../../store/configureStore';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { AddPhotoDialog } from '../photos/AddPhotoDialog';
import { IconButton, Menu, MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            padding: theme.spacing(0.25),
            backgroundColor: theme.palette.primary.main,
        },
        root: {
            flexGrow: 1,
        },
        button: {
            margin: theme.spacing(0),
        },
        moreHorizIcon: {
            color: 'white',
            fontSize: '2rem',
        },
        muiIconButton: {
            '&.MuiIconButton-root': {
                padding: 0,
            },
        },
    })
);

export const PhotosSidebarMenu = () => {
    const classes = useStyles();
    const accessLevel = useSelector((state: RootState) => {
        const orgUser = state.organization.orgUser;
        if (orgUser && orgUser !== 'not_initialized') {
            return orgUser.organizationAccessLevel;
        }
    });

    const selectedPhase2 = useSelector(
        (state: RootState) => state.phase2.selectedPhase2
    );

    const [inAddPhoto, setInAddPhoto] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const startAddPhoto = () => {
        setInAddPhoto(true);
        handleClose();
    };

    const openDriveFolder = () => {
        window.open(
            'https://drive.google.com/drive/folders/' +
                selectedPhase2?.gdriveFolderId,
            '_blanket'
        );
        handleClose();
    };

    return (
        <React.Fragment>
            <Paper variant="outlined" className={classes.paperRoot}>
                <div className={classes.root}>
                    <Grid container spacing={0}>
                        <Grid container item xs={12}>
                            <Grid item xs={1}>
                                <Box alignItems="center">
                                    <PhotoIcon style={{ color: 'white' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography
                                    variant="h6"
                                    style={{ color: 'white' }}
                                >
                                    <FormattedMessage
                                        id="app.photos"
                                        defaultMessage="Fotos"
                                    />
                                </Typography>
                            </Grid>
                            {accessLevel !== 'Guest' &&
                                accessLevel !== 'Temp' && (
                                    <Grid item xs={2}>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={handleOptionsClick}
                                                className={
                                                    classes.muiIconButton
                                                }
                                            >
                                                <MoreHorizIcon
                                                    className={
                                                        classes.moreHorizIcon
                                                    }
                                                />
                                            </IconButton>
                                        </Box>
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={open}
                                            onClose={handleClose}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: 48 * 4.5,
                                                    // width: '20ch',
                                                },
                                            }}
                                        >
                                            <MenuItem
                                                key={`viewFolder`}
                                                // selected={option === 'Pyxis'}
                                                onClick={openDriveFolder}
                                            >
                                                <OpenInNewIcon color="primary" />
                                                <FormattedMessage
                                                    id="app.viewFolder"
                                                    defaultMessage="Visualizar pasta"
                                                />
                                            </MenuItem>
                                            <MenuItem
                                                key={`addPhoto`}
                                                // selected={option === 'Pyxis'}
                                                onClick={startAddPhoto}
                                            >
                                                <AddIcon color="primary" />
                                                <FormattedMessage
                                                    id="app.addPhoto"
                                                    defaultMessage="Adicionar foto"
                                                />
                                            </MenuItem>
                                        </Menu>
                                        {/* <Button
                    fullWidth
                    variant='contained'
                    color='default'
                    size='small'
                    className={classes.button}
                    startIcon={<AddIcon />}
                    onClick={() => setInAddPhoto(true)}
                  >
                    <FormattedMessage id='app.add' defaultMessage='Adicionar' />
                  </Button> */}
                                    </Grid>
                                )}
                        </Grid>
                        <Grid item xs={12}>
                            <Box marginTop={1} marginBottom={1}>
                                <Divider />
                            </Box>
                        </Grid>

                        <Grid container item xs={12} spacing={0}>
                            <PhotosListSidebar />
                            <AddPhotoDialog
                                open={inAddPhoto}
                                setOpen={setInAddPhoto}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </React.Fragment>
    );
};
