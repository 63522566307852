export const startPolygonAnnotationsSource = (map: any) => {
    let emptyGeojson = {
        type: 'FeatureCollection',
        features: [],
    };

    map.addSource('polygonAnnotations', {
        type: 'geojson',
        data: emptyGeojson,
    });

    // Add a polygons layer
    map.addLayer({
        id: 'polygonAnnotations',
        type: 'line',
        source: 'polygonAnnotations',
        paint: {
            'line-color': 'blue',
            'line-width': 3,
            'line-opacity': 0.8,
        },
        //filter: ['==', '$type', 'Polygon'], // this assures db contains polygons
    });
};

export const startPolygonAnnotationsTitlesSource = (map: any) => {
    let emptyGeojson = {
        type: 'FeatureCollection',
        features: [],
    };

    map.addSource('polygonAnnotationsTitles', {
        type: 'geojson',
        data: emptyGeojson,
    });

    map.addLayer({
        id: 'polygonAnnotationsTitles',
        type: 'symbol',
        source: 'polygonAnnotationsTitles',
        layout: {
            'text-field': ['get', 'title'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            // "text-offset": [0, 0.6],
            // "text-anchor": "top",
            'text-size': 24,
        },
        paint: {
            'text-color': 'white',
        },
    });

    // Add a symbol layer
};

export const startLinestringsAnnotationsSource = (map: any) => {
    let emptyGeojson = {
        type: 'FeatureCollection',
        features: [],
    };

    map.addSource('linestringsAnnotations', {
        type: 'geojson',
        data: emptyGeojson,
    });

    // Add a polygons layer
    map.addLayer({
        id: 'linestringsAnnotations',
        type: 'line',
        source: 'linestringsAnnotations',
        paint: {
            'line-color': 'blue',
            'line-width': 3,
            'line-opacity': 1.0,
        },
        //filter: ['==', '$type', 'Polygon'], // this assures db contains polygons
    });
};

export const startLinestringsAnnotationsTitlesSource = (map: any) => {
    let emptyGeojson = {
        type: 'FeatureCollection',
        features: [],
    };

    map.addSource('linestringsAnnotationsTitles', {
        type: 'geojson',
        data: emptyGeojson,
    });

    map.addLayer({
        id: 'linestringsAnnotationsTitles',
        type: 'symbol',
        source: 'linestringsAnnotationsTitles',
        layout: {
            'text-field': ['get', 'title'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            // "text-offset": [0, 0.6],
            // "text-anchor": "top",
            'text-size': 24,
        },
        paint: {
            'text-color': 'white',
        },
    });

    // Add a symbol layer
};

export const startPointsAnnotationsSource = (map: any) => {
    //pointsAnnotations
    let emptyGeojson = {
        type: 'FeatureCollection',
        features: [],
    };

    map.addSource('pointsAnnotations', {
        type: 'geojson',
        data: emptyGeojson,
    });

    map.addLayer({
        id: 'pointsAnnotations',
        type: 'symbol',
        source: 'pointsAnnotations',
        layout: {
            'text-field': ['get', 'title'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            // "text-offset": [0, 0.6],
            // "text-anchor": "top",
            'text-size': 24,
        },
        paint: {
            'text-color': 'white',
        },
    });
};
