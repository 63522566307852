import React, { useContext, useEffect, useState } from 'react';
import HeaderSidebarMenu from '../sidebarMenu/HeaderSidebarMenu';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { Box, Button, Typography } from '@material-ui/core';
import { Phase2PageContext } from '../../contexts/Phase2PageContext';
import { getLatestCreatedDrawing } from '../../utils/mapbox/getLatestCreatedDrawing';
import * as turf from '@turf/turf';
import { getElevation } from './getElevation';
import mapboxgl from 'mapbox-gl';
import { FieldblocksForm } from './FieldblocksForm';
import { useParams } from 'react-router';
import ListOfFieldblocksRegistries from './ListOfFieldblocksRegistries';
import { storeFieldblockFeature } from '../../formfy/firebase/utils/storeFieldblockFeature';
import { FormattedMessage } from 'react-intl';
import { CustomMapboxGeocoder } from './CustomMapboxGeocoder';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divList: {
            width: 350,
        },
        disabled: {
            pointerEvents: 'none',
            opacity: 0.5,
        },
    })
);

type Params = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

type Props = {
    setisDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isDrawerOpen: boolean;
};

const FieldBlocksSidebar = ({ isDrawerOpen, setisDrawerOpen }: Props) => {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id }: Params = useParams();
    const [inAddFieldBlock, setInAddFieldBlock] = useState(false);
    const [inDraw, setInDraw] = useState(false);
    const [hasDrawed, setHasDrawed] = useState(false);
    const [isOnDrawCreateStarted, setIsOnDrawCreateStarted] = useState(false);
    const [drawnFeature, setDrawnFeature] = useState<any>(undefined);
    const [fbArea, setArea] = useState<any>(null);
    const [fbElevation, setElevation] = useState(null);
    console.log('fbElevation=>', fbElevation);
    const { draw, mapClass } = useContext(Phase2PageContext);
    const organizationAccessToken = useSelector((state: RootState) => {
        return state.organization.publicInfo.mapboxDefaultPublicToken;
    });
    const [inShowForm, setInShowForm] = useState(false);

    const toggleDrawer =
        (openBoolean: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            setisDrawerOpen(openBoolean);
        };

    const openAdd = () => {
        setInAddFieldBlock(true);
    };

    const closeAdd = () => {
        // const draw = getDraw();
        draw.deleteAll();
        setInDraw(false);
        setHasDrawed(false);
        setInAddFieldBlock(false);
        setInShowForm(false);
        setArea(null);
        setElevation(null);
    };

    const openFielblocksForm = () => {
        setInShowForm(true);
    };

    const closeFielblocksForm = () => {
        setInShowForm(false);
    };

    const startDraw = () => {
        // const draw = getDraw();
        draw.changeMode('draw_polygon');
        setInDraw(true);
    };

    const cancelDraw = () => {
        // const draw = getDraw();
        draw.changeMode('simple_select');
        draw.trash();
        setInDraw(false);
    };

    const onFieldblocksFormSave = (fieldblockId: string) => {
        storeFieldblockFeature(orgId, phase1Id, phase2Id, fieldblockId, {
            ...drawnFeature,
            area: fbArea,
        }).then(() => {
            closeAdd();
        });
    };

    const bufferArea = () => {
        const feature = getLatestCreatedDrawing(draw);
        if (!feature) return alert('Error in AddFieldBlock! No feature!');

        const data = turf.polygon(feature.geometry.coordinates);
        var area = turf.area(data);
        // restrict to area to 2 decimal points
        var hectares = area / 10000;
        var rounded_hectares = Math.round(hectares * 100) / 100;
        // console.log('rounded_area', rounded_hectares);
        setArea(rounded_hectares);
    };

    const bufferAltitude = () => {
        const feature = getLatestCreatedDrawing(draw);
        var polygon = turf.polygon(feature.geometry.coordinates);
        const centerOfMass = turf.centerOfMass(polygon);
        // console.log(centerOfMass);
        const [lng, lat] = centerOfMass.geometry.coordinates;
        mapboxgl.accessToken = organizationAccessToken;
        getElevation(lng, lat, (elevation: any) => {
            // console.log('elevation', elevation);
            setElevation(elevation);
        });
    };

    const extractFeature = () => {
        bufferArea();
        bufferAltitude();

        const features = draw.getAll().features;
        if (!(features.length > 0)) {
            alert('Erro ao processar o desenho!');
            closeAdd();
        }

        setDrawnFeature(features[0]);
    };

    const onDrawCreate = () => {
        // console.log('onDrawCreate');
        bufferArea();
        bufferAltitude();
        extractFeature();

        setInDraw(false);
        setHasDrawed(true);
    };

    const onDrawUpdate = () => {
        // console.log('onDrawUpdate');

        extractFeature();
    };

    useEffect(() => {
        if (inDraw) {
            // const map = getMap();
            const map = mapClass.map;
            if (map && !isOnDrawCreateStarted) {
                map.on('draw.create', (e: any) => onDrawCreate());
                map.on('draw.update', (e: any) => onDrawUpdate());
                setIsOnDrawCreateStarted(true);
            }
        }
    }, [inDraw]); // eslint-disable-line

    return (
        <React.Fragment>
            <Drawer
                variant="persistent"
                anchor="right"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
            >
                <div className={classes.divList}>
                    <HeaderSidebarMenu toggleDrawer={toggleDrawer} />

                    {/* <ReportsSession /> */}

                    <CustomMapboxGeocoder />

                    {inAddFieldBlock && (
                        <>
                            <Box padding={1}>
                                <Typography variant="h5">
                                    Registrar um Talhão
                                </Typography>
                                <Typography variant="subtitle1">
                                    <FormattedMessage
                                        id="app.firstDrawAMap"
                                        defaultMessage="1º Desenhe um talhão no Mapa."
                                    />
                                </Typography>
                                <Typography variant="subtitle1">
                                    <FormattedMessage
                                        id="app.secondFillForm"
                                        defaultMessage="2º Preencha as informações do talhão no formulário."
                                    />
                                </Typography>
                            </Box>
                            <Box padding={0.5}>
                                {!inDraw ? (
                                    <div
                                        className={
                                            hasDrawed ? classes.disabled : ''
                                        }
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            onClick={startDraw}
                                        >
                                            <FormattedMessage
                                                id="app.drawFieldblock"
                                                defaultMessage="Desenhar talhão"
                                            />
                                        </Button>
                                    </div>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        onClick={cancelDraw}
                                    >
                                        <FormattedMessage
                                            id="app.cancelDraw"
                                            defaultMessage="Cancelar desenho"
                                        />
                                    </Button>
                                )}
                            </Box>
                            {!inShowForm && (
                                <Box padding={0.5}>
                                    <div
                                        className={
                                            !hasDrawed ? classes.disabled : ''
                                        }
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            onClick={openFielblocksForm}
                                        >
                                            <FormattedMessage
                                                id="app.fillInformations"
                                                defaultMessage="Preencher informações"
                                            />
                                        </Button>
                                    </div>
                                </Box>
                            )}
                            {inShowForm && (
                                <Box padding={1}>
                                    <FieldblocksForm
                                        onSaveCallback={onFieldblocksFormSave}
                                        onCancel={closeFielblocksForm}
                                        drawnFeature={drawnFeature}
                                    />
                                </Box>
                            )}
                            <Box padding={0.5}>
                                <div
                                    className={
                                        !inAddFieldBlock ? classes.disabled : ''
                                    }
                                >
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        onClick={closeAdd}
                                    >
                                        <FormattedMessage
                                            id="app.discardRegistry"
                                            defaultMessage="Descartar registro"
                                        />
                                    </Button>
                                </div>
                            </Box>
                        </>
                    )}

                    {!inAddFieldBlock && (
                        <Box padding={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                onClick={openAdd}
                            >
                                <FormattedMessage
                                    id="app.addFieldblock"
                                    defaultMessage="Adicionar talhão"
                                />
                            </Button>
                        </Box>
                    )}

                    <Box padding={0.5}>
                        <ListOfFieldblocksRegistries />
                    </Box>
                </div>
            </Drawer>
        </React.Fragment>
    );
};

export default FieldBlocksSidebar;
