import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: 'AIzaSyCfaeVhWt27JMDhx1oN_l-vBAFk4kMMHgQ',
    authDomain: 'novo-coopasvtech.firebaseapp.com',
    projectId: 'novo-coopasvtech',
    storageBucket: 'novo-coopasvtech.appspot.com',
    messagingSenderId: '184885367982',
    appId: '1:184885367982:web:ea034ee85381e346a1493f',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const Timestamp = firebase.firestore.Timestamp;
export const isTimestamp = (value: any) => value instanceof Timestamp;

export type FirebaseQuery =
    firebase.firestore.Query<firebase.firestore.DocumentData>;

export type CollectionReference =
    firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

export default db;
