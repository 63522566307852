import { makeTitlesFeatures } from './makeTitlesFeatures';
import { Feature } from './../../types/feature';

const startFieldBlocksSource = (map: any) => {
    let emptyGeojson = {
        type: 'FeatureCollection',
        features: [],
    };

    map.addSource('fieldblocks', {
        type: 'geojson',
        data: emptyGeojson,
    });

    // Add a polygons layer
    map.addLayer({
        id: 'fieldblocks',
        type: 'line',
        source: 'fieldblocks',
        paint: {
            'line-color': 'yellow',
            'line-width': 4,
            'line-opacity': 0.85,
        },
        //filter: ['==', '$type', 'Polygon'], // this assures db contains polygons
    });
};

const startFieldBlocksTitlesSource = (map: any) => {
    let emptyGeojson = {
        type: 'FeatureCollection',
        features: [],
    };

    map.addSource('fieldblocksTitles', {
        type: 'geojson',
        data: emptyGeojson,
    });

    // Add a polygons layer
    map.addLayer({
        id: 'fieldblocksTitles',
        type: 'symbol',
        source: 'fieldblocksTitles',
        layout: {
            'text-field': ['get', 'title'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            // "text-offset": [0, 0.6],
            // "text-anchor": "top",
            'text-size': 24,
        },
        paint: {
            'text-color': 'white',
        },
    });
};

export const setFieldblocksLayer = (map: any, features: Feature[]) => {
    // console.log('in setFieldblocksLayer');
    const parsedFeatures = features.map((feature) => {
        const coordinates = JSON.parse(feature.geometry.coordinates);

        let newFeature = {
            ...feature,
            geometry: { ...feature.geometry, coordinates },
        };

        return newFeature;
    });

    let geojson = {
        type: 'FeatureCollection',
        features: parsedFeatures,
    };

    const fieldblocksSource = map.getSource('fieldblocks');

    if (!fieldblocksSource) {
        startFieldBlocksSource(map);
        startFieldBlocksTitlesSource(map);
    }

    map.getSource('fieldblocks').setData(geojson);

    const titleFeatures = makeTitlesFeatures(features);
    map.getSource('fieldblocksTitles').setData({
        type: 'FeatureCollection',
        features: titleFeatures,
    });
};
