import { Feature } from './../../types/feature';
import { setFeaturesType } from './../../formfy/features/fieldblocks/fieldblocksSlice';
import { db } from './../firebase';
// import { OrgUser } from './../../../types/organization';

// export const isTimestamp = (value: any) => value instanceof Timestamp;

export const downloadFieldblocksFeatures = (
    orgId: string,
    // orgUser: OrgUser,
    phase1Id: string,
    phase2Id: string,
    setFeatures: setFeaturesType
) => {
    db.collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('fieldblocks')
        .onSnapshot((snapshot) => {
            let features: Feature[] = [];

            snapshot.forEach((doc) => {
                const data: any = doc.data();
                if (data.feature && !data.isDeleted) {
                    const feature: Feature = data.feature;
                    feature.properties.title = data.registry.name;
                    feature.id = doc.id;
                    features.push(feature);
                }
            });

            setFeatures(features);
        });
};
