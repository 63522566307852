import React, { FunctionComponent } from 'react';
import { Registry } from '../../formfy/types';
import { FormattedDate } from 'react-intl';

//MaterialUI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { FieldblockCardOptions } from './FieldblockCardOptions';
import { useFieldblocksFormFields } from '../../formfy/API/useFieldblocksFormFields';
import { RenderRegistryFields } from '../ui/RenderRegistryFields';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1, 1, 1) /* top | horizontal | bottom */,
        },
        gridPaperContent: {
            flexGrow: 1,
        },
        gridItemMoreVertIcon: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        paperNoProjects: {
            padding: theme.spacing(1),
        },
        flex: {
            display: 'flex',
        },
        mousePointer: {
            cursor: 'pointer',
        },
    })
);

type Props = {
    fieldblockRegistry: Registry;
    fieldblocksFieldIdsToNames: { [key: string]: string };
    onFieldblockClick: () => void;
};

export const FieldblockCard: FunctionComponent<Props> = ({
    fieldblockRegistry,
    onFieldblockClick,
}) => {
    const classes = useStyles();

    // console.log(fieldblockRegistry);

    const { fieldblocksFieldIdsToNames, fieldblocksFieldIdsToTypes } =
        useFieldblocksFormFields();

    return (
        <Paper elevation={1} className={classes.paper}>
            <Grid
                container
                className={classes.gridPaperContent}
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid
                            item
                            xs={7}
                            onClick={onFieldblockClick}
                            className={classes.mousePointer}
                        >
                            <Box paddingRight={1}>
                                <Typography variant="h6" component="h2">
                                    {fieldblockRegistry.name}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={4}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <DateRangeIcon fontSize="small" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        {/* {`${tileset.mappedOn.toLocaleDateString()}`} */}
                                        <FormattedDate
                                            //@ts-ignore
                                            value={fieldblockRegistry.createdAt}
                                            year="numeric"
                                            month="numeric"
                                            day="numeric"
                                            //weekday="long"
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={1}>
                            {/* <DateRangeIcon /> */}
                            <FieldblockCardOptions
                                fieldblockRegistry={fieldblockRegistry}
                            />
                        </Grid>
                    </Grid>
                    <Box paddingBottom={2}>
                        <Divider />
                    </Box>
                </Grid>

                <Grid item>
                    <Typography variant="body2" display="block">
                        {fieldblockRegistry.area &&
                            `Área: ${fieldblockRegistry.area} ha`}
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    zeroMinWidth
                    onClick={onFieldblockClick}
                    className={classes.mousePointer}
                >
                    <RenderRegistryFields
                        registry={fieldblockRegistry}
                        idsToNames={fieldblocksFieldIdsToNames}
                        idsToTypes={fieldblocksFieldIdsToTypes}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};
