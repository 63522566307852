// @ts-nocheck
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormFieldTypes, Registry } from '../../formfy/types';

// import DatePicker from '../../formfy/components/DatePicker';
import { Typography } from '@material-ui/core';
import { FormattedDate } from 'react-intl';

type Props = {
    registry: Registry;
    idsToNames: { [key: string]: string };
    idsToTypes: { [key: string]: string };
};

type ShortField = {
    name: string;
    type: string;
    value: FormFieldTypes | null;
};

export const RenderRegistryFields: FunctionComponent<Props> = ({
    registry,
    idsToNames,
    idsToTypes,
}) => {
    const [fieldsArray, setFieldsArray] = useState<ShortField[]>([]);

    useEffect(() => {
        // Gambiarra pra corrigir o problema em: https://trello.com/c/hYrKuoNp/113-fieldblocks-informa%C3%A7%C3%B5es

        const keys = [
            'IEqJsDibRhZPaOVU8IhM',
            'Rj2TTf4hvIoiqvxY3VyL',
            'rEkquKIcTnQ661Jp2y5U',
            'IwM7gJ1OSWrqNCwQSVk3',
            'XKKeUrLm9eJkYPDTvM65',
            'B91zOcR2gHTai8J3VMGw',
        ];

        const fieldsArray = keys.map((key) => {
            return {
                name: idsToNames[key],
                type: idsToTypes[key],
                value: registry[key],
            };
        });

        setFieldsArray(fieldsArray);
    }, [registry, idsToNames, idsToTypes]);

    return (
        <div>{fieldsArray.map((shortField) => parseRender(shortField))}</div>
    );
};

const parseRender = (shortField: ShortField) => {
    if (!shortField.value) return <></>;

    switch (shortField.type) {
        case 'Data':
        case 'Data e Hora':
            return (
                <Typography variant="body1" gutterBottom>
                    {/* {`${tileset.mappedOn.toLocaleDateString()}`} */}
                    <strong>{shortField.name} </strong>
                    {shortField.name === 'Ano do plantio' ? (
                        new Date(shortField.value).getFullYear()
                    ) : (
                        <FormattedDate
                            value={shortField.value}
                            year="numeric"
                            month="short"
                            day="numeric"
                            //weekday="long"
                        />
                    )}
                </Typography>
            );

        case 'Seleção de Lista':
        case 'Texto Curto':
        case 'Texto Longo':
        case 'Email':
        case 'Número de Telefone':
        case 'CPF':
        case 'CNPJ':
            return (
                <Typography variant="body1">
                    <strong>{shortField.name}</strong>{' '}
                    {`${shortField.value ? shortField.value : '-'}`}
                </Typography>
            );

        case 'CheckBox':
            let text = '-';
            if (shortField.value) {
                text =
                    shortField.value.length === 0
                        ? '-'
                        : shortField.value.join(' | ');
            }
            return (
                <>
                    <Typography variant="body1">
                        <strong>{shortField.name} </strong>
                        {text}
                    </Typography>
                </>
                // <ChecklistFormField field={field} value={value} onChange={onChange} />
            );

        // case 'Texto Longo':
        //   return (
        //     <TextField
        //       label={field.name}
        //       multiline
        //       rows={4}
        //       value={value || ''}
        //       onChange={(e) => onChange(e.target.value)}
        //       variant="filled"
        //       fullWidth
        //     />
        //   );

        // case 'Email':
        //   return <EmailFormField field={field} value={value} onChange={onChange} />;

        // case 'Número de Telefone':
        //   return <PhoneFormField field={field} value={value} onChange={onChange} />;

        // case 'CPF':
        //   return <CPFFormField field={field} value={value} onChange={onChange} />;

        // case 'CNPJ':
        //   return <CNPJFormField field={field} value={value} onChange={onChange} />;

        default:
            return <div>default, probably not implemented type</div>;
    }
};
