import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router';

//Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';

//Types
import { Phase1Info } from '../../formfy/types/phase1';
import { Phase2Info } from '../../formfy/types/phase2';

//MaterialUI
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { FormattedMessage } from 'react-intl';

interface ToggleDrawer {
    toggleDrawer: (
        openBoolean: boolean
    ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

type Params = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

export default function HeaderSidebarMenu({ toggleDrawer }: ToggleDrawer) {
    const { orgId, phase1Id }: Params = useParams();
    const phase1Info: Phase1Info | null = useSelector((state: RootState) => {
        return state.phase1.selectedPhase1;
    });

    const phase2Info: Phase2Info | null = useSelector((state: RootState) => {
        return state.phase2.selectedPhase2;
    });

    return (
        <React.Fragment>
            <Box padding={0.25}>
                <Button
                    // className={classes.divHideMenuButton}
                    variant="contained"
                    color="primary"
                    // startIcon={<MenuIcon />}
                    aria-label="close menu"
                    size="large"
                    onClick={toggleDrawer(false)}
                    fullWidth
                >
                    <FormattedMessage
                        id="app.hide-sidebar-menu-nutton"
                        defaultMessage="OCULTAR MENU LATERAL"
                    />
                </Button>
            </Box>

            <Box paddingBottom={0.5} paddingTop={0.25}>
                <Divider />
            </Box>
            <Box paddingX={0.25}>
                <Link
                    color="textPrimary"
                    component={RouterLink}
                    to={`/organizationId/${orgId}/phase1Id/${phase1Id}`}
                >
                    <Typography variant="subtitle2" align="center" noWrap>
                        {phase1Info?.name}
                    </Typography>
                </Link>

                {/* <Link
          color="textPrimary"
          component={RouterLink}
          to={`/organizationId/${orgId}/phase1Id/${phase1Id}/phase2Id/${phase2Id}`}
        > */}
                <Typography variant="h6" align="center" noWrap>
                    {phase2Info?.name}
                </Typography>
                {/* </Link> */}
            </Box>

            {/* <Typography variant="h6" align="center">
        Fazenda Água Limpa
      </Typography> */}
            <Box paddingY={0.5}>
                <Divider />
            </Box>
        </React.Fragment>
    );
}
