import { Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export const ErrorPage = () => {
    return (
        <div>
            <Typography variant="h2">
                <FormattedMessage
                    id="app.pageNotFound"
                    defaultMessage="Página não encontrada"
                />
                !
            </Typography>
            <Link to="/" />
        </div>
    );
};
