import { isTimestamp } from './downloadTilesets';
import { PhotoData } from './../../types/photoData';
import { setPhotosType } from './../../features/photos/photosSlice';
import { db } from '../firebase';

export const downloadPhotos = (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    setPhotos: setPhotosType
) => {
    return db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('photos')
        .onSnapshot((querySnapshot) => {
            const photos: PhotoData[] = [];

            querySnapshot.forEach((doc: any) => {
                const data: any = doc.data();

                Object.entries(data).forEach((e) => {
                    if (isTimestamp(e[1])) {
                        // @ts-ignore
                        data[e[0]] = e[1].toDate();
                    }
                });

                data.photoDocId = doc.id;
                photos.push(data);
            });

            setPhotos(photos);
        });
};
