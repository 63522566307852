import * as turf from '@turf/turf';

//Types
import { Annotation } from '../../types/annotation';

export const CalculateCenterCoordinates = function (
    //coordsString: number[][],
    annotation: Annotation
) {
    const coordsString = annotation.feature.geometry.coordinates;
    const coordsArray = JSON.parse(`${coordsString}`);
    const annotationType = annotation.feature.geometry.type;

    if (annotationType === 'Polygon') {
        const polygon = turf.polygon([coordsArray[0]]);
        const centroid = turf.centerOfMass(polygon); //Centroid of a polygon
        const centroidCoords = centroid.geometry.coordinates;

        //console.log(centroidCoords, 'centroidCoords');

        return centroidCoords;
    } else if (annotationType === 'LineString') {
        if (coordsArray.length % 2 === 1) {
            let midPoint = coordsArray[Math.floor(coordsArray.length / 2)];
            return midPoint;
        } else {
            let midPoint1 = coordsArray[coordsArray.length / 2 - 1];
            let midPoint2 = coordsArray[coordsArray.length / 2];

            let midPoint3 = midPoint1.map(function (n: any, i: any) {
                return (n + midPoint2[i]) / 2;
            });

            return midPoint3;
        }
    } else if (annotationType === 'Point') {
        return coordsArray;
    }

    //     if (annotation.annotationType === 'POLYGON') {
    //       const polygon = turf.polygon([coordsArray[0]]);
    //       const centroid = turf.centerOfMass(polygon); //Centroid of a polygon
    //       const centroidCoords = centroid.geometry.coordinates;
    //       return centroidCoords;
    //     }
    //   if (annotation.annotationType === "LINE") {
    //        const lastCoord = coordsArray[0][(coordsArray[0].length - 1)];
    //        return lastCoord;
    //   }
};
