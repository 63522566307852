import {
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    TextField,
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router';
//import React, { FunctionComponent } from 'react';
// import { LinkData } from './linksSlice';
import { LinkData } from '../../types/link';
import { updateLink } from '../../firebase/utils/updateLink';
import { deleteLink } from '../../firebase/utils/deleteLink';
import DatePicker from '../ui/DatePicker';
import { FormattedMessage } from 'react-intl';

type Props = {
    linkData: LinkData;
};

type Params = {
    orgId: string;
    phase1Id: string;
    phase2Id: string;
};

export const LinkCard: FunctionComponent<Props> = ({ linkData }) => {
    const { orgId, phase1Id, phase2Id } = useParams<Params>();
    const [inOptions, setInOptions] = useState(false);
    const [inRename, setInRename] = useState(false);
    const [inRemove, setInRemove] = useState(false);
    const [inRedate, setInRedate] = useState(false);
    const [newName, setNewName] = useState(linkData.name);
    const [newDate, setNewDate] = useState(linkData.date);

    const reset = () => {
        setNewName('');
        setInOptions(false);
        setInRename(false);
        setInRemove(false);
    };

    const onRemove = () => {
        deleteLink(orgId, phase1Id, phase2Id, linkData.linkDocId);
    };

    const onNameSave = () => {
        updateLink(orgId, phase1Id, phase2Id, linkData.linkDocId, {
            name: newName,
        });
    };

    const onDateSave = () => {
        updateLink(orgId, phase1Id, phase2Id, linkData.linkDocId, {
            date: newDate,
        });
    };

    return (
        <div>
            <Card>
                <CardContent>
                    <div>
                        <strong>Name: {`${linkData.name}`}</strong>
                    </div>
                    <div>
                        <strong>Link: {`${linkData.link}`}</strong>
                    </div>
                    <div>
                        <strong>Date: {`${linkData.date.toString()}`}</strong>
                    </div>
                </CardContent>
                {inOptions && (
                    <Card>
                        <CardActions>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setInRename(true)}
                            >
                                <FormattedMessage
                                    id="app.rename"
                                    defaultMessage="Renomear"
                                />
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setInRedate(true)}
                            >
                                <FormattedMessage
                                    id="app.changeDate"
                                    defaultMessage="Mudar Data"
                                />
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setInRemove(true)}
                            >
                                <FormattedMessage
                                    id="app.remove"
                                    defaultMessage="Remover"
                                />
                            </Button>
                        </CardActions>
                    </Card>
                )}
                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setInOptions(!inOptions)}
                    >
                        <FormattedMessage
                            id="app.options"
                            defaultMessage="Opções"
                        />
                    </Button>
                </CardActions>
            </Card>
            {inRemove && (
                <Dialog open={inRemove} onClose={() => setInRemove(false)}>
                    <FormattedMessage
                        id="app.removeLink"
                        defaultMessage="Remover Link?"
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onRemove}
                    >
                        <FormattedMessage
                            id="app.remove"
                            defaultMessage="Remover"
                        />
                    </Button>
                    <Button variant="contained" color="primary" onClick={reset}>
                        <FormattedMessage
                            id="app.cancel"
                            defaultMessage="Cancelar"
                        />
                    </Button>
                </Dialog>
            )}
            {inRename && (
                <Dialog open={inRename} onClose={() => setInRename(false)}>
                    <TextField
                        id="filled-basic"
                        label="Novo Nome"
                        variant="filled"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onNameSave}
                    >
                        <FormattedMessage
                            id="app.save"
                            defaultMessage="Salvar"
                        />
                    </Button>
                </Dialog>
            )}
            {inRedate && (
                <Dialog open={inRedate} onClose={() => setInRedate(false)}>
                    <DatePicker
                        label={'Date'}
                        selectedDate={newDate}
                        setSelectedDate={setNewDate}
                        pickerId={'linksDatePicker'}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onDateSave}
                    >
                        <FormattedMessage
                            id="app.save"
                            defaultMessage="Salvar"
                        />
                    </Button>
                </Dialog>
            )}
        </div>
    );
};
