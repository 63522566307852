// export const imageGPSToPosition = (
//   GPSLatitude: number[],
//   GPSLongitude: number[],
//   GPSLatitudeRef: 'N' | 'S',
//   GPSLongitudeRef: 'W' | 'E'
//) => {
export const imageGPSToPosition = (fileMetadata: any) => {
    const { GPSLatitude, GPSLongitude, GPSLatitudeRef, GPSLongitudeRef } =
        fileMetadata;

    var lat = GPSLatitude[0] + GPSLatitude[1] / 60 + GPSLatitude[2] / 3600;
    var lng = GPSLongitude[0] + GPSLongitude[1] / 60 + GPSLongitude[2] / 3600;

    if (GPSLatitudeRef === 'S') {
        lat = lat * -1;
    }
    if (GPSLongitudeRef === 'W') {
        lng = lng * -1;
    }

    return { lat, lng };
};
