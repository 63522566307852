import React from 'react';

//Components
import NavbarOrganization from '../features/navbars/NavbarOrganization';

//Formfy
import { Phase1Form } from '../features/phase1/Phase1Form';
import { usePhase1FormFields } from '../formfy/API/usePhase1FormFields';
import { usePhase1RegistryState } from '../formfy/API/usePhase1RegistryState';

//Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(8, 0, 4) /* top | horizontal | bottom */,
        },
        paper: {
            padding: theme.spacing(2, 2, 2) /* top | horizontal | bottom */,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            paddingTop: theme.spacing(2),
        },
    })
);

export default function CreateNewPhase1() {
    const classes = useStyles();

    const { phase1FormFields } = usePhase1FormFields();

    const phase1RegistryState = usePhase1RegistryState();

    return (
        <React.Fragment>
            <CssBaseline />
            <NavbarOrganization />

            <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.root}
            >
                <Grid item xs={10} sm={8} md={6} lg={5} xl={4}>
                    <div>
                        <Phase1Form
                            phase1RegistryState={phase1RegistryState}
                            phase1FormFields={phase1FormFields}
                        />
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
