import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { addAuthStateListener } from '../firebase/utils/addAuthStateListener';
import { FFAppRouter } from '../formfy/routers/FFAppRouter';
import { useActions } from '../hooks/useActions';
import MembersManagementPage from 'pages/MembersManagementPage';
import { TestUploadPage } from 'pages/TestUploadPage';
import { GeneralRoute } from './GeneralRoute';
import { OrganizationRoute } from './OrganizationRoute';
import { SharingRoute } from './SharingRoute';

//Pages
import { UserDashboardPage } from 'pages/UserDashboardPage';
import { OrganizationPage } from 'pages/OrganizationPage';
import { Phase1Page } from 'pages/Phase1Page';
import { Phase2Page } from 'pages/Phase2Page';
import NotFoundPage from 'pages/NotFoundPage';
import { ManageTilesetsPage } from 'pages/ManageTilesetsPage';
import { ManageLinksPage } from 'pages/ManageLinksPage';
import { ManageAnnotationsPage } from 'pages/ManageAnnotationsPage';
import CreateNewPhase2 from 'pages/CreateNewPhase2';
import CreateNewPhase1 from 'pages/CreateNewPhase1';
import Phase2ManageMembersPage from 'pages/Phase2ManageMembersPage';
import SoilAnalysisManageLinksPage from 'pages/SoilAnalysisManageLinksPage';

// Internationalization
import { IntlProvider } from 'react-intl';
import messages_pt from 'translations/pt.json';
import messages_en from 'translations/en.json';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';

const messages = {
    pt: messages_pt,
    en: messages_en,
};

export const AppRouter = () => {
    const { setUserId } = useActions();

    // const language = navigator.language.split(/[-_]/)[0]; // language without region code

    useEffect(() => {
        addAuthStateListener(setUserId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const globalLocale: string = useSelector((state: RootState) => {
        return state.translations.locale;
    });
    console.log('Boa pai', globalLocale);

    return (
        <IntlProvider
            defaultLocale="pt-BR"
            locale={globalLocale}
            //@ts-ignore
            messages={messages[globalLocale]}
        >
            <Router>
                <Switch>
                    <OrganizationRoute
                        path="/organizationId/:orgId/formfy"
                        component={FFAppRouter}
                    />
                    <OrganizationRoute
                        path="/organizationId/:orgId"
                        component={OrganizationPage}
                        exact
                    />
                    <OrganizationRoute
                        path="/organizationId/:orgId/members"
                        component={MembersManagementPage}
                        exact
                    />
                    <OrganizationRoute
                        path="/organizationId/:orgId/create-new-phase1"
                        component={CreateNewPhase1}
                        exact
                    />
                    <OrganizationRoute
                        path="/organizationId/:orgId/phase1Id/:phase1Id"
                        component={Phase1Page}
                        exact
                    />
                    <OrganizationRoute
                        path="/organizationId/:orgId/phase1Id/:phase1Id/create-new-phase2"
                        component={CreateNewPhase2}
                        exact
                    />
                    <OrganizationRoute
                        path="/organizationId/:orgId/phase1Id/:phase1Id/phase2Id/:phase2Id"
                        component={Phase2Page}
                        exact
                    />
                    <OrganizationRoute
                        path="/organizationId/:orgId/phase1Id/:phase1Id/phase2Id/:phase2Id/uploadTest"
                        component={TestUploadPage}
                        exact
                    />
                    <OrganizationRoute
                        path="/organizationId/:orgId/phase1Id/:phase1Id/phase2Id/:phase2Id/manage-maps"
                        component={ManageTilesetsPage}
                        exact
                    />
                    <OrganizationRoute
                        path="/organizationId/:orgId/phase1Id/:phase1Id/phase2Id/:phase2Id/manage-links"
                        component={ManageLinksPage}
                        exact
                    />
                    <OrganizationRoute
                        path="/organizationId/:orgId/phase1Id/:phase1Id/phase2Id/:phase2Id/manageAnnotations"
                        component={ManageAnnotationsPage}
                        exact
                    />
                    <OrganizationRoute
                        path="/organizationId/:orgId/phase1Id/:phase1Id/phase2Id/:phase2Id/manageMembers"
                        component={Phase2ManageMembersPage}
                        exact
                    />
                    <SharingRoute
                        path="/organizationId/:orgId/phase1Id/:phase1Id/phase2Id/:phase2Id/shareLink/:shareLink"
                        //component={Phase2Page}
                        exact
                    />
                    <OrganizationRoute
                        path="/organizationId/:orgId/phase1Id/:phase1Id/phase2Id/:phase2Id/soil-analysis-manage-links"
                        component={SoilAnalysisManageLinksPage}
                        exact
                    />
                    <GeneralRoute
                        path="/"
                        component={UserDashboardPage}
                        exact
                    />
                    <Route component={NotFoundPage} />
                </Switch>
            </Router>
        </IntlProvider>
    );
};

export default AppRouter;
