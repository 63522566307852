//import { isTimestamp } from './downloadTilesets'
import { db } from '../firebase';

export const validateShareLink = (
    orgId: string,
    phase1Id: string,
    phase2Id: string,
    shareLink: string
) => {
    return db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('shareLinks')
        .where('linkUrl', '==', shareLink)
        .get()
        .then((querySnapshot) => {
            const docs: any = [];
            querySnapshot.forEach((doc) =>
                docs.push({ ...doc.data(), id: doc.id })
            );

            const shareLinkDoc = docs[0];

            if (shareLinkDoc) {
                return {
                    success: shareLinkDoc.expirationDate.toDate() > new Date(),
                    shareLinkId: shareLinkDoc.id,
                };
            }
        });
};
