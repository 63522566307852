import './CustomMapboxGeocoder.css';
import React, { useEffect, useRef, useContext, useState } from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
//@ts-ignore
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import { Phase2PageContext } from '../../contexts/Phase2PageContext';
import { awaitMapLoad } from '../../utils';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        geocoderContainer: {
            height: '44px',
            backgroundColor: '#ddd',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            paddingTop: '4px',
            marginTop: '4px',
        },
    })
);

export const CustomMapboxGeocoder = () => {
    const classes = useStyles();
    const geocoderRef = useRef(null);
    const { mapClass } = useContext(Phase2PageContext);
    const [childAppended, setChildAppended] = useState(false);

    useEffect(() => {
        if (geocoderRef.current && !childAppended) {
            awaitMapLoad(mapClass).then(() => {
                const geocoder = new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    mapboxgl: mapboxgl,
                });

                setChildAppended(true);
                const element = geocoder.onAdd(mapClass.map);
                //@ts-ignore
                geocoderRef.current.appendChild(element);
            });
        }
    }, [geocoderRef]); //eslint-disable-line

    useEffect(() => {
        if (childAppended) {
            const div = document.getElementsByClassName(
                'mapboxgl-ctrl-geocoder'
            )[0];

            div.setAttribute('class', 'mapboxgl-ctrl-geocoder');
        }
    }, [childAppended]);

    return (
        <div className={classes.geocoderContainer}>
            <div id="geocoder" ref={geocoderRef}></div>
        </div>
    );
};
