import React, { FunctionComponent } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from '@material-ui/core';

type Props = {
    message: Element | string;
    onClose: () => void;
};

export const CustomAlert: FunctionComponent<Props> = ({ message, onClose }) => {
    return (
        <Dialog open={true} onClose={onClose}>
            <DialogContent>
                <Typography variant="h6">{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    fullWidth
                    color="primary"
                    variant="contained"
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};
