import { db } from '../firebase';

export const createOrganizationDefaultTemporaryUser = (
    orgId: string,
    tempUserId: string
) => {
    return db
        .collection('organizations')
        .doc(orgId)
        .collection('users')
        .doc(tempUserId)
        .set({ organizationAccessLevel: 'Temp', id: tempUserId })
        .then(() => {
            console.log(
                'createOrganizationDefaultTemporaryUser user correctly set!'
            );
        });
};
