import React, { useContext, useEffect } from 'react';

//MaterialUI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton';
import { Phase2PageContext } from '../../contexts/Phase2PageContext';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperTileset: {
            padding: theme.spacing(1, 1, 1, 1),
            margin: theme.spacing(0.5, 0, 0.5, 0),
            cursor: 'pointer',
        },
        divCenterName: {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
        },
    })
);

type ShortTileset = {
    id: string;
    label: string;
    sourceName: string;
    tilesetId: string;
    index: number;
    center: number[];
    tilesetDocId?: string;
};

export default function TilesetsListSidebarComponent() {
    const classes = useStyles();
    const accessLevel = useSelector((state: RootState) => {
        const orgUser = state.organization.orgUser;
        if (orgUser && orgUser !== 'not_initialized') {
            return orgUser.organizationAccessLevel;
        }
    });
    const { mapClass, filteredTilesets, storeMoveUp, storeMoveDown } =
        useContext(Phase2PageContext);
    const [state, setState] = React.useState<{ [key: string]: boolean }>({});
    const [shortTilesets, setShortTilesets] = React.useState<
        Array<ShortTileset>
    >([]);

    const [map, setMap] = React.useState(mapClass.map);

    useEffect(() => {
        if (!map) {
            setMap(mapClass.map);
        }
    }, [mapClass.map]); //eslint-disable-line

    useEffect(() => {
        const shortTilesets = filteredTilesets
            .sort((ts1, ts2) => ts1.index - ts2.index)
            .map((ts, index) => {
                return {
                    id: index.toString(),
                    label: ts.mapType,
                    center: ts.center,
                    sourceName: `raster-layer-${ts.tilesetId}`,
                    tilesetId: `${ts.tilesetId}`,
                    tilesetDocId: ts.tilesetDocId,
                    index,
                };
            });

        setShortTilesets(shortTilesets);
    }, [filteredTilesets]);

    useEffect(() => {
        let state: any = {};

        shortTilesets.forEach((ts) => {
            state[ts.tilesetId] = true;
        });

        setState(state);
    }, [shortTilesets]);

    const hideLayer = (sourceName: string) => {
        map.setLayoutProperty(sourceName, 'visibility', 'none');
    };

    const showLayer = (sourceName: string) => {
        map.setLayoutProperty(sourceName, 'visibility', 'visible');
    };

    const flyTo = (tileset: ShortTileset) => {
        const [lat, lng] = tileset.center;
        map.flyTo({
            center: [lat, lng],
            zoom: 16,
        });
    };

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        tileset: ShortTileset
    ) => {
        if (event.target.checked) {
            showLayer(tileset.sourceName);
        } else {
            hideLayer(tileset.sourceName);
        }
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const moveUp = (tileset: ShortTileset) => {
        if (tileset.index === 0) return;

        const aboveTS = shortTilesets.find(
            (ts) => ts.index === tileset.index - 1
        );

        if (!aboveTS) {
            return;
        }

        map.moveLayer(aboveTS.sourceName, tileset.sourceName);
        storeMoveUp(tileset, aboveTS);
    };

    const moveDown = (tileset: ShortTileset) => {
        if (tileset.index === shortTilesets.length - 1) return;

        const belowTS = shortTilesets.find(
            (ts) => ts.index === tileset.index + 1
        );

        if (!belowTS) {
            return;
        }

        map.moveLayer(tileset.sourceName, belowTS.sourceName);
        storeMoveDown(tileset, belowTS);
    };

    return (
        <React.Fragment>
            {shortTilesets
                .sort((ts1, ts2) => ts1.index - ts2.index)
                .map((tileset) => {
                    return (
                        <Grid key={tileset.id} item xs={12}>
                            <Paper className={classes.paperTileset}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={7}
                                        onClick={() => flyTo(tileset)}
                                    >
                                        <div className={classes.divCenterName}>
                                            <Typography variant="body1">
                                                <strong>{tileset.label}</strong>
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {accessLevel !== 'Guest' &&
                                            accessLevel !== 'Temp' && (
                                                <>
                                                    <IconButton
                                                        disabled={
                                                            tileset.index === 0
                                                        }
                                                        size="small"
                                                        onClick={() =>
                                                            moveUp(tileset)
                                                        }
                                                    >
                                                        <ArrowUpwardIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        disabled={
                                                            tileset.index ===
                                                            shortTilesets.length -
                                                                1
                                                        }
                                                        size="small"
                                                        onClick={() =>
                                                            moveDown(tileset)
                                                        }
                                                    >
                                                        <ArrowDownwardIcon />
                                                    </IconButton>
                                                </>
                                            )}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Switch
                                            size="small"
                                            checked={
                                                state[tileset.tilesetId] !==
                                                undefined
                                                    ? state[tileset.tilesetId]
                                                    : true
                                            }
                                            onChange={(e) =>
                                                handleChange(e, tileset)
                                            }
                                            name={tileset.tilesetId}
                                            inputProps={{
                                                'aria-label':
                                                    'secondary checkbox',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    );
                })}
        </React.Fragment>
    );
}
