import React from 'react';

//Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
// import { OrgUser } from '../../types/organization';
import { createOrgUser } from './createOrgUser';
import { uploadOrgUser } from './uploadOrgUser';

//Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { OutlinedInput } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '20vw',
            minWidth: '270px',
        },
        option: {
            height: '10vh',
        },
        formControl: {
            marginTop: theme.spacing(1),
            minWidth: 120,
        },
        divProgress: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
    })
);

type SelectElementChange = { name?: string | undefined; value: unknown };

export default function AddMemberBoard() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        resetState();
    };

    const classes = useStyles();
    const [role, setRole] = React.useState<any>('');
    const [email, setEmail] = React.useState<string>('');
    const [nome, setNome] = React.useState<string>('');
    const [roleOptions, setRoleOptions] = React.useState<Array<string>>([]);
    const [onCreatingUser, setOnCreatingUser] = React.useState(false);

    const orgId: string = useSelector(
        (state: RootState) => state.organization.orgId
    );
    const userId = useSelector((state: RootState) => state.auth.userId);

    React.useEffect(() => {
        // const accessLevel: string = orgUser.organizationAccessLevel;
        let roles: Array<string> = [];

        // if (accessLevel === 'Admin') {
        //   roles = ['Admin', 'Staff/Partner', 'Guest'];
        // }
        roles = ['Admin', 'Staff/Partner', 'Guest'];

        setRoleOptions(roles);
    }, []);

    const handleRoleChange = (
        event: React.ChangeEvent<SelectElementChange>
    ) => {
        const role = event.target.value;
        setRole(role);
    };

    const resetState = () => {
        setEmail('');
        setRole('');
        setNome('');
        setOnCreatingUser(false);
    };

    const onCreateClick = async () => {
        setOnCreatingUser(true);
        if (userId === null) {
            return alert('Error!! User not authenticated!');
        }

        const response = await createOrgUser(orgId, email, userId);
        // console.log('response', response);
        // console.log('typeof response', typeof response);

        if (response.success) {
            uploadOrgUser(
                role,
                orgId,
                email,
                response.userId,
                nome,
                response.password
            ).then((error) => {
                if (!error) {
                    alert('Usuário cadastrado como: ' + role);
                    handleClose();
                }
            });
        }
    };

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                size="large"
                // className={classes.button}
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
                fullWidth
            >
                <FormattedMessage
                    id="app.addMember"
                    defaultMessage="Adicionar Membro"
                />
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">
                    <FormattedMessage
                        id="app.addMember"
                        defaultMessage="Adicionar Membro"
                    />
                </DialogTitle>
                <DialogContent>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="component-outlined">
                            <FormattedMessage
                                id="app.name"
                                defaultMessage="Nome"
                            />
                        </InputLabel>
                        <OutlinedInput
                            id="component-outlined"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                            label="Name"
                        />
                    </FormControl>
                    <Box my={2}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="component-outlined">
                                <FormattedMessage
                                    id="app.email"
                                    defaultMessage="E-mail"
                                />
                            </InputLabel>
                            <OutlinedInput
                                id="component-outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                label="E-mail"
                            />
                        </FormControl>
                    </Box>

                    <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            <FormattedMessage
                                id="app.role"
                                defaultMessage="Função"
                            />
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={role}
                            onChange={handleRoleChange}
                            label="Role  "
                        >
                            {roleOptions.map((option) => {
                                return (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        {onCreatingUser ? (
                            <Grid item xs={12}>
                                <div className={classes.divProgress}>
                                    <LinearProgress />
                                </div>
                            </Grid>
                        ) : (
                            <React.Fragment>
                                <Grid item xs={4}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        onClick={handleClose}
                                        color="primary"
                                    >
                                        <FormattedMessage
                                            id="app.cancel"
                                            defaultMessage="Cancelar"
                                        />
                                    </Button>
                                </Grid>
                                <Grid item xs={8}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        onClick={onCreateClick}
                                        color="primary"
                                    >
                                        <FormattedMessage
                                            id="app.add"
                                            defaultMessage="Adicionar"
                                        />
                                    </Button>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}
