import { auth } from './../../firebase/firebase';

export const signIn = (email: string, password: string) => {
    return auth
        .signInWithEmailAndPassword(email, password)
        .then((credential) => {
            if (credential.user) {
                console.log(
                    'The user has been successfully signed in! ' +
                        credential.user.uid
                );
            }
        })
        .catch((error) => {
            throw error;
        });
};
