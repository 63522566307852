import { FunctionComponent, useEffect, useState } from 'react';
import { Upload } from '@aws-sdk/lib-storage';
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@material-ui/core';
import { useMbxProcessWatcher } from './useMbxProcessWatcher';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { S3Watcher } from './S3Watcher';
import ClearIcon from '@material-ui/icons/Clear';
import { MapboxUploadWatcher } from './MapboxUploadWatcher';
import { MapboxProcessingWatcher } from './MapboxProcessingWatcher';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fullWidth: {
            width: '100%',
        },
        root: {
            flexGrow: 1,
            marginBottom: theme.spacing(2),
            position: 'relative',
        },
        container: {
            height: '100%',
        },
        cardHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        closeCard: {
            position: 'absolute',
            cursor: 'pointer',
            right: '0px',
            opacity: 0.5,
            '&:hover': {
                opacity: 1,
            },
            // float: 'right',
            // marginTop: '5px',
            // marginRight: '25px',
            // width: '20px',
        },
        cardContent: {
            margin: 0,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        dividerRight: {
            borderRight: `1px solid ${theme.palette.divider}`,
        },
        subWatcher: {
            height: '100%',
            padding: theme.spacing(2),
        },
        iconButton: {
            padding: '4px',
        },
    })
);

export type ProcessWatcherProps = {
    s3Upload: Upload;
    file: File;
    // metadata: any;
    tilesetDocId: string;
};

type Props = ProcessWatcherProps & {
    removeWatcher: (watcher: any) => void;
};

export const TiffUploadProcessWatcher: FunctionComponent<Props> = ({
    s3Upload,
    file,
    tilesetDocId,
    removeWatcher,
}) => {
    const classes = useStyles();
    const [status, setStatus] = useState(0);
    const processWatcher = useMbxProcessWatcher();

    // SOLUÇÃO BEM TOSCA, SÓ PRA QUESTÕES DE TESTES !!! A REFATORAR!
    const [hasS3UploadFinished, setHasS3UploadFinished] = useState(false);
    const [hasMapboxUploadFinished, setHasMapboxUploadFinished] =
        useState(false);

    const [inConfirmAbort, setInConfirmAbort] = useState(false);

    useEffect(() => {
        if (status === 2) {
            setHasS3UploadFinished(true);
        }
    }, [status]);

    useEffect(() => {
        if (status === 4) {
            setHasMapboxUploadFinished(true);
        }
    }, [status]);

    const abortUpload = () => {
        // TODO: abort upload in s3, mapbox, and firestore
        processWatcher.stop();
        removeWatcher({ tilesetDocId });
    };

    const startAbortUpload = () => {
        console.log(status);

        if (status === 5) {
            abortUpload();
        } else {
            setInConfirmAbort(true);
        }
    };
    const stopAbortUpload = () => {
        setInConfirmAbort(false);
    };

    return (
        <Card className={classes.root}>
            <div className={classes.closeCard}>
                <IconButton
                    className={classes.iconButton}
                    color="primary"
                    onClick={startAbortUpload}
                >
                    <ClearIcon />
                </IconButton>
                <Dialog open={inConfirmAbort && !(status === 5)}>
                    <DialogTitle>
                        <FormattedMessage
                            id="abortUpload"
                            defaultMessage="Abortar Upload"
                        />
                    </DialogTitle>
                    <DialogContent>
                        <FormattedMessage
                            id="confirmAbortMessage"
                            defaultMessage="Se clicar em abortar esse mapa não será carregado!"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={stopAbortUpload}
                        >
                            <FormattedMessage
                                id="app.cancel"
                                defaultMessage="Cancelar"
                            />
                        </Button>
                        <Box flexGrow={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={abortUpload}
                            >
                                <FormattedMessage
                                    id="app.abort"
                                    defaultMessage="Abortar"
                                />
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            </div>
            <Grid container spacing={1} alignItems="stretch">
                <Grid item xs={4}>
                    <div
                        className={clsx(
                            classes.subWatcher,
                            classes.dividerRight
                        )}
                    >
                        <S3Watcher
                            s3Upload={s3Upload}
                            file={file}
                            uploadStatus={status}
                            setUploadStatus={setStatus}
                        />
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div
                        className={clsx(
                            classes.subWatcher,
                            classes.dividerRight
                        )}
                    >
                        <MapboxUploadWatcher
                            hasS3UploadFinished={hasS3UploadFinished}
                            tilesetDocId={tilesetDocId}
                            processWatcher={processWatcher}
                            setUploadStatus={setStatus}
                        />
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className={classes.subWatcher}>
                        <MapboxProcessingWatcher
                            hasMapboxUploadFinished={hasMapboxUploadFinished}
                            tilesetDocId={tilesetDocId}
                            setUploadStatus={setStatus}
                            processWatcher={processWatcher}
                        />
                    </div>
                </Grid>
            </Grid>
        </Card>
    );
};
