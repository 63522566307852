import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles, Button } from '@material-ui/core';
import { removeField } from '../../firebase/utils/removeField';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    button: {
        textTransform: 'none',
    },
}));

export const RemoveField = ({ field, fields, closePopover, orgId }) => {
    const classes = useStyles();

    const handleRemove = () => {
        removeField(field, fields, orgId);
        closePopover();
    };

    return (
        <div>
            <Button
                size="small"
                className={`${classes.margin} ${classes.button} `}
                startIcon={<DeleteIcon />}
                onClick={handleRemove}
                disabled={
                    field.fieldId === 'name' || field.fieldId === 'createdAt'
                }
            >
                Remover
            </Button>
        </div>
    );
};

const formIdToName = (formId) => {
    switch (formId) {
        case 'phase1FormFields':
            return 'phase1';
        case 'phase2FormFields':
            return 'phase2';
        case 'fieldblocksFormFields':
            return 'fieldblocks';
        default:
            return '';
    }
};

const selectFields = (state, ownProps) => {
    const formName = formIdToName(ownProps.formId);
    return state[formName].formFields;
};

const selectOrgId = (state) => state.organization.orgId;

const mapStateToProps = (state, ownProps) => ({
    fields: selectFields(state, ownProps),
    orgId: selectOrgId(state),
});

export default connect(mapStateToProps)(RemoveField);
