import { downloadAllowedOrgIds } from './../../firebase/utils/downloadAllowedOrgIds';
import { useActions } from './../../formfy/customHooks/useActions';
import { RootState } from './../../store/configureStore';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export const useHasFieldblocksAccess = () => {
    const allowedOrgIds = useSelector(
        (state: RootState) => state.fieldblocks.allowedOrgIds
    );

    const { setAllowedOrgIds } = useActions();
    const currentOrgId = useSelector(
        (state: RootState) => state.organization.orgId
    );

    useEffect(() => {
        if (allowedOrgIds === 'not_initialized') {
            downloadAllowedOrgIds(setAllowedOrgIds);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const hasFieldblocksAccess =
        allowedOrgIds !== 'not_initialized' &&
        allowedOrgIds.includes(currentOrgId);

    return { hasFieldblocksAccess };
};
