const serverUrl =
    // 'http://localhost:5001/drone-report-beta/us-central1/createOrgUser';
    // 'https://us-central1-coopasvtech-drb-dcaa7.cloudfunctions.net/createOrgUser';
    'https://us-central1-novo-coopasvtech.cloudfunctions.net/createOrgUser ';

export const createOrgUser = async (
    orgId: string,
    email: string,
    authId: string
) => {
    try {
        // console.log(
        //     JSON.stringify({
        //         email,
        //         orgId,
        //         authId,
        //     })
        // );

        const response = await fetch(serverUrl, {
            method: 'POST',
            body: JSON.stringify({
                email,
                orgId,
                authId,
            }),
        }).then((res) => res.json());

        // console.log(response, 'response');
        if (response.error) {
            alert(response.error);
            return { success: false };
        }
        return {
            userId: response.uid,
            message: response.message,
            success: true,
            password: response.password,
        };
    } catch (error: any) {
        alert(error.message);
        return { error: error.message };
    }
};
