import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import { useParams } from 'react-router';
import { useParams } from 'react-router';

//Redux
import { useAppSelector } from '../../store/reduxCustomHooks';
import { selectOrgUsers } from '../../features/members/membersSlice';

//Components
import Phase2PaperItemMember from './Phase2PaperItemMember';

//Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
// import AddIcon from '@material-ui/icons/Add';
// import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paperRoot: {
            padding: theme.spacing(1),
        },
        paperMember: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    })
);

// type URLParams = {
//   orgId: string;
// };

// style={{ backgroundColor: 'white' }}

interface MemberItem {
    userId: string;
    orgId: string;
    phase1Id: string;
    phase2Id: string;
    userName: string;
    email: string;
    organizationAccessLevel: string;
    userAlreadyHasAccess: boolean;
}

type URLParams = {
    phase1Id: string;
    phase2Id: string;
    orgId: string;
};

export default function Phase2MembersTablePaper() {
    const classes = useStyles();
    const { orgId, phase1Id, phase2Id }: URLParams = useParams();

    const [membersList, setmembersList] = React.useState<MemberItem[]>([]);
    const orgUsers = useAppSelector(selectOrgUsers);
    console.log('orgUsers', orgUsers);
    console.log('membersList =>', membersList);

    React.useEffect(() => {
        const list: MemberItem[] = [];
        for (const user of orgUsers) {
            if (
                user.organizationAccessLevel !== 'Admin' &&
                user.organizationAccessLevel !== 'Temp'
            ) {
                if (user.phase2UserHasAccess) {
                    const found = user.phase2UserHasAccess.find(
                        (e) => e === phase2Id
                    );
                    if (found) {
                        list.push({
                            userId: user.id,
                            orgId: orgId,
                            phase1Id: phase1Id,
                            phase2Id: phase2Id,
                            userName: user.name,
                            email: user.email,
                            organizationAccessLevel:
                                user.organizationAccessLevel,
                            userAlreadyHasAccess: true,
                        });
                    }
                }
            }
        }
        setmembersList(list);
    }, [orgUsers, orgId, phase1Id, phase2Id]);

    return (
        <Paper className={classes.paperRoot}>
            <div className={classes.root}>
                <Grid container spacing={0}>
                    <Grid container item xs={12}>
                        <Grid item xs={4}>
                            <Box paddingLeft={1}>
                                <Typography variant="h6" align="left" noWrap>
                                    <FormattedMessage
                                        id="app.name"
                                        defaultMessage="Nome"
                                    />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box paddingLeft={1}>
                                <Typography variant="h6" align="left" noWrap>
                                    <FormattedMessage
                                        id="app.email"
                                        defaultMessage="E-mail"
                                    />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box paddingLeft={0.5}>
                                <Typography variant="h6" align="left" noWrap>
                                    <FormattedMessage
                                        id="app.accessLevel"
                                        defaultMessage="Nível de acessso"
                                    />
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {membersList.map((user) => {
                            return (
                                <Phase2PaperItemMember
                                    key={user.userId}
                                    user={user}
                                />
                            );
                        })}
                    </Grid>
                </Grid>
            </div>
        </Paper>
    );
}
