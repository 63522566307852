import { FormField } from './../../types/formField';
import db from '../../../firebase/firebase';

export const storePhase1Field = (field: FormField, orgId: string) => {
    return db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1FormFields')
        .add(field)
        .then((docRef) => docRef.id)
        .catch((err) => console.log(err));
};
