import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import { GeneralLoginPage } from '../pages/GeneralLoginPage';
import { RootState } from '../store/configureStore';

export const GeneralRoute = ({ component, ...rest }: any) => {
    const userId = useSelector((state: RootState) => state.auth.userId);

    return (
        <div>
            {userId ? (
                <Route component={component} {...rest} />
            ) : (
                <Route component={GeneralLoginPage} {...rest} />
            )}
        </div>
    );
};
