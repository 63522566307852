import React, { FunctionComponent, useEffect, useState } from 'react';
import './Timeline.css';

//internationalization
import { FormattedDate } from 'react-intl';

//Components
import TimelineDialog from './TimelineDialog';

//MaterialUI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import { useActions } from '../../hooks/useActions';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { useDatesInMilis } from './useDatesInMilis';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            display: 'inline-block',
            padding: theme.spacing(1),
        },
        ArrowBackButton: {
            marginRight: theme.spacing(1),
        },
        ArrowForwardButton: {
            marginLeft: theme.spacing(1),
        },
        divCenter: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    })
);

type Props = {
    // selectedDate: Date;
    // setSelectedDate: (date: Date) => void;
};

export const Timeline: FunctionComponent<Props> = () => {
    const classes = useStyles();
    //const [datesInMilis, setDatesInMilis] = useState<number[]>([]);
    const { datesInMilis } = useDatesInMilis();
    const { setSelectedDate } = useActions();
    const [dateIndex, setDateIndex] = useState(-1);
    const selectedDate = useSelector(
        (state: RootState) => state.timeline.selectedDate
    );

    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (selectedDate !== 'not_sure') {
            const idx = datesInMilis.indexOf(selectedDate.getTime());

            if (idx !== -1) {
                setDateIndex(idx);
            } else {
                setDateIndex(0);
            }
        }
    }, [datesInMilis, selectedDate]); //eslint-disable-line

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value: any) => {
        setOpen(false);
        setSelectedDate(value);
    };

    const forwardDate = () => {
        if (selectedDate !== 'not_sure') {
            const idx = datesInMilis.indexOf(selectedDate.getTime());
            if (idx !== -1 && idx < datesInMilis.length - 1) {
                setSelectedDate(new Date(datesInMilis[idx + 1]));
                setDateIndex(idx + 1);
            }
        }
    };

    const backwardDate = () => {
        if (selectedDate !== 'not_sure') {
            const idx = datesInMilis.indexOf(selectedDate.getTime());
            if (idx !== -1 && idx > 0) {
                setSelectedDate(new Date(datesInMilis[idx - 1]));
                setDateIndex(idx - 1);
            }
        }
    };

    return (
        // <div className='sidebarStyle'>
        <div>
            <div className={classes.root}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={classes.divCenter}>
                            <Fab
                                className={classes.ArrowBackButton}
                                color="primary"
                                aria-label="Back Date"
                                disabled={dateIndex === 0}
                                size="large"
                                onClick={backwardDate}
                            >
                                <ArrowBackIcon />
                            </Fab>

                            <Button
                                variant="contained"
                                color="default"
                                onClick={handleClickOpen}
                            >
                                <FormattedDate
                                    value={selectedDate}
                                    year="numeric"
                                    month="short"
                                    day="numeric"
                                    //weekday="long"
                                />
                            </Button>
                            <TimelineDialog
                                selectedDate={
                                    selectedDate !== 'not_sure'
                                        ? selectedDate
                                        : new Date()
                                }
                                open={open}
                                onClose={handleClose}
                            />
                            <Fab
                                className={classes.ArrowForwardButton}
                                color="primary"
                                aria-label="Forward Date"
                                disabled={dateIndex === datesInMilis.length - 1}
                                size="large"
                                onClick={forwardDate}
                            >
                                <ArrowForwardIcon />
                            </Fab>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
