import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, {
    ChangeEvent,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { FormField, FormFieldTypes } from '../../formfy/types';

type Props = {
    field: FormField;
    value: FormFieldTypes | null;
    onChange: (value: FormFieldTypes) => void;
};

type ChecklistState = {
    [key: string]: boolean;
};

export const ChecklistFormField: FunctionComponent<Props> = ({
    field,
    value,
    onChange,
}) => {
    const [checklistState, setChecklistState] = useState<ChecklistState>({});

    useEffect(() => {
        // console.log('reexecute <|||-||-||-||-||-||-||-||-||-||-||-|||> wicked!');
        let initialState: ChecklistState = {};

        if (field.checklistItems) {
            field.checklistItems.forEach((item: string) => {
                initialState[item] =
                    value instanceof Array && value.includes(item);
            });
        }

        setChecklistState(initialState);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
        let newState = { ...checklistState, [e.target.name]: e.target.checked };
        setChecklistState(newState);

        const checkedArray: Array<string> = [];
        Object.entries(newState).forEach(([key, value]) => {
            if (value === true) {
                checkedArray.push(key);
            }
        });

        // console.log(checkedArray);
        // handleAddStateChange(field.fieldId, checkedArray);
        onChange(checkedArray);
    };

    return (
        <div>
            <h3>
                <strong>{field.name}</strong>
            </h3>
            <div>
                {field.checklistItems &&
                    field.checklistItems.map((item: string) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    // checked={state.checkedA}
                                    value={item}
                                    onChange={handleCheck}
                                    name={item}
                                    checked={checklistState[item] || false}
                                    // checked={isChecked}
                                    // checked={checked.includes(item)}
                                />
                            }
                            label={item}
                            key={item}
                        />
                    ))}
            </div>
        </div>
    );
};
