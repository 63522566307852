import { setCenterAndBounds } from './setCenterAndBounds';
import { RootState } from '../../store/configureStore';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import mbxClient from '@mapbox/mapbox-sdk';
import mbxUploads from '@mapbox/mapbox-sdk/services/uploads';
//import mbxTilesets from '@mapbox/mapbox-sdk/services/tilesets';

const accessToken =
    //'sk.eyJ1IjoiZGltaXRyZW9ydHQiLCJhIjoiY2tuZnNkZHd4MDVlbjJ3czNpY3YzOG9pbiJ9.ut7s_WHUQiO4fkCvb2t8aA';
    'sk.eyJ1IjoiZHJvbmVyZXBvcnRiZXRhMSIsImEiOiJja285OXJidWgyMzhyMnVscnhiYTZpaHZ1In0.bslMCUJSUKJEjxzhNxVGhw';

const baseClient = mbxClient({ accessToken });
const uploadsClient = mbxUploads(baseClient);
//const tilesetsService = mbxTilesets(baseClient);

type Params = {
    orgId: string;
    phase1Id: string;
    phase2Id: string;
};

export const useMbxProcessWatcher = () => {
    const [uploadId, setUploadId] = useState('');
    const [tilesetId, setTilesetId] = useState('');
    const timerRef = useRef<number>();
    const [active, setActive] = useState(false);
    const [progress, setProgress] = useState(0);
    const [complete, setComplete] = useState(false);
    const { orgId, phase1Id, phase2Id } = useParams<Params>();

    const tileset = useSelector((state: RootState) => {
        if (state.tilesets.tilesets !== 'not_initialized') {
            return state.tilesets.tilesets.find(
                (ts) => ts.tilesetId === tilesetId
            );
        }
    });

    useEffect(() => {
        if (active) {
            const timerId: any = setInterval(() => {
                uploadsClient
                    .getUpload({
                        uploadId,
                    })
                    .send()
                    .then((response: any) => {
                        // console.log(response);

                        const { progress, complete, tileset } = response.body;
                        if (!tilesetId) {
                            setTilesetId(tileset);
                        }
                        setProgress(progress);
                        setComplete(complete);
                        if (progress === 1) {
                            stop();
                        }
                    });
            }, 5 * 1000); // change this to proper number of seconds

            timerRef.current = timerId;
        } else {
            clearInterval(timerRef.current);
        }
    }, [active]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (complete) {
            if (tileset && tileset.tilesetDocId) {
                const { tilesetId, tilesetDocId } = tileset;
                setCenterAndBounds(
                    orgId,
                    phase1Id,
                    phase2Id,
                    tilesetId,
                    tilesetDocId
                );
            }
        }
    }, [complete]); // eslint-disable-line react-hooks/exhaustive-deps

    const start = () => {
        setActive(true);
    };

    const stop = () => {
        setActive(false);
    };

    return { start, stop, setUploadId, progress, complete };
};
