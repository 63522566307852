import { setAllowedOrgIdsType } from './../../formfy/features/fieldblocks/fieldblocksSlice';
import { db } from '../firebase';

export const downloadAllowedOrgIds = (
    setAllowedOrgIds: setAllowedOrgIdsType
) => {
    db.collection('fieldblocksFormFy')
        .doc('accessList')
        .onSnapshot((doc) => {
            const data = doc.data();
            if (data) {
                setAllowedOrgIds(data.allowedOrgIds);
            }
        });
};
