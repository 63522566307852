import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles, Popover, Paper } from '@material-ui/core';
import EditField from './EditField';
// import MoveFieldUp from './MoveFieldUp';
// import MoveFieldDown from './MoveFieldDown';
import RemoveField from './RemoveField';

const useStyles = makeStyles((theme) => ({
    optionsPopover: {
        padding: theme.spacing(2),
    },
}));

export const FieldOptions = ({ field, formId }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // console.log(field, formId);

    return (
        <>
            <IconButton
                color="primary"
                aria-label="edit field"
                component="span"
                onClick={handleClick}
            >
                <MoreHorizIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Paper className={classes.optionsPopover}>
                    <EditField field={field} closePopover={handleClose} />
                    {/* <MoveFieldUp
            field={field}
            closePopover={handleClose}
            formId={formId}
          />
          <MoveFieldDown
            field={field}
            closePopover={handleClose}
            formId={formId}
          /> */}
                    <RemoveField
                        field={field}
                        closePopover={handleClose}
                        formId={formId}
                    />
                </Paper>
            </Popover>
        </>
    );
};

// export default connect(null, mapDispatchToProps)(FieldOptions);
export default FieldOptions;
