// import { IOrganizationPublic } from './../types/organization';
import { useActions } from './useActions';
import { useEffect, useState } from 'react';
import { db } from './../firebase/firebase';

export const useDownloadOrgInfo = (orgId: string) => {
    const { setOrgPublicInfo, resetOrgPublicInfo } = useActions();
    const { setOrgId } = useActions();
    const [loading, setLoading] = useState(true);
    const [isOrg, setIsOrg] = useState(false);

    useEffect(() => {
        db.collection('organizations')
            .doc(orgId)
            .collection('organizationInfo')
            .doc('publicInfo')
            .get()
            .then((docRef) => {
                if (docRef.exists) {
                    const publicInfo: any = docRef.data();
                    setOrgPublicInfo(publicInfo);
                    setOrgId(orgId);
                    setIsOrg(true);
                    // setDocumentTitle(publicInfo.shortOrganizationName);
                } else {
                    alert('Id provided does not refer to an organization!');
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });

        return () => {
            resetOrgPublicInfo(undefined);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return [loading, isOrg];
};
