import React from 'react';

//Redux
import { useAppDispatch } from '../../store/reduxCustomHooks';
import { AsyncRemovePhase2UserAcces } from '../members/membersSlice';

//Types
import { UserHasAccess } from '../../types/user';

//Hooks
import useGetPhase1AndPhase2Names from '../members/useGetPhase1AndPhase2Names';

//MaterialUI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: theme.spacing(0.5),
            margin: 0,
        },
        chip: {
            margin: theme.spacing(0.5),
        },
    })
);

type Props = {
    user: UserHasAccess;
};

interface ChipData {
    phase1Name: string;
    phase2Name: string;
    phase2Id: string;
    phase1Id: string;
    orgId: string;
    // key: number;
    // label: string;
}

export default function Phase2UserHasAccessMembersPage({ user }: Props) {
    const dispatch = useAppDispatch();
    const classes = useStyles();

    const result = useGetPhase1AndPhase2Names({ user });

    const [chipData, setChipData] = React.useState<ChipData[]>([]);

    React.useEffect(() => {
        if (result !== null) {
            setChipData(result);
        }
    }, [result]);

    const handleDelete = (chipToDelete: ChipData) => () => {
        console.log('alo', chipToDelete);

        dispatch(
            AsyncRemovePhase2UserAcces({
                phase1Id: chipToDelete.phase1Id,
                phase2Id: chipToDelete.phase2Id,
                orgId: chipToDelete.orgId,
                userId: user.id,
            })
        );
        setChipData((chips) =>
            chips.filter((chip) => chip.phase2Id !== chipToDelete.phase2Id)
        );
    };

    if (user.organizationAccessLevel === 'Admin') {
        return (
            <Paper variant="outlined" component="ul" className={classes.root}>
                <Chip
                    color="primary"
                    label={
                        <FormattedMessage
                            id="app.adminAccessText"
                            defaultMessage="'Administradores têm acesso a todos os projetos e áreas criadas.'"
                        />
                    }
                    // onDelete={false}
                    className={classes.chip}
                />
            </Paper>
        );
    }

    return (
        <React.Fragment>
            <Paper variant="outlined" component="ul" className={classes.root}>
                {chipData.map((data) => {
                    return (
                        <li key={data.phase2Id}>
                            <Chip
                                color="primary"
                                label={`${data.phase1Name} / ${data.phase2Name}`}
                                onDelete={handleDelete(data)}
                                className={classes.chip}
                            />
                        </li>
                    );
                })}
            </Paper>
        </React.Fragment>
    );
}
