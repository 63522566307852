export * from './awaitMapLoad';

export const dateToMilis = (date: Date) => {
    // let milis = new Date(date.getTime());
    const milis = date.setHours(0, 0, 0, 0);
    return milis;
};

export const formatSizeUnits = (bytes: number) => {
    let sizeStr = '';

    if (bytes >= 1073741824) {
        sizeStr = (bytes / 1073741824).toFixed(2) + ' GB';
    } else if (bytes >= 1048576) {
        sizeStr = (bytes / 1048576).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
        sizeStr = (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes > 1) {
        sizeStr = bytes + ' bytes';
    } else if (bytes === 1) {
        sizeStr = bytes + ' byte';
    } else {
        sizeStr = '0 bytes';
    }
    return sizeStr;
};

export const round = (value: number, decimals: number) => {
    return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

export const setDocumentTitle = (organizationShortName: string) => {
    console.log('aousjdiausdh', organizationShortName);
    document.title = organizationShortName + ' | DroneReport';
};

export const makeId = (length: number) => {
    var result: any = [];
    var characters =
        // 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        'abcdefghijklmnopqrstuvwxyz0123456789';

    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result.push(
            characters.charAt(Math.floor(Math.random() * charactersLength))
        );
    }
    return result.join('');
};
