import React, { FunctionComponent, useEffect, useState } from 'react';
import { Registry } from '../../formfy/types';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Typography,
    Card,
    CardMedia,
    CardContent,
    CardActionArea,
} from '@material-ui/core';
import { getAvatarDownloadUrl } from '../../firebase/storage/getAvatarDownloadUrl';

import { Link as RouterLink, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // maxWidth: 345,
        },
    })
);

type Props = {
    phase1Registry: Registry;
    phase1FieldIdsToNames: { [key: string]: string };
};

export const NewPhase1Card: FunctionComponent<Props> = ({ phase1Registry }) => {
    const location = useLocation();
    const classes = useStyles();
    const defaultResponse = 'A definir';

    const [avatarUrl, setAvatarUrl] = useState(
        'https://firebasestorage.googleapis.com/v0/b/novo-coopasvtech.appspot.com/o/avatars%2Fno-img.png?alt=media&token=02cbe43e-0a47-4636-a742-be5baa3f22e2'
    );

    const removeNonDigits = (inputString: string): string => {
        // Use a regular expression to match non-digit characters (anything that is not 0-9)
        const regex = /[^0-9]/g;
        // Use the replace method to remove all non-digit characters and return the result
        return inputString.replace(regex, '');
    };

    const brazilianPhoneNumberMask = (phone: any) => {
        if (!phone || typeof phone !== 'string') {
            return defaultResponse;
        }

        const phoneNumbers = removeNonDigits(phone);

        const firstTwoDigits = phoneNumbers.slice(0, 2);
        const midFiveDigits = phoneNumbers.slice(2, 7);
        const lastFourDigits = phoneNumbers.slice(7);

        const phoneMask = `(${firstTwoDigits}) ${midFiveDigits}-${lastFourDigits}`;

        if (phoneMask.length === 15) {
            return phoneMask;
        }

        return defaultResponse;
    };

    const cpfMask = (cpf: any) => {
        if (!cpf) {
            return defaultResponse;
        }
        const cpfNumbers = removeNonDigits(cpf);
        const firstThree = cpfNumbers.slice(0, 3);
        const secondThree = cpfNumbers.slice(3, 6);
        const thirdThree = cpfNumbers.slice(6, 9);
        const lastTwo = cpfNumbers.slice(9);
        const cpfMask = `${firstThree}.${secondThree}.${thirdThree}-${lastTwo}`;

        if (cpfMask.length === 14) {
            return cpfMask;
        }

        return defaultResponse;
    };

    useEffect(() => {
        let id = phase1Registry.phase1Id;
        if (id) {
            getAvatarDownloadUrl(id).then((url) => {
                console.log('AVATAR url', url);
                setAvatarUrl(url);
            });
        }
    }, [phase1Registry.phase1Id]);

    return (
        <Card className={classes.root}>
            <CardActionArea
                component={RouterLink}
                to={`${location.pathname.replace(/\/$/, '')}/phase1Id/${
                    phase1Registry.phase1Id
                }`}
            >
                <CardMedia
                    component="img"
                    alt="Produtor"
                    height="300"
                    image={avatarUrl}
                />
                <CardContent>
                    <Typography
                        noWrap
                        gutterBottom
                        variant="body1"
                        component="h2"
                    >
                        {phase1Registry.name}
                    </Typography>
                    <Typography
                        variant="body2"
                        align="left"
                        color="textSecondary"
                        noWrap
                    >
                        CPF: {cpfMask(phase1Registry.cpf)}
                    </Typography>
                    <Typography
                        variant="body2"
                        align="left"
                        color="textSecondary"
                        noWrap
                    >
                        Telefone:{' '}
                        {brazilianPhoneNumberMask(phase1Registry.phoneNumber)}
                    </Typography>
                    <Typography
                        variant="body2"
                        align="left"
                        color="textSecondary"
                        gutterBottom
                        noWrap
                    >
                        Número de Cota: {phase1Registry.codeERPProdutor}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};
