import React, { useState, useEffect } from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { moveFieldUp } from '../../firebase/utils/moveField';
// import { moveFieldUp } from '../../firebase/utils/updateField';

// const useStyles = makeStyles((theme) => ({
//   margin: {
//     margin: theme.spacing(1),
//   },
//   button: {
//     textTransform: 'none',
//   },
// }));

export const MoveFieldUp = ({ field, closePopover, aboveField, orgId }) => {
    // const classes = useStyles();
    const [pending, setPending] = useState(false);

    useEffect(() => {
        if (pending) setTimeout(() => setPending(false), 1000);
    }, [pending]);

    const moveUp = () => {
        if (aboveField) {
            moveFieldUp(field, aboveField, orgId);
        }
        closePopover();
    };

    return (
        // <div>
        <IconButton
            size="small"
            color="primary"
            // className={`${classes.margin} ${classes.button} `}
            // startIcon={<ArrowUpwardIcon />}
            onClick={moveUp}
            disabled={field.idx === 0}
        >
            {/* Mover para cima */}
            <ArrowUpwardIcon />
        </IconButton>
        // </div>
    );
};

const formIdToName = (formId) => {
    switch (formId) {
        case 'phase1FormFields':
            return 'phase1';
        case 'phase2FormFields':
            return 'phase2';
        case 'fieldblocksFormFields':
            return 'fieldblocks';
        default:
            return '';
    }
};

const selectAboveField = (state, idx, ownProps) => {
    // console.log(state, idx, ownProps);
    const formName = formIdToName(ownProps.formId);
    return idx > 0 ? state[formName].formFields[idx - 1] : undefined;
};

const selectOrgId = (state) => state.organization.orgId;

const mapStateToProps = (state, ownProps) => ({
    aboveField: selectAboveField(state, ownProps.field.idx, ownProps),
    orgId: selectOrgId(state),
});

export default connect(mapStateToProps)(MoveFieldUp);
