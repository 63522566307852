import { downloadPhase1NameAndCreateAt } from './../firebase/utils/downloadPhase1NameAndCreatedAt';
import { RootState } from './../../store/configureStore';
import { useSelector } from 'react-redux';
import { Phase1Info } from './../types/phase1';
import { useParams } from 'react-router-dom';
import { useActions } from './useActions';
import { useEffect } from 'react';

type Phase1Params = {
    orgId: string;
    phase1Id: string;
};

export const usePhase1NameAndCreatedAt = () => {
    const { orgId, phase1Id }: Phase1Params = useParams();

    const phase1Info: Phase1Info | null = useSelector((state: RootState) => {
        return state.phase1.selectedPhase1;
    });

    const orgUser = useSelector((state: RootState) => {
        return state.organization.orgUser;
    });

    const { setPhase1NameAndCreatedAt } = useActions();

    useEffect(() => {
        console.log(orgUser);

        downloadPhase1NameAndCreateAt({
            orgId,
            phase1Id,
            setPhase1NameAndCreatedAt,
        });
    }, [orgUser]); //eslint-disable-line

    return { phase1Info };
};
