import { createSlice } from '@reduxjs/toolkit';

type Form = any;

type SliceState = Form[] | 'not_initialized';

// First approach: define the initial state using that type
const initialState: SliceState = 'not_initialized';

const formsSlice = createSlice({
    name: 'formsSlice',
    initialState,
    reducers: {
        setForms: (state, action) => {
            return action.payload;
        },
    },
});

export const actions = formsSlice.actions;
export const { setForms } = formsSlice.actions;
export type setFormsType = typeof setForms;
export default formsSlice.reducer;
