// AWS
import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';

type S3ObjectMetadata =
    | {
          [key: string]: string;
      }
    | undefined;

const REGION = 'sa-east-1';
// const IDENTITY_POOL_ID = 'sa-east-1:6ff1f0d2-8543-4509-89a9-d87f9edc2799';
const IDENTITY_POOL_ID = 'sa-east-1:4d128829-10d6-43ad-bc21-ac11828969ab';

// Initialize the Amazon Cognito credentials provider
const s3 = new S3Client({
    region: REGION,
    credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: REGION }),
        identityPoolId: IDENTITY_POOL_ID, //
    }),
});

const bucketName = 'coopasvtech-drb';

export const uploadTiff = async (
    tiffFile: File,
    metadata: S3ObjectMetadata
) => {
    const tiffName = tiffFile.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

    const tiffKey = encodeURIComponent(tiffName);

    //@ts-ignore
    const { tilesetrandomid } = metadata;

    const key = 'tiffs/' + tilesetrandomid + '.' + tiffKey;

    const params = {
        Bucket: bucketName,
        Key: key,
        Body: tiffFile,
        Metadata: metadata,
    };

    const s3Upload = new Upload({
        client: s3,
        params,
    });

    return s3Upload;
};
