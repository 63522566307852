import { Registry } from './../../types/registry';
import db from '../../../firebase/firebase';

export const storeFieldblockRegistry = (
    orgId: string,
    registry: Registry,
    phase1Id: string,
    phase2Id: string,
    fieldblockId: string | undefined
) => {
    const collectionRef = db
        .collection('organizations')
        .doc(orgId)
        .collection('phase1')
        .doc(phase1Id)
        .collection('phase2')
        .doc(phase2Id)
        .collection('fieldblocks');

    console.log('fieldblockId iwh');

    const docRef = fieldblockId
        ? collectionRef.doc(fieldblockId)
        : collectionRef.doc();

    const promise = fieldblockId
        ? docRef.update({ registry })
        : docRef.set({ registry });

    return promise
        .then(() => docRef.id)
        .catch((err) => {
            console.log(err);
            alert('Error!! Não foi possível salvar o registro de Fieldblocks!');
        });
};
