import React, { useEffect, useState } from 'react';

//Components
import IconList from './IconList';

//internationalization
import { FormattedDate, FormattedMessage } from 'react-intl';

//MaterialUI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
// import { Tileset } from '../../types/tileset';
// import { nanoid } from 'nanoid';
import { makeFixturesByDate } from './makeFixturesByDate';

export interface SimpleDialogProps {
    open: boolean;
    selectedDate: Date;
    onClose: (value: Date) => void;
}

type DatesObjectList = {
    date: Date;
    maps: boolean;
    photos: boolean;
    annotations: boolean;
    attachments: boolean;
    id: number;
}[];

export default function TimelineDialog(props: SimpleDialogProps) {
    const { onClose, selectedDate, open } = props;

    const tilesets = useSelector((state: RootState) => state.tilesets.tilesets);
    const links = useSelector((state: RootState) => state.links.links);
    const photos = useSelector((state: RootState) => state.photos.photos);
    const annotations = useSelector(
        (state: RootState) => state.annotations.annotations
    );
    const [datesObjectList, setDatesObjectList] = useState<DatesObjectList>([]);
    const [ableToStart, setAbleToStart] = useState(false);

    useEffect(() => {
        if (
            tilesets !== 'not_initialized' &&
            annotations !== 'not_initialized' &&
            links !== 'not_initialized' &&
            photos !== 'not_initialized'
        ) {
            setAbleToStart(true);
        }
    }, [tilesets, annotations, links, photos, selectedDate]);

    useEffect(() => {
        if (ableToStart) {
            const datesObjectList: DatesObjectList = makeFixturesByDate(
                //@ts-ignore
                tilesets,
                annotations,
                links,
                photos
            );
            setDatesObjectList(datesObjectList);
        }
    }, [ableToStart, tilesets, annotations, links, photos]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClose = () => {
        onClose(selectedDate);
    };

    const handleListItemClick = (value: Date) => {
        onClose(value);
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                <FormattedMessage
                    id="app.choose-date"
                    defaultMessage="Escolha uma data"
                />
            </DialogTitle>
            <List>
                {datesObjectList.map((dateObj: any) => (
                    <ListItem
                        button
                        onClick={() => handleListItemClick(dateObj.date)}
                        key={dateObj.id}
                    >
                        <ListItemText
                            primary={
                                <FormattedDate
                                    value={dateObj.date}
                                    year="numeric"
                                    month="short"
                                    day="numeric"
                                />
                            }
                        />
                        <IconList dateObj={dateObj} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}
